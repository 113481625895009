import React, { useState } from 'react'
import { ShareOverlay } from '../'
import Share from 'svgs/share/share'
import { isMobileOS } from '../../lib/userAgent'

import styles from './sharebutton.css'

const ShareButton = ({ shareDialog, overlay }) => {
  const [showFallback, setShowFallback] = useState(false)
  return (
    <div className={styles.container}>
      <button
        className={styles.button}
        onClick={() => {
          if (navigator.share && isMobileOS()) {
            navigator
              .share({
                title: document.title,
                url: document.location.href,
                text: shareDialog
              })
              .catch(() => {
                setShowFallback(!showFallback)
              })
          } else {
            setShowFallback(!showFallback)
          }
        }}
      >
        <Share />
      </button>
      {showFallback && (
        <ShareOverlay
          shareDialog={shareDialog}
          overlay={overlay}
          setShowFallback={setShowFallback}
        />
      )}
    </div>
  )
}

export default ShareButton
