import { connect } from 'react-redux'
import { trackPageView } from 'actions/analytics'

import DrinksCompleted from 'components/drinkcompleted/drinkcompleted'

function mapStateToProps(state) {
  const customRoutes = state.customRoutes ? state.customRoutes : ''
  return {
    customRoutes
  }
}

export default connect(mapStateToProps)(DrinksCompleted)
