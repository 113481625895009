import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Generic, HeroComponent, MetaData } from 'components'
import { Recommendations } from 'containers'

import styles from './article.css'

class GenericPage extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false
    }
  }

  toggleContactText = () => {
    this.setState({ showText: !this.state.showText })
  }

  render() {
    const { content, trackingVideo, endPoint } = this.props
    const genericComponents = content.components
    const hero = content.hero
    return (
      <main className={styles.article} role="main" id="mainContent">
        <MetaData metadata={content.metadata} />
        {hero && (
          <section className={styles.articleHeader}>
            {hero && hero.contentType === 'heroComponent' ? (
              <section key={hero.title} className={styles.hero}>
                <HeroComponent hero={hero} />
              </section>
            ) : (
              <Generic
                components={hero}
                trackingVideo={trackingVideo}
                key={hero.id}
                endPoint={endPoint}
              />
            )}
          </section>
        )}
        {genericComponents && (
          <section className={styles.articleContainer}>
            <div className={styles.layout}>
              {genericComponents &&
                genericComponents.map((d) => (
                  <Generic
                    components={d}
                    trackingVideo={trackingVideo}
                    key={d.id}
                    endPoint={endPoint}
                  />
                ))}
            </div>
          </section>
        )}
        {content.recommendations && (
          <Recommendations recommendations={content.recommendations} />
        )}
      </main>
    )
  }
}

GenericPage.defaultProps = {
  content: {},
  trackingVideo: () => {},
  endPoint: ''
}
GenericPage.propTypes = {
  content: PropTypes.object,
  endPoint: PropTypes.string,
  shareImage: PropTypes.string,
  trackingVideo: PropTypes.func
}
export default GenericPage
