import React, { Component } from 'react'
import classNames from 'classnames/bind'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Markdown } from 'components'
import {
  send,
  formErrorMessage,
  formFieldsRequired,
  subscribeNo,
  subscribeYes,
  salutationOne,
  salutationTwo,
  salutationThree,
  mailChimpListId,
  charactersCounter,
  date
} from 'utils/i18n'
import fetch from '../../lib/fetch'
import styles from './form.css'
import ContactForm from './ContactForm'

// TODO: there's a lot of overlap between integrated froms and norma forms, we should look at created a component to remove the duplications
class IntegratedForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      renderForm: false,
      thankYou: false,
      submitResult: null,
      submitting: false,
      submittErr: false
    }
  }

  renderForm(id) {
    const arrNum = id - 1
    const formUpdate = {
      arryIndex: arrNum,
      renderForm: true
    }
    this.setState(formUpdate)
  }

  renderThankyouMessage(content) {
    const { thankYou } = this.state
    const css = {
      visible: styles.show
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.thankYou, {
      visible: thankYou
    })
    return (
      <div className={classes}>
        <h2>{content.successTitle}</h2>
        <Markdown markdownHtml={content.successMessage} />
      </div>
    )
  }

  renderPreSubmitMessage(content) {
    const { thankYou } = this.state
    const css = {
      visible: styles.show
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.thankYou, {
      visible: !thankYou
    })
    return (
      <div className={classes}>
        <Markdown markdownHtml={content.preSubmitMessage} />
      </div>
    )
  }

  postData(formData, endpoint) {
    const { onSuccess, trackOnSuccess } = this.props
    fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          this.setState({
            submitResult: false,
            thankYou: true,
            submitting: false
          })
          onSuccess(formData)
          trackOnSuccess()
        } else {
          this.setState({
            submitResult: false,
            submittErr: true,
            thankYou: false,
            submitting: false
          })
          throw new Error('Network response was not ok.')
        }
      })
      .catch((error) => {
        this.setState({
          submittErr: true,
          submitting: false
        })
        throw new Error(error)
      })
  }

  handleSubmit = (values) => {
    const {
      googleReCaptchaProps,
      trackOnSubmit,
      data,
      integrationsFormEndpoint,
      complaintFormEndpoint,
      locale
    } = this.props
    trackOnSubmit()
    if (!this.state.submitting) {
      this.setState(
        {
          submitResult: null,
          submitting: true
        },
        () => {
          const formValues = values
          const formData = new FormData()
          data.fields.forEach((field) => {
            if (
              field.contentType === 'hiddenFormFieldComponent' ||
              field.type === 'mailChimp' ||
              field.type === 'tag' ||
              field.type === 'interestId'
            ) {
              formValues[field.type] = field.label
            }
          })
          formData.append('fields', JSON.stringify(formValues))
          // ! these forms only support one field to upload file/files.
          if (formValues.file) {
            formData.append('file', formValues.file)
          } else if (formValues.files) {
            Array.from(formValues.files).forEach((file) => {
              formData.append('files', file)
            })
          }
          if (data.formType === 'complaint') {
            formData.append('formType', data.formType)
            formData.append('locale', locale)
            this.postData(formData, complaintFormEndpoint)
          } else {
            googleReCaptchaProps
              .executeRecaptcha()
              .then((token) => {
                formData.append('token', token)
                this.postData(
                  formData,
                  `${integrationsFormEndpoint}/${data.formType}`
                )
              })
              .catch((error) => {
                this.setState({
                  submittErr: true,
                  submitting: false
                })
                throw new Error(error)
              })
          }
        }
      )
      // TODO: validation
    }
  }

  render() {
    const { formClass, data, theme, containerClass, compact } = this.props
    const containerFieldsProp = data.formContainerFields &&
      data.formContainerFields.fields && {
        containerFields: data.formContainerFields.fields
      }
    const { thankYou, submittErr, submitting } = this.state
    const formClasses = classNames(styles.form, {
      [styles.hide]: thankYou,
      [styles[theme]]: theme
    })
    const siteText = {
      send: send(),
      formFieldsRequired: formFieldsRequired(),
      formErrorMessage: formErrorMessage(),
      yes: subscribeYes(),
      no: subscribeNo(),
      salutationOne: salutationOne(),
      salutationTwo: salutationTwo(),
      salutationThree: salutationThree(),
      mailChimpListId: mailChimpListId(),
      charactersCounter: charactersCounter(),
      date: date()
    }
    return (
      <div className={containerClass}>
        <div className={formClasses}>
          <span className={styles.error}>{siteText.formFieldsRequired} </span>
          {submittErr && (
            <span className={styles.error}> {siteText.formErrorMessage} </span>
          )}
          <div className={formClass}>
            <ContactForm
              onSubmit={this.handleSubmit}
              fields={data.fields}
              {...containerFieldsProp}
              error={this.validate}
              siteText={siteText}
              locale={this.props.locale}
              compact={compact}
              submitting={submitting}
            />
          </div>
          {submitting && (
            <div className={styles.loading}>
              <div className={styles['dot-flashing']} />
            </div>
          )}
        </div>
        {data.message && (
          <div className={styles.message}>
            <Markdown markdownHtml={data.message} />
          </div>
        )}
        {this.renderThankyouMessage(data)}
        {data.preSubmitMessage && this.renderPreSubmitMessage(data)}
      </div>
    )
  }
}

export default withGoogleReCaptcha(IntegratedForm)
