import React from 'react'
import PropTypes from 'prop-types'

import {
  HeroComponent,
  HalfWidthPng,
  FullWidthPng,
  FullBleedImage,
  ProductIngredients,
  MetaData
} from 'components'
import { Recommendations } from 'containers'

import styles from './stagornostag.css'
import ingredientsMarkdown from './ingredientsmd.css'

const StagOrNoStag = ({ content }) => (
  <main className={styles.wrapper} role="main" id="mainContent">
    <MetaData metadata={content.metadata} />

    {content.hero && <HeroComponent hero={content.hero} />}

    {content.halfWidthPngOne && (
      <div className={styles.halfWidthPngOne}>
        <HalfWidthPng data={content.halfWidthPngOne} />
      </div>
    )}

    {content.fullBleedImageOne && (
      <FullBleedImage
        data={content.fullBleedImageOne}
        imageClass={styles.fullBleedImageOneImage}
      />
    )}

    {content.halfWidthPngTwo && (
      <div className={styles.halfWidthPngTwo}>
        <HalfWidthPng
          data={content.halfWidthPngTwo}
          imageClass={styles.halfWidthPngTwoImage}
          textLonger
          reverseMobile
        />
      </div>
    )}

    {content.fullBleedImageTwo && (
      <FullBleedImage
        data={content.fullBleedImageTwo}
        textInnerClass={styles.fullBleedImageTwoText}
        headlineClass={styles.fullBleedImageTwoHeadline}
      />
    )}

    {content.halfWidthPngThree && (
      <HalfWidthPng
        data={content.halfWidthPngThree}
        imageClass={styles.halfWidthPngThreeImage}
        reverseMobile
      />
    )}

    {content.fullWidthPng && (
      <div className={styles.fullWidthPngOne}>
        <FullWidthPng data={content.fullWidthPng} imageStickToLeft />
      </div>
    )}

    {content.fullBleedImageThree && (
      <FullBleedImage data={content.fullBleedImageThree} />
    )}

    {content.ingredientsOne && (
      <div className={styles.ingredientsOne}>
        <ProductIngredients
          ingredients={content.ingredientsOne}
          markdownStyles={ingredientsMarkdown}
        />
      </div>
    )}

    {content.ingredientsTwo && (
      <ProductIngredients
        ingredients={content.ingredientsTwo}
        markdownStyles={ingredientsMarkdown}
        textRight
      />
    )}

    {content.fullBleedImageFour && (
      <FullBleedImage data={content.fullBleedImageFour} />
    )}

    {content.recommendations && (
      <Recommendations recommendations={content.recommendations} />
    )}
  </main>
)

StagOrNoStag.propTypes = {
  content: PropTypes.object.isRequired
}
export default StagOrNoStag
