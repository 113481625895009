import React from 'react'

import styles from './zeit.css'

function Krauter() {
  return (

    <svg
      viewBox="0 0 322.4 116.3"
    >
      <g>
        <path
          fill="#DD5A12" d="M183.2,35.8c0.4-0.3,0.6-0.5,1-0.9c0.4-0.4,0.4-0.8,0.4-1.5l0,0c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.6
      c-0.1-0.3-0.2-0.7-0.4-1l0,0c-0.4-0.4-0.8-0.8-1.4-1.2c-1.6-1.1-2.6-1.3-3.1-1.3c-0.3,0-0.4,0.1-0.4,0.1c0,0-0.1,0.1-0.2,0.1
      l-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.9,0.8-1,1.3-1.1,1.6c-0.4,2.2,3.2,3.7,4.8,4.2C182.7,35.6,183,35.7,183.2,35.8z"
        />
        <path
          fill="#DD5A12" d="M184.3,40c-1.1-0.7-2.6-1.4-3.6-1.7c-0.2-0.1-0.4-0.1-0.5-0.2c-0.9,0.5-2.5,2.6-2.5,3.8c0,0.7,0.1,1,0.5,1.5
      l0,0c0.2,0.2,0.9,1.3,2.5,1.9c0.1,0.1,0.3,0.1,0.4,0.1l0.2,0c0.5,0.1,1,0.2,1.7,0.2c0.5,0,0.9-0.1,1.4-0.2c0.1,0,0.1,0,0.2-0.1
      c0.4-0.2,0.7-0.4,0.9-0.8c0.4-0.5,0.5-0.9,0.5-0.9l0-0.1c0.1-0.2,0.1-0.6,0.1-0.8C185.9,41.8,185,40.4,184.3,40z"
        />
        <path
          fill="#DD5A12" d="M212.6,35.8c0.4-0.3,0.6-0.5,1-0.9c0.4-0.4,0.4-0.8,0.4-1.5l0,0c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.6
      c-0.1-0.3-0.2-0.7-0.4-1l0,0c-0.4-0.4-0.8-0.8-1.4-1.2c-1.6-1.1-2.6-1.3-3.1-1.3c-0.3,0-0.4,0.1-0.4,0.1c0,0-0.1,0.1-0.2,0.1
      l-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.9,0.8-1,1.3-1.1,1.6c-0.4,2.2,3.2,3.7,4.8,4.2C212.1,35.6,212.4,35.7,212.6,35.8z"
        />
        <path
          fill="#DD5A12" d="M237.1,27.5c-0.5-1.4-1.7-4.2-4-6.9H89.9c-0.1,0.1-0.1,0.2-0.2,0.2c-2.2,2.6-3.3,5.3-3.8,6.7
      c-3.3,1.4-5.6,4.8-5.6,8.6c0,3.5,2,6.6,4.9,8.2c0.2,0.6,1.6,4.7,4.5,8.2c0.1,0.1,0.1,0.2,0.2,0.3h143.1c3.1-3.5,4.5-7.8,4.7-8.5
      c2.9-1.5,4.9-4.7,4.9-8.2C242.7,32.3,240.4,28.9,237.1,27.5z M178.6,29.3C178.6,29.3,178.6,29.3,178.6,29.3L178.6,29.3
      C178.6,29.3,178.6,29.3,178.6,29.3z M140.4,25.6c0-0.3,0.3-0.4,0.5-0.4c0.4,0,1,0,1.5,0c0.4,0,0.6,0.5,0.3,0.7l-2.8,2.8
      C139.9,28.6,140.3,26.6,140.4,25.6z M117.5,46.1c-1.9,1.6-3.1,2.1-4.9,2.1c-4.4-0.6-7-4.4-7-4.4l2.8-2.9c1.7,4.5,5.5,5.6,7.4,5.7
      c0.6,0,1.1-0.2,1.5-0.6c2.9-2.7-0.5-5-0.5-5l-5.3-3.7l-3.4-2.5c-1.8-1.5-1.8-4.2,0-5.8l1.1-1c0.2-0.2,0.4-0.3,0.6-0.5
      c0.3-0.2,0.8-0.6,1.4-0.9c1.3-0.9,3-1.3,4.5-1c3.7,0.6,3.9,3.6,3.9,3.6s-0.8,0.8-2.6,2.7c-2.6-6-6.7-4.6-7.4-4
      c-3.5,2.6,3.8,6.4,6.2,7.7C124.2,39.9,119.4,44.5,117.5,46.1z M131.5,45.7c-1.1,1.2-3,1.4-4.3,0.3l-2.6-2.1
      c-0.4-0.4-0.7-0.9-0.7-1.4v-8.2c0-0.3,0.2-0.7,0.4-0.9l0.2-0.1c0,0,0.1,0,0.1-0.1c0.5-0.3,5.1-3.5,5.1-3.5l3,3.4
      c0.9,1,0.8,2.5-0.2,3.4l-1.5,1.3l-2,1.4c-0.4,0.3-0.6,0.4-0.6,0.4l0.6-0.4c0,0,0.1-0.1,0.1-0.1l-1.4,1l-0.1-0.1l2.2-1.6
      c0.3-0.2,0.8-0.7,1.1-1.2c0.3-0.5,0.3-1,0-1.4c-1-1.6-3-3.7-3-3.7h-0.8v9.7c0,0.2,0.1,0.3,0.2,0.4l3,2.2c0,0,1.6,0.7,2.9-0.6
      c0.4-0.4,0.5-0.6,0.5-0.6l0.1,0L131.5,45.7z M142,46.4c-0.9,0.6-1.7,0.6-2.3,0l-1.2-1.2v-9.5c0-2.4-2.1-2.5-2.1-2.5l3.3-2.9l1.5,1
      c0.3,0.2,0.5,0.6,0.5,1c0,0,0,0,0,0v11.2c0,0.9,2.5,1.4,2.5,1.4L142,46.4z M154.3,32.8c-0.1,0-3.7,0-3.7,0v9.8l0,1.6
      c0,0.7,2,1.2,2,1.2l-1.7,1.3c-0.7,0.5-1.1,0.5-1.9,0l-1.6-1V32.8c0,0-1,0-1.5,0V32c2.6-0.6,4.6-3.8,4.7-3.8V32h3.7
      C154.3,32,154.4,32.8,154.3,32.8z M170.2,46.7c-0.6,0.6-1.7,0.6-2.3,0l-1.2-1.2V32.7c0-2.4-2.1-2.5-2.1-2.5l3.3-2.9l1.5,1
      c0.3,0.2,0.5,0.6,0.5,1c0,0,0,0,0,0v14.6c0,0.9,1.9,1.4,1.9,1.4L170.2,46.7z M187.5,42.7c0,0.1-0.1,0.2-0.1,0.2
      c-0.3,0.6-0.7,1.4-2.7,3.1l0,0l-0.2,0.1c-1,0.8-2.1,1.2-3.4,1.2c-1.4,0-2.5-0.5-3.2-0.8c-2.7-1.6-1.9-4.3-1.6-5.1
      c0.5-1.3,2.4-2.9,3.4-3.5c-4.1-1.8-3.9-4-3.7-5.1c0.2-1.5,2-2.9,2.6-3.3l0,0l0,0c0.1-0.1,1.1-0.6,1.6-0.8c0.6-0.2,0.8-0.3,1.2-0.4
      c0.2,0,0.6-0.1,0.7-0.1c2.7,0.2,4.6,1.7,4.6,4c0,1.5-0.7,2.5-3.1,3.9c0.8,0.3,1.5,0.8,2.1,1.3C187.1,38.4,188.8,40.2,187.5,42.7z
       M202.4,28.8c0,0-5.9,7.3-6.6,17.1c-2.5,0.9-4,1.5-4,1.5s0.3-7.2,8.5-16.2c0.1-0.1,0-0.2-0.1-0.2l-7.4,0c-0.8,0-0.7,0-1.5,0.4
      l-0.2,0.1c0,0-0.2-1.2,1-3c0.2-0.2,0.4-0.4,0.7-0.4h9.7V28.8z M216.9,42.7c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.6-0.7,1.4-2.7,3.1l0,0
      l-0.2,0.1c-1,0.8-2.1,1.2-3.4,1.2c-1.4,0-2.5-0.5-3.2-0.8c-2.7-1.6-1.9-4.3-1.6-5.1c0.5-1.3,2.4-2.9,3.4-3.5
      c-4.1-1.8-3.9-4-3.7-5.1c0.2-1.5,2-2.9,2.6-3.3l0,0l0,0c0.1-0.1,1.1-0.6,1.6-0.8c0.6-0.2,0.8-0.3,1.2-0.4c0.2,0,0.6-0.1,0.7-0.1
      c2.7,0.2,4.6,1.7,4.6,4c0,1.5-0.7,2.5-3.1,3.9c0.8,0.3,1.5,0.8,2.1,1.3C216.5,38.4,218.2,40.2,216.9,42.7z"
        />
        <path
          fill="#DD5A12" d="M213.7,40c-1.1-0.7-2.6-1.4-3.6-1.7c-0.2-0.1-0.4-0.1-0.5-0.2c-0.9,0.5-2.5,2.6-2.5,3.8c0,0.7,0.1,1,0.5,1.5
      l0,0c0.2,0.2,0.9,1.3,2.5,1.9c0.1,0.1,0.3,0.1,0.4,0.1l0.2,0c0.5,0.1,1.2,0.1,1.8,0.1c0.5,0,0.8,0,1.2-0.1c0.1,0,0.1,0,0.2-0.1
      c0.4-0.2,0.7-0.4,0.9-0.8c0.4-0.5,0.5-0.9,0.5-0.9l0-0.1c0.1-0.2,0.1-0.6,0.1-0.8C215.4,41.8,214.4,40.4,213.7,40z"
        />
      </g>
      <path
        fill="#DD5A12" d="M191.1,59.2c0,0.8-0.7,1.5-1.5,1.5c-0.9,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.5-1.5
    C190.4,57.7,191.1,58.4,191.1,59.2z M196,59.2c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.5-1.5
    C195.3,57.7,196,58.4,196,59.2z"
      />
      <path fill="#DD5A12" d="M85.9,62.4l-6.6,14.6h-4.1L72.3,69l-2.9,8.1h-4.1l-6.6-14.6H63l4.2,9l3.3-9h3.6l3.3,9l4.2-9H85.9z" />
      <path
        fill="#DD5A12" d="M101.7,69.8c0,4.3-3.6,7.7-8.1,7.7c-4.5,0-8.1-3.4-8.1-7.7s3.6-7.7,8.1-7.7C98.1,62.1,101.7,65.5,101.7,69.8z
     M98.1,69.8c0-2.4-2-4.3-4.5-4.3c-2.5,0-4.6,2-4.6,4.3s2,4.3,4.6,4.3C96.1,74.1,98.1,72.2,98.1,69.8z"
      />
      <path fill="#DD5A12" d="M118,73.6v3.5h-13.3V62.4h3.7v11.1H118z" />
      <path fill="#DD5A12" d="M125.2,65.6v3.3h6.2v3.2h-6.2v5h-3.7V65.6v-3.2h11.6v3.2H125.2z" />
      <path fill="#DD5A12" d="M138.9,65.6v2.6h6.2v3.1h-6.2V74h7.9v3.1h-11.6V62.4h11.6v3.2H138.9z" />
      <path fill="#DD5A12" d="M165.6,62.4v14.6h-3.7l-7.7-8.8v8.8h-3.7V62.4h3.7l7.7,9.1v-9.1H165.6z" />
      <path
        fill="#DD5A12" d="M182.2,72.4c0,2.9-2.4,4.7-4.8,4.7h-8.7V62.4h8.2c2.2,0,4.6,1.5,4.6,4.4c0,1-0.3,1.8-0.6,2.5
    C181.7,70.1,182.2,71.1,182.2,72.4z M172.5,68.2h4.3c0.7,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4h-4.3V68.2z M178.9,72.6
    c0-0.9-0.7-1.6-1.5-1.6h-4.8v3.1h4.8C178.2,74.1,178.9,73.5,178.9,72.6z"
      />
      <path
        fill="#DD5A12" d="M199.8,62.4v7.2c0,4.7-3.5,7.8-7.8,7.8c-4.4,0-7.8-3.1-7.8-7.8v-7.2h3.8v7.2c0,2.3,1.7,4.2,4.1,4.2
    c2.3,0,4-1.9,4-4.2v-7.2H199.8z"
      />
      <path fill="#DD5A12" d="M216.8,65.6H211v11.5h-3.7V65.6h-5.8v-3.2h15.4V65.6z" />
      <path fill="#DD5A12" d="M234,65.6h-5.8v11.5h-3.7V65.6h-5.8v-3.2H234V65.6z" />
      <path fill="#DD5A12" d="M240,65.6v2.6h6.2v3.1H240V74h7.9v3.1h-11.6V62.4h11.6v3.2H240z" />
      <path fill="#DD5A12" d="M263.8,73.6v3.5h-13.3V62.4h3.7v11.1H263.8z" />
      <path
        fill="#DD5A12" d="M117.5,92.6L117.5,92.6c-1.5,1.9-3.9,3.1-6.5,3.1c-4.5,0-8.1-3.4-8.1-7.7c0-4.3,3.6-7.7,8.1-7.7
    c2.7,0,4.8,1.1,6.3,3l-2.8,1.7c-0.9-0.8-2-1.3-3.5-1.3c-2.5,0-4.6,2-4.6,4.3c0,2.4,2.1,4.3,4.6,4.3c1.3,0,2.5-0.5,3.3-1.3v-1.5h-4.7
    v-2.8h7.9V92.6z"
      />
      <path fill="#DD5A12" d="M123.8,83.9v2.6h6.2v3.1h-6.2v2.6h7.9v3.1h-11.6V80.7h11.6v3.2H123.8z" />
      <path
        fill="#DD5A12" d="M143.5,95.4l-2.5-3.6h-2.7v3.6h-3.8V80.7c0,0,5.6,0,7.5,0c3.1,0,5.5,2.6,5.5,5.8c0,2.1-1.1,3.6-2.7,4.5l3.4,4.4
    H143.5z M138.3,88.5h3.1c1.3,0,2.3-0.8,2.3-2c0-1.2-1-2.2-2.3-2.2h-3.1V88.5z"
      />
      <path fill="#DD5A12" d="M167.7,80.7v14.6H164v-7.5l-3.6,7.5h-1.6l-3.5-7.4v7.4h-3.7V80.7h3.7v0.1l4.3,8.2l4.4-8.3v0v0H167.7z" />
      <path fill="#DD5A12" d="M182.9,93.3h-7.4l-1,2h-4.2l7.6-14.6h2.8l7.4,14.6H184L182.9,93.3z M181.5,90.5l-2.3-4.7l-2.2,4.7H181.5z" />
      <path fill="#DD5A12" d="M205.2,80.7v14.6h-3.7l-7.7-8.8v8.8h-3.7V80.7h3.7l7.7,9.1v-9.1H205.2z" />
      <path fill="#DD5A12" d="M224.2,80.7l-6.5,8.5v6.2h-4.6v-6.2l-6.5-8.5h4.6l4.2,5.3l4.2-5.3H224.2z" />
    </svg>

  )
}

export default Krauter
