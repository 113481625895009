import PropTypes from 'prop-types'
import cx from 'classnames'
import React, { useEffect, useReducer, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Title, Markdown, Img } from 'components'
import {
  isEmailish,
  isPostcodeish,
  isTelephoneish
} from '../../lib/validation-regexp'
import { charactersCounter } from 'utils/i18n'
import styles from './form.css'

let verifyEmailValue = ''
const emailValue = (value) => {
  if (value) {
    verifyEmailValue = value
  }
  return verifyEmailValue
}
const required = (value) => (value ? undefined : 'Required')
const email = (value) =>
  isEmailish(value) && emailValue(value) ? undefined : 'Invalid email address'
const postcode = (value) =>
  isPostcodeish(value) ? undefined : 'invalid postcode'
const phone = (value) =>
  isTelephoneish(value) ? undefined : 'invalid telephone number'
const verifyEmail = (value) =>
  value === verifyEmailValue ? undefined : 'emails dont match'
const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span>{error}</span> : false

const renderField = ({
  input,
  label,
  type,
  formError,
  data,
  autoFocus,
  maxCharacters,
  maxCharactersNumber,
  meta: { touched, error, warning },
  ...rest
}) =>
  (type === 'radio' && (
    <span className={styles.radioLabel}>
      {touched &&
        ((error && <span className={styles.error}>{formError}</span>) ||
          (warning && <span>{warning}</span>))}
      <div className={styles.radioTitle}>
        <label htmlFor={label} className={styles.label}>
          {input.name}
        </label>
      </div>
      <div className={styles.radio}>
        <label htmlFor={label}>
          <input
            id={label}
            {...input}
            type={type}
            className={styles.input}
            value={label}
          />
          <span className={styles.radio} />
          {label}
        </label>
      </div>
    </span>
  )) ||
  (type === 'dob' && (
    <span>
      {type === 'dob' ? (
        <input
          {...input}
          placeholder={rest.placeholder}
          type="date"
          pattern={rest.pattern}
          className={styles.date}
        />
      ) : (
        ''
      )}
      {touched &&
        ((error && <span className={styles.error}>{formError}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  )) ||
  (type === 'month' && (
    <span>
      <input
        {...input}
        placeholder={rest.placeholder}
        type="month"
        pattern={rest.pattern}
        className={styles.date}
      />
      {touched &&
        ((error && <span className={styles.error}>{formError}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  )) ||
  (type === 'hidden' && (
    <span>
      {type === 'hidden' ? (
        <input
          {...input}
          placeholder={type}
          type="hidden"
          className={styles.input}
          value={label}
        />
      ) : (
        ''
      )}
    </span>
  )) ||
  (type === 'checkbox' && (
    <span>
      {type === 'checkbox' ? (
        <div className={styles.checkRow}>
          <input
            {...input}
            placeholder={label}
            type="checkbox"
            className={styles.input}
            id={label}
          />
          <span className={styles.checkbox} />
          <label htmlFor={label} className={styles.checkLabel}>
            <div dangerouslySetInnerHTML={{ __html: label }} />
          </label>
        </div>
      ) : (
        ''
      )}
      {touched &&
        ((error && <div className={styles.error}>{formError}</div>) ||
          (warning && <div>{warning}</div>))}
    </span>
  )) || (
    <span>
      {type !== 'radio' ? (
        <input
          {...input}
          placeholder={label}
          type={type}
          pattern={rest.pattern}
          className={styles.input}
          autoFocus={autoFocus}
          maxLength={
            maxCharacters
              ? maxCharactersNumber && maxCharactersNumber !== 0
                ? maxCharactersNumber
                : 100
              : ''
          }
        />
      ) : (
        ''
      )}
      {touched &&
        ((error && <span className={styles.error}>{formError}</span>) ||
          (warning && <span>{warning}</span>))}
      <div
        className={maxCharacters ? styles.charactersCount : styles.hideCounter}
      >
        {charactersCounter()} {input.value.length} /{' '}
        {maxCharactersNumber && maxCharactersNumber !== 0
          ? maxCharactersNumber
          : 100}
      </div>
    </span>
  )
const renderTextarea = ({
  input,
  label,
  placeholder,
  type,
  formError,
  maxCharacters,
  maxCharactersNumber,
  meta: { touched, error, warning }
}) => {
  const countCharacters = input.value.length

  let newMaxCharacter =
    maxCharactersNumber && maxCharactersNumber !== 0 ? maxCharactersNumber : 255
  return (
    <div>
      <textarea
        {...input}
        placeholder={placeholder || label}
        type={type}
        className={styles.textarea}
        maxLength={maxCharacters ? newMaxCharacter : ''}
      />
      <div
        className={maxCharacters ? styles.charactersCount : styles.hideCounter}
      >
        {charactersCounter()} {countCharacters} / {newMaxCharacter}
      </div>
      {touched &&
        ((error && <span className={styles.error}>{formError}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  )
}
const renderFields = (d, idx) => (
  <Field
    name={d.title}
    type={d.type}
    key={idx}
    component={renderField}
    validate={[required]}
    value={d.label}
    formError={d.errorMessage}
    className={styles.type}
    label={d.label}
  />
)
const textField = (data, idx, compact, autoFocusField) => {
  const name = data.title ? data.title : data.type
  return (
    <div
      className={cx(styles.row, {
        [styles.compact]: compact
      })}
    >
      {!compact && <label className={styles.label}>{data.label}</label>}
      <Field
        name={name}
        type="text"
        key={idx + data.label}
        component={data.renderField}
        validate={data.errorMessage && [required]}
        formError={data.errorMessage}
        label={data.label}
        maxCharacters={data.maxCharacters}
        maxCharactersNumber={data.maxCharactersNumber}
        className={styles.input}
        autoFocus={autoFocusField === name}
        placeholder={data.placeholder}
      />
    </div>
  )
}

const emailField = (data, idx, compact) => (
  <div
    className={cx(styles.row, {
      [styles.compact]: compact
    })}
  >
    {!compact && <label className={styles.label}>{data.label}</label>}
    <Field
      name={data.title ? data.title : data.type}
      type="email"
      key={idx + data.label}
      component={data.renderField}
      validate={[email]}
      formError={data.errorMessage}
      label={data.label}
      className={styles.input}
    />
  </div>
)
const verifyEmailField = (data, idx) => (
  <div className={styles.row}>
    <label className={styles.label}>{data.label}</label>
    <Field
      name={data.title ? data.title : data.type}
      type="email"
      key={idx + data.label}
      component={data.renderField}
      validate={[verifyEmail]}
      formError={data.errorMessage}
      label={data.label}
      className={styles.input}
    />
  </div>
)
const postCodeField = (data, idx, compact) => (
  <div
    className={cx(styles.row, {
      [styles.compact]: compact
    })}
  >
    {!compact && <label className={styles.label}>{data.label}</label>}
    <Field
      name={data.title ? data.title : data.type}
      type="text"
      key={idx + data.label}
      component={data.renderField}
      validate={[postcode]}
      formError={data.errorMessage}
      label={data.label}
      className={styles.input}
    />
  </div>
)
const hiddenField = (data, idx) => (
  <div className={styles.hiddenRow}>
    <Field
      name={data.type}
      type="hidden"
      placeholder={data.value}
      key={idx + data.label}
      label={data.label}
      value={data.value}
      className={styles.input}
      component={data.renderField}
    />
  </div>
)
const title = (data) => (
  <div className={styles.headline} key={data.id}>
    <Title title={data.title} key={data.id} />
  </div>
)

const longText = (data) => <Markdown key={data.id} markdownHtml={data.text} />

const titleTextImage = (data) => {
  return (
    <div key={data.id}>
      {data.title && (
        <div className={styles.headline} key={data.id}>
          <Title title={data.title} key={data.id} />
        </div>
      )}
      <Img
        className={styles.titleTextImage}
        src={data.image.url}
        alt={data.image.title}
      />
      {data.text && <Markdown markdownHtml={data.text} />}
    </div>
  )
}
const telephoneField = (data, idx) => (
  <div className={styles.row}>
    <label className={styles.label}>{data.label}</label>
    <Field
      name={data.title ? data.title : data.type}
      type="tel"
      key={idx + data.label}
      component={data.renderField}
      validate={data.errorMessage && [phone]}
      formError={data.errorMessage}
      label={data.label}
      className={styles.input}
    />
  </div>
)

const checkBoxField = (data, idx) => (
  <div key={idx}>
    {
      <Field
        name={data.title}
        type="checkbox"
        key={data.title}
        component={data.renderField}
        validate={data.errorMessage && [required]}
        formError={data.errorMessage}
        label={data.label}
        className={styles.checkbox}
        value={data.label}
      />
    }
  </div>
)
const renderRadio = ({
  input,
  label,
  type,
  formError,
  meta: { touched, error, warning }
}) => (
  <span className={styles.radioLabel}>
    {touched &&
      ((error && <span className={styles.error}>{formError}</span>) ||
        (warning && <span>{warning}</span>))}
    <span className={styles.radio}>
      <label htmlFor={label}>
        <input
          id={label}
          {...input}
          type={type}
          className={styles.input}
          value={label}
        />
        <span className={styles.radio} />
        {label}
      </label>
    </span>
  </span>
)

const renderDOB = (data, idx, locale, siteText, compact) => {
  /**
   * Variable to set the name of the Date field.
   * When using _Form Field Builder_ component with type BirthDate, we need the name of the field to be set as birthDate
   * rather than the value that the content editors set for the global _siteText_ properties. This is due to 1-1 mapping of values
   * between the form submission and other 3rd pary services. Thus, when the type of the field is called birthDate,
   * rather than DOB or something else, we set the name of the field as *birthDate*
   */
  const dobValue = {
    date: data.type === 'birthDate' ? 'birthDate' : siteText.date
  }
  const isUS = locale.indexOf('US') > -1 ? true : false
  return (
    <div
      className={cx(styles.row, {
        [styles.compact]: compact
      })}
    >
      {!compact && <label className={styles.label}>{data.label}</label>}
      <div className={styles.row}>
        {dobValue.date &&
          dobValue.date.length > 0 &&
          (!isUS ? (
            <Field
              name={dobValue.date}
              type="dob"
              key={dobValue.date}
              placeholder="dd/mm/yyyy"
              pattern="^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$"
              className={styles.date}
              validate={[required]}
              formError={data.errorMessage}
              component={renderField}
              label="Date"
            />
          ) : (
            <Field
              name={dobValue.date}
              type="dob"
              key={dobValue.date}
              placeholder="mm/dd/yyyy"
              pattern="^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)(?:31))\1|(?:(?:0?[13-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$"
              className={styles.date}
              validate={[required]}
              formError={data.errorMessage}
              component={renderField}
              label="Date"
            />
          ))}
      </div>
    </div>
  )
}

const renderMonthYearField = (data, compact, idx) => {
  return (
    <div
      className={cx(styles.row, {
        [styles.compact]: compact
      })}
      key={idx}
    >
      {!compact && <label className={styles.label}>{data.label}</label>}
      <div className={styles.row}>
        <Field
          name={data.name ? data.name : data.title}
          type="month"
          placeholder={data.placeholder}
          component={renderField}
          formError={data.errorMessage}
          validate={data.errorMessage && [required]}
          className={styles.date}
        />
      </div>
    </div>
  )
}

const renderUploadFormField = ({
  input,
  formError,
  accept,
  placeholder,
  buttonName,
  multiple,
  label,
  meta: { error, touched }
}) => {
  delete input.value
  const [filename, setFilename] = useState(null)

  const onUpload = (e) => {
    const { onChange } = input
    if (multiple) {
      onChange(e.target.files)
    } else {
      onChange(e.target.files[0])
    }
    setFilename(
      Array.from(e.target.files)
        .map((file) => file.name)
        .join(', ')
    )
    // setTouched(true)
  }

  return (
    <div>
      <label className={styles.label}>{label}</label>
      <input
        id={input.name}
        name={input.name}
        onChange={onUpload}
        placeholder={placeholder}
        type="file"
        className={styles.uploadInput}
        accept={accept}
        multiple={multiple}
        hidden
      />
      <div className={styles.uploadRow}>
        <label
          id="inputLabel"
          className={styles.uploadInput}
          htmlFor={input.name}
        >
          {(error && placeholder) || filename}
        </label>
        <label
          htmlFor={input.name}
          type="button"
          id="btnLoad"
          className={styles.uploadBtn}
        >
          {buttonName}
        </label>
      </div>

      {touched && error && <span className={styles.error}>{formError}</span>}
    </div>
  )
}

const uploadFormField = (data, idx) => {
  // * validation functions currently return a boolean value whether there's an error or not, for compatibility with redux-forms
  const validateSize = (files) => {
    if (files) {
      if (files.length > data.numberOfFiles) {
        return true
      }
      return !Array.from(files).every((file) => {
        const fileSize = file.size / 1024 / 1024 // in MiB
        if (fileSize > data.maxFileSize) {
          return false
        } else {
          return true
        }
      })
    } else {
      return false
    }
  }

  const validateFormat = (files) => {
    if (files) {
      return !Array.from(files).every((file) => {
        if (
          data.acceptedTypes.some(
            (acceptedType) => acceptedType.type === file.type
          )
        ) {
          return true
        } else {
          return false
        }
      })
    } else {
      return false
    }
  }

  return (
    <div className={styles.upload} key={data.id}>
      <Field
        name={data.title ? data.title : data.contentType}
        type="file"
        key={idx + data.label}
        component={renderUploadFormField}
        formError={data.errorMessage}
        label={data.label}
        className={styles.input}
        placeholder={data.placeholder}
        accept={data?.acceptedTypes
          .map((acceptedType) => acceptedType.type)
          .join(',')}
        validate={
          data.errorMessage && [validateFormat, validateSize] && [required]
        }
        buttonName={data.buttonName}
        multiple={data.numberOfFiles > 1}
      />
    </div>
  )
}

const instagramHandle = (data, compact) => {
  return (
    <div
      className={cx(styles.row, {
        [styles.compact]: compact
      })}
    >
      {!compact && <label className={styles.label}>{data.label}</label>}
      <Field
        name={data.title ? data.title : data.type}
        type="text"
        key={data.label}
        pattern="^@?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$"
        validate={data.errorMessage && [required]}
        formError={data.errorMessage}
        label={data.label}
        className={styles.input}
        component={data.renderField}
      />
    </div>
  )
}

const radioField = (data, siteText) => {
  const radioValue = {
    valueOne:
      data.type === 'salutation' ? siteText.salutationOne : siteText.yes,
    valueTwo: data.type === 'salutation' ? siteText.salutationTwo : siteText.no,
    valueThree: data.type === 'salutation' ? siteText.salutationThree : ''
  }
  return (
    <div className={styles.radioLabel}>
      <label className={styles.label}>{data.label}</label>
      <div className={styles.row}>
        {radioValue.valueOne && radioValue.valueOne.length > 0 && (
          <div className={styles.radio}>
            <Field
              name={data.type === 'salutation' ? 'salutation' : 'subscribe'}
              type="radio"
              key={radioValue.valueOne}
              component={renderRadio}
              validate={[required]}
              formError={data.errorMessage}
              label={radioValue.valueOne || ''}
              className={styles.radio}
              value={radioValue.valueOne}
            />
            <span className={styles.radio} />
          </div>
        )}
        {radioValue.valueTwo && radioValue.valueTwo.length > 0 && (
          <div className={styles.radio}>
            <Field
              name={data.type === 'salutation' ? 'salutation' : 'subscribe'}
              type="radio"
              key={radioValue.valueTwo}
              component={renderRadio}
              validate={[required]}
              formError={data.errorMessage}
              label={radioValue.valueTwo || ''}
              className={styles.radio}
              value={radioValue.valueTwo}
            />
            <span className={styles.radio} />
          </div>
        )}
        {radioValue.valueThree && radioValue.valueThree.length > 0 && (
          <div className={styles.radio}>
            <Field
              name={data.type === 'salutation' ? 'salutation' : 'subscribe'}
              type="radio"
              key={radioValue.valueThree}
              component={renderRadio}
              validate={[required]}
              formError={data.errorMessage}
              label={radioValue.valueThree || ''}
              className={styles.radio}
              value={radioValue.valueThree}
            />
            <span className={styles.radio} />
          </div>
        )}
      </div>
    </div>
  )
}

const renderSelect = ({
  input,
  formError,
  meta: { touched, error, warning },
  children
}) => (
  <div>
    <select className={styles.select} {...input}>
      {children}
    </select>
    {touched &&
      ((error && <span className={styles.error}>{formError}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
)

const selectField = (data, setValue, isConditional = false) => {
  return (
    <div className={styles.row}>
      <label className={styles.label}>{data.label}</label>
      <Field
        name={data.title}
        component={renderSelect}
        formError={data.errorMessage}
        validate={data.errorMessage && [required]}
        {...(isConditional && {
          onChange: (e) => {
            setValue({
              type: 'change',
              value: e.currentTarget?.value,
              name: data.title
            })
          }
        })}
      >
        <option></option>
        {data.options &&
          data.options.map((option, index) => (
            <option value={option.value} key={`field-options-${index}`}>
              {option.text}
            </option>
          ))}
      </Field>
    </div>
  )
}

const conditionalField = (data, locale, compact, siteText, state, dispatch) => {
  return (
    <div>
      {selectField(data.field, dispatch, true)}
      {data.flows?.map((flow, index) => {
        return renderFlow(
          flow,
          `${data.title}-${index}`,
          locale,
          compact,
          state[data.field?.title],
          siteText,
          state,
          dispatch
        )
      })}
    </div>
  )
}

const renderFlow = (
  data,
  idx,
  locale,
  compact,
  value,
  siteText,
  state,
  dispatch
) => {
  if (data.values.some((conditionValue) => conditionValue.value === value)) {
    return (
      <div className={styles.flow} key={idx}>
        {data.fields?.map((d, index) =>
          formRender(
            d,
            `${data.title}-${index}`,
            locale,
            compact,
            siteText,
            state,
            dispatch
          )
        )}
      </div>
    )
  } else {
    return <div />
  }
}

const fieldType = (
  d,
  idx,
  locale,
  compact,
  siteText,
  conditionalFieldsState,
  dispatch,
  autoFocusField
) => {
  const data = d
  data.renderField = renderField
  if (d.type === 'salutation' || d.type === 'subscribe') {
    return radioField(data, siteText)
  } else if (data.type === 'message' || d.type === 'textarea') {
    data.fieldType = 'textarea'
    data.renderField = renderTextarea
    return textField(d, idx)
  } else if (data.contentType === 'hiddenFormFieldComponent') {
    // This field type hiddenFormFieldComponent does not need to return anything to be rendered
    // even though there is a legacy hiddenField component used by other types
    // the data from this field is configured and added on the SalesforceForm.js
    return
  } else if (
    data.type === 'mailChimp' ||
    d.type === 'hidden' ||
    data.type === 'tag' ||
    data.type === 'interestId'
  ) {
    return hiddenField(data, idx)
  } else if (data.type === 'email') {
    return emailField(data, idx, compact)
  } else if (data.type === 'postcode') {
    return postCodeField(data, idx, compact)
  } else if (data.type === 'dob' || data.type === 'birthDate') {
    return renderDOB(data, idx, locale, siteText, compact)
  } else if (data.type === 'date-month-year') {
    return renderMonthYearField(data, compact, idx)
  } else if (data.type === 'telephone') {
    return telephoneField(data, idx)
  } else if (data.type === 'verifyEmail') {
    return verifyEmailField(data, idx)
  } else if (data.type === 'checkBox') {
    return checkBoxField(data, idx)
  } else if (data.contentType === 'title') {
    return title(data)
  } else if (data.contentType === 'fullWidthArticleTextComponent') {
    return longText(data)
  } else if (data.contentType === 'selectFormField') {
    return selectField(data)
  } else if (data.contentType === 'formFieldComponentContainer') {
    return formContainerRender(data, locale, compact, siteText, autoFocusField)
  } else if (data.type === 'instagramHandle') {
    return instagramHandle(data, compact)
  } else if (data.contentType === 'uploadFormField') {
    return uploadFormField(data, idx, compact)
  } else if (data.contentType === 'titleTextImage') {
    return titleTextImage(data, idx)
  } else if (data.contentType === 'conditionalField') {
    return conditionalField(
      data,
      locale,
      compact,
      siteText,
      conditionalFieldsState,
      dispatch
    )
  }
  return textField(data, idx, compact, autoFocusField)
}

const formRender = (
  d,
  idx,
  locale,
  compact,
  siteText,
  conditionalFieldsState,
  dispatch,
  autoFocusField
) =>
  d && d.type === 'radio' ? (
    renderFields(d, idx)
  ) : (
    <div key={idx}>
      {fieldType(
        d,
        idx,
        locale,
        compact,
        siteText,
        conditionalFieldsState,
        dispatch,
        autoFocusField
      )}{' '}
    </div>
  )

const formContainerRender = (
  data,
  locale,
  compact,
  siteText,
  autoFocusField
) => (
  <div
    className={cx(styles.formContainer, {
      [styles.equalSplit]: data.equalSplit
    })}
  >
    {data.fields.map((fieldTypes, index) =>
      fieldType(
        fieldTypes,
        index,
        locale,
        compact,
        siteText,
        null,
        null,
        autoFocusField
      )
    )}
  </div>
)

const conditionalFieldReducer = (state, action) => {
  if (action.type === 'change') {
    return { ...state, [action.name]: action.value }
  } else {
    return state
  }
}

const ContactForm = (props) => {
  const {
    handleSubmit,
    fields,
    endPoint,
    locale,
    compact,
    submitting,
    siteText,
    autoFocusField
  } = props
  const [conditionalFieldsState, dispatch] = useReducer(
    conditionalFieldReducer,
    { value: null }
  )
  useEffect(() => {
    props.reset()
    return
  }, [])
  return (
    <form onSubmit={handleSubmit} action={endPoint} method="post">
      {/* do we need to add this: enctype="multipart/form-data" */}
      {fields &&
        fields.map((d, idx) =>
          formRender(
            d,
            idx,
            locale,
            compact,
            siteText,
            conditionalFieldsState,
            dispatch,
            autoFocusField
          )
        )}
      <button
        type="submit"
        className={cx(styles.submitBtn, {
          [styles.compact]: compact
        })}
        name="send"
        disabled={submitting}
        value={siteText.sendTxt}
        id="button_form_submit"
      >
        {siteText.sendTxt || 'Submit'}
      </button>
    </form>
  )
}

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  formContainerFields: PropTypes.array,
  siteText: PropTypes.object.isRequired,
  endPoint: PropTypes.string,
  submitting: PropTypes.bool,
  compact: PropTypes.bool
}

// Decorate the form component
export default reduxForm({
  form: 'contact',
  destroyOnUnmount: false,
  enableReinitialize: true
})(ContactForm)
