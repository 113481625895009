import React, { Component } from 'react'
import classNames from 'classnames/bind'
import { Markdown } from 'components'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  send,
  formErrorMessage,
  formFieldsRequired,
  subscribeNo,
  subscribeYes,
  salutationOne,
  salutationTwo,
  salutationThree,
  mailChimpListId,
  date
} from 'utils/i18n'
import styles from './form.css'
import ContactForm from './ContactForm'

// TODO: there's a lot of overlap between integrated froms and normal forms, we should look at created a component to remove the duplications
class SalesforceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      renderForm: false,
      thankYou: false,
      submitResult: null,
      submitting: false,
      submittErr: false
    }
  }

  renderForm(id) {
    const arrNum = id - 1
    const formUpdate = {
      arryIndex: arrNum,
      renderForm: true
    }
    this.setState(formUpdate)
  }

  renderThankyouMessage(content) {
    const { thankYou } = this.state
    const css = {
      visible: styles.show
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.thankYou, {
      visible: thankYou
    })
    return (
      <div className={classes}>
        <h2>{content.successTitle}</h2>
        <Markdown markdownHtml={content.successMessage} />
      </div>
    )
  }

  postData(formData) {
    const { trackOnSuccess, salesforceSyncEndpoint } = this.props
    fetch(salesforceSyncEndpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        if (response.ok) {
          this.setState({
            submitResult: false,
            thankYou: true,
            submitting: false
          })
          trackOnSuccess()
        } else {
          this.setState({
            submitResult: false,
            submittErr: true,
            thankYou: false,
            submitting: false
          })
          throw new Error('Network response was not ok.')
        }
      })
      .catch((error) => {
        this.setState({
          submittErr: true,
          submitting: false
        })
        console.error(error)
      })
  }

  handleSubmit = (values) => {
    const {
      googleReCaptchaProps,
      trackOnSubmit,
      data,
      locale,
      endpoint
    } = this.props
    trackOnSubmit()
    if (!this.state.submitting) {
      this.setState({
        submitResult: null,
        submitting: true
      })
      // TODO: validation
      const formValues = values
      const formData = new FormData()
      data.fields.forEach((field) => {
        if (
          field.type === 'mailChimp' ||
          field.type === 'tag' ||
          field.type === 'interestId'
        ) {
          formValues[field.type] = field.label
        }
        // This field type does not render a component in the browser,
        // it is not needed as it is hidden, only appending the field to the payload is required
        // below we set the formValues item to have the field value
        if (field.contentType === 'hiddenFormFieldComponent') {
          formValues[field.name] = field.value
        }
      })
      googleReCaptchaProps
            .executeRecaptcha()
            .then((token) => {
              formData.append('token', token)
              this.postData(formValues)
            })
            .catch((error) => {
              this.setState({
                submittErr: true,
                submitting: false
              })
              throw new Error(error)
            })
    }
  }

  render() {
    const { formClass, data, theme, containerClass, compact } = this.props
    const containerFieldsProp = data.formContainerFields &&
      data.formContainerFields.fields && {
        containerFields: data.formContainerFields.fields
      }
    const { thankYou, submittErr } = this.state
    const formClasses = classNames(styles.form, {
      [styles.hide]: thankYou,
      [styles[theme]]: theme
    })
    const siteText = {
      send: send(),
      formFieldsRequired: formFieldsRequired(),
      formErrorMessage: formErrorMessage(),
      yes: subscribeYes(),
      no: subscribeNo(),
      salutationOne: salutationOne(),
      salutationTwo: salutationTwo(),
      salutationThree: salutationThree(),
      mailChimpListId: mailChimpListId(),
      date: date()
    }
    return (
      <div className={containerClass}>
        <div className={formClasses}>
          <span className={styles.error}>{siteText.formFieldsRequired} </span>
          {submittErr && (
            <span className={styles.error}> {siteText.formErrorMessage} </span>
          )}
          <div className={formClass}>
            <ContactForm
              onSubmit={this.handleSubmit}
              fields={data.fields}
              {...containerFieldsProp}
              error={this.validate}
              siteText={siteText}
              locale={this.props.locale}
              compact={compact}
              submitting={this.state.submitting}
            />
          </div>
        </div>
        {data.message && (
          <div className={styles.message}>
            <Markdown markdownHtml={data.message} />
          </div>
        )}
        {this.renderThankyouMessage(data)}
      </div>
    )
  }
}

export default withGoogleReCaptcha(SalesforceForm)
