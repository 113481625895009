import React from 'react'
import PropTypes from 'prop-types'

import Generic from './generic'

const Generics = (props) => {
  const { components, endPoint } = props
  return [
    components &&
      components.map((d) => (
        <Generic components={d} key={d.id} endPoint={endPoint} />
      ))
  ]
}

Generics.propTypes = {
  components: PropTypes.array.isRequired,
  endPoint: PropTypes.string
}
Generics.defaultProps = {
  endPoint: ''
}
export default Generics
