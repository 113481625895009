import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './arrow.css'

const Arrow = ({ direction, active, usePresetSpacing, viewBox }) => (
  <svg
    viewBox={viewBox}
    className={classNames({
      [styles.arrow]: true,
      [styles.up]: direction === 'up',
      [styles.right]: direction === 'right',
      [styles.left]: direction === 'left',
      [styles.active]: active,
      [styles.defaultSpacing]: usePresetSpacing
    })}
  >
    <path d="M270.3 58.65L153 175.95 35.7 58.65 0 94.35l153 153 153-153" />
  </svg>
)

Arrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  active: PropTypes.bool,
  usePresetSpacing: PropTypes.bool,
  viewBox: PropTypes.string
}

Arrow.defaultProps = {
  direction: 'down',
  active: false,
  usePresetSpacing: false,
  viewBox: '0 0 306 306'
}

export default Arrow
