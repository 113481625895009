/* global window */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { FiftySix, Markdown, OnVisible } from '../'

import styles from './productIngredients.css'
import ingredientStyles from './ingredientsstyles.css'

class ProductIngredients extends Component {

  renderIngredients(images) {
    if (!images) return null
    return images.map((image, idx) => (
      <img src={image.url} alt={this.title} key={idx} />
    ))
  }
  render() {
    const ingredients = this.props.ingredients
    return (
      <OnVisible
        className={styles.transitionStart}
        visibleClassName={styles.transitionEnd}
        percent={-10}
      >
        <div className={styles.productIngredients}>
          <div className={styles.productIngredientsContainer}>
            <div className={ingredientStyles.ingredients}>
              {this.renderIngredients(ingredients.images)}
            </div>
          </div>
          <div
            className={classNames(styles.textRow, {
              [styles.textRowRight]: this.props.textRight
            })}
          >
            <div className={styles.text}>
              {this.props.fiftySix && <FiftySix />}
              <Markdown markdownHtml={ingredients.text} styles={this.props.markdownStyles} />
            </div>
          </div>
        </div>
      </OnVisible>
    )
  }
}

ProductIngredients.propTypes = {
  ingredients: PropTypes.object.isRequired,
  fiftySix: PropTypes.bool,
  markdownStyles: PropTypes.object,
  textRight: PropTypes.bool
}

ProductIngredients.defaultProps = {
  fiftySix: false,
  markdownStyles: null,
  textRight: false
}

export default ProductIngredients
