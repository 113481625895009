
import { TOGGLE_FILTER } from '../actions/filter'

export default function (state = {}, action) {
  let hasChanged = false
  let newState
  switch (action.type) {
    case TOGGLE_FILTER: {
      hasChanged = true
      newState = { ...state }
      newState[action.filterKey] = { ...(newState[action.filterKey] || {}) }
      newState[action.filterKey][action.optionKey] = !newState[action.filterKey][action.optionKey]
      break
    }
    default:
      break
  }

  return hasChanged ? newState : state
}
