import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'

import styles from './loading.css'

const transitionClasses = {
  enter: styles.loading,
  enterActive: styles.active,
  leave: styles.unload,
  leaveActive: styles.unloadActive
}
const Loading = () => (<CSSTransitionGroup
  transitionName={transitionClasses}
  transitionEnterTimeout={400}
  transitionLeaveTimeout={300}
>
  <div className={styles.loaderContainer}>
    <div className={styles.loader}>
      <div className={styles.circles}>
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
        <div className={styles.floatingCircle} />
      </div>
    </div>
  </div>
</CSSTransitionGroup>
)

export default Loading
