import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './artistsocialmedialinks.css'

import * as socialIcons from '../../svgs/artistsocialIcons/artistsocialicons'

const socialIcon = (icon) => {
  const iconName = icon.toLowerCase()
  switch (iconName) {
    case 'facebook':
      return socialIcons.facebook()
    case 'twitter':
      return socialIcons.twitter()
    case 'instagram':
      return socialIcons.instagram()
    case 'bandcamp':
      return socialIcons.bandcamp()
    case 'youtube':
      return socialIcons.youtube()
    case 'mixcloud':
      return socialIcons.mixcloud()
    case 'soundcloud':
      return socialIcons.soundcloud()
    case 'website':
      return socialIcons.website()
    default:
      return null
  }
}
const ArtistSocialMediaLinks = (props) => {
  const trackClick = props.trackClick
  const { data, musicPage } = props
  return (
    <div
      className={classNames(styles.followLinks, {
        [styles.musicPageSocialLinks]: musicPage
      })}
    >
      {data && data.title && <h3 className={styles.heading}>{data.title}</h3>}
      {data &&
        data.links &&
        data.links.map((d, idx) => (
          <div className={styles.socialMediaLink} key={d.id + idx}>
            <a
              key={d.id + idx}
              className={styles.socialMediaLinks}
              onClick={() => trackClick(d.text)}
              href={d.link && d.link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {d.text && socialIcon(d.text)}
              {!musicPage && (
                <span className={styles.socialMediaIcon}> {d.text} </span>
              )}{' '}
            </a>
          </div>
        ))}
    </div>
  )
}

ArtistSocialMediaLinks.propTypes = {
  data: PropTypes.object.isRequired,
  trackClick: PropTypes.func.isRequired,
  musicPage: PropTypes.bool
}

ArtistSocialMediaLinks.defaultProps = {
  musicPage: false
}

export default ArtistSocialMediaLinks
