let height = 800

const setHeight = (itemHeight, withCheck) => {
  if (withCheck) {
    if (itemHeight > height) {
      height = itemHeight
    }
  } else {
    height = itemHeight
  }
}

const getHeight = () => {
  return height
}

export { setHeight, getHeight }
