// Standard tile used by the grid

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './title.css'
import { Markdown } from '../'
import { overrideEntryTitle } from '../helpers'

const Title = ({ title, theme, headingLevel, bestNights }) => {
  const BN = bestNights
  return (
    <div className={styles.headline}>
      {(headingLevel &&
        headingLevel !== 'Original default' &&
        overrideEntryTitle({
          headline: title,
          headingLevel,
          className: classNames(styles.title, {
            [styles.h1title]: theme === 'musicPage',
            [styles.defaultTitle]: !theme || theme !== 'musicPage'
          })
        })) || (
        <h1
          className={classNames(BN ? styles.titleBN : '', styles.title, {
            [styles.h1title]: theme === 'musicPage',
            [styles.defaultTitle]: !theme || theme !== 'musicPage'
          })}
        >
          <Markdown markdownHtml={title} />
        </h1>
      )}
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.string,
  headingLevel: PropTypes.string,
  bestNights: PropTypes.bool
}

Title.defaultProps = {
  theme: '',
  bestNights: false
}

export default Title
