import React from 'react'

const QuoteIcon = () => (
  <svg viewBox="0 0 42 42">
    <path
      d="M0.3,24.7c0.2-7.3,1.4-12.6,3.5-15.8C5.9,5.6,9.5,4,14.8,4l1.4,6.1c-2.9,0.1-4.8,1-5.8,2.8c-1,1.8-1.5,4.5-1.5,8h8.4V38
H0.1C0.1,34.3,0.1,29.8,0.3,24.7z M24.9,24.7c0.2-7.3,1.4-12.6,3.5-15.8c2.1-3.3,5.8-4.9,11-4.9l1.4,6.1c-2.9,0.1-4.8,1-5.8,2.8
c-1,1.8-1.5,4.5-1.5,8h8.4V38H24.7C24.7,34.3,24.7,29.8,24.9,24.7z"
    />
  </svg>
)

export default QuoteIcon
