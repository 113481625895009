import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Img,
  Markdown,
  OnVisible,
  Generic,
  HalfWidthPngPopup,
  VideoFeature
} from '../'
import { Button, ScrollToButton } from 'containers'
import { overrideEntryTitle } from '../helpers'

import styles from './halfwidthpng.css'
const config = {
  noText: {
    textClass: ''
  },
  imageLeftTextMiddleCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'left'
  },
  imageLeftTextBottomCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textBottom,
    imagePosition: 'left'
  },
  imageLeftTextTopCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textTop,
    imagePosition: 'left'
  },
  imageRightTextTopCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textTop,
    imagePosition: 'right'
  },
  imageRightTextBottomCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textBottom,
    imagePosition: 'right'
  },
  imageRightTextMiddleCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'right'
  },
  imageCenterTextMiddleLeft: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'center'
  },
  heroImageLeftTextMiddleCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'left'
  },
  heroImageLeftTextBottomCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textBottom,
    imagePosition: 'left'
  },
  heroImageRightTextBottomCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textBottom,
    imagePosition: 'right'
  },
  heroImageRightTextMiddleCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'right'
  },
  heroImageCenterTextMiddleLeft: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'center'
  }
}

const HalfWidthPng = (props) => {
  const {
    image,
    headline,
    headingLevel,
    text,
    mode,
    deepLink,
    buttons,
    video,
    popupTitle,
    popupText,
    readMoreText
  } = props.data
  const ishero = 'hero'
  const videoInfo = video
  const isVideo = videoInfo ? true : false
  const {
    historyOne,
    historyTwo,
    historyThree,
    jagertalesHero,
    textToTheRight,
    circleImage,
    withCaption,
    textLonger,
    textLongest,
    headlineClass,
    imageClass,
    contentWidthClass,
    animationOffsetPercent,
    reverseMobile,
    theme,
    useColdBrewNewAssets,
    bestNights,
    contentType
  } = props
  if (bestNights) {
    config.imageLeftTextMiddleCenter.textHorizontalClass = styles.textAlignBN
    config.imageRightTextMiddleCenter.textHorizontalClass = styles.textAlignBN
    config.imageRightTextMiddleCenter.headlineClass = classNames(
      styles.headline
    )
  }

  const wrapperClasses = classNames(
    styles.wrapper,
    styles.wrapperPaddingBottom,
    {
      [styles.wrapperReverse]: config[mode].imagePosition === 'right',
      [styles.wrapperCenter]: config[mode].imagePosition === 'center',
      [styles.wrapperHistoryOne]: historyOne,
      [styles.wrapperHistoryTwo]: historyTwo,
      [styles.wrapperReverseMobile]: reverseMobile,
      [styles.jagertalesHero]: jagertalesHero,
      [styles.wrapperBN]: bestNights
    }
  )

  const wrapperClassesHero = classNames(
    styles.wrapperHero,
    styles.wrapperPaddingBottom,
    {
      [styles.wrapperReverse]: config[mode].imagePosition === 'right',
      [styles.wrapperCenter]: config[mode].imagePosition === 'center',
      [styles.wrapperHistoryOne]: historyOne,
      [styles.wrapperHistoryTwo]: historyTwo,
      [styles.wrapperReverseMobile]: reverseMobile,
      [styles.jagertalesHero]: jagertalesHero
    }
  )
  const textClasses = classNames(
    styles.text,
    config[mode].textHorizontalClass,
    config[mode].textVerticalClass
  )
  const textInnerClasses = classNames(styles.textInner, {
    [styles.textToTheRight]: textToTheRight,
    [styles.textLonger]: textLonger,
    [styles.innerTwentyFive]:
      config[mode].textVerticalClass === styles.textMiddle,
    [styles.textLongest]: textLongest
  })

  const imageClasses = classNames(styles.image, {
    [styles.imageCircled]: circleImage,
    [styles.imageFullWidth]: config[mode].imagePosition === 'center',
    [styles.imageHistoryThree]: historyThree
  })
  // Check whether to use the new background image, use different background image from style
  const transitionEnd = classNames(styles.transitionEnd, {
    [styles.coldBrew]: theme === 'coldBrew' && !useColdBrewNewAssets,
    [styles.coldBrew2022]: theme === 'coldBrew' && useColdBrewNewAssets
  })

  const [isPopupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const body = document.querySelector('body')
    const nav = document.querySelector('nav')
    body.style.overflow = isPopupOpen ? 'hidden' : 'auto'
    nav.style.display = isPopupOpen ? 'none' : 'block'
    console.log('nav', nav.style)
  }, [isPopupOpen])

  return (
    <OnVisible
      className={classNames(
        theme === 'BestNights' ? styles.blackBackgroundBN : '',
        styles.transitionStart
      )}
      visibleClassName={transitionEnd}
      percent={animationOffsetPercent}
    >
      <div
        className={mode.includes(ishero) ? wrapperClassesHero : wrapperClasses}
      >
        <div
          className={classNames(
            theme === 'BestNights' ? styles.columnBN : '',
            isVideo ? styles.videoColumn : ''
          )}
        >
          {isVideo ? (
            <div
              className={classNames(
                imageClass || imageClasses,
                styles.videoWrapper
              )}
            >
              <VideoFeature
                data={videoInfo}
                key={videoInfo.id}
                tracking={() => {}}
                theme={theme}
                isHalfWidthPng={true}
              />
            </div>
          ) : (
            <div className={imageClass || imageClasses}>
              {image && <Img src={image.url} alt={image.title} sizes="50vw" />}
              {withCaption && (
                <span className={styles.caption}>{image.description}</span>
              )}
            </div>
          )}
        </div>
        <div
          className={classNames(
            styles.column,
            {
              [styles.withComponents]:
                contentType === 'halfWidthPngGenericComponent'
            },
            isVideo ? styles.videoText : ''
          )}
        >
          <div className={textClasses || ''}>
            <div className={contentWidthClass || textInnerClasses}>
              {(headline &&
                headingLevel &&
                headingLevel !== 'Original default' &&
                overrideEntryTitle({
                  headline,
                  headingLevel,
                  className: classNames(
                    headlineClass,
                    config[mode].headlineClass,
                    bestNights ? styles.headlineNoStrike : ''
                  )
                })) || (
                <div
                  className={classNames(
                    headlineClass,
                    config[mode].headlineClass,
                    bestNights ? styles.headlineNoStrike : ''
                  )}
                >
                  <Markdown
                    inline
                    markdownHtml={headline}
                    contentType={contentType}
                    theme={theme}
                  />
                </div>
              )}
              <div
                className={classNames(styles.markdown, styles.textTranslate)}
              >
                <Markdown
                  markdownHtml={text}
                  contentType={contentType}
                  theme={theme}
                />
                {deepLink && (
                  <div>
                    {/* maintaining this for legacy reasons, otherwise, all buttons should be rendered through the function below */}
                    <ScrollToButton
                      data={deepLink}
                      theme={theme}
                      trackingEvent={deepLink.trackingEvent}
                    />
                  </div>
                )}
                {isVideo && popupText && (
                  <span
                    className={styles.popupOpenBtn}
                    onClick={() => setPopupOpen(true)}
                  >
                    {readMoreText}
                  </span>
                )}
                {buttons &&
                  buttons.length > 0 &&
                  buttons.map((btn) => {
                    if (btn.contentType === 'scrollToButton') {
                      return (
                        <ScrollToButton
                          key={btn.id}
                          data={btn}
                          theme={theme}
                          trackingEvent={btn.trackingEvent}
                        />
                      )
                    } else if (btn.contentType === 'button') {
                      return (
                        <Button
                          key={btn.id}
                          href={btn.link}
                          className={classNames({
                            [styles.secondaryBtn]: btn.style === 'secondary',
                            [styles.primaryBtn]: btn.style === 'primary'
                          })}
                          trackingEvent={btn.trackingEvent}
                        >
                          {btn.text}
                        </Button>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
          {contentType === 'halfWidthPngGenericComponent' &&
            props.data.components &&
            props.data.components.length > 0 && (
              <div className={styles.subComponentsContainer}>
                {props.data.components.map((component) => (
                  <Generic
                    components={component}
                    key={component.id}
                    config={props.config}
                    userData={props.userData}
                    endpoint={props.endpoint}
                    theme={theme}
                    force
                    alignRight={config[mode].imagePosition === 'right'}
                  />
                ))}
              </div>
            )}
        </div>
      </div>
      <HalfWidthPngPopup
        trigger={isPopupOpen}
        setTrigger={setPopupOpen}
        popupTitle={popupTitle}
        popupText={popupText}
        theme={theme}
        contentType={contentType}
      />
    </OnVisible>
  )
}

HalfWidthPng.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string,
    mode: PropTypes.oneOf(Object.keys(config)),
    popupTitle: PropTypes.string,
    popupText: PropTypes.string,
    readMoreText: PropTypes.string
  }).isRequired,
  circleImage: PropTypes.bool,
  withCaption: PropTypes.bool,
  textToTheRight: PropTypes.bool,
  textLonger: PropTypes.bool,
  historyOne: PropTypes.bool,
  historyTwo: PropTypes.bool,
  historyThree: PropTypes.bool,
  headlineClass: PropTypes.string,
  imageClass: PropTypes.string,
  contentWidthClass: PropTypes.string,
  animationOffsetPercent: PropTypes.number,
  reverseMobile: PropTypes.bool,
  bestNights: PropTypes.bool
}

HalfWidthPng.defaultProps = {
  data: {
    image: { url: '', title: '' },
    headline: '',
    text: '',
    mode: 'imageRightTextBottomCenter',
    popupTitle: '',
    popupText: '',
    readMoreText: ''
  },
  circleImage: false,
  withCaption: false,
  textToTheRight: false,
  textLonger: false,
  historyOne: false,
  historyTwo: false,
  historyThree: false,
  headlineClass: '',
  imageClass: '',
  contentWidthClass: '',
  animationOffsetPercent: 2,
  reverseMobile: false,
  bestNights: false
}

export default HalfWidthPng
