/**
 * Taken from: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 * used to hash a string with SHA-256 hash algorithim
 * @param {string} message string to hash
 * @returns hashed string
 */
export function digestMessage(message) {
  // encode as (utf-8) Uint8Array
  const msgUint8 = new TextEncoder().encode(message)
  // hash the message
  return crypto.subtle.digest('SHA-256', msgUint8).then((hashBuffer) => {
    // convert buffer to byte array
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('') // convert bytes to hex string
    return hashHex
  }) // hash the message
}
