export const PAGE_VIEW = 'PAGE_VIEW'
export const VIDEO_PLAY = 'VIDEO_PLAY'
export const EVENT = 'EVENT'
export const HASH_EMAIL = 'HASH_EMAIL'

export function trackPageView(payload) {
  return {
    type: PAGE_VIEW,
    payload
  }
}
export function trackVideo(payload) {
  return {
    type: VIDEO_PLAY,
    payload
  }
}
export function trackEvent(payloadProp) {
  const payload = payloadProp
  if (!Object.prototype.hasOwnProperty.call(payload, 'event')) {
    payload.event = 'Undefined Event'
  }
  return {
    type: EVENT,
    payload
  }
}

export function hashEmail(payload) {
  return {
    type: HASH_EMAIL,
    payload
  }
}
