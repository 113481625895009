import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { Markdown } from 'components'
import {
  send,
  formErrorMessage,
  formFieldsRequired,
  subscribeNo,
  subscribeYes,
  salutationOne,
  salutationTwo,
  salutationThree,
  mailChimpListId,
  date,
  charactersCounter
} from 'utils/i18n'
import fetch from '../../lib/fetch'
import styles from './form.css'
import ContactForm from './ContactForm'

class Form extends Component {
  constructor(...rest) {
    super(...rest)
    this.endPoint = ''
    this.formType = ''
    this.state = {
      renderForm: false,
      thankYou: false,
      submitResult: null,
      submitting: false,
      submittErr: false
    }
  }

  handleSubmit = (values) => {
    this.props.trackOnSubmit(this.props.locale, values.email)
    this.validateForm(values)
  }

  validateForm = (values) => {
    if (!this.state.submitting) {
      this.setState({
        submitResult: null,
        submitting: true
      })
      const formData = values
      formData.formType = this.formType
      formData.locale = this.props.locale
      this.props.data.fields.forEach((field) => {
        if (
          field.contentType === 'hiddenFormFieldComponent' ||
          field.type === 'mailChimp' ||
          field.type === 'tag' ||
          field.type === 'interestId'
        ) {
          formData[field.type] = field.label
        }
      })
      fetch(this.endPoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
        .then((response) => {
          if (response.ok) {
            this.setState({
              submitResult: false,
              thankYou: true,
              submitting: false
            })
            this.props.onSuccess(formData)
            this.props.trackOnSuccess()
          } else {
            this.setState({
              submitResult: false,
              submittErr: true,
              thankYou: false,
              submitting: false
            })
            throw new Error('Network response was not ok.')
          }
        })
        .catch((error) => {
          this.setState({
            submittErr: true,
            submitting: false
          })
          throw new Error(error)
        })
    }
  }
  renderForm(id) {
    const arrNum = id - 1
    const formUpdate = {
      arryIndex: arrNum,
      renderForm: true
    }
    this.setState(formUpdate)
  }
  renderThankyouMessage(content) {
    const { thankYou } = this.state
    const css = {
      visible: styles.show
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.thankYou, {
      visible: thankYou
    })
    return (
      <div className={classes}>
        <h2>{content.successTitle}</h2>
        <Markdown markdownHtml={content.successMessage} />
      </div>
    )
  }
  renderPreSubmitMessage(content) {
    const { thankYou } = this.state
    const css = {
      visible: styles.show
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.thankYou, {
      visible: !thankYou
    })
    return (
      <div className={classes}>
        <Markdown markdownHtml={content.preSubmitMessage} />
      </div>
    )
  }
  render() {
    const {
      formClass,
      data,
      endPoint,
      theme,
      containerClass,
      compact
    } = this.props
    this.endPoint = endPoint
    this.formType = data.formType
    const containerFieldsProp = data.formContainerFields &&
      data.formContainerFields.fields && {
        containerFields: data.formContainerFields.fields
      }
    const { thankYou, submittErr } = this.state
    const formClasses = classNames(styles.form, {
      [styles.hide]: thankYou,
      [styles[theme]]: theme
    })
    const siteText = {
      send: send(),
      formFieldsRequired: formFieldsRequired(),
      formErrorMessage: formErrorMessage(),
      yes: subscribeYes(),
      no: subscribeNo(),
      salutationOne: salutationOne(),
      salutationTwo: salutationTwo(),
      salutationThree: salutationThree(),
      mailChimpListId: mailChimpListId(),
      date: date(),
      charactersCounter: charactersCounter()
    }
    return (
      <div className={containerClass}>
        <div className={formClasses}>
          <span className={styles.error}>{siteText.formFieldsRequired} </span>
          {submittErr && (
            <span className={styles.error}> {siteText.formErrorMessage} </span>
          )}
          <div className={formClass}>
            <ContactForm
              onSubmit={this.handleSubmit}
              fields={data.fields}
              {...containerFieldsProp}
              error={this.validate}
              endPoint={this.endPoint}
              siteText={siteText}
              locale={this.props.locale}
              compact={compact}
              autoFocusField={data.autoFocusField}
            />
          </div>
        </div>
        {data.message && (
          <div className={styles.message}>
            <Markdown markdownHtml={data.message} />
          </div>
        )}
        {this.renderThankyouMessage(data)}
        {data.preSubmitMessage && this.renderPreSubmitMessage(data)}
      </div>
    )
  }
}
Form.propTypes = {
  formClass: PropTypes.string,
  data: PropTypes.object.isRequired,
  endPoint: PropTypes.string.isRequired,
  locale: PropTypes.string,
  onSuccess: PropTypes.func,
  theme: PropTypes.string,
  trackOnSubmit: PropTypes.func,
  trackOnSuccess: PropTypes.func
}
Form.defaultProps = {
  formClass: '',
  locale: 'en',
  theme: null,
  onSuccess: () => {},
  trackOnSubmit: () => {},
  trackOnSuccess: () => {}
}
export default Form
