import { connect } from 'react-redux'
import Footer from 'components/footer/Footer'
import { trackEvent } from 'actions/analytics'

function mapDispatchToProps(dispatch, ownProps) {
  return {
    trackNavigationClick: (navItemName) => {
      dispatch(
        trackEvent({
          event: 'Site Navigation',
          eventType: 'Footer Bar Bottom Click',
          eventDetail: navItemName
        })
      )
    },
    trackSocialClick: (socialNetworkName) => {
      dispatch(
        trackEvent({
          event: 'Social',
          SocialContentId: 'Footer Bar',
          eventDetail: socialNetworkName
        })
      )
    },
    firstRowNav: ownProps.content.navigation.footerFirstRow,
    secondRowNav: ownProps.content.navigation.footerSecondRow,
    socialLinks: ownProps.content.socialLinks,
    cookiesConsentBanner: ownProps.content.cookiesConsentBanner,
    cookiesSettingsBtn: ownProps.content.cookiesSettingsBtn,
    footerImageStrip: ownProps.content.footerImageStrip
  }
}

export default connect(null, mapDispatchToProps)(Footer)
