import React, { Component } from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'
import classNames from 'classnames'
import 'videojs-youtube'
// TODO: Put videojs stuff into client.js and only load on client?
import { OnVisible } from '../'
import styles from './videoplayer.css'

class VideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.listenToMute = this.listenToMute.bind(this)
    this.isComponentVisible = this.isComponentVisible.bind(this)
    this.parsedOptions = JSON.parse(this.props.options)
    this.state = {
      isMuted: false,
      visible: false,
      showTint:
        this.parsedOptions.tint === undefined ||
        this.parsedOptions.tint === null
          ? true
          : this.parsedOptions.tint
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.triggerPlay !== this.props.triggerPlay &&
      this.props.triggerPlay
    ) {
      this.play()
    }
    if (
      prevProps.triggerPause !== this.props.triggerPause &&
      this.props.triggerPause
    ) {
      this.pause()
    }
  }
  componentDidMount() {
    const isHalfWidthPng = this.props.isHalfWidthPng
    this.parsedOptions.controlBar = {
      children: [
        'playToggle',
        'progressControl',
        'currentTimeDisplay',
        'durationDisplay',
        'volumeMenuButton'
      ]
    }

    this.parsedOptions.inactivityTimeout = 1500
    this.player = videojs(this.videoNode, this.parsedOptions)
    let videoPlayer = this.player
    let windowBreakpoint = window.matchMedia('(max-width: 480px)')
    let poster = this.props.poster
    let posterMobile = this.props.posterMobile
    videoPlayer.poster(this.props.poster)
    isHalfWidthPng
      ? videoPlayer.aspectRatio('9:16')
      : videoPlayer.aspectRatio('16:9')
    if (
      typeof this.parsedOptions.sources[1] !== 'undefined' &&
      this.parsedOptions.sources[1].src !== ''
    ) {
      if (windowBreakpoint.matches) {
        videoPlayer.src(this.parsedOptions.sources[1])
        videoPlayer.poster(posterMobile)
        videoPlayer.aspectRatio('9:16')
      }
    }
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    window.addEventListener('resize', () => {
      // let options = JSON.parse(this.props.options)
      let windowNewWidth = window.innerWidth
      let windowNewHeight = window.innerHeight
      /*console.log('vecchia larghezza: ' + windowWidth)
      console.log('vecchia altezza: ' + windowHeight)
      console.log('larghezza attuale: ' + windowNewWidth)
      console.log('altezza attuale: ' + windowNewHeight)*/
      if (windowNewWidth !== windowWidth) {
        windowWidth = windowNewWidth
        windowHeight = windowNewHeight
        if (
          typeof this.parsedOptions.sources[0] !== 'undefined' &&
          this.parsedOptions.sources[0].src !== ''
        ) {
          setTimeout(() => {
            // let options = JSON.parse(this.props.options)
            if (windowBreakpoint.matches) {
              videoPlayer.src(this.parsedOptions.sources[1])
              videoPlayer.poster(posterMobile)
              videoPlayer.aspectRatio('9:16')
            } else {
              videoPlayer.src(this.parsedOptions.sources[0])
              videoPlayer.poster(poster)
              isHalfWidthPng
                ? videoPlayer.aspectRatio('9:16')
                : videoPlayer.aspectRatio('16:9')
              /*console.log('source desktop')*/
            }
          }, 100)
        }
      }
    })
    // implementation of intersection observer to automatically play video
    const startVideo = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          //console.log('entered')
          videoPlayer.muted(true)
          videoPlayer.play()
        } else {
          //console.log('exiting')
          videoPlayer.pause()
        }
      })
    }
    if (this.props.playsinline) {
      let videoTarget = this.player.el_
      let autoPlayOptions = {
        threshold: 0.5
      }
      let observer = new IntersectionObserver(startVideo, autoPlayOptions)
      observer.observe(videoTarget)
    }
    // end of intersection observer implementation
    this.player.on('volumechange', this.listenToMute)
    this.player.on('play', this.onPlay)
    this.player.on('pause', this.onPause)
    this.player.on('ended', this.videoEnd)
    /*if (this.props.options.autoplay) {
      const playPromise = this.player.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // video played successfully
          })
          .catch((e) => {
            // unmute video if it fails to autoplay
            this.player.muted(false)
            this.setState({ isMuted: false })
          })
      }
    }*/
    if (!this.state.showTint) {
      this.player.removeClass('vjs-paused')
    }
    if (this.parsedOptions && this.parsedOptions.autoplay) {
      this.player.muted(true)
    }
    if (this.props.triggerPlay && !this.parsedOptions.autoplay) {
      this.play()
    }
  }
  onPlay = (e) => {
    this.tracking(e)
  }

  play = () => {
    this.player.play().catch((error) => {
      // ! video failed to play because there was no user interaction registered
    })
  }

  pause = () => {
    this.player.pause()
  }
  onPause = (e) => {
    this.tracking(e)
    if (!this.state.showTint) {
      this.player.removeClass('vjs-paused')
    }
  }
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
  setVideoNode(node) {
    this.videoNode = node
  }
  isComponentVisible = (isVisible) => {
    if (isVisible) {
      this.setState({
        visible: isVisible
      })
    }
  }
  listenToMute = () => {
    const muted = {
      type: this.parsedOptions.autoplay ? 'muted' : 'unmuted'
    }
    this.setState({
      isMuted: !this.parsedOptions.autoplay
    })
    this.tracking(muted)
  }

  tracking = (el) => {
    const videoTracking = {
      type: el.type,
      id: this.parsedOptions.id
    }
    this.props.tracking(videoTracking)
  }

  videoEnd = () => {
    this.props.videoEnded()
  }

  render() {
    let options = this.parsedOptions
    return (
      <OnVisible
        className={styles.videoContainer}
        onChange={this.isComponentVisible}
        visibleClassName={styles.visible}
        percent={-10}
        key={options.id}
      >
        <div
          key={options.id}
          className={classNames({
            [styles.muted]: this.state.isMuted
          })}
        >
          <div data-vjs-player>
            <video
              playsInline={this.props.playsinline}
              muted={this.state.isMuted}
              ref={(node) => this.setVideoNode(node)}
              /*poster={this.props.poster}*/
              className={'video-js ' + this.props.mobileClass}
            />
          </div>
        </div>
      </OnVisible>
    )
  }
}

VideoPlayer.propTypes = {
  options: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  tracking: PropTypes.func.isRequired,
  poster: PropTypes.string,
  playsinline: PropTypes.bool.isRequired,
  videoEnded: PropTypes.func,
  triggerPause: PropTypes.bool,
  triggerPlay: PropTypes.bool
}
VideoPlayer.defaultProps = {
  poster: '',
  isVisible: false,
  triggerPause: false,
  triggerPlay: false,
  videoEnded: () => {}
}
export default VideoPlayer
