import React from 'react'
import PropTypes from 'prop-types'
import { Markdown } from '../'

import styles from './halfwidthpngpopup.css'
function HalfWidthPngPopup(props) {
  return props.trigger ? (
    <div className={styles.popupContainer}>
      <div className={styles.textBox}>
        <div className={styles.textWrapper}>
          <h1>{props.popupTitle}</h1>
          <Markdown
            inline
            markdownHtml={props.popupText}
            contentType={props.contentType}
            theme={props.theme}
          />
        </div>
        <div
          className={styles.closeBtn}
          onClick={() => props.setTrigger(false)}
        >
          <span className={styles.closeImg}></span>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

HalfWidthPngPopup.defaultProps = {
  popupTitle: '',
  popupText: '',
  isPopupOpen: false,
  theme: '',
  contentType: ''
}
HalfWidthPngPopup.propTypes = {
  popupTitle: PropTypes.string,
  popupText: PropTypes.string,
  isPopupOpen: PropTypes.bool,
  theme: PropTypes.string,
  contentType: PropTypes.string
}

export default HalfWidthPngPopup
