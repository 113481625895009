import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import {
  Generic,
  HalfWidthPng,
  HalfWidthPngChart,
  FullBleedImage,
  FullWidthPng,
  MetaData,
  OnVisible
} from 'components'
import { Recommendations } from 'containers'

import { prost } from 'utils/i18n'

import split from '../../../lib/split'
import styles from './scharf.css'
const siteText = {
  prost: prost()
}
class ScharfPage extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false,
      started: false
    }
  }
  componentDidMount() {
    this.setState({
      showText: true
    })
  }
  onVisible = (isVisible) => {
    if (isVisible && !this.state.started) {
      this.setState({
        started: true
      })
    }
    if (!isVisible) {
      this.setState({
        started: false
      })
    }
  }
  componentRender = (components, number) => {
    return (
      (components.contentType === 'fullBleedImageComponent' && (
        <FullBleedImage
          key={number}
          data={components}
          headlineClass={styles.headlineScharf}
          textInnerClass={number < 1 ? styles.titleText : ''}
          reverseMobile={number > 1 ? true : false} //can't think of a better way to identify the last fullbleed image component on teh scharf page
        />
      )) ||
      (components.contentType === 'fullWidthPngComponent' && (
        <FullWidthPng data={components} />
      )) ||
      (components.contentType === 'halfWidthPngComponentChart' && (
        <HalfWidthPngChart
          data={components}
          key={number}
          headlineClass={styles.headlineScharf}
          imageClass={styles.scharfImage}
          contentWidthClass={styles.innerChartText}
          reverseMobile
        />
      )) ||
      (components.contentType === 'halfWidthPngComponent' && (
        <HalfWidthPng
          data={components}
          key={number}
          headlineClass={styles.headlineScharf}
          imageClass={styles.scharfImage}
          contentWidthClass={styles.innerText}
        />
      )) || (
        <Generic
          components={components}
          contentType={components.contentType}
          theme="scharf"
          endPoint={this.props.endPoint}
        />
      )
    )
  }
  render() {
    const { content, trackingVideo, endPoint } = this.props
    const { showText } = this.state
    const components = content.components
    const secondaryComponents = content.secondaryComponents
    const hero = content.hero
    const transitionClasses = {
      title: {
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterActive,
        leave: styles.leave,
        leaveActive: styles.leaveActive
      }
    }
    const classes = classNames(styles.title, {
      [styles.titleEnterActive]: showText
    })
    //
    return (
      <main className={styles.scharf} role="main" id="mainContent">
        <MetaData metadata={content.metadata} />{' '}
        {hero && (
          <section className={styles.header}>
            {hero && hero.contentType === 'fullBleedImageComponent' && (
              <FullBleedImage data={hero} theme="scharf" />
            )}{' '}
          </section>
        )}{' '}
        {components &&
          components.map((d, idx) => (
            <section key={d.id} className={styles.divider}>
              {' '}
              {this.componentRender(d, idx)}{' '}
            </section>
          ))}
        <OnVisible
          onChange={this.onVisible}
          className={styles.scharfometer}
          visibleClassName={styles.visible}
          percent={30}
        >
          <div className={styles.scharfometerBar} />
        </OnVisible>
        <OnVisible
          onChange={this.onVisible}
          className={styles.scharfProst}
          visibleClassName={styles.visible}
          percent={-30}
        >
          <h2 className={styles.scharfProstTitle}>
            {split(siteText.prost, styles.scharfTitle)}
          </h2>
        </OnVisible>
        {secondaryComponents &&
          secondaryComponents.map((d, idx) => (
            <section key={d.id} className={styles.divider}>
              {this.componentRender(d, idx)}
            </section>
          ))}
        {content.recommendations && (
          <Recommendations recommendations={content.recommendations} />
        )}{' '}
      </main>
    )
  }
}

ScharfPage.defaultProps = {
  content: {},
  trackingVideo: () => {},
  endPoint: ''
}
ScharfPage.propTypes = {
  content: PropTypes.object,
  endPoint: PropTypes.string,
  shareImage: PropTypes.string,
  trackingVideo: PropTypes.func
}
export default ScharfPage
