import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import scrollToElement from 'scroll-to-element'
import qs from 'qs'
import { Generic } from 'components'

const ScrollToView = ({
  components,
  link,
  idx,
  theme,
  endPoint,
  autoScroll,
  config,
  userData,
  salesforceSyncEndpoint
}) => {
  const stripLink = (deepLink) => {
    const index = deepLink.indexOf('?')
    if (index > -1) {
      return deepLink.substr(0, index)
    } else {
      return deepLink
    }
  }

  useEffect(() => {
    const strippedLink = stripLink(link)
    let timeOut
    if (
      autoScroll &&
      window &&
      window.location.hash &&
      window.location.hash === `#${strippedLink}`
    ) {
      timeOut = setTimeout(() => {
        const el = document.getElementById(strippedLink)
        scrollToElement(el)
      }, 500) // a small timeout is needed to give the dom enough time to mount all the components that are being rendered on the page, so the scroll happens to the correct place.
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut)
      }
    }
  }, [])
  return (
    <div key={idx} id={stripLink(link)}>
      {components &&
        components.map((component, number) => (
          <Generic
            components={component}
            theme={theme}
            contentType={component.contentType}
            key={number}
            endPoint={endPoint}
            userData={userData}
            config={config}
            salesforceSyncEndpoint={salesforceSyncEndpoint}
          />
        ))}
    </div>
  )
}

ScrollToView.propTypes = {
  components: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
  theme: PropTypes.string,
  autoScroll: PropTypes.bool,
  salesforceSyncEndpoint: PropTypes.string
}

ScrollToView.defaultProps = {
  theme: 'default',
  autoScroll: false,
  salesforceSyncEndpoint: null
}

export default ScrollToView
