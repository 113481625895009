// Standard tile used by the grid

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { VideoFeature, Button } from 'components'

import styles from './videotovideo.css'

class VideoToVideo extends Component {
  static defaultProps = {
    videos: {}
  }
  static propTypes = {
    videos: PropTypes.object
  }

  constructor(...props) {
    super(...props)
    this.toggle.bind(this)
    this.state = {
      showVideo: false
    }
  }

  resetVideos = () => {
    this.setState({
      showVideo: false
    })
  }

  toggle(state) {
    this.setState({
      showVideo: state
    })
  }

  renderVideoOne = (videos) => (
    <div className={styles.video}>
      <VideoFeature id="videoOne" data={videos.videoOne}>
        <Button onClick={() => this.toggle(this, true)} className={styles.cta}>
          {videos.button}
        </Button>
      </VideoFeature>
    </div>
  )

  renderVideoTwo = (videos) => {
    const { showVideo } = this.state
    const css = {
      visible: styles.visible
    }
    const cx = classNames.bind(css) // () => { classNames(css) }
    const classes = cx(styles.video, {
      visible: showVideo
    })
    return (
      <div className={classes}>
        <VideoFeature data={videos.videoTwo} videoEnded={this.resetVideos} />
      </div>
    )
  }

  render() {
    const videos = this.props.videos
    const { showVideo } = this.state
    return (
      <div className={styles.container}>
        {showVideo && this.renderVideoTwo(videos)}
        {this.renderVideoOne(videos)}
      </div>
    )
  }
}

export default VideoToVideo
