import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  Article,
  ArtistDetail,
  Contact,
  Drink,
  Drinks,
  EventOverview,
  EventDetail,
  Factory,
  Generic,
  Home,
  History,
  JaegerTales,
  JaegerTalesContent,
  Legal,
  MusicOverview,
  NotFound,
  Product,
  ProductB,
  Products,
  Scharf,
  StagOrNoStag,
  ColdBrew,
  MusicPage,
  BestNightsOverview,
  BestNightsArticle
} from '..'
import { loadTrackingCookie } from '../../lib/agegate'
import { Page as ComposedPage } from '../../hoc'

class Router extends Component {
  // A mapping between contentful types and React components.
  static getCustomRoutes = (content) => {
    const contentTypesThatHaveTopLevelSlugs = {
      homePage: Home,
      genericPage: Generic,
      ProductPage: Product,
      scharfPage: Scharf,
      articlePage: Article,
      legalPages: Legal,
      jaegermeisterProductB: ProductB,
      productsPage: Products,
      historyPage: History,
      factoryTourPage: Factory,
      stagOrNoStagPage: StagOrNoStag,
      contactPage: Contact,
      drinksList: Drinks,
      coldBrewPage: ColdBrew,
      musicPage: MusicPage,
      bestNightsOverviewPage: BestNightsOverview
    }
    let customRoutesList = Object.keys(contentTypesThatHaveTopLevelSlugs)
      .filter((contentType) => {
        return content[contentType]
      })
      .reduce(
        (customRoutes, contentType) => ({
          ...customRoutes,
          ...Object.keys(content[contentType])
            // if content[contentType][slug] is null then don't include it
            .filter((slug) => content[contentType][slug])
            .reduce((slugs, slug) => {
              return {
                ...slugs,
                [slug]: {
                  disableAgeGate: content[contentType][slug].disableAgeGate,
                  disableNavigation:
                    content[contentType][slug].disableNavigation,
                  disableNavigationAndConnectTray:
                    content[contentType][slug].disableNavigationAndConnectTray,
                  disableGlobalPopUp:
                    content[contentType][slug].disableGlobalPopUp,
                  component: contentTypesThatHaveTopLevelSlugs[contentType],
                  contentNode: contentType
                }
              }
            }, {})
        }),
        {}
      )
    // console.log('-------------------HERE') TODO REMOVE DEBUG
    // console.log(customRoutesList)
    /*for (const [key, value] of Object.entries(customRoutesList)) {
      if (key.indexOf('best') >= 0) {
        console.log(`${key}: ${value}`)
      }
    }*/
    // Custom route for best-nights since it is outside of the standard content tree

    return customRoutesList
  }

  constructor(...rest) {
    super(...rest)
    this.topLevelSlugs = Router.getCustomRoutes(
      this.props.content,
      this.props.url
    )
    this.tracking = {
      event: 'VirtualPageview',
      Segment: '',
      url: ''
    }
  }

  render() {
    const redirects = this.props.content.redirect
    this.tracking.Segment = this.props.trackingSegment
    const tracking = this.tracking
    const routes = this.props.customRoutes
    // TODO: we shouldn't need to pass the tracking object to every ComposedPage.
    // The eventual use of this object seems to be:
    //    anaylytics reducer -> trackPageView action -> beacon middleware.
    // We could probably save the required information in the state at this point,
    // then access it again from the redux state when we want to send the tracking event.
    return (
      <Switch>
        <Route
          key={`/${routes.contactRoute}`}
          path={`/${routes.contactRoute}`}
          component={ComposedPage(Contact, {
            disableAgeGate: true,
            tracking,
            contentNode: 'contactPage',
            slug: routes.contactRoute
          })}
        />
        {Object.keys(this.topLevelSlugs).map((slug) => (
          <Route
            exact
            key={slug}
            path={`/${slug}`}
            component={ComposedPage(this.topLevelSlugs[slug].component, {
              slug,
              disableAgeGate: this.topLevelSlugs[slug].disableAgeGate,
              disableGlobalPopUp: this.topLevelSlugs[slug].disableGlobalPopUp,
              disableNavigation: this.topLevelSlugs[slug].disableNavigation,
              disableNavigationAndConnectTray: this.topLevelSlugs[slug].disableNavigationAndConnectTray,
              tracking,
              contentNode: this.topLevelSlugs[slug].contentNode
            })}
          />
        ))}
        <Route
          exact
          path="/"
          component={ComposedPage(Home, {
            slug: 'home',
            tracking,
            contentNode: 'homePage'
          })}
        />
        <Redirect exact from="/home" to="/" />
        <Route
          exact
          path={`/${routes.musicRoute}/`}
          component={ComposedPage(MusicOverview, {
            tracking,
            contentNode: 'musicOverviewPage'
          })}
        />
        <Route
          exact
          path={`/${routes.musicRoute}/:slug`}
          component={ComposedPage(ArtistDetail, {
            tracking,
            contentNode: 'artistDetailPage'
          })}
        />
        <Route
          exact
          path={`/${routes.artistRoute}`}
          component={ComposedPage(ArtistDetail, {
            tracking,
            contentNode: 'artistDetailPage'
          })}
        />
        <Route
          exact
          path={`/${routes.artistRoute}/:slug`}
          component={ComposedPage(ArtistDetail, {
            tracking,
            contentNode: 'artistDetailPage'
          })}
        />
        <Route
          exact
          path={`/${routes.eventsRoute}/`}
          component={ComposedPage(EventOverview, {
            tracking,
            contentNode: 'eventOverviewPage'
          })}
        />
        <Route
          exact
          path={`/${routes.eventsRoute}/:slug`}
          component={ComposedPage(EventOverview, {
            tracking,
            contentNode: 'eventOverviewPage'
          })}
        />
        <Redirect
          exact
          from={`/${routes.eventRoute}`}
          to={`/${routes.eventsRoute}`}
        />
        <Route
          path={`/${routes.eventRoute}/:slug`}
          component={ComposedPage(EventDetail, {
            tracking,
            contentNode: 'eventDetailPage'
          })}
        />
        <Route
          exact
          path={`/${routes.drinksRoute}/`}
          component={ComposedPage(Drinks, {
            tracking,
            contentNode: 'drinksList'
          })}
        />
        <Route
          path={`/${routes.drinksRoute}/:slug`}
          component={ComposedPage(Drink, { tracking, contentNode: 'drink' })}
        />
        <Route
          exact
          path={`/${routes.jaegerTalesRoute}`}
          component={ComposedPage(JaegerTales, {
            tracking,
            contentNode: 'jgerTalesLandingPage'
          })}
        />
        <Route
          path={`/${routes.jaegerTalesRoute}/:slug`}
          component={ComposedPage(JaegerTalesContent, {
            tracking,
            contentNode: 'jaegerPediaContentPage'
          })}
        />
        <Route
          exact
          path={`/${routes.bestNightsRoute}/`}
          component={ComposedPage(BestNightsOverview, {
            slug: 'best-nights',
            tracking,
            contentNode: 'bestNightsOverviewPage'
          })}
        />
        <Route
          path={`/${routes.bestNightsRoute}/:slug`}
          component={ComposedPage(BestNightsArticle, { tracking, contentNode: 'bestNightsArticle' })}
        />
        <Redirect from="/imprint/" to={`/${routes.legalRoute}/imprint/`} />
        <Route
          path={`/${routes.legalRoute}/:slug`}
          component={ComposedPage(Legal, {
            disableAgeGate: true,
            tracking,
            contentNode: 'legalPages'
          })}
        />
        {redirects &&
          Object.keys(redirects)
            .map(
              (key) =>
                redirects[key].target && (
                  <Redirect
                    exact
                    key={redirects[key].slug}
                    from={`/${redirects[key].slug}`}
                    to={redirects[key].target.route}
                  />
                )
            )
            .filter((redirect) => redirect)}
        <Route
          component={ComposedPage(NotFound, {
            disableAgeGate: true
          })}
        />
      </Switch>
    )
  }
}

Router.propTypes = {
  content: PropTypes.object,
  url: PropTypes.string,
  trackingSegment: PropTypes.string,
  customRoutes: PropTypes.object
}
Router.defaultProps = {
  content: {},
  url: '',
  trackingSegment: 'is18,returning visitor',
  customRoutes: {}
}

function mapStateToProps(state) {
  const visitorType = loadTrackingCookie()
    ? 'visitor from social'
    : 'returning visitor'
  const newVisitor = state.visitorAge.age ? 'new visitor' : visitorType
  const visitorAge = state.visitorAge.age
    ? state.visitorAge.age
    : `is${state.ageGateConfig.legalAge}`
  const content = state.content
  const customRoutes = state.customRoutes ? state.customRoutes : {}
  return {
    content,
    customRoutes,
    trackingSegment: `${visitorAge},${newVisitor}`
  }
}

export default withRouter(connect(mapStateToProps)(Router))
