import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'

/**
 * The MetaData component acts as a wrapper for Helmet.
 *
 * As with Helmet, it is designed to be called multiple times per render,
 * with child elements overriding properties set by parent elements.
 *
 * As such, we need to ensure we are passing undefined to props in Helmet
 * to ensure we don't override them.
 */
class MetaData extends Component {
  static defaultProps = {
    metadata: undefined,
    pageName: undefined,
    openGraphType: undefined,
    brandName: undefined,
    theme: undefined,
    image: undefined,
    lang: undefined,
    canonicalBase: undefined,
    video: undefined,
    shareImage: undefined
  }
  static shareImage = {
    url: ''
  }

  // renders a meta data tag if content is defined, otherwise returns undefined
  static metaTag(name, content) {
    if (content !== undefined && content !== null) {
      return <meta property={name} content={content} />
    }
    return undefined
  }
  static metaTagName(name, metadata) {
    if (metadata !== undefined && name !== undefined && metadata !== null) {
      return <meta name={name} content={metadata[name]} />
    }
    return undefined
  }
  static propTypes = {
    // Metadata object represents authorable content that comes from contentful
    metadata: PropTypes.shape({
      pageName: PropTypes.string,
      sectionName: PropTypes.string,
      theme: PropTypes.oneOf(['default', 'manifest']),
      description: PropTypes.string,
      image: PropTypes.object,
      video: PropTypes.object,
      openGraphType: PropTypes.string,
      shareImage: PropTypes.string
    }),
    // Other properties are non authorable and can be set by parent
    pageName: PropTypes.string,
    brandName: PropTypes.string,
    theme: PropTypes.oneOf(['default', 'manifest']),
    image: PropTypes.object, // Contentful image object
    lang: PropTypes.string,
    canonicalBase: PropTypes.string, // TODO:
    location: PropTypes.shape({
      pathname: PropTypes.string
    }).isRequired,
    openGraphType: PropTypes.string,
    shareImage: PropTypes.string,
    video: PropTypes.object// Contentful video object,
  }

  getCanonical() {
    if (!this.props.canonicalBase) return undefined
    return `${this.props.canonicalBase}${this.props.location.pathname}`
  }

  getImage() {
    if (this.props.shareImage) {
      MetaData.shareImage.url = this.props.shareImage
    }
    return (this.props.metadata && this.props.metadata.image) || this.props.image
  }


  // TODO: BERJMWEBSZ-273 Calculate URL for open graph video + image, and
  // optimise height/width for Facebook wall + Pinterest
  // eslint-disable-next-line class-methods-use-this, no-unused-vars
  getImageUrl(image, width, height) {
    let url = ''
    if (image && image.url.indexOf('http') !== -1) {
      url = `${image.url}?w=${width}&h=${height}`
    } else {
      url = `https:${image.url}?w=${width}&h=${height}`
    }
    return url
  }

  // Open graph uses the incorrect format of en_US rather than en-US because Facebook
  getOgLocale() {
    if (this.props.lang) {
      return this.props.lang.replace('-', '_')
    }
    return undefined
  }

  getPageTitle() {
    // following https://moz.com/learn/seo/title-tag for optimal page title format
    const pageName = (this.props.metadata && this.props.metadata.pageName) || this.props.pageName
    const sectionName = (this.props.metadata && this.props.metadata.sectionName)
    if (pageName || sectionName) {
      if (pageName && sectionName) {
        return `${pageName} - ${sectionName}`
      }
      return pageName || sectionName
    }
    return undefined
  }

  getThemeColour(theme) {
    switch (theme) {
      case 'default':
        return '#184b0b'
      case 'manifest':
        return '#000000'
      default:
        return undefined
    }
  }

  getTitleTemplate() {
    // following https://moz.com/learn/seo/title-tag for optimal page title format
    const brandName = this.props.brandName
    if (brandName) {
      return `%s | ${brandName}`
    }
    return undefined
  }

  getVideo() {
    return (this.props.metadata && this.props.metadata.video) || this.props.video
  }


  // TODO: BERJMWEBSZ-273 Calculate URL for open graph video + image, and
  // optimise height/width for Facebook wall + Pinterest
  // eslint-disable-next-line class-methods-use-this, no-unused-vars
  getVideoUrl(video, width, height) {
    return video.url
  }

  metaTagFromMetaDataProp(name, propName) {
    if (this.props.metadata && this.props.metadata[propName]) {
      return MetaData.metaTag(name, this.props.metadata[propName])
    }
    return undefined
  }

  render() {
    const theme = (this.props.metadata && this.props.metadata.theme) || this.props.theme
    const themeColor = this.getThemeColour(theme) || undefined
    const title = this.getPageTitle() || undefined
    const image = this.getImage() || MetaData.shareImage
    const video = this.getVideo()
    const canonical = this.getCanonical()
    const type = (this.props.metadata && this.props.metadata.openGraphType) || 'website'

    const htmlAttributes = {}
    if (theme) htmlAttributes['jm-theme'] = theme
    if (this.props.lang) htmlAttributes.lang = this.props.lang
    // We make sure to pass undefined to Helmet where properties have not been set
    // to persist inheritance of properties
    return (
      <Helmet htmlAttributes={htmlAttributes}>
        {title && <title>{title}</title>}
        {MetaData.metaTag('theme-color', themeColor)}
        {MetaData.metaTagName('description', this.props.metadata)}
        {canonical && <link rel="canonical" href={`https:${canonical}`} />}

        {MetaData.metaTag('og:title', title)}
        {MetaData.metaTag('og:type', type) }

        {/* TODO: BERJMWEBSZ-273 images in seperate functions */}
        {image && MetaData.metaTag('og:image', image && this.getImageUrl(image, 300, 300))}
        {image && MetaData.metaTag('og:image:width', 300)}
        {image && MetaData.metaTag('og:image:height', 300)}

        {image && MetaData.metaTag('og:image', image && this.getImageUrl(image, 900, 900))}
        {image && MetaData.metaTag('og:image:width', 900)}
        {image && MetaData.metaTag('og:image:height', 900)}

        {canonical && MetaData.metaTag('og:url', `https:${canonical}`) }
        {this.metaTagFromMetaDataProp('og:description', 'description')}
        {MetaData.metaTag('og:locale', this.getOgLocale()) }

        {MetaData.metaTag('og:site_name', this.props.brandName) }

        {/* TODO: BERJMWEBSZ-273 video in a seperate function */}
        {video && MetaData.metaTag('og:video', this.getVideoUrl(video, 300, 300)) }
        {video && MetaData.metaTag('og:video:width', 300) }
        {video && MetaData.metaTag('og:video:height', 300) }
      </Helmet>
    )
  }
}

export default withRouter(MetaData)
