import objectAssign from 'object-assign'

const Actions = {
  EVENT: 'EVENT',
  PAGE_VIEW: 'PAGE_VIEW',
  HASH_EMAIL: 'HASH_EMAIL'
}

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Actions.PAGE_VIEW:
      return objectAssign({}, state, { currentPage: action.payload })
    case Actions.EVENT:
      return objectAssign({}, state, { lastEvent: action.payload })
    case Actions.HASH_EMAIL:
      return objectAssign({}, state, { email: action.payload })
    default:
      return state
  }
}
