import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  ArtistSocialMediaLinks,
  DownloadTracks,
  Img,
  Markdown,
  OnVisible
} from '../'
import styles from './artistbiography.css'

const config = {
  noText: {
    textClass: ''
  },
  imageLeftTextMiddleCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'left'
  },
  imageLeftTextBottomCenter: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textBottom,
    imagePosition: 'left'
  },
  imageRightTextBottomCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textBottom,
    imagePosition: 'right'
  },
  imageRightTextMiddleCenter: {
    headlineClass: classNames(styles.headline, styles.headlineRight),
    textHorizontalClass: styles.textRight,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'right'
  },
  imageCenterTextMiddleLeft: {
    headlineClass: styles.headline,
    textHorizontalClass: styles.textLeft,
    textVerticalClass: styles.textMiddle,
    imagePosition: 'center'
  }
}

const ArtistBiogrpahy = (props) => {
  const { image, headline, text, socialMedia, downloadTracks, mode } = props.data
  const {
    animationOffsetPercent,
    contentType,
    trackArtistSocialClicks,
    reverseMobile,
    textToTheRight,
    textLonger,
    imageClass,
    noTint,
    circleImage,
    headlineClass,
    contentWidthClass
  } = props

  const wrapperClasses = classNames(
    styles.wrapper,
    styles.wrapperPaddingBottom,
    {
      [styles.wrapperReverse]: config[mode] && config[mode].imagePosition && config[mode].imagePosition === 'right',
      [styles.wrapperCenter]: config[mode] && config[mode].imagePosition  && config[mode].imagePosition === 'center',
      [styles.wrapperReverseMobile]: reverseMobile,
    }
  )
  const textClasses = classNames(
    styles.text,
    config[mode] && config[mode].textHorizontalClass,
    config[mode] && config[mode].textVerticalClass
  )
  const textInnerClasses = classNames(styles.textInner, {
    [styles.textToTheRight]: textToTheRight,
    [styles.textLonger]: textLonger,
    [styles.innerTwentyFive]:
    config[mode] && config[mode].textVerticalClass === styles.textMiddle
  })
  const imageClasses = classNames(styles.image, {
    [styles.imageCircled]: circleImage,
    [styles.imageFullWidth]: config[mode] && config[mode].imagePosition && config[mode].imagePosition === 'center',
  })
  const tintClasses = !noTint ? styles.tint : ''
  return (
    <OnVisible
      className={styles.transitionStart}
      visibleClassName={styles.transitionEnd}
      percent={animationOffsetPercent}
    >
      <div className={wrapperClasses}>
        <div className={styles.column}>
          <div className={imageClass || imageClasses}>
            {image && (
              <figure className={tintClasses} aria-label={image.title}>
                <Img src={image.url} alt={image.title} className={styles.image} />
              </figure>
            )}
          </div>
        </div>

        <div className={styles.column}>
          <div className={textClasses || ''}>
            <div className={contentWidthClass || textInnerClasses}>
              <div className={classNames(headlineClass, config[mode] && config[mode].headlineClass || styles.headline)}>{headline}</div>
                <div className={styles.markdown}>
                  <Markdown markdownHtml={text} contentType={contentType} />
                </div>
                {socialMedia && <div>
                  <ArtistSocialMediaLinks
                    trackClick={trackArtistSocialClicks}
                    data={socialMedia}
                  />
                  <DownloadTracks data={downloadTracks} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </OnVisible>
  )
}

ArtistBiogrpahy.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  circleImage: PropTypes.bool,
  noTint: PropTypes.bool,
  animationOffsetPercent: PropTypes.number,
  reverseMobile: PropTypes.bool,
  trackArtistSocialClicks: PropTypes.func,
  headlineClass: PropTypes.string,
  imageClass: PropTypes.string,
  contentWidthClass: PropTypes.string,
}

ArtistBiogrpahy.defaultProps = {
  data: {
    image: { url: '', title: '' },
    headline: '',
    text: '',
    mode: 'imageLeftTextMiddleCenter'
  },
  textToTheRight: false,
  textLonger: false,
  circleImage: false,
  noTint: false,
  animationOffsetPercent: 2,
  reverseMobile: false,
  headlineClass: '',
  imageClass: '',
  contentWidthClass: '',
  trackArtistSocialClicks: () => {}
}

export default ArtistBiogrpahy
