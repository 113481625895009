import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { noEvents } from 'utils/i18n'

import styles from './eventlist.css'
import { Event, Button } from '..'

const icon = (<svg
  viewBox="0 0 39.2 39.2" fill="rgb(221, 90, 18)"
>
  <g>
    <path d="M9.1,30.6h21V16h-21V30.6z M19.6,20.2h7.5v7.5h-7.5V20.2z" />
    <path
      d="M34.3,0H4.9C2.2,0,0,2.2,0,4.9v29.4c0,2.7,2.2,4.9,4.9,4.9h29.4c2.7,0,4.9-2.2,4.9-4.9V4.9C39.2,2.2,37,0,34.3,0z M33.1,30.6c0,1.6-1.3,3-3,3h-21c-1.7,0-3-1.3-3-3l0-19.1c0-1.6,1.3-3,3-3h1.5v-3h3v3h12v-3h3v3h1.5c1.6,0,3,1.3,3,3V30.6z"
    />
  </g>
</svg>)
const pageLength = 10
let scroll

class EventList extends Component {
  static defaultProps = {
    data: {}
  }

  static propTypes = {
    data: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      isScroll: true,
      itemsToShow: pageLength,
      items: props.data.events && props.data.events.slice(0, pageLength),
      isMore: props.data.events && props.data.events.length > pageLength
    }
  }
  componentDidMount() {
    const onScroll = () => {
      scroll = window.pageYOffset
      return scroll
    }
    if (this.state.isScroll) {
      window.addEventListener('scroll', onScroll)
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.data.events && nextProps.data.events.slice(0, this.state.itemsToShow),
      isMore: nextProps.data.events && nextProps.data.events.length > this.state.itemsToShow
    })
  }

  seeMoreButtClick = () => {
    this.setState({
      scroll: false,
      itemsToShow: this.state.itemsToShow + pageLength,
      items: this.props.data.events && this.props.data.events.slice(0, this.state.itemsToShow + pageLength),
      isMore: this.props.data.events && this.props.data.events.length > this.state.itemsToShow + pageLength
    })
    setTimeout(() => {
      window.scrollTo(0, scroll)
    }, 0)
  };

  render() {
    const { data } = this.props
    const siteText = {
      noEvents: noEvents()
    }
    return (<div className={styles.container}>
      <h1 className={styles.title}>{data.title}</h1>
      <div className={styles.listContainer}>
        { (this.state.items && <ul className={styles.events}>{this.state.items.map((event, idx) =>
          // eslint-disable-next-line react/no-array-index-key
          <li key={event.id + idx} className={styles.event}><Event event={event} /></li>
      )}</ul>) || (<div className={styles.noEvents}><span className={styles.icon}>{icon}</span> {siteText.noEvents} </div>)}
      </div>
      {this.state.isMore && <div className={styles.button}>
        <Button id="button" onClick={this.seeMoreButtClick} className={styles.cta}>{data.seeMore}</Button>
      </div>}
    </div>)
  }
}

export default EventList
