/* global window, document */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import classNames from 'classnames/bind'

import { Image, OnVisible, Zeit } from '../'

import styles from './hero.css'

class Hero extends Component {
  static defaultProps = {
    align: 'bottom',
    restrict: 100,
    size: '33vw'
  }
  static propTypes = {
    imageSrc: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    imageSize: PropTypes.number,
    temperature: PropTypes.string,
    align: PropTypes.oneOf([
      'center',
      'topCenter',
      'top',
      'bottom',
      'right',
      'left',
      'bottom-right'
    ]),
    alt: PropTypes.string,
    restrict: PropTypes.number,
    links: PropTypes.array,
    time: PropTypes.bool,
    ribbon: PropTypes.bool
  }
  constructor(...rest) {
    super(...rest)
    this.state = {
      height: null,
      imageLoaded: false
    }
  }
  componentDidMount() {
    // @TODO make this less horrible
    this.setState({
      height: window.innerHeight * (this.props.restrict / 100)
    })
  }

  onImageLoaded = () => {
    this.setState({
      imageLoaded: true
    })
  };

  render() {
    const css = {
      left: styles.left,
      topCenter: styles.topCenter,
      center: styles.center,
      loaded: this.state.imageLoaded
    }
    const cx = classNames.bind(css)
    const classes = cx(this.props.align)
    const sloganClasses = classes
    const hasHeight = this.state.height
    const isSizingImage = this.props.imageSize
    const holderStyling = {}
    const imageStyling = {}
    const time = this.props.time
    const imageSrc = this.props.imageSrc
    const altText = this.props.alt
    const heroText = this.props.children
    const heroLinks = this.props.links
    const linksItems = heroLinks && heroLinks.map((d, idx) =>
      <Link className={styles.links} key={idx} to={d.route}>{d.text}</Link>)
    const heroClasses = classNames(styles.hero, this.props.className)
    let zeit = null
    if (time) {
      zeit = <Zeit />
    }

    if (hasHeight) {
      holderStyling.height = this.state.height
    }
    if (isSizingImage) {
      imageStyling.height = `${this.props.imageSize}%`
    }
    return (
      <div className={styles.heroContainer}>
        {this.props.ribbon && <span className={styles.heroRibbon} /> }
        <div className={heroClasses} style={holderStyling} >
          { imageSrc && <Image src={imageSrc} alt={altText} style={imageStyling} /> }
        </div>
        <div className={styles.slogans}>
          <OnVisible className={classes} percent={-20}>
            <div className={styles.children}>
              {zeit}
              { heroText && heroText }
              { heroLinks && linksItems }
            </div>
          </OnVisible>
        </div>
      </div>
    )
  }
}

export default Hero
