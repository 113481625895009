import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import analytics from './analytics'
import position from './position'
import filter from './filter'
import content from './content'
import config from './config'
import locale from './locale'
import ageGateConfig from './ageGateConfig'
import recaptchaKey from './recaptchaKey'
import visitorAge from './visitorAge'
import customRoutes from './customRoutes'
import storyCount from './story'
import formEndpoint from './formEndpoint'
import shareImage from './shareImage'
import newsLetterFormEndpoint from './newsLetterFormEndpoint'
import integrationsFormEndpoint from './integrationsFormEndpoint'
import complaintFormEndpoint from './complaintFormEndpoint'
import salesforceSyncEndpoint from './salesforceSyncEndpoint'
import bestNightsNewsletterEndpoint from './bestNightsNewsletterEndpoint'
import queries from './queries'

const rootReducer = combineReducers({
  form,
  position,
  filter,
  content,
  config,
  analytics,
  locale,
  ageGateConfig,
  storyCount,
  formEndpoint,
  shareImage,
  visitorAge,
  customRoutes,
  newsLetterFormEndpoint,
  integrationsFormEndpoint,
  complaintFormEndpoint,
  salesforceSyncEndpoint,
  bestNightsNewsletterEndpoint,
  recaptchaKey,
  queries
})

export default rootReducer
