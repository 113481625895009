import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Story } from 'components'

import { STORY_SHOWN } from 'actions/story'

class StoryContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired
  }
  constructor(...rest) {
    super(...rest)
    this.props.dispatch({ type: STORY_SHOWN })
    this.state = {
      storyComplete: false
    }
  }

  onComplete = () => {
    this.setState({
      storyComplete: true
    })
  }

  render() {
    const { storyComplete } = this.state
    const { count } = this.props
    return (
      /** To do preload video when count = 3 */
      <div>
        {!storyComplete && count === 4 && (
          <Story {...this.props} onComplete={this.onComplete} />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const count = state && state.storyCount.count
  return {
    count
  }
}
export default connect(mapStateToProps)(StoryContainer)
