import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Markdown, Close } from '../'

import styles from './cookiebanner.css'

class CookieBanner extends Component {
  constructor(props) {
	super(props)
    this.state = {
	  accepting: false,
    }
  }

  componentDidMount() {
	  this.startListening()
  }

  componentWillUnmount() {
    this.stopListening()
  }

  outsideClickListener = (event) => {
    if (event.target.id !== 'cookie-banner' && event.target.parentNode.id !== 'cookie-banner') {
      this.hideCookieBanner()
    }
  }

  onKeydownListener = (event) => {
    this.hideCookieBanner()
  }


  hideCookieBanner = () => {
    this.setState({ accepting: true })
    this.props.hideCookieBanner()
    this.stopListening()
    setTimeout(() => {
      this.setState({ accepting: false })
    }, 2000)
  }
  onScroll = () => {
    if (this.props.visible) {
      this.hideCookieBanner()
    }
  }

  startListening() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('click', this.outsideClickListener)
    window.addEventListener('keydown', this.onKeydownListener)
  }

  stopListening() {
    window.removeEventListener('click', this.outsideClickListener)
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('keydown', this.onKeydownListener)
  }
  render() {
	const { content, visible } = this.props
	const { accepting } = this.state
    return (
      <div
        id="cookie-banner"
        tabIndex="1"
        className={classNames(styles.banner, {
          [styles.hide]: !visible && accepting,
		      [styles.hidden]: !visible && !accepting
        })}
      >
        <Markdown className={styles.text} markdownHtml={content.text} />
        <div className={styles.close}>
          <button
            onClick={this.hideCookieBanner}
          >
            <Close />
          </button>
        </div>
      </div>
    )
  }
}

CookieBanner.propTypes = {
  content: PropTypes.object.isRequired,
  hideCookieBanner: PropTypes.func.isRequired,
  visible: PropTypes.bool
}

CookieBanner.defaultProps = {
  visible: false
}

export default CookieBanner
