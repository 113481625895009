import React from 'react'
import PropTypes from 'prop-types'

import { FullBleedImage, MetaData, Grid, Tile } from 'components'
import MailchimpForm from '../../mailchimpForm/mailchimpForm'

import styles from './BestNightsOverview.css'

const BestNightsOverview = ({ content, bestNightsNewsletterEndpoint }) => {
  const bestNights = content
  if (!bestNights) {
    return <div data-error="No best nights content" />
  }

  let isMailChimpForm, mailchimpForm
  bestNights.components &&
  bestNights.components.length > 0 &&
  bestNights.components[0].contentType === 'mailchimpForm'
    ? (isMailChimpForm = true)
    : (isMailChimpForm = false)
  if (isMailChimpForm) {
    mailchimpForm = bestNights.components[0]
  }
  const articles = Object.keys(bestNights.articles)
  const heroSlug = articles[0]
  const hero = bestNights.articles[heroSlug]
  hero.linkTo = `/best-nights/${heroSlug}`
  if (hero.contentType === 'videoComponent') {
    //Make videoComponent headline compatible with fullBleedImage component's
    hero.headline = hero.headline ? `<h1>${hero.headline}</h1>` : ''
  }
  return (
    <main role="main" id="mainContent" className={styles.backgroundBNTexture}>
      <MetaData
        metadata={bestNights.metadata}
        canonicalBase="//www.jagermeister.com/en"
      />

      <section className={styles.header}>
        {hero && (
          <FullBleedImage
            data={hero}
            key={hero.id}
            theme="bestNightsBanner"
            // theme="bestNightsHero"
            textInnerClass={styles.heroText}
          />
        )}
      </section>
      <section key={'BestNightsGrid'} className={styles.grid}>
        <Grid theme={'bestNights'}>
          {articles &&
            articles.slice(1).map((articleSlug) => {
              if (!articleSlug) {
                return null
              }
              let article = bestNights.articles[articleSlug]
              return (
                <Tile
                  key={article.id}
                  linkTo={`/best-nights/${articleSlug}`} // TODO IMPROVE LOCALE MANAGEMENT
                  image={article.image}
                  caption={article.text}
                  title={article.headline}
                  theme={'bestNightsPage'}
                />
              )
            })}
        </Grid>
      </section>
      {isMailChimpForm && mailchimpForm && (
        <MailchimpForm
          claim={mailchimpForm.claim}
          disclaimer={mailchimpForm.disclaimer}
          thankYouMessage={mailchimpForm.thankYouMessage}
          errorMessage={mailchimpForm.errorMessage}
          userAlreadyExistsErrorMessage={mailchimpForm.userAlreadyExistsErrorMessage}
          endpoint={bestNightsNewsletterEndpoint}
        />
      )}
    </main>
  )
}

BestNightsOverview.propTypes = {
  content: PropTypes.object.isRequired,
  slug: PropTypes.string
}

BestNightsOverview.defaultProps = {
  slug: 'default'
}

export default BestNightsOverview
