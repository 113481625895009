import React from 'react'
import PropTypes from 'prop-types'

import { MetaData, Generics, Generic, Button } from 'components'
import { Recommendations } from 'containers'

import styles from './eventdetail.css'
/*
TODO: There's too much duplicated code for generic-like pages, we should be able
      to use a single component, generate components or use an HOC.*/
const EventDetail = (
  {
    content,
  },
) => (
  <main role="main" id="mainContent">
    <MetaData metadata={content.metadata} />
    {content.hero &&
    <Generic components={content.hero} />}
    <section className={styles.eventDetails}>
      <div className={styles.eventDetailsContainer}>
        <Generics components={content.components} />
        {content.cta && <div className={styles.button}>
          <Button href={content.cta.route} className={styles.cta}>{content.cta.text}</Button>
          </div>}
      </div>
    </section>
    {content.recommendations &&
    <Recommendations recommendations={content.recommendations} />}
  </main>
  )

EventDetail.propTypes = {
  content: PropTypes.object
}

EventDetail.defaultProps = {
  content: {}
}

export default EventDetail
