import React from 'react'
import PropTypes from 'prop-types'

import styles from './prost.css'

const Prost = ({ toast, temperatureInF, freeze, frosted }) => (
  <div className={`${styles.prost} ${frosted ? styles.frosted : ''}`}>
    <p className={styles.toast}>{toast}</p>
    <p className={styles.message}>
      <span className={styles.temperature}>
        {temperatureInF ? '0°F' : '-18°C'}
      </span>
      <span className={styles.freeze}>{freeze}</span>
    </p>
  </div>
)

Prost.propTypes = {
  toast: PropTypes.string,
  freeze: PropTypes.string,
  temperatureInF: PropTypes.bool,
  frosted: PropTypes.bool
}

Prost.defaultProps = {
  toast: '',
  freeze: '',
  temperatureInF: false,
  frosted: false
}

export default Prost
