import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import throttle from 'lodash.throttle'
import { windowPosition, gesture } from 'actions/position'
import { trackEvent } from 'actions/analytics'

import { Navbar } from 'components'

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.onScroll = throttle(this.onScroll.bind(this), 200)
    this.navHeight = 77
    this.state = {
      updated: false,
      navClass: null,
      lastScrollTop: 0
    }
  }
  componentDidMount() {
    this.startListening()
    this.onScroll()
  }
  componentWillReceiveProps(nextProps) {
    if (!this.state.updated && nextProps.position.pageY !== this.props.position.pageY) {
      this.setState({
        updated: true
      })
    }
  }
  componentWillUnmount() {
    this.stopListening()
  }
  onScroll() {
    this.props.dispatch(windowPosition(window.pageYOffset))
    this.scrollingNav()
  }

  /** TODO Keep an eye on this as possible performance risk */
  onWheel = (e) => {
    this.props.dispatch(gesture(e.deltaY))
  };

  scrollingNav() {
    const st = this.props.position.pageY
    if (Math.abs(this.state.lastScrollTop - st) <= this.props.position.delta) {
      return
    }

    if (st > this.state.lastScrollTop && st > this.navHeight && !this.props.countrySelectorOpen) {
      this.setState({
        navClass: 'navUp'
      })
    } else if (st + window.innerHeight <= document.documentElement.offsetHeight) {
      this.setState({
        navClass: ''
      })
    }
    this.state.lastScrollTop = st
  }
  startListening() {
    window.addEventListener('scroll', this.onScroll)
  }
  stopListening() {
    window.removeEventListener('scroll', this.onScroll)
  }

  trackNavigationClick = (navItemName) => {
    this.props.dispatch(trackEvent({
      event: 'Site Navigation',
      eventType: 'Navigation Bar Top Click',
      eventDetail: navItemName
    }))
  };

  render() {
    const navItems = this.props.navItems
    return (
      <Navbar
        navItems={navItems}
        locale={this.props.locale}
        navClass={this.state.navClass}
        countrySelectorButton={this.props.countrySelectorButton}
        connect={this.props.connect}
        toggle={this.props.toggle}
        socialLinks={this.props.socialLinks}
        trackNavigationClick={this.trackNavigationClick}
        disableNavigation={this.props.disableNavigation}
        disableNavigationAndConnectTray={this.props.disableNavigationAndConnectTray}
      />
    )
  }
}

Navigation.propTypes = {
  navItems: PropTypes.array.isRequired,
  connect: PropTypes.object,
  socialLinks: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  countrySelectorButton: PropTypes.any.isRequired,
  position: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  countrySelectorOpen: PropTypes.bool
}

Navigation.defaultProps = {
  countrySelectorOpen: false,
  connect: {}
}

export default connect(
  (state) => {
    const { position } = state
    return {
      position
    }
  }
)(Navigation)
