import React from 'react'
import PropTypes from 'prop-types'
import FilterGroup from './filtergroup/filtergroup'
import FilterOption from './filteroption/filteroption'

import styles from './filter.css'

const Filter = (
  {
    groups,
    filterKey,
  },
) => {
  if (!groups) {
    return <div data-error="jm-filter-no-groups" />
  }
  return (
    <div className={styles.filter}>
      {groups.map(group => (<div key={group.id} className={styles.filtergroup}>
        <FilterGroup
          filterKey={filterKey}
          groupKey={group.id}
          name={group.title}
          items={group.tags}
        /></div>))}
    </div>
  )
}

Filter.propTypes = {
  groups: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired
}

export default Filter
export { FilterGroup }
export { FilterOption }
