// taken from https://github.com/jeffposnick/create-react-app/blob/27424cef01518c5f85066f1e0cc22b2ecd599c3e/packages/react-scripts/template/src/service-worker-registration.js

import { version } from '../../../package.json'

const swUrl = `/sw.${version}.js`
export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator && window.location.hostname.indexOf('preview') === -1) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(swUrl).then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and
                // the fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in your web app.
                console.log('New content is available; please refresh.')
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.')
              }
            }
          }
        }
      }).catch((error) => {
        console.error('Error during service worker registration:', error)
      })
    })
    self.addEventListener('fetch', function (event) {
      event.respondWith(caches.match(event.request))
    })
    self.addEventListener('activate', function (event) {
      event.waitUntil(caches.keys().then(function (cacheNames) {
        return Promise.all(cacheNames.filter(function (cacheName) {
          // Return true if you want to remove this cache,
          // but remember that caches are shared across
          // the whole origin
        }).map(function (cacheName) {
          return caches.delete(cacheName)
        }))
      }))
    })
  }
}

export function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(async (names) => {
          await Promise.all(names.map(name => caches.delete(name)));
        });
      }
    })
  }
}
