// Standard tile used by the grid

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import styles from './tile.css'
import { Anchor, Image, Markdown } from '../'

import { ITEMS as GRID_ITEMS } from '../grid/Grid'
import FieldsetBN from '../fieldsetBN/FieldsetBN'

class Tile extends Component {
  static defaultProps = {
    caption: '',
    linkTo: '',
    tint: '',
    tracking: () => {},
    imageClassName: null,
    gridItemConfig: null,
    theme: 'standard'
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    caption: PropTypes.string,
    image: PropTypes.object.isRequired,
    imageClassName: PropTypes.string,
    linkTo: PropTypes.string,
    tint: PropTypes.string,
    tracking: PropTypes.func,
    gridItemConfig: PropTypes.oneOf(
      Object.keys(GRID_ITEMS).map((key) => GRID_ITEMS[key])
    ),
    theme: PropTypes.string
  }
  renderTileContents() {
    const imageClasses = classNames(styles.image, this.props.imageClassName)
    return (
      <div
        className={classNames({
          [styles.hoverBN]: this.isBestNightsPage(),
          [styles.tint]:
            this.props.tint.length > 0 && this.props.tint === 'tint'
        })}
      >
        {this.isBestNights() && (
          <FieldsetBN
            headline={
              this.isBestNightsBanner()
                ? `<h3>${this.props.title}</h3>`
                : this.props.title
            }
            text={
              this.isBestNightsBanner()
                ? `<p>${this.props.caption}</p>`
                : this.props.caption
            }
            isLogo={this.isBestNightsBanner()}
            isGridTile={this.isBestNightsPage()}
            linkTo={this.props.linkTo || ''}
          />
        )}
        {!this.isBestNights() && (
          <h3 className={styles.title}>
            <Markdown markdownHtml={this.props.title} />
          </h3>
        )}
        {!this.isBestNights() && this.props.caption && (
          <p className={styles.caption}>{this.props.caption}</p>
        )}
        {/*{this.isBestNightsBanner() && (
          <div className={styles.mobileOnly}>
            {this.props.title && (
              <h3 className={styles.title}>
                <Markdown markdownHtml={this.props.title} />
              </h3>
            )}
            {this.props.caption && (
              <p className={styles.caption}>{this.props.caption}</p>
            )}
          </div>
        )}*/}
        {this.props.image && (
          <Image
            sizes={
              (this.props.gridItemConfig < 50
                ? 75
                : this.props.gridItemConfig) + 'vw'
            }
            mobileSizes={this.props.gridItemConfig === '25' ? '50vw' : '100vw'}
            className={imageClasses}
            src={this.props.image.url}
            alt={
              this.props.image.title ||
              this.props.image.description ||
              this.props.title
            }
          />
        )}
      </div>
    )
  }
  isBestNights() {
    return this.isBestNightsPage() || this.isBestNightsBanner()
  }
  isBestNightsPage() {
    return this.props.theme === 'bestNightsPage'
  }
  isBestNightsBanner() {
    return this.props.theme === 'bestNightsBanner'
  }
  render() {
    let tileStyle

    switch (this.props.gridItemConfig) {
      case GRID_ITEMS.SMALL:
      case GRID_ITEMS.SMALLEST:
        tileStyle = styles.tileCompact
        break
      case GRID_ITEMS.FULL:
      case GRID_ITEMS.LARGE:
      case GRID_ITEMS.MEDIUM:
      default:
        tileStyle = styles.tile
    }
    if (this.props.linkTo) {
      return (
        <Anchor
          to={this.props.linkTo}
          className={classNames(
            this.isBestNightsPage() ? styles.hoverBN : tileStyle,
            this.isBestNightsBanner() ? styles.tileBannerBN : ''
          )}
          onClick={this.props.tracking}
        >
          {this.renderTileContents()}
        </Anchor>
      )
    }
    return <div className={tileStyle}>{this.renderTileContents()}</div>
  }
}

export default Tile
