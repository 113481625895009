import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Arrow, Img } from '../'
import VideoSlide from './videoslide'
import { getHeight, setHeight } from './helper'

import styles from './videocarousel.css'

const videoJsDefaultOptions = {
  aspectRatio: '16:9',
  techOrder: ['html5', 'youtube'],
  sources: [],
  youtube: {
    iv_load_policy: 3,
    ytControls: 1,
    modestbranding: 0,
    color: 'white',
    host: '//www.youtube.com',
    enablejsapi: 1,
    customVars: { wmode: 'transparent' },
    enablePrivacyEnhancedMode: true
  },
  nativeControlsForTouch: true
}

const VideoCarousel = ({ component, queries, bestNights }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [rightSlide, setRightSlide] = useState(1)
  const [leftSlide, setLeftSlide] = useState(component.items.length - 1)
  const [transition, setTransition] = useState('Left')
  const [xDown, setXDown] = useState(null)
  const [yDown, setYDown] = useState(null)

  useEffect(() => {
    if (window.innerWidth > 1024 && component.desktopMinHeight) {
      setHeight(component.desktopMinHeight, false)
    } else if (window.innerWidth < 1025 && component.mobileMinHeight) {
      setHeight(component.mobileMinHeight, false)
    }
  }, [])

  useEffect(() => {
    // check if queries are set & has a slide attribute & the slide value is of type number & the value is within the accepted parameters
    if (
      queries &&
      queries.query &&
      queries.query.slide &&
      !isNaN(queries.query.slide) &&
      queries.query.slide * 1 - 1 > -1 &&
      queries.query.slide * 1 - 1 < component.items.length &&
      queries.query.slide * 1 - 1 !== slideIndex
    ) {
      const slide = queries.query.slide * 1 - 1
      if (slide > 0 && slide < component.items.length - 1) {
        setSlideIndex(slide)
        setLeftSlide(slide - 1)
        setRightSlide(slide + 1)
      } else if (slide === 0) {
        setSlideIndex(slide)
        setLeftSlide(component.items.length - 1)
        setRightSlide(slide + 1)
      } else if (slide === component.items.length - 1) {
        setSlideIndex(slide)
        setLeftSlide(slide - 1)
        setRightSlide(0)
      }
    }
  }, [queries])

  const getTouches = (evt) => {
    return evt.touches
  }

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0]
    setXDown(firstTouch.clientX)
    setYDown(firstTouch.clientY)
  }

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return
    }

    const xUp = evt.touches[0].clientX
    const yUp = evt.touches[0].clientY

    const xDiff = xDown - xUp
    const yDiff = yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        switchSlide(+1)
      } else {
        switchSlide(-1)
      }
    }
    setXDown(null)
    setYDown(null)
  }

  const getNextSlideIndex = (idx) => {
    if (idx + 1 !== component.items.length) {
      return idx + 1
    } else {
      return 0
    }
  }

  const getPreviousSlide = (idx) => {
    if (idx !== 0) {
      return idx - 1
    } else {
      return component.items.length - 1
    }
  }

  //TODO: clean up this function
  const switchSlide = (n) => {
    const direction = n > 0 ? 'Left' : 'Right'
    if (slideIndex + n < component.items.length && slideIndex + n > -1) {
      if (n > 0) {
        setLeftSlide(slideIndex)
        setRightSlide(getNextSlideIndex(slideIndex + n))
      } else {
        setRightSlide(slideIndex)
        setLeftSlide(getPreviousSlide(slideIndex + n))
      }
      setTransition(direction)
      setSlideIndex(slideIndex + n)
    } else if (slideIndex + n < 0) {
      if (n > 0) {
        setLeftSlide(slideIndex)
        setRightSlide(getNextSlideIndex(component.items.length - 1))
      } else {
        setRightSlide(slideIndex)
        setLeftSlide(getPreviousSlide(component.items.length - 1))
      }
      setSlideIndex(component.items.length - 1)
      setTransition(direction)
    } else if (slideIndex + n > component.items.length - 1) {
      if (n > 0) {
        setLeftSlide(slideIndex)
        setRightSlide(getNextSlideIndex(0))
      } else {
        setRightSlide(slideIndex)
        setLeftSlide(getPreviousSlide(0))
      }
      setSlideIndex(0)
      setTransition(direction)
    }
  }

  const isMostLeftTransition = (idx) => {
    return transition === 'Left' && idx === getPreviousSlide(leftSlide)
  }

  const isMostRightTransition = (idx) => {
    return transition === 'Right' && idx === getNextSlideIndex(rightSlide)
  }

  const showCustomControls = (data) => {
    if (data.youtubeId) return false
    return data.controls
  }

  return (
    <div
      className={styles.carousel}
      style={{ minHeight: getHeight() }}
      onTouchStart={(e) => {
        handleTouchStart(e)
      }}
      onTouchMove={(e) => handleTouchMove(e)}
    >
      {component.image && (
        <Img
          src={component.image.url}
          alt={component.image.title}
          className={styles.img}
          loadedClassName={styles.imgLoaded}
        />
      )}
      <div className={styles.slides}>
        {component.items &&
          component.items.length > 0 &&
          component.items.map((item, idx) => {
            const data = item
            data.mode = 'noText'
            let videoSources = []
            if (data.videoMobile === null && data.youtubeIdMobile === null) {
              videoSources = [
                {
                  type: data.youtubeId ? 'video/youtube' : 'video/mp4',
                  src: data.youtubeId
                    ? data.youtubeId.indexOf('www.youtube') > -1
                      ? data.youtubeId
                      : `//www.youtube.com/watch?v=${data.youtubeId}`
                    : data.video.url
                }
              ]
            } else {
              videoSources = [
                {
                  type: data.youtubeId ? 'video/youtube' : 'video/mp4',
                  src: data.youtubeId
                    ? data.youtubeId.indexOf('www.youtube') > -1
                      ? data.youtubeId
                      : `//www.youtube.com/watch?v=${data.youtubeId}`
                    : data.video.url
                },
                {
                  type: data.youtubeIdMobile ? 'video/youtube' : 'video/mp4',
                  src: data.youtubeIdMobile
                    ? data.youtubeIdMobile.indexOf('www.youtube') > -1
                      ? data.youtubeIdMobile
                      : `//www.youtube.com/watch?v=${data.youtubeIdMobile}`
                    : data.videoMobile.url
                }
              ]
            }
            const videoJsOptions = {
              ...videoJsDefaultOptions,
              sources: videoSources,
              controls: showCustomControls(data),
              preload: 'auto',
              loop: false,
              autoplay: false,
              tint: data.tint,
              id: data.id
            }
            return (
              <div key={idx} className={styles.slideContainer}>
                <div
                  className={classNames(styles.slide, {
                    [styles[`visible${transition}`]]: slideIndex === idx,
                    [styles[`leftSlide${transition}`]]:
                      slideIndex !== idx && idx === leftSlide,
                    [styles[`rightSlide${transition}`]]:
                      slideIndex !== idx && idx === rightSlide,
                    [styles.hiddenRightSlide]: isMostRightTransition(idx),
                    [styles.hiddenLeftSlide]: isMostLeftTransition(idx),
                    [styles.hidden]:
                      slideIndex !== idx &&
                      idx !== leftSlide &&
                      idx !== rightSlide &&
                      !isMostRightTransition(idx) &&
                      !isMostLeftTransition(idx)
                  })}
                >
                  <VideoSlide
                    slideIndex={slideIndex}
                    data={data}
                    videoJsOptions={videoJsOptions}
                    idx={idx}
                    bestNights={bestNights}
                  />
                </div>
              </div>
            )
          })}
      </div>
      <div className={styles.arrowsContainer}>
        <div className={styles.arrows}>
          <button
            className={classNames(styles.arrow, {
              [styles.leftArrow]: true
            })}
            onClick={() => switchSlide(-1)}
          >
            <Arrow direction="left" />
          </button>
          <button
            className={classNames(styles.arrow, {
              [styles.rightArrow]: true
            })}
            onClick={() => switchSlide(+1)}
          >
            <Arrow direction="right" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoCarousel
