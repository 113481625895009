import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import styles from './image.css'

import { Img } from '..'

const cx = classNames.bind(styles)
/**
 * Fills the containing element with an image using absolute positioning.
 *
 * By default, the source set that this component generates is optimised for
 * full browser width images. Specify e.g. sizes="(min-width: 600px) 200px, 50vw"
 * to optimise this for non full width images.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes
 *
 * TODO: The following should be supported, or should be a seperate component with
 * some of the contentful functions in shared code:
 * - object-fit: contain/fill/scale-down/none within parent element rather than just cover
 * - Behave as a normal image element and push down document, but still allow constraints such as
 *   max-width, max-height i.e. not absolutely positioned, possibly use contentful height and
 *   width props.
 * (We need to plan what res mappings would be used for these scenarios)
 */
const Image = ({
  className,
  id,
  style,
  onLoad,
  sizes,
  mobileSizes,
  src,
  role,
  alt,
  objectFit,
  lazyLoading
}) => {
  const holderClasses = cx(styles.adaptive, className)
  return (
    <figure className={holderClasses} id={id} style={style} aria-label={alt}>
      <Img
        className={styles.adaptiveImg}
        loadedClassName={styles.loaded}
        onLoad={onLoad}
        sizes={sizes}
        mobileSizes={mobileSizes}
        src={src}
        role={role}
        alt={alt}
        objectFit={objectFit}
        lazyLoading={lazyLoading}
      />
    </figure>
  )
}

Image.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  mobileSizes: PropTypes.string,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  style: PropTypes.object,
  alt: PropTypes.string.isRequired,
  role: PropTypes.string,
  objectFit: PropTypes.bool,
  lazyLoading: PropTypes.bool
}

Image.defaultProps = {
  id: null,
  className: null,
  onLoad: null,
  sizes: '100vw',
  mobileSizes: '100vw',
  style: null,
  role: 'presentation',
  objectFit: true,
  lazyLoading: false
}

export default Image
