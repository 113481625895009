import { connect } from 'react-redux'
import { trackEvent } from 'actions/analytics'

import Grid from 'components/grid/Grid'

function mapDispatchToProps(dispatch) {
  return {
    trackGridClick: (e, trackType) => {
      switch (trackType) {
        case 'twitter':
          dispatch(
            trackEvent({
              event: 'TwitterCTA',
              contentType: 'Homepage',
              contentId:
                e.target.parentNode.innerText && e.target.parentNode.innerText
            })
          )
          break
        case 'instagram':
          dispatch(
            trackEvent({
              event: 'InstagramCTA',
              contentType: 'Homepage',
              contentId:
                e.target.parentNode.innerText && e.target.parentNode.innerText
            })
          )
          break
        case 'ribbon':
          dispatch(
            trackEvent({
              event: 'RibbonCTA',
              contentType: 'Homepage',
              contentId:
                e.target.parentNode.parentNode.getElementsByTagName('h3')
                  .length > 0
                  ? e.target.parentNode.parentNode.getElementsByTagName('h3')[0]
                      .innerText
                  : e.target.parentNode.parentNode.parentNode.parentNode.getElementsByTagName(
                      'h3'
                    )[0].innerText
            })
          )
          break
        default:
          dispatch(
            trackEvent({
              event: 'Title',
              contentType: 'Homepage',
              contentId:
                e.target.parentNode.parentNode.parentNode.parentNode.getElementsByTagName(
                  'p'
                )[0].innerText &&
                e.target.parentNode.parentNode.parentNode.parentNode.getElementsByTagName(
                  'p'
                )[0].innerText
            })
          )
      }
    }
  }
}

export default connect(null, mapDispatchToProps)(Grid)
