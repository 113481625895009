import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './quote.css'
const Quote = (props) => {
  const BN = props.bestNights
  const quoteText = props.quote
  return (
    <div className={classNames(styles.quote, BN ? styles.quoteBN : '')}>
      <blockquote
        className={classNames(styles.quoteMark, BN ? styles.quoteMarkBN : '')}
      >
        {quoteText}
      </blockquote>
    </div>
  )
}

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  bestNights: PropTypes.bool
}

export default Quote
