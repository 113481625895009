import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Grid, FullBleedImage, Markdown, MetaData } from 'components'
import { FormContainer, Recommendations } from 'containers'

import styles from './factory.css'

class FactoryTour extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false
    }
  }

  columnsRender(component) {
    return (
      <div className={styles.column}>
        {component.components && component.components.map(this.componentRender)}
      </div>
    )
  }

  componentRender = (components) => {
    const { showText } = this.state

    const classes = classNames(styles.content, {
      [styles.open]: showText
    })
    return (
      (components.contentType === 'fullBleedImageComponent' && (
        <FullBleedImage data={components} />
      )) ||
      (components.contentType === 'form' && (
        <div className={styles.content}>
          <FormContainer
            data={components}
            toggle={this.toggleContactText}
            endPoint={this.props.endPoint}
          />
        </div>
      )) ||
      (components.contentType === 'contentComponent' && (
        <div className={classes}>
          <Markdown markdownHtml={components.text} theme="factory" />
        </div>
      )) ||
      (components.contentType === 'contentComponent' && (
        <div className={classes}>
          <Markdown markdownHtml={components.text} />
        </div>
      )) ||
      (components.contentType === 'gridComponent' && (
        <div className={classes}>
          <Grid tiles={components.tiles} />
        </div>
      )) ||
      (components.contentType === 'column' && this.columnsRender(components))
    )
  }

  toggleContactText = () => {
    this.setState({ showText: !this.state.showText })
  }

  render() {
    const data = this.props.content
    return (
      <main className={styles.factoryTour} role="main" id="mainContent">
        <MetaData metadata={data.metadata} />

        {data.hero &&
          data.hero.map(
            (d) =>
              d.image && (
                <section className={styles.hero} key={d.id}>
                  <FullBleedImage
                    data={d}
                    headlineClass={styles.factoryHeadline}
                  />
                </section>
              )
          )}

        {data.columnOne && (
          <section className={styles.factoryTourContainer}>
            <div className={styles.columns}>
              {data.columnOne && (
                <div className={styles.column}>
                  {data.columnOne &&
                    data.columnOne.map(
                      (d) =>
                        d.contentType === 'contentComponent' && (
                          <div className={styles.content} key={d.id}>
                            <Markdown markdownHtml={d.text} />
                          </div>
                        )
                    )}
                </div>
              )}
              {data.columnTwo && (
                <div className={styles.column}>
                  {data.columnTwo &&
                    data.columnTwo.map(
                      (d) =>
                        (d.contentType === 'contentComponent' && (
                          <div className={styles.content} key={d.id}>
                            <Markdown markdownHtml={d.text} />
                          </div>
                        )) ||
                        (d.contentType === 'form' && (
                          <div className={styles.content} key={d.id}>
                            <FormContainer
                              data={d}
                              endPoint={this.props.endPoint}
                            />
                          </div>
                        ))
                    )}
                </div>
              )}
            </div>
          </section>
        )}
        <section className={styles.factoryImage}>
          {data.fullBleedImage && (
            <section className={styles.hero}>
              <FullBleedImage data={data.fullBleedImage} />
            </section>
          )}
        </section>
        {data.recommendations && (
          <Recommendations recommendations={data.recommendations} />
        )}
      </main>
    )
  }
}

FactoryTour.defaultProps = {
  content: {}
}
FactoryTour.propTypes = {
  content: PropTypes.object
}
export default FactoryTour
