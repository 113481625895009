import React from 'react'
import PropTypes from 'prop-types'
import { Navigation, SocialMedia, CookiesSettingsBtn, Image } from '../'
import * as socialIcons from 'svgs/socialIcons/SocialIcons'
import classNames from 'classnames'

import styles from './footer.css'

const Footer = ({
  firstRowNav,
  secondRowNav,
  socialLinks,
  globalText,
  trackNavigationClick,
  trackSocialClick,
  cookiesConsentBanner,
  cookiesSettingsBtn,
  footerImageStrip
}) => (
  <footer className={styles.wrapper}>
    {footerImageStrip && (
      <div
        className={classNames(styles.footerStripWrapper, [
          styles[
            `background${
              footerImageStrip.content.background
                ? footerImageStrip.content.background
                : 'White'
            }`
          ]
        ])}
      >
        <div className={styles.footerImageWrapper}>
          <Image
            className={styles.footerImage}
            src={footerImageStrip.content.image?.url}
            alt={footerImageStrip.content.image?.title}
            lazyLoading={true}
          />
        </div>
        <div className={styles.footerImageCopy}>
          <p>{footerImageStrip.content.text}</p>
        </div>
      </div>
    )}
    <div className={styles.navigation}>
      <Navigation
        navigation={firstRowNav}
        itemClassName={styles.navItem}
        containerClassName={styles.navContainer}
        trackClick={trackNavigationClick}
      />
      <Navigation
        navigation={secondRowNav}
        itemClassName={styles.navItem}
        containerClassName={styles.navContainer}
        trackClick={trackNavigationClick}
      />
      {cookiesConsentBanner &&
        cookiesConsentBanner.content &&
        cookiesConsentBanner.content.enabled &&
        cookiesSettingsBtn &&
        cookiesSettingsBtn.content && (
          <CookiesSettingsBtn text={cookiesSettingsBtn.content.text} />
        )}
    </div>

    <div className={styles.bottomRow}>
      <div
        className={styles.slogan}
        dangerouslySetInnerHTML={{ __html: globalText.footerSlogan }}
      />
      {socialLinks && (
        <SocialMedia
          social={socialLinks}
          icons={socialIcons}
          trackClick={trackSocialClick}
        />
      )}
    </div>
  </footer>
)

Footer.propTypes = {
  firstRowNav: PropTypes.object,
  secondRowNav: PropTypes.object,
  socialLinks: PropTypes.object,
  globalText: PropTypes.shape({
    footerSlogan: PropTypes.string.isRequired
  }).isRequired,
  trackNavigationClick: PropTypes.func,
  trackSocialClick: PropTypes.func
}

Footer.defaultProps = {
  firstRowNav: { items: [] },
  secondRowNav: { items: [] },
  socialLinks: {},
  globalText: { footerSlogan: '' },
  trackNavigationClick: () => {},
  trackSocialClick: () => {}
}

export default Footer
