import React from 'react'
import PropTypes from 'prop-types'

import { Generic, MetaData } from 'components'
import { StoryContainer, Recommendations } from 'containers'
const Home = ({ content, trackingVideo }) => {
  const contentType = 'Homepage'

  return (
    <main role="main" id="mainContent">
      <MetaData metadata={content.metadata} />
      {content.entryAnimation && content.entryAnimation.video && (
        <StoryContainer video={content.entryAnimation.video} />
      )}
      {content.hero && (
        <Generic
          components={content.hero}
          trackingVideo={trackingVideo}
          contentType={contentType}
          theme="default"
        />
      )}
      {content.components &&
        content.components.map((d) => (
          <Generic
            components={d}
            trackingVideo={trackingVideo}
            key={d.id}
            contentType={contentType}
            theme="default"
          />
        ))}

      {content.recommendations && (
        <Recommendations recommendations={content.recommendations} />
      )}
    </main>
  )
}

Home.defaultProps = {
  content: {},
  trackingVideo: () => {}
}
Home.propTypes = {
  content: PropTypes.object,
  trackingVideo: PropTypes.func
}
export default Home
