
import { STORY_SHOWN } from '../actions/story'

export default function (state = { count: 0 }, action) {
  let hasChanged = false
  let newState
  switch (action.type) {
    case STORY_SHOWN: {
      hasChanged = true
      newState = { ...state }
      /** arbitary number as we dont want to block page rendering every time due to seo */
      if (state.count >= 5) {
        newState.count = 0
      } else {
        newState.count += 1
      }
      break
    }
    default:
      break
  }
  return hasChanged ? newState : state
}
