import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  FullBleedImage,
  HalfWidthPng,
  HeroComponent,
  ProductIngredients,
  OnVisible,
  Shotometer,
  VideoFeature,
  MetaData,
  Generic
} from 'components'
import { StoryContainer, ProductMystery, Recommendations } from 'containers'

import styles from './product.css'
import ingredientMarkdown from './ingredientsmd.css'

const Product = (props) => {
  const { trackingVideo } = props
  const product = props.content
  const style = {}
  return (
    <main className={styles.product} role="main" id="mainContent">
      <MetaData metadata={product.metadata} />
      {product.entryAnimation && product.entryAnimation.video && (
        <StoryContainer video={product.entryAnimation.video} />
      )}

      {product.hero && <HeroComponent hero={product.hero} time />}
      {product.videoComponent && (
        <section>
          <VideoFeature
            data={product.videoComponent}
            tracking={trackingVideo}
            contentType={props.contentType}
          />
        </section>
      )}
      <section className={styles.productIngredientsContainer}>
        {product.ingredients && (
          <ProductIngredients
            ingredients={product.ingredients}
            markdownStyles={ingredientMarkdown}
            fiftySix
          />
        )}
      </section>
      {product.imageCaptionOne && (
        <div className={styles.productImageCaptionOne}>
          <HalfWidthPng
            data={product.imageCaptionOne}
            imageClass={styles.imageClass}
            contentWidthClass={styles.productTextInner}
            textToTheRight
            reverseMobile
          />
        </div>
      )}
      {product.mystery && (
        <section className={styles.productContainer}>
          <ProductMystery mystery={product.mystery} />
        </section>
      )}
      {product.barrels && (
        <section className={style.barrelsContainer}>
          <FullBleedImage
            data={product.barrels}
            textInnerClass={styles.barrelsTextPosition}
          />
        </section>
      )}
      {product.imageCaptionTwo && (
        <OnVisible className={styles.productImageCaptionTwo} percent={-10}>
          <HalfWidthPng
            data={product.imageCaptionTwo}
            imageClass={styles.imageClass}
            contentWidthClass={styles.productTextInner}
            textToTheRight
            reverseMobile
          />
        </OnVisible>
      )}
      {product.shotoMeter && <Shotometer data={product.shotoMeter} />}
      {product.iframe && (
        <Generic
          components={product.iframe}
          contentType={product.iframe.contentType}
          endPoint={props.endPoint}
        />
      )}
      {product.recommendations && (
        <Recommendations recommendations={product.recommendations} />
      )}
    </main>
  )
}

Product.defaultProps = {
  content: {},
  contentType: 'Product',
  trackingVideo: () => {}
}
Product.propTypes = {
  content: PropTypes.object,
  contentType: PropTypes.string,
  trackingVideo: PropTypes.func
}
export default Product
