import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Anchor } from '../'

import styles from './button.css'

class Button extends Component {
  onClick = (e) => {
    if (this.props.onClick) {
      e.preventDefault()
      e.stopPropagation()
      this.props.track()
      this.props.onClick()
    }
  }

  render() {
    const classes = cx(styles.button, this.props.className)

    if (this.props.href) {
      return (
        <Anchor
          to={this.props.href}
          className={classes}
          style={this.props.style}
          onClick={() => this.props.track()}
        >
          {this.props.children}
        </Anchor>
      )
    }
    return (
      <button
        className={classes}
        style={this.props.style}
        onClick={this.onClick}
      >
        {this.props.children}
      </button>
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  track: PropTypes.func
}

Button.defaultProps = {
  className: null,
  style: null,
  children: null,
  onClick: null,
  href: null,
  track: () => {}
}

export default Button
