// The purpose of this component is to have a single place where all the
// duplicated code across containers can live.
//
// I think there may be further refacoring needed, but first step (i.e. this file)
// is to ensure refactoring only needs to be done in one place.
//
// Further possible work:
//  - we we have a hoc for Page already, but this is a seperate HoC, we maybe don’t need both

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import { NotFound } from 'containers'
import { Loading } from 'components'
import { trackPageView } from 'actions/analytics'
import { accessContent } from 'actions/content'

const composedPageContainer = (
  WrappedComponent,
  {
    contentNodeName,
    endPointName,
    integrationEndpointName,
    complaintFormEndpointName,
    salesforceSyncEndpointName,
    bestNightsNewsletterEndpointName,
    trackContentCategory = 'Content Page',
    trackDefaultPageName = 'Untitled Page',
    trackAlwaysUseDefaultPageName = false,
    defaultSlug = 'default'
  }
) => {
  function mapStateToProps(state, ownProps) {
    const slug = ownProps.slug || defaultSlug
    const content =
      state &&
      state.content &&
      state.content[contentNodeName] &&
      state.content[contentNodeName][slug]
    const endPoint = endPointName ? state && state[endPointName] : undefined
    const integrationsFormEndpoint = integrationEndpointName
      ? state && state[integrationEndpointName]
      : undefined
    const complaintFormEndpoint = complaintFormEndpointName
      ? state && state[complaintFormEndpointName]
      : undefined
    const salesforceSyncEndpoint = salesforceSyncEndpointName
      ? state && state[salesforceSyncEndpointName]
      : undefined
    const bestNightsNewsletterEndpoint = state.bestNightsNewsletterEndpoint
    const shareImage = state.shareImage
    return {
      contentNodeName,
      slug,
      content,
      shareImage,
      endPoint,
      integrationsFormEndpoint,
      complaintFormEndpoint,
      salesforceSyncEndpoint,
      bestNightsNewsletterEndpoint,
      ageGateConfig: state.ageGateConfig,
      recaptchaKey: state.recaptchaKey,
      locale: state.locale
    }
  }

  return withRouter(
    connect(mapStateToProps)(
      class extends Component {
        static propTypes = {
          contentNodeName: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          content: PropTypes.object.isRequired,
          dispatch: PropTypes.func.isRequired,
          tracking: PropTypes.object.isRequired,
          shareImage: PropTypes.string.isRequired
        }
        constructor(props) {
          super(props)
          this.trackVideo = this.trackVideo.bind(this)
          this.renderContent = this.renderContent()
        }
        renderContent() {
          this.props.dispatch(
            accessContent(this.props.contentNodeName, this.props.slug)
          )
        }
        componentDidMount() {
          if (this.props.content && this.props.content.metadata) {
            const trackingObj = this.props.tracking
            trackingObj.contentCategory = trackContentCategory
            trackingObj.page =
              (!trackAlwaysUseDefaultPageName &&
                this.props.content &&
                this.props.content.metadata &&
                `${this.props.content.metadata.pageName} - ${this.props.content.metadata.sectionName}`) ||
              trackDefaultPageName
            this.props.content.metadata.shareImage = this.props.shareImage
            trackingObj.url = document.location.href
            this.props.dispatch(trackPageView(trackingObj))
          }
        }

        trackVideo = (videoTrack) => {
          if (!videoTrack) return
          this.props.dispatch(
            trackPageView({
              event: 'video',
              videoAction: videoTrack.type || 'undefined',
              ContentId: videoTrack.id || 'undefined videoId'
            })
          )
        }

        render() {
          // TODO: if this.props.content.notLoaded (or similar)
          // trigger async load/action
          // return <Loading />
          // not very scientific but if there are more than 2 object keys then we know that
          // we have the page content and not the async loading content
          if (this.props && this.props.content && this.props.content.metadata) {
            return (
              <WrappedComponent
                {...this.props}
                trackingVideo={this.trackVideo}
              />
            )
          }
          if (this.props.content && !this.props.content.loaded) {
            return <Loading />
          }
          if (this.props.content && this.props.content.isLoading) {
            this.renderContent(this.props)
          }
          return <NotFound />
        }
      }
    )
  )
}

export default composedPageContainer
