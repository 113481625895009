import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FullBleedImage from '../fullbleedimage/FullBleedImage'
import styles from '../pages/coldbrew/coldbrew.css'
import { Tile } from '../index'

const BestNightsBanner = ({ name, context, component }) => {
  const fullBleedData = {
    id: component.id,
    image: component.image,
    linkTo: component.linkTo?.route,
    headline: `<${component.headingLevel}>${component.title}</${component.headingLevel}>`,
    headingLevel: component.headingLevel,
    text: '<p>' + component.caption + '</p>',
    mode: 'textMiddleRight',
    tint: '',
    deepLink: null,
    buttons: null,
    contentType: 'fullBleedImageComponent'
  }
  return (
    (context === 'components' && (
      <FullBleedImage
        key={name}
        data={fullBleedData}
        theme={'bestNightsBanner'}
      />
    )) || (
      <Tile
        title={component.title}
        caption={component.caption}
        image={component.image}
        linkTo={component.linkTo?.route}
        theme={'bestNightsBanner'}
      />
    )
  )
}

BestNightsBanner.propTypes = {
  name: PropTypes.string,
  context: PropTypes.string
}

BestNightsBanner.defaultProps = {
  name: '',
  context: 'components'
}

export default BestNightsBanner
