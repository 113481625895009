// Wraps a component and provides a track function
//
// This is so that deeply nested, generic components such as Markdown can trigger
// tracking events without needing their own Container.
//
// Adding a Container for components that need to be tracked may be a better approach,
// but in the case of Markdown would need a lot of refactoring of current usage and
// would be prone to error if a parent component imported the Markdown component rather than
// the Markdown container.
import { trackPageView } from 'actions/analytics'

import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => ({
  track: ({ event = 'CTA', ContentType = 'undefined', ContentId }) => {
    dispatch(trackPageView({
      event,
      ContentType,
      ContentId,
      url: document.location.href
    }))
  }
})

const track = ComposedComponent => connect(null, mapDispatchToProps)(ComposedComponent)

export default track
