import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MarkdownForErrorPage } from '../'

import styles from './errorpage.css'

class ErrorPage extends Component {
  componentWillMount() {
    if (this.context.router && this.context.router.staticContext) {
      // running on the server, so specify response status
      this.context.router.staticContext.status = this.props.errorCode
    }
  }

  render() {
    return (
      <main className={styles.wrapper} role="main" id="mainContent">
        <div
          className={classNames({
            [styles.image404]: this.props.errorCode === 404,
            [styles.image500]: this.props.errorCode === 500
          })}
        >
          {/* TODO: make this work: */}
          {/* <img src={image} alt="404" className={styles.image} /> */}
        </div>
        <div className={styles.text}>
          <div className={styles.notFound}>
            {this.props.errorText}
          </div>
          <div className={styles.markdown}>
            {/* We don't want to use the router in error pages,
            because the 500 error is rendered without a router  */}
            <MarkdownForErrorPage markdownHtml={this.props.text} />
          </div>
        </div>
      </main>
    )
  }
}

ErrorPage.contextTypes = {
  router: PropTypes.shape({
    staticContext: PropTypes.object
  }).isRequired
}

ErrorPage.propTypes = {
  errorCode: PropTypes.oneOf([404, 500]).isRequired,
  errorText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default ErrorPage
