import React, { Component } from 'react'
import { connect } from 'react-redux'
import { trackPageView, hashEmail } from 'actions/analytics'
import SalesforceForm from 'components/form/SalesforceForm'
import { digestMessage } from '../../lib/digestMessage'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'


class SalesforceFormContainer extends Component {
  render() {
    const { config } = this.props
    if (config?.recaptchaKey) {
      return (
        <GoogleReCaptchaProvider
          scriptProps={{
            async: true
          }}
          reCaptchaKey={config.recaptchaKey}
        >
          <SalesforceForm {...this.props} />
        </GoogleReCaptchaProvider>
      )
    }
    return <SalesforceForm {...this.props} />
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { data, locale } = ownProps
  return {
    trackOnSubmit: (locale, email) => {
      if (data && data.onSubmitTracking) {
        dispatch(
          trackPageView({
            event: 'Form',
            eventType: data.onSubmitTracking.eventType,
            eventDetail: data.onSubmitTracking.eventDetail
          })
        )
      }
      if (
        locale &&
        (locale.indexOf('en-US') > -1 || locale.indexOf('en-us') > -1) &&
        email
      ) {
        digestMessage(email).then((hash) => {
          dispatch(
            hashEmail({
              event: 'Hash',
              eventType: 'submit',
              userData: {
                email_sh256: hash
              }
            })
          )
        })
      }
    },
    trackOnSuccess: () => {
      if (data && data.onSuccessTracking) {
        dispatch(
          trackPageView({
            event: 'Form',
            eventType: data.onSuccessTracking.eventType,
            eventDetail: data.onSuccessTracking.eventDetail
          })
        )
      }
    }
  }
}
function mapStateToProps(state) {
  return {
    content: state.content,
    locale: state.locale
  }
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
  )(SalesforceFormContainer)
