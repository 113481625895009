import React from 'react'
import styles from './BNTitleText.css'
import PropTypes from 'prop-types'
import { Markdown } from '../index'

const BNTitleText = ({ text, headline, contentType, theme }) => {
  return (
    <div className={styles.titleText}>
      <div className={styles.textContainer}>
        {headline !== '' && (
          <Markdown
            markdownHtml={headline}
            contentType={contentType}
            theme={theme}
          />
        )}
        <Markdown markdownHtml={text} contentType={contentType} theme={theme} />
      </div>
    </div>
  )
}

BNTitleText.propTypes = {
  text: PropTypes.string,
  headline: PropTypes.string,
  contentType: PropTypes.string,
  theme: PropTypes.string
}

BNTitleText.defaultProps = {
  text: '',
  headline: '',
  contentType: '',
  theme: 'coldBrew'
}
export default BNTitleText
