import fetch from '../lib/fetch'

export const REQUEST_CONTENT = 'REQUEST_CONTENT'
export const RECEIVE_CONTENT = 'RECEIVE_CONTENT'
export const ACCESS_CONTENT = 'ACCESS_CONTENT'
export const ERROR_CONTENT = 'ACCESS_CONTENT'

// please remove if merged into master
// function requestContent(contentType, slug) {
//   return {
//     type: REQUEST_CONTENT,
//     contentType,
//     slug
//   }
// }

function receiveContent(contentType, slug, json) {
  return {
    type: RECEIVE_CONTENT,
    contentType,
    slug,
    content: json
  }
}

function accessLoadedContent(contentType, slug, json) {
  return {
    type: ACCESS_CONTENT,
    contentType,
    slug,
    content: json
  }
}
function errorInContent(contentType, slug, error) {
  return {
    type: ACCESS_CONTENT,
    contentType,
    slug,
    content: error
  }
}

// TODO: need to get locale
function fetchContent(contentType, slug, locale = 'en') {
  return dispatch =>
    // this should only happen on the client causing too many calls - if merge into master remove
    // the below line.
    // dispatch(requestContent(contentType, slug))
     fetch(`/api/content/${locale}/${contentType}/${slug}?cdn-verified-age=true`)
      .then(response => response.json())
      .then(json => dispatch(receiveContent(contentType, slug, json)))
      .catch((error) => {
        dispatch(errorInContent(contentType, slug, error))
        console.error('Api Page Error', error)
      })
}

function shouldFetchContent(state, contentType, slug) {
  if (!state.config || !state.config.allowAsync) return false
  const content = state.content[contentType] ? state.content[contentType][slug] : false
  if (!content) {
    return true
  } else if (content.isFetching || content.loaded) {
    return false
  }
  return !content.loaded
}

export function accessContent(contentType, slug) {
  return (dispatch, getState) => {
    if (shouldFetchContent(getState(), contentType, slug)) {
      return dispatch(fetchContent(contentType, slug, getState().locale))
    }
    return dispatch(accessLoadedContent(contentType, slug))
  }
}
