import React from 'react'
import PropTypes from 'prop-types'

import { MetaData, Generics, Generic } from 'components'
import { Recommendations } from 'containers'

import styles from './eventoverview.css'

const EventOverview = props => {
  return (
    <main className={styles.overview} role="main" id="mainContent">
      <MetaData metadata={props.content.metadata} />
      {props.content.hero &&
      <div className={styles.header}><Generic components={props.content.hero} /></div>}
      <section className={styles.content}><Generics components={props.content.components} /></section>
      {props.content.recommendations &&
        <Recommendations recommendations={props.content.recommendations} />}
    </main>
  );
};

EventOverview.propTypes = {
  content: PropTypes.object
};

EventOverview.defaultProps = {
  content: {}
};

export default EventOverview
