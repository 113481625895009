import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Grid, Tile, Tweet, InstagramPost } from 'components'

const DrinksGrid = (
  {
    listItems,
    customRoutes,
  },
) => {
  const drinkRoute = customRoutes.drinksRoute
  return (<Grid>
    {listItems && listItems.map((listItem) => {
      if (!listItem) {
        return null
      }

      switch (listItem.contentType) {
        case 'drink':
          return (<Tile
            key={listItem.key}
            linkTo={`/${drinkRoute}/${listItem.key}`}
            image={listItem.thumbnail}
            caption={listItem.caption}
            title={listItem.title}
          />)
        case 'tweet':
          return (<Tweet
            key={listItem.key}
            url={listItem.url}
            handle={listItem.handle}
            content={listItem.content}
            isBrandAccount={listItem.isBrandAccount}
            profileImage={listItem.profileImage}
          />)
        case 'instagramPost':
          return (<InstagramPost
            key={listItem.key}
            url={listItem.url}
            handle={listItem.handle}
            isBrandAccount={listItem.isBrandAccount}
            image={listItem.image}
          />)
        default:
          return null
      }
    }
  )}
  </Grid>)
}

DrinksGrid.propTypes = {
  listItems: PropTypes.array.isRequired,
  customRoutes: PropTypes.object.isRequired
}

export default DrinksGrid
