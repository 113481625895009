import { REQUEST_CONTENT, RECEIVE_CONTENT, ACCESS_CONTENT, ERROR_CONTENT } from 'actions/content'

export default function (state = {}, action) {
  let hasChanged = false
  let newState
  switch (action.type) {
    case REQUEST_CONTENT: {
      const { contentType, slug } = action
      if (state[contentType][slug] && !state[contentType][slug].isLoading) {
        hasChanged = true
        newState = {
          ...state
        }
        newState[contentType] = { ...newState[contentType] || {} }
        newState[contentType][slug] = { ...newState[contentType][slug] || {} }
        newState[contentType][slug].isLoading = true
      }
      break
    }
    case RECEIVE_CONTENT: {
      const { contentType, slug, content } = action
      hasChanged = true
      newState = {
        ...state
      }
      newState[contentType] = { ...newState[contentType] || {} }
      newState[contentType][slug] = content
      newState[contentType][slug].loaded = true
      break
    }
    case ACCESS_CONTENT: {
      const { contentType, slug } = action
      if (state[contentType] && state[contentType][slug] && !state[contentType][slug].loaded) {
        hasChanged = true
        newState = {
          ...state
        }
        newState[contentType] = { ...newState[contentType] || {} }
        newState[contentType][slug] = { ...newState[contentType][slug] || {} }
        newState[contentType][slug].loaded = true
      }
      break
    }
    case ERROR_CONTENT: {
      Object.assign({}, state, {
        error: {
          code: 'INVALID ASYNC ACTION',
          message: 'The ASYNC action received was empty, probably due to an misconfiguration in the CMS',
        }
      })
      break
    }
    default:
      break
  }

  return hasChanged ? newState : state
}
