import React from 'react'
import PropTypes from 'prop-types'

import { MetaData, Generics, Generic } from 'components'
import { Recommendations } from 'containers'

const MusicOverview = ({ content, endPoint }) => (
  <main role="main" id="mainContent">
    <MetaData metadata={content.metadata} />
    {content.hero && <Generic components={content.hero} />}
    {content.components && (
      <Generics components={content.components} endPoint={endPoint} />
    )}
    {content.recommendations && (
      <Recommendations recommendations={content.recommendations} />
    )}
  </main>
)
MusicOverview.propTypes = {
  content: PropTypes.object,
  endPoint: PropTypes.string
}

MusicOverview.defaultProps = {
  content: {},
  endPoint: ''
}

export default MusicOverview
