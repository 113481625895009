import React from 'react'

import styles from './fiftySix.css'

function FiftySix() {
  return (
    <svg viewBox="0 0 207.6 201.7" className={styles.fiftySix}>
      <path
        fill="#DD5A12" d="M7.7,139.5v13.8c0,30,19.5,41,44.5,41c30,0,42-18,42-56v-25c0-30.2-9.2-47.5-33-47.5c-9.5,0-16,1.2-23.8,10.5
       l-0.5-0.5l1.8-39h48.8l1-25.5H13.2c0,42.8-1.2,64.8-2.2,92.8h24c2.8-7.5,7.2-13.8,16.8-13.8c7,0,13,4.8,13,16.5v41
        c0,16.2-5.5,22-15.2,22c-8.2,0-13.8-6-13.8-15.8v-14.5H7.7z"
      />
      <path
        fill="#DD5A12" d="M196.9,59.7v-11c0-23-15.8-40.5-40.2-40c-31,0-44,15.5-44,49.5v86.5c0,34,13,49.5,44,49.5s44-17,44-55v-21.5
        c0-26.5-15-37-33-37c-12.5,0-17.5,4-25.5,12V50.5c0-11.8,5.2-17.2,14.5-17.2s13.2,5.5,13.2,17.2v9.2H196.9z M142.2,113.5
        c3.8-3,10.5-8.2,16-8.2c7.8,0,13,5.5,13,17.2v30c0,11.8-5.2,17.2-14.5,17.2s-14.5-5.5-14.5-17.2V113.5z"
      />
    </svg>
  )
}

export default FiftySix

