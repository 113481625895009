import React from 'react'
import PropTypes from 'prop-types'

import styles from './downloadabledocument.css'

const DownloadableDocument = (props) => (
  <div className={styles.downloadableDocument}>
    <p className={styles.downloadableDocumentImageContainer}>
      <img
        src={props.image.url}
        alt={props.image.title}
        className={styles.downloadableDocumentImage}
      />
    </p>
    <a
      className={styles.downloadableDocumentTitle}
      href={props.file.url}
      target="_blank"
    >
      {props.name}
    </a>
    <p className={styles.downloadableDocumentDescription}>
      {props.description}
    </p>
  </div>
)

DownloadableDocument.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  file: PropTypes.any.isRequired,
  image: PropTypes.any.isRequired
}
DownloadableDocument.defaultProps = {
  onClick: () => {}
}
export default DownloadableDocument
