/*
 * Override the default entry title with a new heading that will be chosen in Contentful
 *
 * headline       The title string
 * headingLevel   The heading level selected in Contentful
 * className      The CSS class to pass, if needed
 */
import React from 'react'

export const overrideEntryTitle = ({ headline, headingLevel, className, addStrong = false }) => {
  // console.log('Triggered overrideEntryTitle')

  const headingLevels = {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6'
  }

  const selectedHeadingLevel = headingLevels[headingLevel]

  // Remove the `p` tag from the contents of `headline` that came from Contentful
  let headlineWithoutPTag = headline.replace(/<p>/g, '').replace(/<\/p>/g, '')

  if (addStrong) {
    headlineWithoutPTag = `<strong>${headlineWithoutPTag}</strong>`
  }

  // console.log('Contentful heading:', headingLevel)
  // console.log('Final heading:', selectedHeadingLevel)

  return React.createElement(selectedHeadingLevel, {
    className,
    dangerouslySetInnerHTML: { __html: headlineWithoutPTag }
  })
}
