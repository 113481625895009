import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { Img, Markdown, OnVisible } from '../'

import styles from './eventdetail.css'

const EventDetail = (props) => {
  const { image, headline, text, cta } = props.data
  const { animationOffsetPercent, contentType } = props
  const wrapperClasses = classNames(
    styles.wrapper,
    styles.wrapperReverseMobile
  )
  const hasLink = (el, idx) => {
    let link = el || ''
    const id = idx || 1
    if (link.route) {
      link = (<NavLink to={link.route} key={link.id + id} className={styles.cta}>{link.text}</NavLink>)
    } else if (link.url) {
      link = (<a href={link.url} key={link.id + id} target="_blank" rel="noopener noreferrer" className={styles.lineUpLink}>{link.text}</a>)
    }
    return link
  }
  return (
    <OnVisible
      className={styles.transitionStart}
      visibleClassName={styles.transitionEnd}
      percent={animationOffsetPercent}
    >
      <div className={wrapperClasses}>
        <div className={styles.column}>
          {image && <figure className={styles.eventDetailImage} aria-label={image.title}><Img src={image.url} alt={image.title} className={styles.eventDetailImg} /></figure>}
        </div>

        <div className={styles.column}>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <div className={styles.headline}>
                {headline}
              </div>
              <div className={styles.markdown}>
                <Markdown markdownHtml={text} contentType={contentType} />
              </div>
              <div>
                { cta && hasLink(cta) }
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </OnVisible>
  )
}

EventDetail.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  animationOffsetPercent: PropTypes.number
}

EventDetail.defaultProps = {
  data: {
    image: { url: '', title: '' },
    headline: '',
    text: ''
  },
  animationOffsetPercent: 2,
  trackArtistSocialClicks: () => {}
}

export default EventDetail
