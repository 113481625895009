/* global window */
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { App } from '../shared/components'
import configureStore from '../shared/store/configureStore'
import { windowPosition } from '../shared/actions/position'

const state = window.jm.state

const store = configureStore(state, window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)

store.dispatch(windowPosition(window.pageYOffset))

const ClientApp = () => {
  const locale = store.getState().locale
  const shareImage = store.getState().shareImage
  return (<Provider store={store}>
    <BrowserRouter basename={`/${locale}`}>
      <App
        locale={locale}
        shareImage={shareImage}
      />
    </BrowserRouter>
  </Provider>)
}

export default ClientApp
