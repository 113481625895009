import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Img, Markdown, OnVisible } from '../'

import styles from './fullwidthpng.css'

const HalfWidthPng = (props) => {
  const { image, headline, text, hideOrangeRibbon } = props.data
  const { imageStickToLeft, contentType } = props

  return (
    <OnVisible
      className={styles.transitionStart}
      visibleClassName={styles.transitionEnd}
      percent={30}
    >
      <div className={styles.wrapper}>
        <div
          className={classNames(styles.image, {
            [styles.imageStickToLeft]: imageStickToLeft
          })}
        >
          <Img src={image.url} alt={image.title} lazyLoading={true} />
        </div>

        <div className={styles.text}>
          <div className={styles.textInner}>
            <div
              className={classNames(
                hideOrangeRibbon === true ? styles.hideRibbon : styles.headline
              )}
            >
              {headline}
            </div>
            <div className={styles.markdown}>
              <Markdown markdownHtml={text} contentType={contentType} />
            </div>
          </div>
        </div>
      </div>
    </OnVisible>
  )
}

HalfWidthPng.propTypes = {
  data: PropTypes.object.isRequired,
  imageStickToLeft: PropTypes.bool,
  contentType: PropTypes.string,
  hideOrangeRibbon: PropTypes.bool
}

HalfWidthPng.defaultProps = {
  imageStickToLeft: false,
  contentType: '',
  hideOrangeRibbon: false
}

export default HalfWidthPng
