export const TOGGLE_FILTER = 'TOGGLE_FILTER'

export function toggleFilter({
  filterKey,
  groupKey,
  optionKey
}) {
  return {
    type: TOGGLE_FILTER,
    filterKey,
    groupKey,
    optionKey
  }
}
