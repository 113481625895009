import React from 'react'
import { Markdown, Image, Anchor } from 'components'
import styles from './event.css'

export default ({ event: {
  title, id, caption, description, date, month, day, image, link, linkText = 'See more', mode
} = {} }) => {
  const featured = mode === 'featured'
  return (<Anchor
    to={link && (link.url || link.route)}
    className={featured ? styles.featured : styles.nonfeatured}
    key={id}
  >
    <div className={styles.dateContainer}>
      <div className={styles.date}>{date < 10 ? `0${date}` : date}</div>
      <div className={styles.month}>{month}</div>
      <div className={styles.day}>{day}</div>
    </div>

    {featured && image && <div className={styles.image}><Image
      src={image.url}
      alt={image.title || image.description}
    /></div>}

    <div className={styles.text}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.caption}>{caption}</p>

      {featured && <div className={styles.description}>
        <Markdown markdownHtml={description} /></div>}
      {link && featured && <div className={styles.seeMore}>{linkText}</div>}
    </div>

    {link && !featured && <div className={styles.arrow}>
      <svg
        className={styles.arrowSvg}
        viewBox="0 0 29 52"
      >
        <path
          fillRule="evenodd" fill="rgb(179, 173, 115)"
          d="M3.420,51.502 L0.005,48.110 L22.184,26.002 L0.005,3.894 L3.420,0.503 L29.002,26.002 L3.420,51.502 Z"
        />
      </svg>
    </div>}
  </Anchor>)
}
