import { connect } from 'react-redux'
import NotFound from 'components/errors/notfound'

// TODO: This component requires data to be in the state on the client side.
// Need to assess how this will work when some state data is loaded async on the client.
function mapStateToProps(state) {
  const errorText = state.content.errors &&
      state.content.errors.default &&
      state.content.errors.default.errorText404
  const text = state.content.errors &&
      state.content.errors.default &&
      state.content.errors.default.errorExtendedText404
  return {
    errorText,
    text
  }
}

export default connect(mapStateToProps)(NotFound)
