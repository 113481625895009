import PropTypes from 'prop-types'
import React from 'react'

import { OnVisible, Img } from '../'
import { Button, DownloadButton } from 'containers'

import {
  prost,
  iceCold,
  categories,
  seeDrinks,
  downloadRecipe
} from 'utils/i18n'
import styles from './drinkcompleted.css'

const DrinkCompleted = (props) => {
  const completedImage = props.completedImage
  const customRoute = props.customRoutes
  if (!completedImage) {
    return <div />
  }
  const siteText = {
    prost: prost(),
    iceCold: iceCold(),
    categories: categories(),
    seeDrinks: seeDrinks(),
    downloadRecipe: downloadRecipe()
  }
  return (
    <div className={styles.gradientWrapper}>
      <OnVisible
        percent={35}
        className={styles.drinkCompleted}
        visibleClassName={styles.visible}
      >
        <div className={styles.completedImage}>
          <Img
            src={completedImage.url}
            sizes="50vw"
            mobileSizes="100vw"
            alt={completedImage.title}
            objectFit={false}
          />
        </div>
        <h2 className={styles.title}>{siteText.prost}</h2>
        <h3 className={styles.bestServed}>{siteText.iceCold}</h3>
        {props.tags && props.tags.length && (
          <h2 className={styles.tagsTitle}>{siteText.categories}</h2>
        )}
        {props.tags && props.tags.length && (
          <ul className={styles.tags}>
            {props.tags.map((tag) => (
              <li key={tag.id} className={styles.tag}>
                {tag.title}
              </li>
            ))}
          </ul>
        )}
        <div className={styles.downloadContainer}>
          {props.recipeDownload && props.recipeDownload.url && (
            <DownloadButton
              trackingEventType="Recipe Download"
              trackingEventDetail={completedImage.title}
              href={(props.recipeDownload && props.recipeDownload.url) || '#'}
            >
              {siteText.downloadRecipe}
            </DownloadButton>
          )}
        </div>
        <div className={styles.allDrinksContainer}>
          <Button
            href={`/${customRoute.drinksRoute}`}
            trackingEventType="Back to Drinks"
            trackingEventDetail={siteText.seeDrinks}
            className={styles.cta}
          >
            {siteText.seeDrinks}
          </Button>
        </div>
      </OnVisible>
    </div>
  )
}

DrinkCompleted.propTypes = {
  completedImage: PropTypes.object.isRequired,
  recipeDownload: PropTypes.object,
  customRoutes: PropTypes.object,
  tags: PropTypes.array.isRequired
}

DrinkCompleted.defaultProps = {
  recipeDownload: null,
  customRoutes: {}
}

export default DrinkCompleted
