import React from 'react'
import PropTypes from 'prop-types'
import { FilterOption as FilterOptionContainer } from 'containers'

import styles from './filtergroup.css'

const FilterGroup = (
  {
    name,
    items,
    filterKey,
    groupKey,
  },
) => {
  return (
    <div className={styles.filtergroup}>
      <h2 className={styles.heading}>{name}</h2>
      {items.map(option => (<div key={option.id} className={styles.filteroption}><FilterOptionContainer
        filterKey={filterKey}
        groupKey={groupKey}
        optionKey={option.id}
        name={option.title}
      /></div>)
      )}
    </div>
  );
};

FilterGroup.propTypes = {
  groupKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired
};

export default FilterGroup
