import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  HalfWidthPng,
  FullBleedImage,
  FullWidthPng,
  MetaData,
  OnVisible,
  VideoFeature,
  Generic,
  Title
} from 'components'
import { Recommendations } from 'containers'
import styles from './musicPage.css'

class MusicPage extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {}
  }

  componentRender = (components, number) => {
    return (
      (components.contentType === 'fullBleedImageComponent' && (
        <div className={styles.fullBleedWrapper}>
          <FullBleedImage
            key={number}
            data={components}
            headlineClass={styles.headline}
            textInnerClass={styles.text}
            theme="musicPage"
            reverseMobile={false}
          />
        </div>
      )) ||
      (components.contentType === 'fullWidthPngComponent' && (
        <FullWidthPng data={components} />
      )) ||
      (components.contentType === 'halfWidthPngComponent' && (
        <HalfWidthPng
          data={components}
          key={number}
          contentWidthClass={styles.textInner}
          reverseMobile={true}
        />
      )) ||
      (components.contentType === 'videoComponent' && (
        <div key={components.id}>
          <VideoFeature
            data={components}
            contentType={components.contentType}
          />{' '}
        </div>
      )) ||
      (components.contentType === 'title' && (
        <Title title={components.title} key={components.id} theme="musicPage" />
      )) || (
        <Generic
          components={components}
          contentType={components.contentType}
          endPoint={this.props.endPoint}
          theme="musicPage"
        />
      )
    )
  }

  render() {
    const { content } = this.props
    const components = content.components
    const hero = content.hero
    return (
      <main role="main" id="mainContent">
        <MetaData metadata={content.metadata} />{' '}
        {hero && (
          <section>
            {hero && hero.contentType === 'fullBleedImageComponent' ? (
              <FullBleedImage
                key={hero.id}
                data={hero}
                theme="musicPage"
                textInnerClass={styles.heroText}
                headlineClass={styles.heroHeadline}
                isHero
              />
            ) : hero && hero.contentType === 'videoComponent' ? (
              <div>
                <VideoFeature
                  data={hero}
                  theme="musicPage"
                  contentType={components.contentType}
                  textInnerClass={styles.heroText}
                  headlineClass={styles.heroHeadline}
                  isHero
                />
              </div>
            ) : (
              ''
            )}
          </section>
        )}
        {components &&
          components.map((d, idx) => (
            <section key={d.id} className={styles.divider}>
              {' '}
              {this.componentRender(d, idx)}{' '}
            </section>
          ))}
        {content.recommendations && (
          <Recommendations recommendations={content.recommendations} />
        )}{' '}
      </main>
    )
  }
}

MusicPage.defaultProps = {
  content: {}
}
MusicPage.propTypes = {
  content: PropTypes.object
}

export default MusicPage
