import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Navigation = ({
  itemClassName,
  containerClassName,
  navigation,
  trackClick
}) => (
  <div className={containerClassName}>
    {navigation &&
      navigation.items &&
      navigation.items.map((item) => {
        if (item.url) {
          return (
            <a
              href={item.url}
              className={itemClassName}
              key={item.id}
              onClick={() => trackClick(item.text)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{item.text}</span>
            </a>
          )
        } else if (item.route) {
          return (
            <Link
              key={item.id}
              to={item.route}
              onClick={() => trackClick(item.text)}
              className={itemClassName}
            >
              {item.text}
            </Link>
          )
        }
        // TODO: if a nav item is missing a url and route, it should be dealt
        // with in transform layer + item shoudn't be included
        return (
          <span key={item.id} className={itemClassName}>
            {item.text}
          </span>
        )
      })}
  </div>
)

Navigation.propTypes = {
  navigation: PropTypes.object.isRequired,
  itemClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  trackClick: PropTypes.func
}

Navigation.defaultProps = {
  itemClassName: '',
  containerClassName: '',
  trackClick: () => {}
}

export default Navigation
