import React, { Component } from 'react'
import qs from 'qs'
import classNames from 'classnames'
import scrollToElement from 'scroll-to-element'
import PropTypes from 'prop-types'

import styles from './scrollToButton.css'

class ScrollToButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queries: this.getQueries(props.data.link || props.data?.components.link),
      link: this.stripLink(props.data?.components.link)
    }
  }

  stripLink = (rawLink) => {
    const index = rawLink.indexOf('?')
    if (index > -1) {
      return rawLink.substr(0, index)
    } else {
      return rawLink
    }
  }

  getQueries = (rawLink) => {
    const index = rawLink.indexOf('?')
    if (index > -1) {
      const queriesString = rawLink.substr(index + 1)
      return qs.parse(queriesString, { ignoreQueryPrefix: true })
    } else {
      return null
    }
  }

  render() {
    const { data, theme, track, queryControls } = this.props
    const { link, queries } = this.state
    return (
      <button
        className={classNames(styles.scrollButton, {
          [styles.secondaryBtn]: data.style === 'secondary',
          [styles.default]: theme === 'default',
          [styles.scharf]: theme === 'scharf'
        })}
        onClick={() => {
          const el = document.getElementById(link)
          if (queryControls && queries) {
            queryControls(queries)
          }
          scrollToElement(el)
          if (track) {
            track()
          }
        }}
      >
        {data.text}
      </button>
    )
  }
}

ScrollToButton.propTypes = {
  data: PropTypes.object.isRequired
}

export default ScrollToButton
