import basePage from 'hoc/basepage/basepage'
import { ProductB } from 'components/pages'

export default basePage(ProductB, {
  contentNodeName: 'jaegermeisterProductB',
  defaultSlug: 'manifest',
  trackContentCategory: 'Product Page',
  trackDefaultPageName: 'Manifest',
  trackAlwaysUseDefaultPageName: true
})
