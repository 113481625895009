import { connect } from 'react-redux'
import { trackPageView } from 'actions/analytics'

import DrinksGrid from 'components/drinksgrid/drinksgrid'

function mapDispatchToProps(dispatch) {
  return {
    onFilter: (filter) => {
      dispatch(trackPageView({
        event: 'Explore',
        eventType: filter.catagory,
        eventDetail: filter.type
      }))
    }
  }
}

// We don't handle filters server side, so we don't need to pass any server state
// to the client for this.
function mapStateToProps(state, ownProps) {
  const customRoutes = state.customRoutes ? state.customRoutes : ''
  const filter = state.filter && state.filter[ownProps.filterKey]

  let listItems = ownProps.listItems
  if (listItems && filter) {
    // if all filters are set to false then don't do anything
    const allFalse = Object.keys(filter)
      .every(optionKey => !filter[optionKey])
    if (!allFalse) {
      // otherwise only show child items who have a tag that is in the filter
      listItems = listItems.filter((listItem) => {
        if (!listItem.tags) {
          return false
        }
        return listItem.tags
          .some(itemFilterTag => filter[itemFilterTag.id])
      })
    }
  }

  return {
    listItems,
    customRoutes
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrinksGrid)
