import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './sitelinks.css'

const SiteLinks = (links) => {
  const linksItems = links && links.links.map(d =>
    <NavLink key={d.id} to={d.route} className={styles.link} activeClassName={styles.active}>{d.text}</NavLink>)
  return (
    <div>
      { linksItems }
    </div>
  )
}

export default SiteLinks
