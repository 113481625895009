/**
 * Component used to determine whether a url is local of external.
 * Then ally the staggerlink class see connect btn for example.
 */
import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './staggerlink.css'
import split from '../../lib/split'

const StaggeredLink = (props) => {
  const { to, className, children, rel } = props
  let link = ''
  if (rel) {
    link = (
      <a
        href={to}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        aria-label={children}
      >
        <span aria-hidden="true">
          {split(children, styles.split)}
        </span>
      </a>
    )
  } else {
    link = (
      <NavLink exact={to === '/'} {...props} activeClassName={styles.active}
               aria-label={props.children}>
        <span aria-hidden="true">
          {split(props.children, styles.split)}
        </span>
      </NavLink>
    )
  }
  return link
}

StaggeredLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  rel: PropTypes.string.isRequired
}
StaggeredLink.defaultProps = {
  rel: ''
}

export default StaggeredLink
