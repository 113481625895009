
import { PAGEY, DELTA } from '../actions/position'

function getDefaultState() {
  return {
    pageY: 0,
    delta: 0,
    direction: 0
  }
}


export default function (state = getDefaultState(), action) {
  let hasChanged = false
  const nextState = {
    ...state
  }

  let direction = state.direction

  switch (action.type) {
    case PAGEY:
      hasChanged = true
      nextState.pageY = action.value
      break
    case DELTA:
      hasChanged = true
      nextState.delta = action.value
      if (action.value === 0) {
        direction = 0
      } else if (action.value > 0) {
        direction = 1
      } else if (action.value < 0) {
        direction = -1
      }
      nextState.direction = direction
      break
    default:
      break
  }
  return hasChanged ? nextState : state
}
