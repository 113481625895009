import React, { useState, useEffect } from 'react'
import styles from './cookiesList.css'

const CookiesList = (props) => {
  const [domainData, setDomainData] = useState(null)
  useEffect(() => {
    setTimeout(() => {
      const data = window.OneTrust && window.OneTrust.GetDomainData()
      setDomainData(data)
    }, 1000)
  }, [domainData])
  return (
    <div className={styles.wrapper}>
      {/* <!-- OneTrust Cookies List start --> */}
      {domainData && (
        <div>
          <h2>{domainData.CookieListTitle}</h2>
          <p className={styles.cookieParagraph}>
            {domainData.CookieListDescription}
          </p>
          {domainData.Groups.map((group) => {
            const groupedFirstPartyCookies = {}
            group.FirstPartyCookies.forEach((cookie) => {
              groupedFirstPartyCookies[cookie.Host] =
                groupedFirstPartyCookies[cookie.Host] || []
              groupedFirstPartyCookies[cookie.Host].push(cookie)
            })
            const groupedThirdPartyCookies = {}

            group.Hosts.forEach((host) => {
              groupedThirdPartyCookies[host.HostName] = host.Cookies
            })
            const count =
              Object.keys(groupedFirstPartyCookies).length +
              Object.keys(groupedThirdPartyCookies).length
            if (count === 0) return null
            return (
              <div key={group.CustomGroupId}>
                <h3 className="ot-sdk-cookie-policy-group">
                  {group.GroupName}
                </h3>
                <p className={styles.cookieParagraph}>
                  {group.GroupDescription}
                </p>
                <div className={styles.oneTrustBannerSdkContainer}>
                  <table
                    id="onetrust-banner-sdk"
                    className={styles.oneTrustBannerSdk}
                  >
                    <thead>
                      <tr>
                        <th className="ot-table-header ot-host">
                          {domainData.CategoriesText}
                        </th>
                        <th className="ot-table-header ot-cookies">
                          {domainData.CookiesText}
                        </th>
                        <th className="ot-table-header ot-cookies-type">
                          {domainData.CookiesUsedText}
                        </th>
                        <th className="ot-table-header ot-life-span">
                          {domainData.LifespanText}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(groupedFirstPartyCookies).map((host) => (
                        <tr key={host}>
                          <td
                            className="ot-host-td"
                            data-label="Cookie Subgroup"
                          >
                            <span className="ot-mobile-border" />
                            <a
                              href={`https://cookiepedia.co.uk/host/${host}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              {host}
                            </a>
                          </td>
                          <td className="ot-cookies-td" data-label="Cookies">
                            <span className="mobile-border" />
                            <span className="ot-cookies-td-content">
                              {groupedFirstPartyCookies[host].map(
                                (cookie, index) => (
                                  <span key={cookie.Host + cookie.Name}>
                                    <a
                                      href={`https://cookiepedia.co.uk/cookies/${cookie.Name}`}
                                      target="_blank"
                                      rel="noopener noreferrer nofollow"
                                    >
                                      {cookie.Name}
                                    </a>
                                    {index <
                                    groupedFirstPartyCookies[host].length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                )
                              )}
                            </span>
                          </td>
                          <td className="ot-cookies-type" data-label="Type">
                            <span className="ot-mobile-border" />
                            <span className="ot-cookies-type-td-content">
                              1st Party
                            </span>
                          </td>
                          <td
                            className="ot-life-span-td"
                            data-label="Life Span"
                          >
                            <span className="ot-mobile-border" />
                            <span className="ot-life-span-td-content">
                              {groupedFirstPartyCookies[host].map(
                                (cookie, index) => (
                                  <span key={cookie.Host + cookie.Name}>
                                    {cookie.Length}
                                    {index <
                                    groupedFirstPartyCookies[host].length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                )
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                      {Object.keys(groupedThirdPartyCookies).map((host) => (
                        <tr key={host}>
                          <td
                            className="ot-host-td"
                            data-label="Cookie Subgroup"
                          >
                            <span className="ot-mobile-border" />
                            <a
                              href={`https://cookiepedia.co.uk/host/${host}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              {host}
                            </a>
                          </td>
                          <td className="ot-cookies-td" data-label="Cookies">
                            <span className="mobile-border" />
                            <span className="ot-cookies-td-content">
                              {groupedThirdPartyCookies[host].map(
                                (cookie, index) => (
                                  <span key={cookie.Host + cookie.Name}>
                                    <a
                                      href={`https://cookiepedia.co.uk/cookies/${cookie.Name}`}
                                      target="_blank"
                                      rel="noopener noreferrer nofollow"
                                    >
                                      {cookie.Name}
                                    </a>
                                    {index <
                                    groupedThirdPartyCookies[host].length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                )
                              )}
                            </span>
                          </td>
                          <td className="ot-cookies-type" data-label="Type">
                            <span className="ot-mobile-border" />
                            <span className="ot-cookies-type-td-content">
                              3rd Party
                            </span>
                          </td>
                          <td
                            className="ot-life-span-td"
                            data-label="Life Span"
                          >
                            <span className="ot-mobile-border" />
                            <span className="ot-life-span-td-content">
                              {groupedThirdPartyCookies[host].map(
                                (cookie, index) => (
                                  <span key={cookie.Host + cookie.Name}>
                                    {cookie.IsSession && cookie.IsSession
                                      ? 'Session'
                                      : `${cookie.Length}`}
                                    {index <
                                    groupedThirdPartyCookies[host].length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                )
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          })}
        </div>
      )}
      {/* <!-- OneTrust Cookies List end --> */}
    </div>
  )
}

export default CookiesList
