import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Markdown, Close } from '../'

import styles from './verificationError.css'

class VerificationError extends Component {
  constructor(props) {
    super(props)
    this.count = 0
  }

  render() {
    const { close, show, content, underAge } = this.props
    if (underAge && this.count === 0) {
      this.props.onFail()
      this.count = +1
    }
    return (
      <div
        className={classNames({
          [styles.wrapper]: true,
          [styles.show]: show
        })}
        aria-live="assertive"
      >
        <button aria-label="close" className={styles.close} onClick={close}>
          <Close />
        </button>
        <div className={styles.message}>
          <div className={styles.headline}>
            {underAge ? content.headlineTooYoung : content.headlineTooOld}
          </div>
          {underAge ? (
            <Markdown
              className={styles.text}
              markdownHtml={content.textTooYoung}
            />
          ) : (
            <Markdown
              className={styles.text}
              markdownHtml={content.textTooOld}
            />
          )}
        </div>
      </div>
    )
  }
}

VerificationError.propTypes = {
  content: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  show: PropTypes.bool,
  underAge: PropTypes.bool.isRequired,
  onFail: PropTypes.func.isRequired
}

VerificationError.defaultProps = {
  show: false
}

export default VerificationError
