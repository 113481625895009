import React from 'react'

import styles from './artistsocialicons.css'

export function facebook() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path fill="#b3ac76" d="M37.52 57.37h6V42.44h5l.75-5.82h-5.74V32.9c0-1.68.47-2.83 2.88-2.83h3.09v-5.2a41.19 41.19 0 0 0-4.5-.24c-4.44 0-7.48 2.71-7.48 7.69v4.29h-5v5.82h5v14.94z" />
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
    </svg>
  )
}

export function instagram() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path id="Instagram" fill="#b3ac76" d="M41 27.13a49.24 49.24 0 0 0-5.63.14 6.94 6.94 0 0 0-3.3.69A7.57 7.57 0 0 0 28 32.07a15.18 15.18 0 0 0-.69 3.3c0 1.51-.14 1.92-.14 5.63a49.24 49.24 0 0 0 .14 5.63 6.94 6.94 0 0 0 .69 3.3A7.57 7.57 0 0 0 32.07 54a15.18 15.18 0 0 0 3.3.69c1.51 0 1.92.14 5.63.14a49.24 49.24 0 0 0 5.63-.14 6.94 6.94 0 0 0 3.3-.69A7.57 7.57 0 0 0 54 49.93a15.18 15.18 0 0 0 .69-3.3c0-1.51.14-1.92.14-5.63a49.26 49.26 0 0 0-.14-5.63 6.94 6.94 0 0 0-.69-3.3A7.57 7.57 0 0 0 49.93 28a15.18 15.18 0 0 0-3.3-.69 49.24 49.24 0 0 0-5.63-.18zm0 2.47a46.28 46.28 0 0 1 5.49.14 8.41 8.41 0 0 1 2.61.41 4.09 4.09 0 0 1 1.51 1 3.56 3.56 0 0 1 1 1.51 8.42 8.42 0 0 1 .39 2.57c0 1.51.14 1.92.14 5.49a46.3 46.3 0 0 1-.14 5.5 8.42 8.42 0 0 1-.41 2.61 4.09 4.09 0 0 1-1 1.51 3.56 3.56 0 0 1-1.51 1 8.41 8.41 0 0 1-2.61.41c-1.51 0-1.92.14-5.49.14a46.28 46.28 0 0 1-5.49-.14 8.41 8.41 0 0 1-2.61-.41 4.09 4.09 0 0 1-1.51-1 3.56 3.56 0 0 1-1-1.51 8.41 8.41 0 0 1-.37-2.61c0-1.51-.14-1.92-.14-5.49a46.28 46.28 0 0 1 .14-5.5 8.42 8.42 0 0 1 .41-2.61 4.09 4.09 0 0 1 1-1.51 3.56 3.56 0 0 1 1.51-1 8.41 8.41 0 0 1 2.61-.41 46.28 46.28 0 0 1 5.47-.1zm0 4.26a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm0 11.54a4.53 4.53 0 1 1 4.53-4.53A4.57 4.57 0 0 1 41 45.39zm8.93-11.81a1.65 1.65 0 1 1-1.65-1.65 1.59 1.59 0 0 1 1.65 1.64z" />
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
    </svg>
  )
}

export function twitter() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path fill="#b3ac76" d="M35.6 52.8c10.94 0 16.76-7.84 16.76-15.73v-.83a14.66 14.66 0 0 0 4.13-6.05 14.68 14.68 0 0 1-6.26.18 5.3 5.3 0 0 0-7.9-.74c-1.52 1.38-2.17 4.77-1.61 6.85a16.35 16.35 0 0 1-12.18-6.09 5.92 5.92 0 0 0 1.8 7.89 5.73 5.73 0 0 1-2.63-.69v.14a5.93 5.93 0 0 0 4.71 5.82 5.65 5.65 0 0 1-2.63.14c.83 2.35 2.39 3.94 4.88 4.08a24.45 24.45 0 0 1-8.07 2.43 17.07 17.07 0 0 0 9 2.63" />
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
    </svg>
  )
}

export function bandcamp() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path fill="#b3ac76" d="M47.45 51.44H23.24l11.31-20.88h24.21L47.45 51.44" />
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
    </svg>
  )
}

export function mixcloud() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
      <path fill="#b3ac76" d="M47.15 38.39a10.93 10.93 0 0 0-21.22-2.44A7.33 7.33 0 0 0 27 50.53h18.82a6.14 6.14 0 0 0 1.32-12.14zm-1.32 9.39H27A4.58 4.58 0 1 1 30.26 40a1.38 1.38 0 1 0 1.95-2 7.28 7.28 0 0 0-3.38-1.92 8.18 8.18 0 0 1 15.62 3.37A8.11 8.11 0 0 1 44 42a1.38 1.38 0 1 0 2.61.87 10.77 10.77 0 0 0 .41-1.66 3.39 3.39 0 0 1-1.23 6.55zM58.87 53.45A1 1 0 0 1 58 51.9a13.72 13.72 0 0 0 0-15.2 1 1 0 1 1 1.65-1.11A15.71 15.71 0 0 1 59.7 53a1 1 0 0 1-.83.45z" />
      <path fill="#b3ac76" d="M54.4 50.92a1 1 0 0 1-.56-.17 1 1 0 0 1-.27-1.38 9 9 0 0 0 0-10.13 1 1 0 0 1 .82-1.55 1 1 0 0 1 .83.44 11 11 0 0 1 0 12.36 1 1 0 0 1-.82.43z" />
    </svg>
  )
}

export function soundcloud() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
      <path fill="#b3ac76" d="M38.25 50.53V32.34q0-.87.51-1A9.29 9.29 0 0 1 41 31a9.55 9.55 0 0 1 4.7 1.2 9.73 9.73 0 0 1 3.52 3.28 9.88 9.88 0 0 1 1.56 4.59 5.52 5.52 0 0 1 6.09 1.22 5.66 5.66 0 0 1 0 8A5.34 5.34 0 0 1 52.89 51H38.55a.39.39 0 0 1-.22-.18.5.5 0 0 1-.07-.23zM24.3 48.16a.82.82 0 0 1-.49-.17.66.66 0 0 1-.27-.6v-4.85a.7.7 0 0 1 .21-.53.66.66 0 0 1 .49-.21.68.68 0 0 1 .51.21.7.7 0 0 1 .21.53v4.85a1 1 0 0 1-.1.58.69.69 0 0 1-.42.18zM28 50.24a1 1 0 0 1-.57-.15s-.13-.09-.13-.32V37.16a.68.68 0 0 1 .21-.51.65.65 0 0 1 .49-.21.67.67 0 0 1 .51.21.68.68 0 0 1 .21.51v12.61c0 .23-.09.29-.13.32a1 1 0 0 1-.59.15zM31.84 51a1 1 0 0 1-.6-.15.33.33 0 0 1-.13-.32V38.68a.72.72 0 0 1 .22-.53.69.69 0 0 1 .52-.22.64.64 0 0 1 .49.21.73.73 0 0 1 .21.54v11.81c0 .23-.09.29-.13.32a1 1 0 0 1-.58.19zM35.62 51c-.72 0-.72-.24-.72-.42V33.33a1.08 1.08 0 0 1 .35-.89.79.79 0 0 1 .5-.23h.13c.31.07.46.44.46 1.1v17.23c0 .17 0 .46-.72.46z" />
    </svg>
  )
}

export function website() {
  return (
    <svg viewBox="0 0 82 82">
      <path d="z" />
      <path
        fill="#B3AC76" d="M41,29c-6.6,0-12,5.4-12,12s5.3,12,12,12c6.6,0,12-5.4,12-12S47.6,29,41,29z M49.3,36.2h-3.5
   c-0.4-1.5-0.9-2.9-1.7-4.3C46.3,32.7,48.1,34.2,49.3,36.2z M41,31.5c1,1.4,1.8,3,2.3,4.7h-4.6C39.2,34.5,40,32.9,41,31.5z
    M31.7,43.4c-0.2-0.8-0.3-1.6-0.3-2.4s0.1-1.6,0.3-2.4h4c-0.1,0.8-0.2,1.6-0.2,2.4s0.1,1.6,0.2,2.4H31.7z M32.7,45.8h3.5
   c0.4,1.5,0.9,2.9,1.7,4.3C35.7,49.3,33.9,47.8,32.7,45.8z M36.3,36.2h-3.5c1.1-2,3-3.5,5.2-4.3C37.2,33.3,36.6,34.7,36.3,36.2z
    M41,50.5c-1-1.4-1.8-3-2.3-4.7h4.6C42.8,47.5,42,49.1,41,50.5z M43.8,43.4h-5.6C38.1,42.6,38,41.8,38,41s0.1-1.6,0.2-2.4h5.6
   c0.1,0.8,0.2,1.6,0.2,2.4S43.9,42.6,43.8,43.4z M44.1,50c0.7-1.3,1.3-2.8,1.7-4.3h3.5C48.1,47.8,46.3,49.3,44.1,50z M46.2,43.4
   c0.1-0.8,0.2-1.6,0.2-2.4s-0.1-1.6-0.2-2.4h4c0.2,0.8,0.3,1.6,0.3,2.4s-0.1,1.6-0.3,2.4H46.2z"
      />
      <path
        fill="#B3AC76" d="M41,2c21.5,0,39,17.5,39,39S62.5,80,41,80S2,62.5,2,41S19.5,2,41,2 M41,0C18.4,0,0,18.4,0,41s18.4,41,41,41
     s41-18.4,41-41S63.6,0,41,0L41,0z"
      />
    </svg>
  )
}

export function youtube() {
  return (
    <svg className={styles.svg} viewBox="0 0 82 82">
      <path fill="#b3ac76" d="M55.75 31.67c-1.16-1.37-3.29-1.93-7.36-1.93H33.61c-4.17 0-6.33.6-7.49 2.06S25 35.32 25 38.23v5.54c0 5.63 1.33 8.49 8.61 8.49h14.78c3.53 0 5.49-.49 6.76-1.71S57 47.28 57 43.77v-5.54c0-3.07-.09-5.18-1.25-6.56zM46.3 42l-7.51 3.92a1.15 1.15 0 0 1-1.69-1v-7.83a1.15 1.15 0 0 1 1.69-1l7.5 3.91a1.15 1.15 0 0 1 0 2z" />
      <path fill="#b3ac76" d="M41 2A39 39 0 1 1 2 41 39 39 0 0 1 41 2m0-2a41 41 0 1 0 41 41A41 41 0 0 0 41 0z" />
    </svg>
  )
}

