import React from 'react'
import classNames from 'classnames'
import styles from './threeImagesQuote.css'
import FieldsetBN from '../fieldsetBN/FieldsetBN'

import { Img } from '../'
import PropTypes from 'prop-types'
const ThreeImagesQuote = (props) => {
  const {
    firstImage,
    secondImage,
    thirdImage,
    quote,
    quotePosition,
    mode
  } = props.component
  const Images = [firstImage]
  if (secondImage) {
    Images.push(secondImage)
  }
  if (thirdImage) {
    Images.push(thirdImage)
  }
  return (
    <div className={classNames(styles.threeImgQuoteWrapper)}>
      <div
        className={classNames(
          styles.imgContainer,
          mode === 'Image and quote 50/50'
            ? quotePosition === 'Quote on right'
              ? styles.quoteOnRight
              : styles.quoteOnLeft
            : ''
        )}
      >
        {Images.map((item, index) => (
          <div
            key={index}
            className={classNames(
              styles.contentWrapper,
              mode === 'Three images 33/33/33'
                ? styles.threeImageWrapper
                : styles.twoImageWrapper
            )}
          >
            <Img className={styles.image} src={item.url} alt={item.title} />
          </div>
        ))}
        {mode === 'Image and quote 50/50' ? (
          <div className={styles.quoteBox}></div>
        ) : (
          ''
        )}
      </div>
      <FieldsetBN
        isLogo={false}
        isGridTile={false}
        text={mode === 'Image and quote 50/50' ? quote : ''}
        quotePosition={quotePosition}
        imgQuote={true}
      />
    </div>
  )
}

ThreeImagesQuote.propTypes = {
  firstImage: PropTypes.object,
  secondImage: PropTypes.object,
  thirdImage: PropTypes.object,
  quote: PropTypes.string,
  quotePosition: PropTypes.string,
  mode: PropTypes.string
}

ThreeImagesQuote.defaultProps = {
  firstImage: { url: '', title: '' },
  secondImage: { url: '', title: '' },
  thirdImage: { url: '', title: '' },
  quote: '',
  quotePosition: 'Quote on right',
  mode: 'Two images 50/50'
}
export default ThreeImagesQuote
