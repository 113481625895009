import React from 'react'
import classNames from 'classnames'
import { Img, Markdown, OnVisible, VideoPlayer } from '../'
import styles from './halfwidthvideo.css'

const videoJsDefaultOptions = {
  aspectRatio: '16:9',
  techOrder: ['html5', 'youtube'],
  sources: [],
  youtube: {
    iv_load_policy: 3,
    ytControls: 1,
    modestbranding: 0,
    color: 'white',
    host: '//www.youtube.com',
    enablejsapi: 1,
    customVars: { wmode: 'transparent' },
    enablePrivacyEnhancedMode: true
  },
  nativeControlsForTouch: true
}

const HalfWidthVideo = ({ component, trackingVideo }) => {
  const showCustomControls = (data) => {
    if (data.youtubeId) return false
    return data.controls
  }
  const { background, video, mode, text } = component
  const videoData = video
  videoData.mode = 'noText'
  let videoSources = []
  if (videoData.videoMobile === null && videoData.youtubeIdMobile === null) {
    videoSources = [
      {
        type: videoData.youtubeId ? 'video/youtube' : 'video/mp4',
        src: videoData.youtubeId
          ? videoData.youtubeId.indexOf('www.youtube') > -1
            ? videoData.youtubeId
            : `//www.youtube.com/watch?v=${videoData.youtubeId}`
          : videoData.video.url
      }
    ]
  } else {
    videoSources = [
      {
        type: videoData.youtubeId ? 'video/youtube' : 'video/mp4',
        src: videoData.youtubeId
          ? videoData.youtubeId.indexOf('www.youtube') > -1
            ? videoData.youtubeId
            : `//www.youtube.com/watch?v=${videoData.youtubeId}`
          : videoData.video.url
      },
      {
        type: videoData.youtubeIdMobile ? 'video/youtube' : 'video/mp4',
        src: videoData.youtubeIdMobile
          ? videoData.youtubeIdMobile.indexOf('www.youtube') > -1
            ? videoData.youtubeIdMobile
            : `//www.youtube.com/watch?v=${videoData.youtubeIdMobile}`
          : videoData.videoMobile.url
      }
    ]
  }
  const videoJsOptions = {
    ...videoJsDefaultOptions,
    sources: videoSources,
    controls: showCustomControls(videoData),
    preload: 'auto',
    loop: false,
    autoplay: false,
    tint: videoData.tint,
    id: videoData.id
  }
  return (
    <OnVisible>
      {background && (
        <Img
          src={background.url}
          alt={background.title}
          className={styles.img}
          loadedClassName={styles.imgLoaded}
        />
      )}
      <div
        className={classNames(styles.container, {
          [styles.videoRight]: mode === 'videoRight'
        })}
      >
        <div className={styles.videoContainer}>
          {videoData.headline && (
            <h2 className={styles.videoHeadline}>{videoData.headline}</h2>
          )}
          <VideoPlayer
            videoId={videoData.id}
            playsinline={true}
            posterImage={
              (videoData && videoData.image && videoData.image.url) || ''
            }
            posterMobile={
              (videoData &&
                videoData.imageMobile &&
                videoData.imageMobile.url) ||
              ''
            }
            tracking={trackingVideo}
            options={JSON.stringify(videoJsOptions)}
          />
          {videoData.text && (
            <div className={styles.videoText}>
              <Markdown markdownHtml={videoData.text} />
            </div>
          )}
        </div>
        <div className={styles.text}>
          <Markdown markdownHtml={text} />
        </div>
      </div>
    </OnVisible>
  )
}

export default HalfWidthVideo
