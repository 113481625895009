/* global window, document */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import split from '../../lib/split'

import styles from './connectButton.css'


class ConnectButton extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showLabel: true,
      showOverlay: false,
      icon: '',
      label: ''
    }
  }
  componentWillMount() {
    this.updateMapping()
    this.showIcon()
  }
  componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  onClick(state, e) {
    if (state) {
      document.documentElement.classList.add(styles.overlayOpen)
    } else {
      document.documentElement.classList.remove(styles.overlayOpen)
    }
    this.setState({
      showOverlay: state
    })
    e.preventDefault()
  }

  showIcon = () => {
    clearTimeout(this.timeout)
    this.setState({
      showLabel: true
    })
    // TODO: this currently prints a warning to the console:
    //
    //    Warning: setState(...): Can only update a mounted or mounting component.
    //    This usually means you called setState() on an unmounted component.
    //    This is a no-op. Please check the code for the ConnectButton component.
    //
    // The timeout needs to be cleared when unmounting this component
    this.timeout = setTimeout(() => {
      this.setState({
        showLabel: false
      })
    }, 6000)
  };

  toggleIcon(state) {
    clearTimeout(this.timeout)
    this.setState({
      showLabel: state
    })
  }
  updateMapping() {
    this.setState({
      showLabel: true,
    }, this.showIcon)
  }
  render() {
    const connectTray = this.props.connectTray
    const { showLabel } = this.state
    const css = {
      visible: styles.visible,
      showIcon: styles.showIcon
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.contextCta, {
      showIcon: !showLabel
    })

    const icon = connectTray.button && connectTray.button.url
    const title = connectTray.button && connectTray.button.title
    return (
      <button
        className={classes}
        key={title}
        role="button"
        onClick={this.props.toggle}
        onMouseEnter={this.toggleIcon.bind(this, true)}
        onMouseLeave={this.toggleIcon.bind(this, false)}
        aria-label={title}
      >
        <div className={styles.label}>{split(title)}</div>
        <div
          className={styles.icon}
          style={{
            backgroundImage: `url(${icon})`
          }}
        />
      </button>
    )
  }
}
ConnectButton.propTypes = {
  connectTray: PropTypes.object,
  toggle: PropTypes.func
}

ConnectButton.defaultProps = {
  connectTray: {},
  toggle: () => {}
}
export default ConnectButton
