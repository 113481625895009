import React from 'react'
import { Link } from 'react-router-dom'
import styles from './recommendations.css'

const RecommendationLink = ({
  onClick,
  to,
  internal,
  children
}) => {
  return (
    <>
      {internal ? (
        <Link
          onClick={onClick}
          to={to}
          className={styles.recommendationLink}
        >
          {children}
        </Link>
      ) : (
        <a
          className={styles.recommendationLink}
          href={to}
          target="_blank"
        >
          {children}
        </a>
      )}
    </>
  )
}

export default RecommendationLink
