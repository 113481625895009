/* global window */
import {AgeGate, Footer, Navigation} from 'containers'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import siteKeys from 'utils/i18n'
import cookie from 'react-cookie'
import {
  Connect,
  CountrySelector,
  CountrySelectorButton,
  CookieBanner,
  GlobalPopup
} from '../'
import {isCookieVerified, saveAgeGateCookies} from '../../lib/agegate'
import {
  setCookiesPermissionCookie,
  loadCookiesPermissionCookie
} from '../../lib/cookiebanner'
import styles from './page.css'
import classNames from "classnames";
import {skipToMainContent} from "../../utils/i18n";

const checkSlug = (node) => {
  let slug
  switch (node) {
    case 'drinksList':
      slug = 'drinks'
      break
    case 'jgerTalesLandingPage':
      slug = 'jaegermeister-tales'
      break
    case 'musicOverviewPage':
      slug = 'music'
      break
    case 'eventOverviewPage':
      slug = 'default'
      break
    default:
      slug = 'GenericPage'
  }
  return slug
}

class Page extends Component {
  constructor(props) {
    super(props)
    this.connectLookUpTable = ''
    this.connectObj = {}
    this.toggleGlobalPopup = this.toggleGlobalPopup.bind(this)
    this.state = {
      isVerified: false,
      openCountrySelector: false,
      cookiesPermission: false,
      isGlobalPopupOpen: true,
      isFirstGlobalPopup: false,
      togglePopup: false,
      toggleConnect: false,
      openConnect: false,
      connectData: this.createConnectData() || {}
    }
  }

  componentWillMount() {
    // TODO: why can't this be done in the constructor? Please add a comment if there's a reason.
    // Load cookie on page mount to check for user age
    this.setState({
      isVerified: this.isVerified(),
      cookiesPermission: loadCookiesPermissionCookie()
    })
  }

  componentDidMount() {
    // TODO: don't do this when live reloading in dev
    if (this.props.content.globalPopup) {
      this.setState(
        {
          isFirstGlobalPopup: cookie.load('popupShown')
        },
        () => {
          if (!this.state.isFirstGlobalPopup) {
            setTimeout(() => {
              this.setState({
                togglePopup: true
              })
            }, 10000)
          }
        }
      )
    }
  }

  createConnectData() {
    const content =
      this.props.content[this.props.contentNode] ||
      this.props.content.ProductPage
    const slug = this.props.slug || checkSlug(this.props.contentNode)
    if (content && content[slug] && content[slug].connect) {
      return content && content[slug] && content[slug].connect
    }
    return {}
  }

  createConnectObj(obj = {}) {
    this.connectObj = obj
    return this.connectObj.connect
  }

  isCookieVerified() {
    return isCookieVerified(this.props.ageGateConfig.legalAge)
  }

  isVerified() {
    // we allow the CDN to override the age gate verified status without writing a cookie
    // this is fairly complex - e.g. crawlers get the same cache as verified users
    // (so that crawlers don't get a perceived slow site)
    return this.props.ageGateConfig.preverified || this.isCookieVerified()
  }

  toggleGlobalPopup = () => {
    const {isGlobalPopupOpen} = this.state
    this.setState({isGlobalPopupOpen: !isGlobalPopupOpen})
    if (isGlobalPopupOpen) {
      const date = new Date()
      const expireDate = new Date(date.getTime() + 10 * 24 * 60 * 60 * 1000)
      cookie.save('popupShown', true, {
        path: '/',
        expires: expireDate
      })
    }
  }
  toggleConnect = () => {
    const trackingObj = {
      page: this.props.contentNode,
      toggle: !this.state.openConnect
    }
    this.props.onConnect(trackingObj)
    this.setState({openConnect: !this.state.openConnect})
  }
  toggleCountrySelector = () => {
    this.setState({openCountrySelector: !this.state.openCountrySelector})
  }
  unlockAgeGate = (userAge) => {
    saveAgeGateCookies(userAge)
    this.props.onUnlock({userAge})
    this.setState({isVerified: true})
  }
  hideCookieBanner = () => {
    setCookiesPermissionCookie()
    this.setState({cookiesPermission: true})
  }

  render() {
    const countrySelectorButton = (
      <CountrySelectorButton
        isOpen={this.state.openCountrySelector}
        currentLocale={this.props.locale}
        countries={this.props.content.country}
        locales={this.props.content.locale}
        toggle={this.toggleCountrySelector}
      />
    )

    const globalText =
      (this.props.content &&
        this.props.content.globalText &&
        this.props.content.globalText.default) ||
      undefined
    const setUpTextKeys = (obj) => {
      const keys = siteKeys(obj)
      return keys
    }
    if (globalText && globalText.siteTextKeys) {
      setUpTextKeys(globalText.siteTextKeys)
    }
    return (
      <div className={styles.pageContainer}>
        <a className={styles.skipToContent} href="#mainContent">{skipToMainContent()}</a>
        {this.props.content.cookieBanner &&
          this.props.content.cookieBanner.content && (
            <CookieBanner
              content={this.props.content.cookieBanner.content}
              visible={!this.state.cookiesPermission}
              hideCookieBanner={this.hideCookieBanner}
            />
          )}
        {this.props.disableAgeGate ||
        this.state.isVerified ||
        !this.props.content.ageGate ||
        !this.props.content.ageGate.default ? (
          <div className={styles.pageContainer}>
            {this.props.content.navigation && (
              <Navigation
                navItems={
                  this.props.content.navigation.main &&
                  this.props.content.navigation.main.items
                }
                countrySelectorButton={countrySelectorButton}
                connect={this.state.connectData || {}}
                locale={this.props.locale}
                mobileMenu={this.props.content.navigation.footerSecondRow}
                toggle={this.toggleConnect}
                socialLinks={this.props.content.socialLinks}
                countrySelectorOpen={this.state.openCountrySelector}
                disableNavigation={this.props.disableNavigation}
                disableNavigationAndConnectTray={this.props.disableNavigationAndConnectTray}
              />
            )}
            {this.props.content.globalPopup &&
              !this.state.isFirstGlobalPopup &&
              this.state.togglePopup &&
              !this.props.disableGlobalPopUp && (
                <GlobalPopup
                  key="globalPopup"
                  popupFormcontent={this.props.content.globalPopup}
                  endPoint={this.props.newsLetterEndpoint}
                  isOpen={this.state.isGlobalPopupOpen}
                  onClose={this.toggleGlobalPopup}
                />
              )}
            {this.props.children}
            {this.props.content.navigation && (
              <Footer content={this.props.content} globalText={globalText}/>
            )}
            {this.state.connectData && this.state.connectData.title && (
              <Connect
                key="connectOverlay"
                connect={this.state.connectData}
                isOpen={this.state.openConnect}
                close={this.toggleConnect}
                tracking={this.props.onConnectCta}
              />
            )}
          </div>
        ) : (
          <AgeGate
            unlock={this.unlockAgeGate}
            config={this.props.ageGateConfig}
            content={
              this.props.content &&
              this.props.content.ageGate &&
              this.props.content.ageGate.default
            }
            navigation={
              this.props.content.navigation &&
              this.props.content.navigation.ageGate
            }
            countrySelectorButton={countrySelectorButton}
            globalText={globalText}
          />
        )}
        {this.props.content.country && (
          <CountrySelector
            key="countrySelector"
            countries={this.props.content.country}
            locales={this.props.content.locale}
            currentLocale={this.props.locale}
            isOpen={this.state.openCountrySelector}
            close={this.toggleCountrySelector}
            globalText={globalText}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const newsLetterEndpoint = state.newsLetterFormEndpoint
  return {newsLetterEndpoint}
}

Page.propTypes = {
  disableAgeGate: PropTypes.bool,
  disableNavigation: PropTypes.bool,
  disableNavigationAndConnectTray: PropTypes.bool,
  disableGlobalPopUp: PropTypes.bool,
  content: PropTypes.object,
  ageGateConfig: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onUnlock: PropTypes.func.isRequired,
  slug: PropTypes.string,
  contentNode: PropTypes.string,
  onConnect: PropTypes.func,
  onConnectCta: PropTypes.func
}
Page.defaultProps = {
  disableAgeGate: false,
  disableNavigation: false,
  disableNavigationAndConnectTray: false,
  disableGlobalPopUp: false,
  slug: '',
  content: {},
  contentNode: '',
  onConnect: () => {
  },
  onConnectCta: () => {
  }
}
// export default withRouter(Page)
export default withRouter(connect(mapStateToProps, null)(Page))
