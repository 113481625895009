import { SET_QUERY } from '../actions/queries'

export default function(state = null, action) {
  switch (action.type) {
    case SET_QUERY:
      return {
        ...state,
        query: action.value
      }
    default:
      return state
  }
}
