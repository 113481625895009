import { connect } from 'react-redux'
import ArtistBiogrpahy from 'components/artistbiography/artistbiography'
import { trackEvent } from 'actions/analytics'

function mapDispatchToProps(dispatch) {
  return {
    trackArtistSocialClicks: (socialNetworkName) => {
      dispatch(trackEvent({
        event: 'Artist Social Links',
        eventType: 'Follow Artist',
        eventDetail: socialNetworkName
      }))
    }
  }
}

export default connect(null, mapDispatchToProps)(ArtistBiogrpahy)
