import React from 'react'

import styles from './horizontalContainer.css'
import { Markdown } from 'components'

const HorizontalContainer = ({ data }) => {
  return (
    <div className={styles.parent}>
      <div className={styles.childContainer}>
        {data.fields.map(({ text }, idx) => (
          <Markdown markdownHtml={text} className={styles.child} key={idx} />
        ))}
      </div>
    </div>
  )
}

export default HorizontalContainer
