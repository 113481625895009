
let siteKeysObj = {}
export default function siteKeys(obj) {
  siteKeysObj = obj
  return siteKeysObj
}
const returnKey = (obj, text) => {
  const filterText = el => (el.key === text ? el.value : '')
  return obj && Object.keys(obj).map(key => obj[key]).filter(filterText).length > 0 && Object.keys(obj).map(key => obj[key]).filter(filterText).reduce(filterText).value
}
export const send = () => returnKey(siteKeysObj, 'send') || 'Send'
export const charactersCounter = () =>
  returnKey(siteKeysObj, 'charactersCounter') || 'Characters number'
export const iceCold = () => returnKey(siteKeysObj, 'iceCold') || 'Ice Cold'
export const precise = () => returnKey(siteKeysObj, 'precise') || 'To be precise'
export const prepare = () => returnKey(siteKeysObj, 'prepare') || 'How to prepare'
export const need = () => returnKey(siteKeysObj, 'need') || 'What you\'ll need'
export const categories = () => returnKey(siteKeysObj, 'categories') || 'categories'
export const prost = () => returnKey(siteKeysObj, 'prost') || 'Prost'
export const downloadRecipe = () => returnKey(siteKeysObj, 'downloadRecipe') || 'Download this recipesss'
export const downloadAllRecipe = () => returnKey(siteKeysObj, 'downloadAllRecipe') || 'Download all recipes'
export const downloadPdf = () => returnKey(siteKeysObj, 'downloadPdf') || 'Download marketing code pdf'
export const seeDrinks = () => returnKey(siteKeysObj, 'seeDrinks') || 'See all drinks'
export const formFieldsRequired = () => returnKey(siteKeysObj, 'formFieldsRequired') || '*All fields are require'
export const formErrorMessage = () => returnKey(siteKeysObj, 'formErrorMessage') || 'Sorry there is problem submitting the form'
export const noEvents = () => returnKey(siteKeysObj, 'noEvents') || 'No Events currently scheduled'
export const subscribeNo = () => returnKey(siteKeysObj, 'subscribeNo') || 'No'
export const subscribeYes = () => returnKey(siteKeysObj, 'subscribeYes') || 'Yes'
export const salutationOne = () => returnKey(siteKeysObj, 'salutationOne') || ''
export const salutationTwo = () => returnKey(siteKeysObj, 'salutationTwo') || ''
export const salutationThree = () => returnKey(siteKeysObj, 'salutationThree') || ''
export const mailChimpListId = () => returnKey(siteKeysObj, 'mailChimpListId') || '56c4d73385'
export const countrySelector = () => returnKey(siteKeysObj, 'countrySelector') || 'Country Selector'
export const date = () => returnKey(siteKeysObj, 'date') || 'date'
export const headerLogoAltText = () => returnKey(siteKeysObj, 'headerLogoAltText') || 'Jägermeister'
export const skipToMainContent = () => returnKey(siteKeysObj, 'skipToMainContent') || 'Skip to main content'

