// Standard tile used by the grid

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './ribbontile.css'
import { Anchor, Image, Markdown } from '../'

import { ITEMS as GRID_ITEMS } from '../grid/Grid'

class IceColdTile extends Component {
  static defaultProps = {
    title: '',
    subtitle: '',
    caption: '',
    linkTo: '',
    gridItemConfig: null,
    tracking: () => {},
    image: {}
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    caption: PropTypes.string,
    image: PropTypes.object.isRequired,
    linkTo: PropTypes.string,
    tracking: PropTypes.func,
    gridItemConfig: PropTypes.oneOf(
      Object.keys(GRID_ITEMS).map((key) => GRID_ITEMS[key])
    )
  }

  renderTileContents() {
    return (
      <div>
        <h3 className={styles.title}>{this.props.title}</h3>
        <h4 className={styles.subtitle}>
          <Markdown markdownHtml={this.props.subtitle} />
        </h4>
        <p className={styles.caption}>{this.props.caption}</p>
        <div className={styles.image}>
          <Image
            mobileSizes={this.props.gridItemConfig === '25' ? '25vw' : '50vw'}
            sizes={this.props.gridItemConfig / 2 + 'vw'}
            src={this.props.image.url}
            alt={this.props.title}
          />
        </div>
      </div>
    )
  }
  render() {
    let tileStyle

    if (this.props.gridItemConfig) {
      switch (this.props.gridItemConfig) {
        case GRID_ITEMS.SMALLEST:
          tileStyle = styles.tileSmallest
          break
        case GRID_ITEMS.SMALL:
          tileStyle = styles.tileSmall
          break
        case GRID_ITEMS.MEDIUM:
          tileStyle = styles.tileMedium
          break
        case GRID_ITEMS.LARGE:
          tileStyle = styles.tileLarge
          break
        case GRID_ITEMS.FULL:
          tileStyle = styles.tileFull
          break
        default:
          tileStyle = styles.tile
      }
    }
    if (this.props.linkTo) {
      return (
        <Anchor
          to={this.props.linkTo}
          className={tileStyle}
          onClick={this.props.tracking}
        >
          {this.renderTileContents()}
        </Anchor>
      )
    }
    return <div className={tileStyle}>{this.renderTileContents()}</div>
  }
}

export default IceColdTile
