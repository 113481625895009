import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Page as PageContainer } from 'containers'

const ComposedPage = (
  ComposedComponent,
  options = {
    disableAgeGate: false,
    disableGlobalPopUp: false,
    slug: undefined,
    routes: {},
    tracking: {}
  }
) =>
  class extends Component {
    static propTypes = {
      match: PropTypes.object
    }

    static defaultProps = {
      match: {}
    }

    render() {
      const slug =
        options.slug ||
        (this.props.match.params && this.props.match.params.slug)
      return (
        <PageContainer
          disableAgeGate={options.disableAgeGate}
          disableGlobalPopUp={options.disableGlobalPopUp}
          disableNavigation={options.disableNavigation}
          disableNavigationAndConnectTray={options.disableNavigationAndConnectTray}
          contentNode={options.contentNode}
          routes={options.routes}
          slug={slug}
        >
          <ComposedComponent slug={slug} tracking={options.tracking} />
        </PageContainer>
      )
    }
  }

export default ComposedPage
