import PropTypes from 'prop-types'
import React from 'react'

import styles from './steps.css'

import { prepare } from 'utils/i18n'
import { Image } from 'components'

const Steps = (props) => {
  const showImages = props.steps
    .every(step => step.image && step.image.url)
  const siteText = {
    prepare: prepare()
  }
  return (
    <div className={styles.steps}>
      <h2 className={styles.title}>{siteText.prepare}</h2>

      {!showImages &&
      <div className={styles.numberedStepsText}>
        {props.steps.map(step => (
          <div key={step.id} className={styles.step}>
            {step.step}
          </div>
        ))}
      </div>
      }
      {showImages && <div className={styles.numberedStepsImage}>
        {props.steps.map(step => (
          <div className={styles.stepImageGroup}>
            <div className={styles.step}>
              {step.step}
            </div>
            <div className={styles.image}>
              {step.image && <Image src={step.image.url} alt={step.image.title} />}
            </div>
          </div>
        ))}
      </div>}
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.array.isRequired
}

export default Steps
