import React from 'react'

import { Anchor, Markdown, IconList, Image } from '../'

import styles from './artistfeatured.css'

export default ({
  data: {
    image,
    title,
    description,
    link,
    icons
  } } = {}) => (<Anchor key={link.id} to={link && (link.url || link.route)} className={styles.container}>
    {image && <Image
      key={image.id}
      className={styles.image}
      src={image.url}
      alt={image.title || image.description || title}
    />}
    <div className={styles.textContainer}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.text}>
        <Markdown
          markdownHtml={description}
        />
      </div>
      <div className={styles.icons}>
        <IconList icons={icons} />
      </div>
    </div>
  </Anchor>)
