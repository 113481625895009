import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'
import classNames from 'classnames'

import ScrollLock from './ScrollLock'

import styles from './overlay.css'

const transitionClasses = {
  top: {
    enter: styles.enterTop,
    enterActive: styles.enterActive,
    leave: styles.leave,
    leaveActive: styles.leaveActiveTop
  },
  left: {
    enter: styles.enterLeft,
    enterActive: styles.enterActive,
    leave: styles.leave,
    leaveActive: styles.leaveActiveLeft
  },
  right: {
    enter: styles.enterRight,
    enterActive: styles.enterActive,
    leave: styles.leave,
    leaveActive: styles.leaveActiveRight
  },
  bottom: {
    enter: styles.enterBottom,
    enterActive: styles.enterActive,
    leave: styles.leave,
    leaveActive: styles.leaveActiveBottom
  }
}
const layoutClasses = {
  top: styles.overlayTop,
  right: styles.overlayRight,
  left: styles.overlayLeft,
  bottom: styles.overlayBottom
}

// TODO: Handle two overlays opened at the same time. Needs global state! Redux?
const Overlay = ({
  isOpen,
  children,
  close,
  overlayClass,
  direction,
  desktopCloseBtn,
  overlayId
}) => {
  const overlayClasses = classNames(styles.overlay, layoutClasses[direction], {
    [styles.overlayOnTopOfNav]: desktopCloseBtn
  })
  const contentClasses = classNames(styles.content, overlayClass)
  const closeButtonClasses = classNames(styles.closeButton, {
    [styles.closeButtonDesktop]: desktopCloseBtn
  })
  function handleKeyDownOverlay(e) {
    if (e.key === 'Escape') {
      close()
    }
  }
  return (
    <CSSTransitionGroup
      transitionName={transitionClasses[direction]}
      transitionEnterTimeout={400}
      transitionLeaveTimeout={300}
    >
      {isOpen && (
        <ScrollLock>
          <div
            key="overlay"
            className={overlayClasses}
            id={overlayId}
            onKeyDown={(e) => handleKeyDownOverlay(e)}
          >
            <div className={contentClasses}>
              <button
                role="button"
                className={closeButtonClasses}
                onClick={close}
              />
              {children}
            </div>
          </div>
        </ScrollLock>
      )}
    </CSSTransitionGroup>
  )
}

Overlay.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  overlayClass: PropTypes.string,
  overlayId: PropTypes.string,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  desktopCloseBtn: PropTypes.bool
}

Overlay.defaultProps = {
  overlayClass: '',
  direction: 'top',
  desktopCloseBtn: false,
  overlayId: false
}

export default Overlay
