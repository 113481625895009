import { connect } from 'react-redux'
import { trackEvent } from 'actions/analytics'

import ProductMystery from 'components/productMystery/ProductMystery'

function mapDispatchToProps(dispatch) {
  return {
    trackMystery: (e) => {
      dispatch(
        trackEvent({
          event: 'Learn More Text CTA',
          contentType: 'Product',
          contentId:
            e.target.parentNode.parentNode.parentNode.getElementsByTagName(
              'button'
            )[0].innerText || 'undefinded'
        })
      )
    }
  }
}

export default connect(null, mapDispatchToProps)(ProductMystery)
