import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AccordionItem, FullBleedImage, MetaData } from 'components'
import {
  FormContainer,
  IntegratedFormContainer,
  SalesforceFormContainer,
  Recommendations
} from 'containers'

import styles from './contact.css'

class Contact extends Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    endPoint: PropTypes.string.isRequired

  }
  constructor(...rest) {
    super(...rest)
    this.state = {
      forms: false,
      expandedAccordianName: null,
      previousAccordianName: null
    }
  }

  handleClick = (value, index) => {
    const previousSelected = this.state.expandedAccordianName
    if (previousSelected !== value) {
      this.setState({ expandedAccordianName: value })
      this.renderForm(index)
    } else {
      this.setState({ expandedAccordianName: null })
    }
  }

  renderForm = (index) => {
    const i = index || 0
    const form = this.props.content.forms[i]
    if (form.contentType === 'integratedForms') {
      return (
        <IntegratedFormContainer
          data={form}
          formClass={styles.contactForm}
          complaintFormEndpoint={this.props.complaintFormEndpoint}
        />
      )
    } else if (form.contentType === 'salesForceForm') {
      return (
        <SalesforceFormContainer
          data={form}
          formClass={styles.contactForm}
          salesforceSyncEndpoint={salesforceSyncEndpoint}
        />
      )
    }
    return (
      <FormContainer
        data={form}
        formClass={styles.contactForm}
        endPoint={this.props.endPoint}
      />
    )
  }

  render() {
    const contact = this.props.content
    const accExp = (title) => title === this.state.expandedAccordianName
    return (
      <main className={styles.contact} role="main" id="mainContent">
        <MetaData metadata={contact.metadata} />
        <section className={styles.contactHero}>
          {contact.hero && (
            <FullBleedImage
              data={contact.hero}
              headlineClass={styles.headline}
            />
          )}
        </section>
        {contact.forms && (
          <section className={styles.contactFormContainer}>
            {contact.forms.map((d, idx) => (
              <AccordionItem
                title={d.title}
                index={idx}
                renderContent={this.renderForm}
                key={d.id}
                expanded={accExp(d.title)}
                onClick={() => {
                  this.handleClick(d.title, idx)
                }}
              />
            ))}
          </section>
        )}
        {contact.recommendations && (
          <Recommendations recommendations={contact.recommendations} />
        )}
      </main>
    )
  }
}

export default Contact
