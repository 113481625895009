import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './socialMedia.css'

const SocialMedia = (props) => {
  const socialLinks = props.social
  const socialIcons = props.icons
  const trackClick = props.trackClick
  return (
    <div className={styles.social}>
      {socialLinks.facebook && (
        <a
          className={styles.socialLink}
          href={socialLinks.facebook.url}
          onClick={() => trackClick('facebook')}
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook link
          <span className={styles.socialIcon}>{socialIcons.facebook()}</span>
        </a>
      )}
      {socialLinks.twitter && (
        <a
          className={styles.socialLink}
          href={socialLinks.twitter.url}
          onClick={() => trackClick('twitter')}
          target="_blank"
          rel="noopener noreferrer"
        >
          XCorp link
          <span className={styles.socialIcon}>{socialIcons.twitter()}</span>
        </a>
      )}
      {socialLinks.instagram && (
        <a
          className={styles.socialLink}
          href={socialLinks.instagram.url}
          onClick={() => trackClick('instagram')}
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram link
          <span className={styles.socialIcon}>{socialIcons.instagram()}</span>
        </a>
      )}
      {socialLinks.youtube && (
        <a
          className={styles.socialLink}
          href={socialLinks.youtube.url}
          onClick={() => trackClick('youtube')}
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube link
          <span className={styles.socialIcon}>{socialIcons.youtube()}</span>
        </a>
      )}
      {socialLinks.linkedIn && (
        <a
          className={styles.socialLink}
          href={socialLinks.linkedIn.url}
          onClick={() => trackClick('linkedIn')}
          target="_blank"
          rel="noopener noreferrer"
        >
          linkedIn link
          <span className={styles.socialIcon}>{socialIcons.linkedIn()}</span>
        </a>
      )}
      {socialLinks.spotify && (
        <a
          className={styles.socialLink}
          href={socialLinks.spotify.url}
          onClick={() => trackClick('spotify')}
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify link
          <span className={styles.socialIcon}>{socialIcons.spotify()}</span>
        </a>
      )}
    </div>
  )
}

SocialMedia.propTypes = {
  social: PropTypes.object.isRequired,
  icons: PropTypes.object.isRequired,
  trackClick: PropTypes.func
}

SocialMedia.defaultProps = {
  trackClick: () => {}
}

export default SocialMedia
