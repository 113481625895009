import basePage from 'hoc/basepage/basepage'
import { StagOrNoStag } from 'components/pages'

export default basePage(StagOrNoStag, {
  contentNodeName: 'stagOrNoStagPage',
  defaultSlug: 'stag-or-not-stag',
  trackContentCategory: 'Content Page',
  trackDefaultPageName: 'Stag or not Stag',
  trackAlwaysUseDefaultPageName: true
})
