import React from 'react'
// Baed on previous example in the Grid.js for using indexs with keys
// https://github.com/yannickcr/eslint-plugin-react/issues/1123
// http://stackoverflow.com/questions/42983067/breaking-children-in-to-chunks-and-react-no-array-index-key
function split(text, splitClass) {
  return String(text)
    .split('')
    .map((letter, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <span data-letter={letter} key={idx + letter} className={splitClass}>
        {letter}
      </span>
    ))
}

export default split
