import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"

import {
  HalfWidthPng,
  FullBleedImage,
  FullWidthPng,
  MetaData,
  OnVisible,
  VideoFeature,
  Generic
} from 'components'
import { Recommendations } from 'containers'
import styles from './coldbrew.css'

class ColdBrewPage extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false,
      started: false
    }
  }
  componentDidMount() {
    this.setState({
      showText: true
    })
  }

  onVisible = (isVisible) => {
    if (isVisible && !this.state.started) {
      this.setState({
        started: true
      })
    }
    if (!isVisible) {
      this.setState({
        started: false
      })
    }
  }

  componentRender = (
    components,
    number,
    locale,
    useNewAssets,
    thermometerGraphics
  ) => {
    return (
      (components.contentType === 'fullBleedImageComponent' && (
        <FullBleedImage
          key={number}
          data={components}
          textInnerClass={number < 1 ? styles.titleText : ''}
          reverseMobile={true}
        />
      )) ||
      (components.contentType === 'fullWidthPngComponent' && (
        <FullWidthPng data={components} />
      )) ||
      (components.contentType === 'halfWidthPngComponent' && (
        <HalfWidthPng
          data={components}
          key={number}
          headlineClass={styles.coldBrewHeadline}
          imageClass={styles.coldBrewImage}
          contentWidthClass={styles.textInner}
          theme="coldBrew"
          useColdBrewNewAssets={useNewAssets}
          reverseMobile={true}
        />
      )) ||
      (components.contentType === 'videoComponent' && (
        <div key={components.id}>
          <VideoFeature
            data={components}
            contentType={components.contentType}
            theme="coldBrew"
          />{' '}
        </div>
      )) || (
        <Generic
          components={components}
          contentType={components.contentType}
          config={{ history: this.props.history }}
          theme="coldBrew"
          endPoint={this.props.endPoint}
        />
      )
    )
  }

  render() {
    const { content, locale } = this.props
    const components = content.components
    const secondaryComponents = content.secondaryComponents
    const hero = content.hero
    const useNewAssets = content.useNewAssets || false
    const thermometerGraphics = content.thermometerGraphics || false
    return (
      <main
        className={[
          styles.coldBrew,
          useNewAssets ? styles.coldBrew2022 : ''
        ].join(' ')}
        role="main"
        id="mainContent"
      >
        <MetaData metadata={content.metadata} />{' '}
        {hero && (
          <section className={styles.header}>
            {hero && hero.contentType === 'fullBleedImageComponent' && (
              <FullBleedImage
                data={hero}
                theme="coldBrew"
                textInnerClass={styles.heroText}
              />
            )}{' '}
          </section>
        )}
        {components &&
          components.map((d, idx) => (
            <section key={d.id} className={styles.divider}>
              {' '}
              {this.componentRender(d, idx, locale, useNewAssets)}{' '}
            </section>
          ))}
        <div
          className={[
            styles.coldbrewmeterBackground,
            thermometerGraphics === 'GreenGlasses-RedThermometer' ||
            (!useNewAssets && !thermometerGraphics)
              ? ''
              : styles.coldbrewmeterBackground2022
          ].join(' ')}
        >
          <OnVisible
            onChange={this.onVisible}
            className={[
              styles.coldbrewmeter,
              thermometerGraphics === 'GreenGlasses-RedThermometer'
                ? ''
                : thermometerGraphics === 'WhiteGlasses-RedThermometer'
                ? styles.coldbrewmeter2022
                : thermometerGraphics === 'WhiteGlasses-BlueThermometer'
                ? styles.coldbrewmeterBlue
                : useNewAssets
                ? styles.coldbrewmeter2022
                : ''
            ].join(' ')}
            visibleClassName={styles.visible}
            percent={30}
          >
            <div
              className={
                thermometerGraphics === 'WhiteGlasses-BlueThermometer'
                  ? styles.coldbrewmeterBarBlue
                  : styles.coldbrewmeterBar
              }
            />
          </OnVisible>
          <OnVisible
            onChange={this.onVisible}
            className={styles.coldbrewProst}
            visibleClassName={styles.visible}
            percent={30}
          />
        </div>
        {secondaryComponents &&
          secondaryComponents.map((d, idx) => (
            <section key={d.id} className={styles.divider}>
              {this.componentRender(d, idx)}
            </section>
          ))}
        {content.recommendations && (
          <Recommendations recommendations={content.recommendations} />
        )}{' '}
      </main>
    )
  }
}

ColdBrewPage.defaultProps = {
  content: {}
}
ColdBrewPage.propTypes = {
  content: PropTypes.object
}

export default ColdBrewPage
