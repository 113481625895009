import React, { Component } from 'react'
import { AccordionItem, DownloadTracks, DownloadableDocument } from 'components'
import styles from './accordion.css'

class Accordion extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      expandedAccordionName: null,
      previousAccordionName: null
    }
  }

  handleClick = (value, index, content, type) => {
    const previousSelected = this.state.expandedAccordionName
    if (previousSelected !== value) {
      this.setState({ expandedAccordionName: value })
      this.renderContent(index, content, type)
    } else {
      this.setState({ expandedAccordionName: null })
    }
  }
  // accordionItem is passed this function and passes these props:
  // props.index, props.childData, props.childType
  renderContent = (index, items, type) => {
    switch (type) {
      case 'downloadItems':
        return <DownloadTracks data={items} forAccordion={true} />
      case 'downloadableDocuments':
        const documents = []
        items.map((item) => {
          documents.push(
            <DownloadableDocument
              name={item.name}
              description={item.description}
              file={item.file}
              image={item.image}
              key={item.id}
            ></DownloadableDocument>
          )
        })
        return documents
      default:
        return null
    }
  }

  render() {
    const items = this.props?.data?.items
    const accExp = (title) => title === this.state.expandedAccordionName
    return (
      // Map out the accordion items within the accordion
      items &&
      items?.length > 0 &&
      items.map((item, index) => {
        const content = item?.downloadableDocuments || item?.content
        const contentType =
          (item?.downloadableDocuments && 'downloadableDocuments') || 'links'
        return (
          <>
            <AccordionItem
              title={item?.title}
              description={item?.description}
              index={index}
              childData={content}
              childType={contentType}
              key={item.id}
              expanded={accExp(item?.title)}
              mode={'embeddedInComponent'}
              renderContent={this.renderContent}
              onClick={() => {
                this.handleClick(item?.title, index, content, contentType)
              }}
            />
          </>
        )
      })
    )
  }
}
export default Accordion
