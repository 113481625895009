import { connect } from 'react-redux'

import VideoCarousel from 'components/videocarousel/videocarousel'
function mapDispatchToProps(dispatch) {
  // add tracking here
  return {}
}

function mapStateToProps(state) {
  return {
    queries: state.queries
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoCarousel)
