import PropTypes from 'prop-types'
import React from 'react'

import { OnVisible, Zeit } from '../'

import {
  prost,
  iceCold
} from 'utils/i18n'
import styles from './drinkprost.css'

const DrinkProst = (props) => {
  const siteText = {
    prost: prost(),
    iceCold: iceCold(),
  }
  return (
    <div className={styles.gradientWrapper}>
      <OnVisible
        percent={35}
        className={styles.drinkProst}
        visibleClassName={styles.visible}
      >
        <h2 className={styles.title}>{siteText.prost}
        <div className={styles.iceContainer}>
          <h3 className={styles.bestServed}>{siteText.iceCold}</h3>
        </div>
        <div className={styles.zeit}>
          <Zeit />
        </div>
        </h2>
      </OnVisible>
    </div>
  )
}

export default DrinkProst
