import { Component } from 'react'
import PropTypes from 'prop-types'

class ScrollLock extends Component {
  componentDidMount() {
    document.body.classList.add('block')
  }
  componentWillUnmount() {
    document.body.classList.remove('block')
  }
  render() {
    return (this.props.children || null)
  }
}

ScrollLock.propTypes = {
  children: PropTypes.any
}
ScrollLock.defaultProps = {
  children: ''
}

export default ScrollLock
