import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { trackPageView } from 'actions/analytics'
import { setQuery } from 'actions/queries'
import ScrollToButton from 'components/scrolltobutton/scrollToButton'

function mapDispatchToProps(dispatch, ownProps) {
  const { trackingEvent, trackingEventType, trackingEventDetail } = ownProps
  return {
    track: () => {
      if (trackingEvent) {
        dispatch(
          trackPageView({
            event: 'Scroll Button',
            eventType: trackingEvent.eventType,
            eventDetail: trackingEvent.eventDetail
          })
        )
      } else {
        dispatch(
          trackPageView({
            event: 'Scroll Button',
            eventType: trackingEventType,
            eventDetail: trackingEventDetail
          })
        )
      }
    },
    queryControls: (queries) => {
      dispatch(setQuery(queries))
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ScrollToButton))
