import { connect } from 'react-redux'
import { trackEvent } from 'actions/analytics'

import Grid from 'components/grid/Grid'

function mapDispatchToProps(dispatch) {
  return {
    trackGridClick: (e) => {
      dispatch(
        trackEvent({
          event: 'Jaegermeister Tales Click',
          contentType: 'Jaegermeister Tales',
          contentId: e.target.parentNode.parentNode.parentNode.getElementsByTagName(
            'p'
          )[0].innerText
        })
      )
    }
  }
}

export default connect(null, mapDispatchToProps)(Grid)
