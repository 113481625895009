import React from 'react'

const PauseIcon = () => (
  <svg viewBox="0 0 126 126">
    <path d="M63 0C28.2 0 0 28.2 0 63s28.2 63 63 63 63-28.2 63-63S97.8 0 63 0zm0 113c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50z" />
    <g>
      <path d="M67 51h8v24h-8zM54 51h8v24h-8z" />
    </g>
  </svg>
)

export default PauseIcon
