import { connect } from 'react-redux'

import AgeGate from 'components/agegate/AgeGate'
import { trackPageView } from 'actions/analytics'

const mapDispatchToProps = dispatch => ({
  onMount: () => {
    dispatch(trackPageView({
      event: 'VirtualPageview',
      page: 'AgeGate',
      url: document.location.href,
      contentCategory: 'Agegate'
    }))
  },
  onUpdate: () => {
    dispatch(trackPageView({
      event: 'age_gate_load'
    }))
  },
  onUnlock: () => {
    dispatch(trackPageView({
      event: 'Agegate',
      result: 'success'
    }))
  },
  onFail: () => {
    dispatch(trackPageView({
      event: 'Agegate',
      result: 'fail'
    }))
  }
})

export default connect(null, mapDispatchToProps)(AgeGate)
