import React from 'react'
import PropTypes from 'prop-types'

import styles from './download.css'
import DownloadIcon from 'svgs/download/download.js'


const DownloadButton = (props) => (<a
  className={styles.download}
  href={props.href}
  onClick={props.onClick}
  target="_blank"
  rel="noopener noreferrer"
><DownloadIcon />{props.children}
</a>)

DownloadButton.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired
}
DownloadButton.defaultProps = {
  onClick: () => {}
}
export default DownloadButton
