import React from 'react'

import styles from './socialicons.css'

export function facebook() {
  return (
    <svg className={styles.svg} viewBox="0 0 64 64">
      <path
        className="st0"
        d="M36.1 58.6V34.3h8.1l1.2-9.5H36v-6c0-2.7.8-4.6 4.7-4.6h5V5.8c-.9-.1-3.8-.4-7.3-.4-7.2 0-12.2 4.4-12.2 12.5v7H18v9.5h8.2v24.3h9.9z"
      />
    </svg>
  )
}

export function instagram() {
  return (
    <svg className={styles.svg} viewBox="0 0 64 64">
      <path
        className="st0"
        d="M32 11.1c6.8 0 7.6 0 10.3.1 2.5.1 3.8.5 4.7.9 1.2.5 2 1 2.9 1.9.9.9 1.4 1.7 1.9 2.9.3.9.8 2.2.9 4.7.1 2.7.1 3.5.1 10.3s0 7.6-.1 10.3c-.1 2.5-.5 3.8-.9 4.7-.5 1.2-1 2-1.9 2.9-.9.9-1.7 1.4-2.9 1.9-.9.3-2.2.8-4.7.9-2.7.1-3.5.1-10.3.1s-7.6 0-10.3-.1c-2.5-.1-3.8-.5-4.7-.9-1.2-.5-2-1-2.9-1.9-.9-.9-1.4-1.7-1.9-2.9-.3-.9-.8-2.2-.9-4.7-.1-2.7-.1-3.5-.1-10.3s0-7.6.1-10.3c.1-2.5.5-3.8.9-4.7.5-1.2 1-2 1.9-2.9.9-.9 1.7-1.4 2.9-1.9.9-.3 2.2-.8 4.7-.9 2.7-.1 3.5-.1 10.3-.1m0-4.6c-6.9 0-7.8 0-10.5.2-2.7.1-4.6.6-6.2 1.2-1.7.7-3.1 1.5-4.5 2.9s-2.3 2.8-2.9 4.5c-.6 1.6-1.1 3.5-1.2 6.2-.2 2.7-.2 3.6-.2 10.5s0 7.8.2 10.5c.1 2.7.6 4.6 1.2 6.2.7 1.7 1.5 3.1 2.9 4.5s2.8 2.3 4.5 2.9c1.6.6 3.5 1.1 6.2 1.2 2.7.1 3.6.2 10.5.2s7.8 0 10.5-.2c2.7-.1 4.6-.6 6.2-1.2 1.7-.7 3.1-1.5 4.5-2.9s2.3-2.8 2.9-4.5c.6-1.6 1.1-3.5 1.2-6.2.1-2.7.2-3.6.2-10.5s0-7.8-.2-10.5c-.1-2.7-.6-4.6-1.2-6.2-.7-1.7-1.5-3.1-2.9-4.5s-2.8-2.3-4.5-2.9c-1.6-.6-3.5-1.1-6.2-1.2-2.7-.2-3.6-.2-10.5-.2z"
      />
      <path
        className="st0"
        d="M32 18.9c-7.2 0-13.1 5.9-13.1 13.1 0 7.2 5.9 13.1 13.1 13.1S45.1 39.2 45.1 32c0-7.2-5.9-13.1-13.1-13.1zm0 21.6c-4.7 0-8.5-3.8-8.5-8.5s3.8-8.5 8.5-8.5 8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5z"
      />
      <circle className="st0" cx="45.6" cy="18.4" r="3.1" />
    </svg>
  )
}

export function twitter() {
  return (
    <svg
      className={styles.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
    </svg>
  )
}

export function youtube() {
  return (
    <svg className={styles.svg} viewBox="0 0 64 64">
      <path d="M58,21.4c0,0-0.5-3.7-2.1-5.3c-2-2.1-4.3-2.1-5.3-2.2C43.2,13.4,32,13.4,32,13.4s-11.1-0.1-18.5,0.5 c-1, 0.1-3.3,0.1-5.3,2.2c-1.6,1.6-2.1,5.3-2.1,5.3S5.6,25.7,5.5,30v4c0.1,4.3,0.6,8.6,0.6,8.6s0.5,3.7,2.1,5.3c2,2.1,4.7,2,5.8,2.3 c4.2,0.4,17.5,0.5,18,0.5c0,0,11.1,0,18.6-0.5c1-0.1,3.3-0.1,5.3-2.2c1.6-1.6,2.1-5.3,2.1-5.3s0.5-4.3,0.6-8.6v-4 C58.5,25.7,58,21.4,58,21.4z M26.6,39.4V24.5L40.9,32L26.6,39.4z" />
    </svg>
  )
}

export function linkedIn() {
  return (
    <svg
      className={styles.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <path
        d="M51.8 8.6H12.2c-1.9 0-3.4 1.5-3.4 3.3v40c0 1.8 1.5 3.3 3.4 3.3h39.6c1.9 0 3.4-1.5 3.4-3.3V12c0-1.9-1.5-3.4-3.4-3.4zm-36 39.2V26.7h7v21.1h-7zm3.6-24c-2.4 0-3.9-1.6-3.9-3.6 0-2.1 1.6-3.6 4-3.6s3.9 1.6 3.9 3.6c-.1 2-1.6 3.6-4 3.6zm28.7 24h-7V36.5c0-2.8-1-4.8-3.6-4.8-1.9 0-3.1 1.3-3.6 2.6-.2.4-.2 1.1-.2 1.7v11.8h-7s.1-19.1 0-21.1h7v3c.9-1.4 2.6-3.5 6.3-3.5 4.6 0 8.1 3 8.1 9.5v12.1z"
        fill="#193e0f"
      />
    </svg>
  )
}

export function spotify() {
  return (
    <svg
      className={styles.svg}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
    </svg>
  )
}
