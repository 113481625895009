import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './gallery.css'
import { Image } from 'components'
import Arrow from 'svgs/arrow/Arrow'

class Gallery extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  }

  constructor() {
    super()
    this.state = {
      index: 0
    }
  }

  imageActiveClass(index) {
    let className
    const nextIndex = this.nextIndex()
    const previousIndex = this.previousIndex()
    if (index === this.state.index) {
      className = styles.active
    } else if (index === nextIndex) {
      className = styles.next
    } else if (index === previousIndex) {
      className = styles.previous
    } else {
      className = styles.inactive
    }
    return className
  }
  next() {
    this.setState({
      index: this.nextIndex()
    })
  }

  nextIndex() {
    const images = this.props.data.images
      ? this.props.data.images
      : this.props.data.galleryImages
    return images && images.length - 1 > this.state.index
      ? this.state.index + 1
      : 0
  }

  previous() {
    this.setState({
      index: this.previousIndex()
    })
  }
  previousIndex() {
    const images = this.props.data.images
      ? this.props.data.images
      : this.props.data.galleryImages
    return images && this.state.index === 0
      ? images.length - 1
      : this.state.index - 1
  }
  renderGalleryImages(image, index) {
    const nextIndex = this.nextIndex()
    const previousIndex = this.previousIndex()
    return (
      <div className={styles.imageContainer}>
        <div className={this.imageActiveClass(index)} key={image.id}>
          {(index === this.state.index ||
            index === nextIndex ||
            index === previousIndex) && (
            <Image
              key={image.id}
              src={image.image.url}
              alt={image.image.title}
            />
          )}
          <div className={styles.caption}>
            <h2 className={styles.captionTitle}>{image.title}</h2>
            <p>{image.caption}</p>
          </div>
        </div>
      </div>
    )
  }
  renderImage(image, index) {
    const nextIndex = this.nextIndex()
    const previousIndex = this.previousIndex()

    return (
      <div className={this.imageActiveClass(index)} key={image.id}>
        {(index === this.state.index ||
          index === nextIndex ||
          index === previousIndex) && (
          <Image key={image.id} src={image.url} alt={image.title} />
        )}
      </div>
    )
  }
  render() {
    const {
      data: {
        title = '',
        images = [],
        galleryImages = [],
        titleType,
        hideOrangeBanner
      } = {}
    } = this.props
    return (
      <div className={styles.container}>
        {title && title.trim() && (<h3
          className={classNames(styles.title, {
            // [styles.strikeThrough]: !hideOrangeBanner,
            [styles[
              `title${titleType}${hideOrangeBanner ? 'NoStrikeThrough' : ''}`
              ]]: titleType
          })}
        >
          {title}
        </h3>)}
        {images && (
          <div>
            <div className={styles.images}>
              {images.map((image, index) => this.renderImage(image, index))}
            </div>
            <div className={styles.caption}>
              {images && images[this.state.index].title}
            </div>
          </div>
        )}

        {galleryImages && galleryImages.length > 0 && (
          <div className={styles.images}>
            {galleryImages.map((image, index) =>
              this.renderGalleryImages(image, index)
            )}
          </div>
        )}

        <button
          className={styles.previousButton}
          onClick={() => this.previous()}
        >
          <Arrow direction="left" />
        </button>
        <button className={styles.nextButton} onClick={() => this.next()}>
          <Arrow direction="right" />
        </button>
      </div>
    )
  }
}

export default Gallery
