import basePage from 'hoc/basepage/basepage'
import { Drinks } from 'components/pages'

export default basePage(Drinks, {
  contentNodeName: 'drinksList',
  defaultSlug: 'drinks',
  trackContentCategory: 'Drinks Overview',
  trackDefaultPageName: 'Drinks Overview',
  trackAlwaysUseDefaultPageName: true
})
