import basePage from 'hoc/basepage/basepage'
import { Product } from 'components/pages'

export default basePage(Product, {
  contentNodeName: 'ProductPage',
  defaultSlug: 'product',
  trackContentCategory: 'Product Page',
  trackDefaultPageName: 'Product',
  trackAlwaysUseDefaultPageName: true
})
