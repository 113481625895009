import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { Arrow } from '..'
import styles from './accordionItem.css'

const AccordionItem = (props) => {
  const { title, description, onClick, expanded, mode } = props
  const css = {
    open: styles.open,
    button: styles.buttonOn
  }
  const embeddedInComponent = mode === 'embeddedInComponent'
  const cx = classNames.bind(css)
  const classes = cx(styles.accordionItemBody, {
    open: expanded
  })
  const buttonClasses = cx(styles.accordionItemToggle, {
    button: expanded
  })
  return (
    <div
      className={[
        styles.accordionItem,
        embeddedInComponent ? styles.accordionItemEmbedded : '',
        expanded ? styles.accordionItemOpen : ''
      ].join(' ')}
    >
      <button onClick={onClick} aria-label={title} className={buttonClasses}>
        {title}
        <Arrow
          className={styles.accordionItemArrow}
          active={expanded}
          direction={expanded ? 'up' : 'down'}
          usePresetSpacing
        />
      </button>
      <div className={classes}>
        {description && <p>{description}</p>}
        {/* What gets rendered here is sent down from the parent */}
        <div
          className={[
            embeddedInComponent
              ? styles.accordionItemDownloads
              : styles.accordionItemStandard
          ]}
        >
          {props &&
            props.expanded &&
            props.renderContent(props.index, props.childData, props.childType)}
        </div>
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  childData: PropTypes.array,
  childType: PropTypes.string,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  renderContent: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  mode: PropTypes.string
}
AccordionItem.defaultProps = {
  children: {}
}
export default AccordionItem
