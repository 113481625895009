import React from 'react'
import PropTypes from 'prop-types'
import ErrorPage from './errorpage'

const ServerError = ({ errorText, text }) => (
  <ErrorPage errorCode={500} errorText={errorText} text={text} />
)

ServerError.propTypes = {
  errorText: PropTypes.string,
  text: PropTypes.string
}

ServerError.defaultProps = {
  errorText: 'Server error',
  text: `
    <h1>
      We're sorry
      <strong>something went wrong.</stong>
    </h1>
    <p>
      We are working on the proplem <br />
      please try again later.
    </p>
  `
}

export default ServerError
