/* global window */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { numberFormat } from 'number-format'

import split from '../../lib/split'

import { OnVisible, Image } from 'components'

import styles from './shotcounter.css'

class ShotCounter extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      total: false
    }
  }
  componentDidMount() {
    this.tick()
  }
  componentWillUnmount() {
    window.cancelAnimationFrame(this.nextFrame)
  }

  tick = () => {
    this.nextFrame = window.requestAnimationFrame(this.tick)
        // timestamp below is 1st October 2016
    const start = new Date(1475272800000).getTime()
    const now = Date.now()
    const between = Math.floor(now - start)
        // number of shots for all time up to date above
    const baseShots = 2100000000 * 30

        // 99~ shots / second
    const total = Math.floor(between / 10.1) + baseShots
    this.setState({
      total
    })
  };

  render() {
    const { total } = this.state
    const data = this.props.data
    return (
      <OnVisible className={styles.shotCounter} percent={10} style={{ transitionDelay: '2000ms' }} >
        <div className={styles.contentContainer}>
          <Image src={data.shotGlass.url} sizes="33vw" mobileSizes="80vw" className={styles.shotGlass} alt={data.shotGlass.title} />
          <div className={styles.content}>
            <div className={styles.zeit}>
              <img src={data.zeit.url} alt={data.zeit.title} />
            </div>
            { total && <h1 className={styles.number}>{numberFormat(total).split(',').map((g, idx, col) => <div style={{ transitionDelay: `${(idx + 1) * 133}ms` }} key={idx}>{split(idx !== col.length - 1 ? `${g},` : g, styles.split)}</div>)}</h1> }
            <h4 className={styles.subtitle}>{data.text}</h4>
          </div>
        </div>
      </OnVisible>
    )
  }
}
ShotCounter.propTypes = {
  data: PropTypes.object.isRequired
}

export default ShotCounter
