import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { VideoPlayer, Markdown, OnVisible } from '../'
import { overrideEntryTitle } from '../helpers'

import fullBleedStyles from '../fullbleedimage/fullbleed.css'
import videoFeatureStyles from './videoFeature.css'
import videoStyles from './video.css'
import FieldsetBN from '../fieldsetBN/FieldsetBN'

const config = {
  noText: {
    textClass: ''
  },
  textBottomLeft: {
    headlineClass: fullBleedStyles.headline,
    textClass: videoStyles.textLeftBottom,
    textAnimation: fullBleedStyles.textAnimation
  },
  textMiddleLeft: {
    headlineClass: fullBleedStyles.headline,
    textClass: fullBleedStyles.textCenterLeft,
    textAnimation: fullBleedStyles.textAnimation
  },
  textBottomRight: {
    headlineClass: classNames(
      fullBleedStyles.headline,
      fullBleedStyles.headlineRight
    ),
    textClass: videoStyles.textRightBottom,
    textAnimation: fullBleedStyles.textAnimation
  }
}

const videoJsDefaultOptions = {
  aspectRatio: '16:9',
  techOrder: ['html5', 'youtube'],
  sources: [],
  youtube: {
    iv_load_policy: 3,
    ytControls: 1,
    modestbranding: 0,
    color: 'white',
    host: '//www.youtube.com',
    enablejsapi: 1,
    customVars: { wmode: 'transparent' },
    enablePrivacyEnhancedMode: true
  },
  nativeControlsForTouch: true
}
class VideoFeature extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      playing: false
    }
  }
  getTheme(themeName) {
    switch (themeName) {
      case 'default':
        return videoStyles
      case 'musicPage':
        return videoFeatureStyles
      case 'none':
        return {}
      default:
        // unknown theme - log error?
        return videoStyles
    }
  }
  tracking = (params) => {
    if (params.type === 'play') {
      this.setState({
        playing: true
      })
    } else if (params.type === 'pause' || params.type === 'ended') {
      this.setState({
        playing: false,
        visible: false
      })
    }
    this.props.tracking(params)
  }
  render() {
    const {
      data,
      headlineClass,
      textInnerClass,
      animationOffsetPercent,
      contentType,
      id,
      videoEnded,
      children,
      theme,
      bestNights,
      isHalfWidthPng
    } = this.props
    const { playing } = this.state
    const {
      video,
      videoMobile,
      youtubeId,
      youtubeIdMobile,
      headline,
      headingLevel,
      text,
      mode,
      loop,
      controls,
      autoplay,
      tint = true
    } = data
    const posterImage = (data && data.image && data.image.url) || '' //default image incase none is specified. Email Veronkia 20-11-18
    const posterImageMobile =
      (data && data.imageMobile && data.imageMobile.url) || '' //default image incase none is specified. Email Veronkia 20-11-18
    const showCustomControls = () => {
      if (youtubeId) return false
      return controls
    }
    const styles = this.getTheme(theme)
    // console.log('autoplay?',headline, autoplay)
    if (autoplay) {
      videoJsDefaultOptions.youtube.autoplay = 1
      videoJsDefaultOptions.youtube.playsinline = 1
    } else {
      videoJsDefaultOptions.youtube.autoplay = 0
      videoJsDefaultOptions.youtube.playsinline = 0
    }

    let videoSources = []
    let mobileSource = {}
    if (videoMobile === null && youtubeIdMobile === null) {
      videoSources = [
        {
          type: youtubeId ? 'video/youtube' : 'video/mp4',
          src: youtubeId
            ? youtubeId.indexOf('www.youtube') > -1
              ? youtubeId
              : `//www.youtube.com/watch?v=${youtubeId}`
            : video.url
        }
      ]
    } else {
      videoSources = [
        {
          type: youtubeId ? 'video/youtube' : 'video/mp4',
          src: youtubeId
            ? youtubeId.indexOf('www.youtube') > -1
              ? youtubeId
              : `//www.youtube.com/watch?v=${youtubeId}`
            : video.url
        },
        {
          type: youtubeIdMobile ? 'video/youtube' : 'video/mp4',
          src: youtubeIdMobile
            ? youtubeIdMobile.indexOf('www.youtube') > -1
              ? youtubeIdMobile
              : `//www.youtube.com/watch?v=${youtubeIdMobile}`
            : videoMobile.url
        }
      ]
      mobileSource = videoSources[1].src
    }
    // console.log('autoplay value here', autoplay)
    const videoJsOptions = {
      ...videoJsDefaultOptions,
      sources: videoSources,
      controls: showCustomControls(),
      preload: 'auto',
      loop,
      autoplay,
      tint,
      id: data.id
    }

    // Using JSON.stringify to address the issue of nested properties that were not correctly passed to the videoplayer component
    const videoJsOptionsString = JSON.stringify(videoJsOptions)

    // Don't apply muted in videojs' options or it will disable autoplay on iOS
    const textClasses =
      theme === 'default'
        ? classNames(styles.text, fullBleedStyles.text, config[mode].textClass)
        : classNames(fullBleedStyles.text, config[mode].textClass, {
            [styles.playing]: playing && theme !== 'musicPage'
          })

    const subTitle = (txt) => {
      if (theme === 'musicPage') {
        txt = txt.replace('<p>', '')
        txt = txt.replace('</p>', '')
        txt = txt.slice(0, txt.indexOf(' '))
        return <p className={fullBleedStyles.musicPageSubtitle}>{txt}</p>
      } else {
        txt = txt.replace('<p>', '')
        txt = txt.replace('</p>', '')
        return txt.slice(0, txt.indexOf(' '))
      }
    }
    const title = (title) => {
      let el = title
      if (theme === 'musicPage') {
        el = el.replace('<p>', '')
        el = el.replace('</p>', '')
        el = el.slice(el.indexOf(' ') + 1, el.length)
        el = <p className={fullBleedStyles.musicPageTitle}>{el}</p>
      }
      return el
    }
    const normalTitle = (title) => {
      let el = title
      el = el.replace('<p>', `<h1 class="${styles.pageTitle}">`)
      el = el.replace('</p>', '</h1>')
      return el
    }
    let videoFeatureJsClass
    let videoContainerClass
    if (mobileSource) {
      videoFeatureJsClass = 'js-video-mobile'
      videoContainerClass = classNames(
        styles.video,
        {
          [styles.nativeVideo]: !youtubeId
        },
        styles.nativeVideoMobile
      )
    } else {
      videoContainerClass = classNames(styles.video, {
        [styles.nativeVideo]: !youtubeId
      })
    }
    const BN = bestNights
    return (
      <OnVisible
        key={id}
        className={classNames(fullBleedStyles.wrapper, {
          [fullBleedStyles.wrapperPaddingBottom]:
            mode === 'textBottomLeft' || mode === 'textBottomRight',
          [fullBleedStyles.wrapperPaddingTop]: mode === 'textTopRight',
          [fullBleedStyles.noText]: mode === 'noText'
        })}
        visibleClassName={fullBleedStyles.visible}
        percent={animationOffsetPercent}
      >
        <div className={videoContainerClass}>
          {theme === 'bestNightsHero' && <FieldsetBN isVideo={true} />}
          <VideoPlayer
            videoEnded={videoEnded}
            videoId={this.props.id}
            options={videoJsOptionsString}
            playsinline={videoJsOptions.autoplay}
            tracking={this.tracking}
            poster={posterImage}
            posterMobile={posterImageMobile}
            mobileClass={videoFeatureJsClass}
            isHalfWidthPng={isHalfWidthPng}
          />
        </div>
        {mode !== 'noText' && (
          <div
            className={classNames(
              BN ? videoFeatureStyles.textWrapperBN : '',
              config[mode].textAnimation || fullBleedStyles.empty
            )}
          >
            <div
              className={classNames(
                BN ? videoFeatureStyles.textLeftBN : '',
                textClasses
              )}
            >
              <div className={textInnerClass || fullBleedStyles.textInner}>
                {(headingLevel && headline && headline.trim() &&
                  headingLevel !== 'Original default' &&
                  overrideEntryTitle({
                    headline,
                    headingLevel,
                    className:
                      headlineClass ||
                      (theme === 'default' && config[mode]
                        ? config[mode].headlineClass
                        : styles.headline)
                  })) || (headline && headline.trim() &&
                  (<div
                    className={classNames(
                      headlineClass,
                      config[mode].headlineClass,
                    )}
                  >
                    {theme === 'musicPage' ? (
                      <div>
                        <Markdown inline markdownHtml={subTitle(headline)} />
                        <Markdown inline markdownHtml={title(headline)} />
                      </div>
                    ) : (
                      <>{headline}</>
                    )}
                  </div>)
                )}
                {text && (<div
                  className={
                    theme ? styles.textMarkdown : fullBleedStyles.textMarkdown
                  }
                >
                  <Markdown markdownHtml={text} contentType={contentType}/>
                </div>)}
                <div>{children}</div>
              </div>
            </div>
          </div>
        )}
      </OnVisible>
    )
  }
}
VideoFeature.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,
    mode: PropTypes.oneOf(Object.keys(config)),
    text: PropTypes.string,
    video: PropTypes.object,
    youtubeId: PropTypes.string,
    image: PropTypes.object,
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    controls: PropTypes.bool
  }).isRequired,
  headlineClass: PropTypes.string,
  textInnerClass: PropTypes.string,
  animationOffsetPercent: PropTypes.number,
  tracking: PropTypes.func,
  children: PropTypes.node,
  theme: PropTypes.string,
  bestNights: PropTypes.bool
}
VideoFeature.defaultProps = {
  headlineClass: null,
  textInnerClass: null,
  animationOffsetPercent: -80,
  tracking: () => {},
  theme: 'default',
  bestNights: false,
  isHalfWidthPng: false
}
export default VideoFeature
