import basePage from 'hoc/basepage/basepage'
import { BestNightsOverview } from 'components/pages'

export default basePage(BestNightsOverview, {
  contentNodeName: 'bestNightsOverviewPage',
  defaultSlug: 'best-nights',
  trackContentCategory: 'Best Nights',
  trackDefaultPageName: 'Best Nights',
  trackAlwaysUseDefaultPageName: true
})
