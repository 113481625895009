/* global window */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import makeOsc from 'eased-oscillator'

import { Markdown, OnVisible, Image } from '../'
import { precise,
         iceCold } from 'utils/i18n'

import styles from './shotometer.css'

function getStepsOsc() {
  return makeOsc({
    max: 0,
    min: 0,
    steps: 180, // 60 fps * 3s (duration of meter animation in css)
    easing: 'easeInOutQuad'
  })
}
class Shotometer extends Component {
  constructor(...rest) {
    super(...rest)
    this.numberSteps = getStepsOsc()
    this.state = {
      number: this.numberSteps(),
      started: false,
      secondsRemaining: 0
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  onVisible = (isVisible) => {
    if (isVisible && !this.state.started) {
      this.numberSteps = getStepsOsc()
      this.setState({
        started: true,
        number: this.numberSteps()
      })
      this.interval = setInterval(this.tick, 200)
    }
    if (!isVisible) {
      this.setState({
        started: false
      })
    }
  };

  tick = () => {
    this.setState({ number: this.state.number - 1 })
    if (Math.abs(this.state.number) >= 18) {
      clearInterval(this.interval)
      this.setState({ number: -18 })
    }
  };

  render() {
    const { started, number } = this.state
    const classes = cx(styles.shotometer, styles.loaded)
    const data = this.props.data
    const siteText = {
      iceCold: iceCold(),
      precise: precise()
    }
    return (

      <div>
        <OnVisible
          onChange={this.onVisible}
          className={classes}
          visibleClassName={styles.visible}
          percent={-30}
        >
          {started &&
            <div className={styles.temps}>
              <div className={styles.textContainer}>
                <div className={styles.temp}>
                  <span />
                </div>
                <div className={cx(styles.textIceCold, {
                  [styles.shortIceColdText]: siteText.iceCold.length < 25,
                  [styles.longIceColdTxt]: siteText.iceCold.length >= 25
                })}>{siteText.iceCold}</div>
                <div className={styles.number}>
                  <span className={styles.tempNumber}>{Math.floor(number)}°c</span>
                  <span className={styles.preciseText}>{siteText.precise}</span>
                </div>
              </div>
            </div>
          }
          <div className={styles.shotometerContainer}>
            <div className={styles.shotometerImage}>
              { data.images.map(d => (
                <Image src={d.url} alt={d.title} key={d.id} />
                ))
              }
            </div>
            <div className={styles.shotometerText}>
              <Markdown markdownHtml={data.text} />
            </div>
          </div>
        </OnVisible>
      </div>
    )
  }
}
Shotometer.propTypes = {
  data: PropTypes.object.isRequired
}
export default Shotometer
