import basePage from 'hoc/basepage/basepage'
import { JaegerTales } from 'components/pages'

export default basePage(JaegerTales, {
  contentNodeName: 'jgerTalesLandingPage',
  defaultSlug: 'jaegermeister-tales',
  trackContentCategory: 'Jaeger Tales Overview',
  trackDefaultPageName: 'Jaeger Tales Overview',
  trackAlwaysUseDefaultPageName: true
})
