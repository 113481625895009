import React from 'react'
import PropTypes from 'prop-types'

import { Generic, MetaData, SiteLinks, CookiesList } from 'components'
import { Recommendations } from 'containers'

import styles from './legal.css'

const Legal = (props) => {
  const { content, trackingVideo } = props
  return (
    <main className={styles.legalPage} role="main" id="mainContent">
      <MetaData metadata={content.metadata} />
      {content.hero && (
        <section className={styles.hero}>
          <Generic components={content.hero} trackingVideo={trackingVideo} />
        </section>
      )}
      {content.siteLinks && (
        <div className={styles.links}>
          <SiteLinks links={content.siteLinks.links} />
        </div>
      )}
      {content.components &&
        content.components.map((d) => (
          <Generic components={d} trackingVideo={trackingVideo} key={d.id} />
        ))}
      {content.cookiesList && <CookiesList />}
      {content.recommendations && (
        <Recommendations recommendations={content.recommendations} />
      )}
    </main>
  )
}

Legal.defaultProps = {
  content: {},
  trackingVideo: () => {},
  siteLinks: {}
}
Legal.propTypes = {
  content: PropTypes.object.isRequired,
  siteLinks: PropTypes.object.isRequired,
  trackingVideo: PropTypes.func
}
export default Legal
