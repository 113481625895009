import React from 'react'
import { Button } from '../'

const CookiesSettingsBtn = ({ text }) => {
  return (
    <Button
      onClick={() => {
        if (window && window.OneTrust) {
          window.OneTrust.ToggleInfoDisplay()
        }
      }}
    >
      {text}
    </Button>
  )
}

export default CookiesSettingsBtn
