import React from 'react'
import PropTypes from 'prop-types'
import ErrorPage from './errorpage'


const NotFound = ({ errorText, text }) => (
  <ErrorPage errorCode={404} errorText={errorText} text={text} />
)

NotFound.propTypes = {
  errorText: PropTypes.string,
  text: PropTypes.string
}

NotFound.defaultProps = {
  errorText: 'Not found',
  text: `
    <h1>
      Look's like
      <strong>you're lost.</stong>
    </h1>
    <p> To get away from here, use the menu above or go to the  <br>
      <a href="/">Homepage</a>
    </p>
  `
}

export default NotFound
