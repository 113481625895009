import basePage from 'hoc/basepage/basepage'
import { Factory } from 'components/pages'

export default basePage(Factory, {
  contentNodeName: 'factoryTourPage',
  defaultSlug: 'factory-tour',
  endPointName: 'formEndpoint',
  // trackContentCategory: 'Content Page',
  trackDefaultPageName: 'Factory Tour',
  trackAlwaysUseDefaultPageName: true
})
