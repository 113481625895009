import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  HalfWidthPng,
  FullBleedImage,
  FullWidthPng,
  MetaData,
  Generic
} from 'components'
import { Recommendations } from 'containers'

import styles from './productB.css'

class ProductB extends Component {
  componentRender = (components) => {
    return (
      (components.contentType === 'fullBleedImageComponent' && (
        <FullBleedImage
          data={components}
          headlineClass={styles.productBHeadline}
        />
      )) ||
      (components.contentType === 'fullWidthPngComponent' && (
        <FullWidthPng data={components} />
      )) ||
      (components.contentType === 'halfWidthPngComponent' && (
        <HalfWidthPng
          data={components}
          headlineClass={styles.productBHeadline}
          imageClass={styles.productBImage}
          contentWidthClass={styles.productBTextInnerAbsolute}
        />
      )) || (
      <Generic components={components} key={components.id} />)
    )
  }
  render() {
    const data = this.props.content
    return (
      <main role="main" id="mainContent">
        <MetaData metadata={data.metadata} />

        <div className={styles.jaegerProductB}>
          {data.hero && (
            <HalfWidthPng
              data={data.hero}
              headlineClass={styles.productBHeadline}
              imageClass={styles.productBImage}
              contentWidthClass={styles.productBTextInner}
              reverseMobile
            />
          )}
          {data.content &&
            data.content.map((d) => (
              <section key={d.id}>{<section key={d.id}>{this.componentRender(d)}</section>}</section>
            ))}
          {data.recommendations && (
            <Recommendations recommendations={data.recommendations} />
          )}
        </div>
      </main>
    )
  }
}

ProductB.defaultProps = {
  content: {}
}
ProductB.propTypes = {
  content: PropTypes.object
}
export default ProductB
