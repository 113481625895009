// A CSS-less image tag that generates a src-set, triggers an onloaded function
// and takes css classes from the parent
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import responsiveImageSizes from 'responsive-image-sizes'

// For mapping a given image to a srcset
const resMappings = [
  {
    size: '320w',
    width: 960,
    quality: 45
  },
  {
    size: '360w',
    width: 1440, // why is this 4x the image width?
    quality: 45
  },
  {
    size: '599w',
    width: 1600,
    quality: 45
  },
  {
    size: '768w',
    width: 1600,
    quality: 45
  },
  {
    size: '1024w',
    width: 2048,
    quality: 45
  },
  {
    size: '1160w',
    width: 1366
  },
  {
    size: '1366w',
    width: 1440
  },
  {
    size: '1920w',
    width: 2048
  },
  {
    size: '2560w',
    width: 2560
  }
]

class Img extends Component {
  static defaultProps = {
    className: null,
    loadedClassName: null,
    defaultWidth: 1366,
    defaultQuality: 66,
    onLoad: null,
    sizes: '100vw',
    mobileSizes: '100vw',
    role: 'presentation',
    generateSrcSet: true,
    objectFit: true,
    lazyLoading: false
  }

  static propTypes = {
    src: PropTypes.string.isRequired,
    sizes: PropTypes.string,
    mobileSizes: PropTypes.string,
    className: PropTypes.string,
    loadedClassName: PropTypes.string,
    onLoad: PropTypes.func,
    defaultWidth: PropTypes.number,
    defaultQuality: PropTypes.number,
    alt: PropTypes.string.isRequired,
    role: PropTypes.string,
    generateSrcSet: PropTypes.bool,
    objectFit: PropTypes.bool,
    lazyLoading: PropTypes.bool
  }

  constructor(...rest) {
    super(...rest)
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    if (this.img.complete) {
      this.imageLoaded()
    }
    if (window.onImageMount) {
      window.onImageMount()
    }
  }

  resizeImage(src, display, size) {
    let options = {
      deviceType: display,
      sourceImageWidth: src,
      widthOnPage: size,
      mode: 'standard',
      topSize: 1920
    }
    return responsiveImageSizes.getResponsiveSizes(options)
  }
  getSrcSet(src) {
    return resMappings.map(
      ({ width, quality = this.props.defaultQuality, size }) => {
        const url = this.getUrl({
          src,
          width,
          quality
        })
        return `${url} ${size}`
      }
    )
  }

  getUrl({ src = this.props.src, width, quality }) {
    return `${src}?w=${width}&q=${quality}&fl=progressive`
  }

  imageLoaded = () => {
    if (!this.state.loaded) {
      if (this.props.onLoad) {
        // TODO: if onLoad returns a promise then wait for promise to resolve before setting loaded
        this.props.onLoad()
      }
      this.setState({
        loaded: true
      })
    }
  }
  render() {
    const classes = classNames(this.props.className, {
      [`${this.props.loadedClassName}`]: this.state.loaded
    })
    const imgSrc = this.props.src
    const mobileImage = this.props.mobileImage ? this.props.mobileImage : ''
    const mobileSize = Number(this.props.mobileSizes.replace('vw', ''))
    const deskSize = Number(this.props.sizes.replace('vw', ''))
    let widthOnPage = deskSize
    let dskImageSizes
    let mobImageSizes

    if (mobileSize === deskSize && mobileImage) {
      mobImageSizes = this.resizeImage(1920, 'mobile', widthOnPage)
      dskImageSizes = this.resizeImage(1920, 'desktop', widthOnPage)
    }
    if (mobileSize === deskSize && !mobileImage) {
      mobImageSizes = this.resizeImage(1920, 'mobile', widthOnPage)
      dskImageSizes = this.resizeImage(1920, 'all', widthOnPage)
    }
    if (mobileSize !== deskSize && mobileImage) {
      mobImageSizes = this.resizeImage(1920, 'mobile', mobileSize)
      dskImageSizes = this.resizeImage(1920, 'desktop', deskSize)
    }
    if (mobileSize !== deskSize && !mobileImage) {
      mobImageSizes = this.resizeImage(1920, 'mobile', mobileSize)
      dskImageSizes = this.resizeImage(1920, 'all', deskSize)
    }

    const progressive =
      imgSrc && imgSrc.indexOf('jpg') !== -1 ? '&fl=progressive' : ''
    return (
      <picture>
        {mobileImage && [
          <source
            type="image/webp"
            key={mobileImage.id}
            /*srcSet={`${mobileImage.url}?fm=webp&w=750&q=${
              this.props.defaultQuality
            }`}*/
            srcSet={`${mobImageSizes
              .slice(0)
              .reverse()
              .map(
                (size) =>
                  `${mobileImage.url}?fm=webp&w=${size}&q=${this.props.defaultQuality} ${size}w`
              )}`}
            media="(max-width: 768px)"
          />,
          <source
            key={mobileImage.id + 'prog'}
            /*srcSet={`${mobileImage.url}?&w=750&q=${
              this.props.defaultQuality
            }${progressive}`}*/
            srcSet={`${mobImageSizes
              .slice(0)
              .reverse()
              .map(
                (size) =>
                  `${mobileImage.url}?&w=${size}&q=${this.props.defaultQuality}${progressive} ${size}w`
              )}`}
            media="(max-width: 768px)"
          />
        ]}
        <source
          type="image/webp"
          /*srcSet={`${imgSrc}?fm=webp&w=${this.props.defaultWidth}&q=${
            this.props.defaultQuality
          }`}*/
          srcSet={`${dskImageSizes
            .slice(0)
            .reverse()
            .map(
              (size) =>
                `${imgSrc}?fm=webp&w=${size}&q=${this.props.defaultQuality} ${size}w`
            )}`}
        />
        <img
          className={classes}
          onLoad={this.imageLoaded}
          sizes={
            this.props.mobileSizes !== this.props.sizes
              ? `(max-width: 640px) ${this.props.mobileSizes}, ${this.props.sizes}`
              : this.props.sizes
          }
          src={`${imgSrc}?&w=${this.props.defaultWidth}&q=${this.props.defaultQuality}${progressive}`}
          /*srcSet={srcset}*/
          srcSet={`${dskImageSizes
            .slice(0)
            .reverse()
            .map(
              (size) =>
                `${imgSrc}?&w=${size}&q=${this.props.defaultQuality}${progressive} ${size}w`
            )}`}
          role={this.props.role}
          alt={this.props.alt}
          ref={(img) => {
            this.img = img
          }}
          data-object-fit={this.props.objectFit}
          loading={this.props.lazyLoading ? 'lazy' : 'eager'}
        />
      </picture>
    )
  }
}

export default Img
