import React, { useState, useEffect, useCallback, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Link from '../staggerlink/staggerlink'
import { Arrow } from '../'
import split from '../../lib/split'

import styles from './navbar.css'

const DropDown = ({ links, text, trackNavigationClick }) => {
  const [open, setOpen] = useState(false)
  const [focused, setFocused] = useState(false)
  const menuBtn = useRef()
  const itemsContainer = useRef()
  const [eventAdded, setEventAdded] = useState(false)
  const expandableMenuUuid = text + 'DropdownExpandable'
  const outsideClickListener = useCallback((event) => {
    if (event.target.id !== text && event.target.parentNode.id !== text) {
      setOpen(false)
    }
  }, [])

  const KeyListener = useCallback((event) => {
    // console.log(event)
    if (event.key === 'ArrowUp') {
      // console.log('close menu')
      setOpen(false)
    }
  }, [])

  function scrollThroughSubelements(direction, focused) {
    let length = itemsContainer.current.children.length
    let nextElementToFocus
    if (direction === 'previous') {
      nextElementToFocus = --focused
      // console.log('nextElementToFocus', nextElementToFocus, 'length', length)
      if (nextElementToFocus < 0) {
        nextElementToFocus = length - 1
      }
    } else {
      nextElementToFocus = ++focused
      // console.log('nextElementToFocus', nextElementToFocus, 'length', length)
      if (nextElementToFocus >= length) {
        nextElementToFocus = 0
      }
    }
    /*// console.log(
      'nextElementToFocus',
      nextElementToFocus,
      itemsContainer.current.children.item(nextElementToFocus)
    )*/
    itemsContainer.current.children.item(nextElementToFocus).focus({
      preventScroll: true,
      focusVisible: true
    })
    // console.log('active element', document.activeElement)
    setFocused(nextElementToFocus)
  }
  function handleKeyDownSubMenu(e, open, focused) {
    // console.log('key down sub menu', e.key, open, focused)
    if (open === true) {
      if (e.key === 'Escape') {
        setOpen(!open)
        // console.log('menu chiuso ' + open)
        menuBtn.current.focus()
      }
      if (e.key === 'Tab') {
        if (!e.target.nextSibling) {
          setOpen(false)
        }
      }
      if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
        // console.log('arrow down on element', e.target, 'focused', focused)
        e.preventDefault()
        e.stopPropagation()
        scrollThroughSubelements('next', focused)
      }
      if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
        // console.log('arrow down on element', e.target, 'focused', focused)
        e.preventDefault()
        e.stopPropagation()
        scrollThroughSubelements('previous', focused)
      }
    }
  }

  function handleKeyDownMenuBtn(e, open, expandableMenuUuid) {
    // console.log('key down', e.key, open)
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      e.stopPropagation()
      if (open === false) {
        // console.log('menu chiuso?', open)
        setOpen(true)
        // console.log('menu chiuso2?', open)
      } else {
        // console.log('menu aperto?', open, itemsContainer.current.firstChild)
        document.getElementById(expandableMenuUuid).firstChild.focus({
          preventScroll: true,
          focusVisible: true
        })
        /*itemsContainer.current.firstChild.focus({
          preventScroll: true,
          focusVisible: true
        })*/
        // console.log('active element', document.activeElement)
        setFocused(0)
      }
      // console.log('menu aperto ' + open)
    }
    if (e.key === 'ArrowUp' && open === true) {
      setOpen(false)
      // console.log('menu chiuso ' + open)
    }
    if (e.key === 'Tab' && e.shiftKey) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open && !eventAdded) {
      window.addEventListener('click', outsideClickListener)
      setEventAdded(true)
    } else if (!open && eventAdded) {
      window.removeEventListener('click', outsideClickListener)
      setEventAdded(false)
    }

    /*if (open && !eventAdded) {
      window.addEventListener('keydown', KeyListener)
      setEventAdded(true)
    } else if (!open && eventAdded) {
      window.removeEventListener('keydown', KeyListener)
      setEventAdded(false)
    }*/
  }, [open])

  const navLinks = links.map((link, index) => {
    const uuid = index

    if (link.url) {
      return (
        <Link
          key={`nav-links-${uuid}-${link.id}`}
          to={link.url}
          className={styles.dropDownItem}
          onClick={() => trackNavigationClick(link.text)}
          onKeyDown={(e) => handleKeyDownSubMenu(e, open, focused)}
          rel="external"
        >
          {link.text}
        </Link>
      )
    } else if (link.route) {
      return (
        <Link
          key={`nav-links-${uuid}-${link.id}`}
          to={link.route}
          className={styles.dropDownItem}
          onClick={() => trackNavigationClick(link.text)}
          onKeyDown={(e) => handleKeyDownSubMenu(e, open, focused)}
        >
          {link.text}
        </Link>
      )
    }
  })
  //// console.log(navLinks)
  return (
    <div
      id={text}
      className={classNames(styles.dropDownContainer, {
        [styles.activeDropDown]: open
      })}
    >
      <button
        onClick={() => {
          setOpen(!open)
        }}
        onKeyDown={(e) => handleKeyDownMenuBtn(e, open, expandableMenuUuid)}
        role="button"
        className={classNames({
          [styles.dropDownBtn]: true,
          [styles.active]: open
        })}
        aria-label={text}
        aria-haspopup="true"
        aria-expanded={open}
        aria-controls={expandableMenuUuid}
        ref={menuBtn}
      >
        <div aria-hidden="true">
          {split(text, styles.split)}{' '}
          <Arrow
            active={open}
            direction={open ? 'up' : 'down'}
            usePresetSpacing
          />
        </div>
      </button>
      <div
        id={expandableMenuUuid}
        className={classNames({
          [styles.dropDown]: true,
          [styles.dropDownShow]: open
        })}
        ref={itemsContainer}
      >
        {navLinks}
      </div>
    </div>
  )
}

export default DropDown
