import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Anchor = (props) => {
  if (!props.to) {
    return <div {...props} />
  }
  if (Anchor.urlIsExternal(props.to)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return (
      <a
        href={props.to}
        {...props}
        target="_blank"
        rel="noopener noreferrer"
      />
    )
  }
  return <Link {...props} />
}

Anchor.propTypes = {
  to: PropTypes.string.isRequired,
}
// could move this out to an external lib
Anchor.urlIsExternal = url => /^(\/\/|[\w\d+.-]+:)/.test(url)
export default Anchor
