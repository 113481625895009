import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Img, Markdown, Zeit, OnVisible } from '../'
import { Button, ScrollToButton } from 'containers'
import { overrideEntryTitle } from '../helpers'

import styles from './hero.css'

const Hero = ({ hero, time, children, theme }) => {
  const {
    image,
    headline,
    headingLevel,
    text,
    fullWidth,
    deepLink,
    buttons
  } = hero
  const imageWrapperClass = classNames(styles.imageWrapper, {
    [styles.fullWidth]: fullWidth
  })
  return (
    <OnVisible className={styles.wrapper} visibleClassName={styles.visible}>
      <div className={styles.ribbonLayer}>
        <div className={styles.imageLayer}>
          {image && (
            <div className={imageWrapperClass}>
              <Img
                src={image.url}
                alt={image.title}
                className={styles.image}
                loadedClassName={styles.imageLoaded}
                mobileSizes="100vw"
                sizes="50vw"
              />
            </div>
          )}
          <div className={styles.gradientLayer}>
            <div className={styles.row}>
              <div className={styles.column}>
                {time && (
                  <div className={styles.zeit}>
                    <Zeit />
                  </div>
                )}
                <div className={styles.content}>
                  {(headingLevel &&
                    headingLevel !== 'Original default' &&
                    overrideEntryTitle({
                      headline,
                      headingLevel,
                      className: styles.headline,
                      addStrong: true
                    })) || (
                    <h1 className={styles.headline}>
                      <strong>{headline}</strong>
                    </h1>
                  )}
                  <div className={styles.text}>
                    <Markdown markdownHtml={text} />
                    {/* maintaining this for legacy reasons, otherwise, all buttons should be rendered through the function below */}
                    {deepLink && (
                      <ScrollToButton
                        data={deepLink}
                        theme={theme}
                        trackingEvent={deepLink.trackingEvent}
                      />
                    )}
                    {buttons &&
                      buttons.length > 0 &&
                      buttons.map((btn) => {
                        if (btn.contentType === 'scrollToButton') {
                          return (
                            <ScrollToButton
                              key={btn.id}
                              data={btn}
                              theme={theme}
                              trackingEvent={btn.trackingEvent}
                            />
                          )
                        } else if (btn.contentType === 'button') {
                          return (
                            <Button
                              key={btn.id}
                              href={btn.link}
                              className={classNames({
                                [styles.secondaryBtn]:
                                  btn.style === 'secondary',
                                [styles.primaryBtn]: btn.style === 'primary'
                              })}
                              trackingEvent={btn.trackingEvent}
                            >
                              {btn.text}
                            </Button>
                          )
                        }
                      })}
                  </div>
                  {children && (
                    <div className={styles.children}>{children}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnVisible>
  )
}

Hero.propTypes = {
  hero: PropTypes.shape({
    image: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string,
    fullWidth: PropTypes.bool
  }).isRequired,
  time: PropTypes.bool
}

Hero.defaultProps = {
  hero: {},
  time: false
}

export default Hero
