import { connect } from 'react-redux'
import { trackPageView, hashEmail } from 'actions/analytics'
import Form from 'components/form/Form'
import { digestMessage } from '../../lib/digestMessage'

function mapDispatchToProps(dispatch, ownProps) {
  const { data, locale } = ownProps
  return {
    onSuccess: (formData) => {
      const formType =
        formData.formType.indexOf('factoryTourForm') !== -1
          ? 'factorytour'
          : 'contactme'
      dispatch(
        trackPageView({
          event: formType,
          message: formData.message
        })
      )
    },
    trackOnSubmit: (locale, email) => {
      if (data && data.onSubmitTracking) {
        dispatch(
          trackPageView({
            event: 'Form',
            eventType: data.onSubmitTracking.eventType,
            eventDetail: data.onSubmitTracking.eventDetail
          })
        )
      }
      if (
        locale &&
        (locale.indexOf('en-US') > -1 || locale.indexOf('en-us') > -1) &&
        email
      ) {
        digestMessage(email).then((hash) => {
          dispatch(
            hashEmail({
              event: 'Hash',
              eventType: 'submit',
              userData: {
                email_sh256: hash
              }
            })
          )
        })
      }
    },
    trackOnSuccess: () => {
      if (data && data.onSuccessTracking) {
        dispatch(
          trackPageView({
            event: 'Form',
            eventType: data.onSuccessTracking.eventType,
            eventDetail: data.onSuccessTracking.eventDetail
          })
        )
      }
    }
  }
}
function mapStateToProps(state) {
  return {
    content: state.content,
    locale: state.locale
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
