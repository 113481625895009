import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './filteroption.css'

class FilterOption extends Component {
  static defaultProps = {
    active: false,
    onClick: null
  }

  static propTypes = {
    onClick: PropTypes.func,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool
  }

  onClick = (event) => {
    const filter = {
      catagory: event.target.parentNode.parentNode.getElementsByTagName('h2')[0].innerText,
      type: event.target.innerText
    }
    event.preventDefault()
    this.props.onClick()
    this.props.onFilter(filter)
  }

  render() {
    return (
      <button
        onClick={this.onClick}
        className={this.props.active ? styles.optionActive : styles.option}
        role="button"
        aria-label={this.props.name}
      >
        {this.props.name}
      </button>)
  }
}
FilterOption.propTypes = {
  onFilter: PropTypes.func.isRequired
}
export default FilterOption
