import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as helper from './helper'

import styles from './input.css'

class Input extends Component {
  handleBlur = (e) => {
    this.props.setValue(
      this.props.id,
      helper.pad(e.target.value, this.props.maxLength)
    )
  }

  handleChange = (e) => {
    const value = e.target.value
    this.props.setValue(this.props.id, value)
    // Jump to next input when input length has reached max length
    if (value.toString().length === this.props.maxLength) {
      this.props.next()
    }
  }

  handleKeyDown = (e) => {
    // Jump to previous input on backspace (keyCode 8)
    const keyCode = e.which || e.keyCode
    const value = e.target.value
    if (keyCode === 8 && value.toString().length === 0) {
      this.props.prev()
    }
  }

  render() {
    const {
      id,
      label,
      ariaLabel,
      value,
      maxLength,
      placeholder,
      setInputRef,
      showError,
      showIntro,
      errorMessage,
      showVerificationError
    } = this.props
    const isComplete = value.toString().length === maxLength
    return (
      <div
        className={classNames({
          [styles.wrapper]: true,
          [styles.wrapperError]:
            (showError && isComplete) || showVerificationError,
          [styles.wrapperLong]: maxLength > 2
        })}
      >
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        <div className={styles.inputBox}>
          <input
            id={id}
            type="number"
            value={value}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            maxLength={maxLength}
            placeholder={placeholder}
            aria-label={ariaLabel}
            className={classNames({
              [styles.input]: true,
              [styles.opaquePlaceholder]: showIntro,
              [styles.inputError]: showError && isComplete,
              [styles.verificationError]: showVerificationError,
              [styles.inputLong]: maxLength > 2
            })}
            ref={(input) => {
              setInputRef(input)
            }}
          />
        </div>
        {isComplete && showError && (
          <div className={styles.errorMessage} aria-live="assertive">
            <span className={styles.errorIcon} />
            {errorMessage}
          </div>
        )}{' '}
      </div>
    )
  }
}

Input.propTypes = {
  id: PropTypes.oneOf(['DD', 'MM', 'YYYY']).isRequired,
  maxLength: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  showVerificationError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showIntro: PropTypes.bool,
  label: PropTypes.string
}

Input.defaultProps = {
  showError: false,
  showVerificationError: false,
  errorMessage: 'Invalid input',
  showIntro: true,
  placeholder: '',
  label: ''
}

export default Input
