import cookie from 'react-cookie'

// TODO: move to a global config location
export const validAgeLimits = [18, 19, 21]

export function loadAgeGateCookies() {
  const cookies = {}
  validAgeLimits.forEach((validAge) => {
    const cookieValue = cookie.load(`is${validAge}`)
    if (cookieValue !== undefined) {
      cookies[`is${validAge}`] = JSON.parse(cookieValue)
    }
  })
  return cookies
}
export function loadTrackingCookie() {
  const cookieValue = cookie.load('socialMedia')
  if (cookieValue !== undefined) {
    return JSON.parse(cookieValue)
  }
}
export function saveAgeGateCookies(userAge, isDefiniteAge = true) {
  validAgeLimits.forEach((validAge) => {
    if (isDefiniteAge || userAge >= validAge) {
      cookie.save(`is${validAge}`, userAge >= validAge, { path: '/' }) //set cookie to expire when session closes
      // const year = 60 * 60 * 24 * 365;
      // cookie.save(`is${validAge}`, (userAge >= validAge), { path: '/', maxAge: year }, ) //set cookie to expire at set time in the future
    }
  })
}

export function isPreVerified(req) {
  return !!req.query['cdn-verified-age']
}

export function isCookieVerified(ageLimit) {
  const cookies = loadAgeGateCookies()
  return !!cookies[`is${ageLimit}`]
}

export function isVerified(ageLimit, req) {
  return isPreVerified(req) || isCookieVerified(ageLimit)
}
