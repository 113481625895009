import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Img, OnVisible, Markdown, Generic } from '../'
import { Button, ScrollToButton } from 'containers' //import from containers to be able to trigger tracking

import split from '../../lib/split'

import defaultTheme from './fullbleed.css'
import scharfTheme from './scharf.css'
import coldBrewTheme from './coldbrew.css'
import musicPageTheme from './musicpage.css'
import { overrideEntryTitle } from '../helpers'
import FieldsetBN from '../fieldsetBN/FieldsetBN'
import styles from '../fieldsetBN/fieldsetBN.css'

const getTheme = (themeName) => {
  switch (themeName) {
    case 'default':
      return defaultTheme
    case 'scharf':
      return scharfTheme
    case 'coldBrew':
      return coldBrewTheme
    case 'musicPage':
      return musicPageTheme
    default:
      // console.log('THEME is ' + themeName)
      // unknown theme - log error?
      return defaultTheme
  }
}
const config = (styles, headlineClass) => {
  return {
    noText: {
      textClass: ''
    },
    bestNights: {
      textClass: ''
    },
    textBottomLeft: {
      headlineClass: headlineClass || styles.headline,
      textClass: defaultTheme.textLeftBottom,
      textAnimation: defaultTheme.textAnimation
    },
    textMiddleLeft: {
      headlineClass: headlineClass || styles.headline,
      textClass: styles.textCenterLeft,
      textAnimation: defaultTheme.textAnimation
    },
    textMiddleRight: {
      headlineClass: classNames(
        headlineClass || styles.headline,
        defaultTheme.headlineRight
      ),
      textClass: styles.textCenterRight,
      textAnimation: defaultTheme.textAnimation
    },
    textBottomRight: {
      headlineClass: classNames(
        headlineClass || styles.headline,
        defaultTheme.headlineRight
      ),
      textClass: defaultTheme.textRightBottom,
      textAnimation: defaultTheme.textAnimation
    },
    textTopRight: {
      headlineClass: classNames(
        headlineClass || styles.headline,
        defaultTheme.headlineRight
      ),
      textClass: defaultTheme.textRightTop,
      textAnimation: defaultTheme.textAnimation
    },
    textTopLeft: {
      headlineClass: classNames(
        headlineClass || styles.headline,
        defaultTheme.headlineLeft
      ),
      textClass: defaultTheme.textLeftTop,
      textAnimation: defaultTheme.textAnimation
    }
  }
}

const FullBleedImage = ({
  data,
  withCaption,
  imageClass,
  headlineClass,
  textInnerClass,
  animationOffsetPercent,
  contentType,
  reverseMobile,
  theme,
  isHero,
  userData,
  endpoint,
  specialType,
  isArticlePage,
  bestNights,
  ...rest
}) => {
  const styles = getTheme(theme, isHero)
  const {
    image,
    mobileImage,
    headline,
    headingLevel,
    text,
    mode,
    tint,
    deepLink,
    buttons,
    components,
    linkTo,
    hideOrangeBanner
  } = data
  const textClass = config(styles)[mode].textClass
  const textClasses = classNames(styles.text, textClass, {
    [styles.textWithCaption]: withCaption
  })
  const imageClasses = classNames(imageClass, {
    [styles.objectFit]: true,
    [styles.objectFitExtensible]: specialType === 'extensible',
    [styles.tint]: tint && tint.length > 0 && tint === 'tint'
  })
  const titleClass = classNames(
    config(styles, headlineClass)[mode].headlineClass,
    {
      [headlineClass]: headlineClass
    },
    data['hideOrangeBanner'] ? styles.hideOrangeBanner : ''
  )

  const subTitle = (txt) => {
    if (theme === 'musicPage') {
      txt = txt.replace('<p>', '')
      txt = txt.replace('</p>', '')
      txt = txt.slice(0, txt.indexOf(' '))
      return <p className={styles.musicPageSubtitle}>{txt}</p>
    } else {
      txt = txt.replace('<p>', '')
      txt = txt.replace('</p>', '')
      return txt.slice(0, txt.indexOf(' '))
    }
  }
  const title = (title) => {
    let el = title
    if (theme === 'scharf') {
      el = el.replace('<p>', '')
      el = el.replace('</p>', '')
      el = el.replace('\n', '')
      el = el.slice(el.indexOf(' ') + 1, el.length)
      el = split(el, styles.scharfTitle)
    } else if (theme === 'coldBrew') {
      el = el.replace('\n', '')
    } else if (theme === 'musicPage') {
      el = el.replace('<p>', '')
      el = el.replace('</p>', '')
      el = el.slice(el.indexOf(' ') + 1, el.length)
      el = <h1 className={styles.musicPageTitle}>{el}</h1>
    }
    return el
  }
  const normalTitle = (title) => {
    let el = title
    el = el.replace('<p>', `<h1 class="${styles.pageTitle}">`)
    el = el.replace('</p>', '</h1>')
    return el
  }
  const isBestNights =
    theme === 'bestNightsBanner' || theme === 'bestNightsHero' || bestNights

  const isBestNightsBanner = theme === 'bestNightsBanner'

  // if text is aligned to the right, don't show left-aligned orange banner
  const hasTextAlignedToRight =
    mode === 'textBottomRight' ||
    mode === 'textTopRight' ||
    mode === 'textMiddleRight'

  return (
    <OnVisible
      className={classNames(
        isBestNights ? styles.wrapperBN : '',
        isBestNightsBanner ? styles.wrapperBannerBN : '',
        styles.wrapper,
        {
          [styles.wrapperPaddingBottom]:
            mode === 'textBottomLeft' || mode === 'textBottomRight',
          [styles.wrapperReverseMobile]: reverseMobile,
          [styles.wrapperPaddingTop]: mode === 'textTopRight',
          [styles.wrapperExtensible]: specialType === 'extensible'
        }
      )}
      visibleClassName={styles.visible}
      percent={animationOffsetPercent}
    >
      <div
        className={
          isBestNights
            ? styles.objectFitBN
            : '' ||
              imageClasses ||
              classNames(styles.image, {
                [styles.imageGradient]: !(
                  mode === 'textMiddleLeft' ||
                  mode === 'textMiddleRight' ||
                  mode === 'noText'
                )
              })
        }
      >
        {isBestNights === true && (
          <FieldsetBN
            text={!isArticlePage ? text : ''}
            mode={mode}
            contentType={contentType}
            theme={theme}
            isLogo={!bestNights}
            headline={!isArticlePage ? normalTitle(headline) : ''}
            linkTo={linkTo}
            isGridTile={false}
            imgQuote={false}
          />
        )}
        {image && (
          <Img
            src={image.url}
            alt={image.title}
            className={styles.img}
            loadedClassName={styles.imgLoaded}
            mobileImage={mobileImage}
          />
        )}{' '}
        {withCaption && (
          <div className={styles.caption}> {image.description} </div>
        )}{' '}
      </div>{' '}
      {(mode === 'noText' && <div></div>) ||
        (mode !== 'noText' && isBestNights === true && (
          <div className={styles.textWrapperBN}>
            {headline !== '' && (
              <Markdown
                markdownHtml={normalTitle(headline)}
                contentType={contentType}
                theme={theme}
              />
            )}
            <Markdown
              markdownHtml={text}
              contentType={contentType}
              theme={theme}
            />
          </div>
        )) || (
          <div
            className={classNames(
              mode === 'textBottomLeft' || mode === 'textBottomRight'
                ? styles.textAnimationBottom
                : '',
              isBestNights && !isArticlePage ? styles.hideText : '',
              isBestNights ? styles.textBestNights : '',
              config(styles)[mode].textAnimation
            )}
          >
            <div
              className={classNames(
                isBestNights ? styles.textBN : '',
                textClasses
              )}
            >
              <div
                className={classNames(
                  isBestNights ? styles.textInnerBN : '',
                  textInnerClass || styles.textInner
                )}
              >
                {headline && headline.trim() && (
                  <div className={titleClass}>
                    {(headingLevel &&
                      headingLevel !== 'Original default' &&
                      overrideEntryTitle({
                        headline,
                        headingLevel,
                        className: styles.pageTitle
                      })) ||
                      ((theme === 'scharf' ||
                        theme === 'coldBrew' ||
                        (theme === 'musicPage' && isHero)) && (
                        <div>
                          <Markdown
                            inline
                            markdownHtml={title(headline)}
                            className={'noOrangeBanner'}
                          />
                        </div>
                      )) || (
                        <div>
                          <Markdown
                            inline
                            markdownHtml={normalTitle(headline)}
                            className={'noOrangeBanner'}
                          />
                        </div>
                      )}
                  </div>
                )}{' '}
                <div className={styles.textMarkdown}>
                  <Markdown
                    markdownHtml={text}
                    contentType={contentType}
                    theme={theme}
                    className="noOrangeBanner"
                  />{' '}
                  {deepLink && (
                    <div className={defaultTheme.scrollButton}>
                      {/* maintaining this for legacy reasons, otherwise, all buttons should be rendered through the function below */}
                      <ScrollToButton
                        data={deepLink}
                        theme={theme}
                        trackingEvent={deepLink.trackingEvent}
                      />
                    </div>
                  )}
                  {buttons && buttons.length > 0 && (
                    <div
                      className={
                        mode !== 'textBottomLeft' ? styles.scrollButton : ''
                      }
                    >
                      {buttons.map((btn) => {
                        if (btn.contentType === 'scrollToButton') {
                          return (
                            <ScrollToButton
                              key={btn.id}
                              data={btn}
                              theme={theme}
                              trackingEvent={btn.trackingEvent}
                            />
                          )
                        } else if (btn.contentType === 'button') {
                          return (
                            <Button
                              key={btn.id}
                              href={btn.link}
                              className={classNames({
                                [styles.secondaryBtn]:
                                  btn.style === 'secondary',
                                [styles.primaryBtn]: btn.style === 'primary'
                              })}
                              trackingEvent={btn.trackingEvent}
                            >
                              {btn.text}
                            </Button>
                          )
                        }
                      })}
                    </div>
                  )}
                </div>{' '}
                {components && components.length > 0 && (
                  <div className={styles.subComponentsContainer}>
                    {components.map((component) => (
                      <Generic
                        components={component}
                        key={component.id}
                        config={rest.config}
                        userData={userData}
                        endpoint={endpoint}
                        theme={theme}
                        force
                        // alignRight={config[mode].imagePosition === 'right'}
                      />
                    ))}
                  </div>
                )}
              </div>{' '}
            </div>{' '}
          </div>
        )
      // end
      }{' '}
    </OnVisible>
  )
}

FullBleedImage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string,
    mode: PropTypes.oneOf(Object.keys(config(styles)))
  }).isRequired,
  withCaption: PropTypes.bool,
  headlineClass: PropTypes.string,
  textInnerClass: PropTypes.string,
  imageClass: PropTypes.string,
  animationOffsetPercent: PropTypes.number,
  contentType: PropTypes.string,
  reverseMobile: PropTypes.bool,
  mode: PropTypes.string,
  specialType: PropTypes.string,
  isHero: PropTypes.bool,
  isArticlePage: PropTypes.bool,
  bestNights: PropTypes.bool
}

FullBleedImage.defaultProps = {
  data: {
    image: {
      url: '',
      title: ''
    },
    headline: '',
    text: '',
    mode: 'noText',
    linkTo: ''
  },
  withCaption: false,
  headlineClass: null,
  textInnerClass: null,
  imageClass: null,
  animationOffsetPercent: 20,
  contentType: '',
  reverseMobile: false,
  theme: 'default',
  mode: 'normal',
  specialType: 'normal',
  styles: null,
  isHero: false,
  isArticlePage: false,
  bestNights: false
}
export default FullBleedImage
