/* global window, document */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { MetaData } from 'components'
import { Router } from 'containers'

import styles from './app.css'

const App = ({ locale, shareImage }) => {
  // TODO: BERJMWEBSZ-273 get from contentful locale config
  const brandName = 'Jägermeister'
  // TODO: use jagermeister.com or jaegermeister.com, as
  // returned by getDomainFromLocaleConfig in domain.js
  // TODO: staging etc. would have a different canonical url, though we should
  // have a way of ensuring these other environments are not indexed by Google
  const canonicalBase = `//www.jagermeister.com/${locale}`
  return (
    <div className={styles.app}>
      <Helmet titleTemplate={`%s | ${brandName}`} defaultTitle={brandName} />
      <MetaData
        theme="default"
        lang={locale}
        brandName={brandName}
        canonicalBase={canonicalBase}
        shareImage={shareImage}
      />

      <Router />
    </div>
  )
}

App.propTypes = {
  content: PropTypes.object.isRequired,
  locale: PropTypes.string,
  shareImage: PropTypes.string
}

App.defaultProps = {
  content: {},
  locale: 'en',
  shareImage: ''
}

export default App
