import React, { useRef, useEffect } from 'react'
import debounce from 'lodash.debounce'
import classNames from 'classnames'
import { VideoPlayer, Markdown } from '../'
import { setHeight } from './helper'

import styles from './videocarousel.css'

const VideoSlide = ({ slideIndex, data, videoJsOptions, idx, bestNights }) => {
  const ref = useRef()

  //calculate component height to set the correct height for the carousel in case a component was specially long
  const calcHeight = debounce((e) => {
    if (
      ref.current &&
      ref.current.clientHeight > 0 &&
      e.target.innerWidth < 1025
    ) {
      setHeight(ref.current.clientHeight + 100, true)
    }
  }, 150)

  const calcHightWithCheck = () => {
    if (
      ref.current &&
      ref.current.clientHeight > 0 &&
      window.innerWidth < 1025
    ) {
      setHeight(ref.current.clientHeight + 100, true)
    }
  }

  useEffect(() => {
    calcHightWithCheck()
    window.addEventListener('resize', calcHeight)
    return () => {
      window.removeEventListener('resize', calcHeight)
    }
  }, [])

  useEffect(() => {
    calcHightWithCheck()
  }, [slideIndex])
  const BN = bestNights
  return (
    <div className={styles.content} ref={ref}>
      {data.headline && (
        <h1
          className={classNames(BN ? styles.headlineBN : '', styles.headline, {
            [styles.contentTransition]: slideIndex === idx,
            [styles.hiddenContent]: slideIndex !== idx
          })}
        >
          {data.headline}
        </h1>
      )}
      <div className={styles.video}>
        <VideoPlayer
          videoId={data.id}
          playsinline={true}
          posterImage={(data && data.image && data.image.url) || ''}
          posterMobile={
            (data && data.imageMobile && data.imageMobile.url) || ''
          }
          tracking={() => {}}
          options={JSON.stringify(videoJsOptions)}
          triggerPause={slideIndex !== idx}
        />
      </div>
      <div
        className={classNames(styles.text, {
          [styles.contentTransition]: slideIndex === idx,
          [styles.hiddenContent]: slideIndex !== idx
        })}
      >
        <Markdown markdownHtml={data.text} />
      </div>
    </div>
  )
}

export default VideoSlide
