import React from 'react'
import PropTypes from 'prop-types'
import {
  HeroComponent,
  HalfWidthPng,
  FullBleedImage,
  VideoFeature,
  MetaData
} from 'components'
import { StoryContainer, Recommendations } from 'containers'

import styles from './history.css'

const History = ({ content, trackingVideo, contentType }) => (
  <main className={styles.history} role="main" id="mainContent">
    <MetaData metadata={content.metadata} />
    {content.entryAnimation && content.entryAnimation.video && (
      <StoryContainer video={content.entryAnimation.video} />
    )}
    {content.hero && <HeroComponent hero={content.hero} />}

    {content.halfWidthPngOne && (
      <div className={styles.halfWidthPngOneWrapper}>
        <HalfWidthPng
          contentType={contentType}
          data={content.halfWidthPngOne}
          historyOne
          withCaption
        />
      </div>
    )}

    {content.fullBleedImageOne && (
      <FullBleedImage
        data={content.fullBleedImageOne}
        withCaption
        headlineClass={styles.headlineOne}
        textInnerClass={styles.textInnerOne}
        contentType={contentType}
      />
    )}

    {content.fullBleedImageTwo && (
      <FullBleedImage
        data={content.fullBleedImageTwo}
        imageClass={styles.imageHistoryTwo}
        contentType={contentType}
      />
    )}

    {content.halfWidthPngTwo && (
      <div className={styles.halfWidthPngTwoWrapper}>
        <HalfWidthPng
          contentType={contentType}
          data={content.halfWidthPngTwo}
          historyTwo
          contentWidthClass={styles.halfWidthPngTwoText}
          imageClass={styles.halfWidthPngTwoImage}
        />
      </div>
    )}

    {content.halfWidthPngThree && (
      <div className={styles.halfWidthPngThree}>
        <HalfWidthPng
          contentType={contentType}
          data={content.halfWidthPngThree}
          historyThree
          textToTheRight
          reverseMobile
        />
      </div>
    )}

    {content.videoComponent && (
      <VideoFeature
        data={content.videoComponent}
        headlineClass={styles.headlineTwo}
        tracking={trackingVideo}
        contentType={contentType}
      />
    )}

    {content.recommendations && (
      <Recommendations recommendations={content.recommendations} />
    )}
  </main>
)

History.defaultProps = {
  content: {},
  trackingVideo: () => {},
  contentType: ''
}
History.propTypes = {
  content: PropTypes.object.isRequired,
  trackingVideo: PropTypes.func,
  contentType: PropTypes.string
}
export default History
