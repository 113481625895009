import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { trackPageView } from 'actions/analytics'
import Button from 'components/button/Button'

function mapDispatchToProps(dispatch, ownProps) {
  const { trackingEvent, trackingEventType, trackingEventDetail } = ownProps
  return {
    track: () => {
      if (trackingEvent) {
        dispatch(
          trackPageView({
            event: 'Button',
            eventType: trackingEvent.eventType,
            eventDetail: trackingEvent.eventDetail
          })
        )
      } else {
        dispatch(
          trackPageView({
            event: 'Button',
            eventType: trackingEventType,
            eventDetail: trackingEventDetail
          })
        )
      }
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Button))
