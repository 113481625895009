import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import debounce from 'lodash.debounce'

import styles from './iframe.css'

class Iframe extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    bestNights: PropTypes.bool
  }
  constructor(...rest) {
    super(...rest)
    this.dynamicIframe = React.createRef()
    this.state = {
      dragging: false
    }
  }

  getOriginFromUrl(url) {
    const pathArr = url.split('/')
    const protocol = pathArr[0]
    const host = pathArr[2]
    return `${protocol}//${host}`
  }

  getMessage = debounce((message) => {
    if (
      this.props.data.url &&
      this.getOriginFromUrl(this.props.data.url) === message.origin
    ) {
      if (
        typeof message.data === 'string' ||
        typeof message.data === 'number'
      ) {
        this.dynamicIframe.current.style.height = `${message.data}px`
      }
    }
  }, 500)

  componentDidMount() {
    const { data } = this.props
    if (data.type === 'dynamic') {
      window.addEventListener('message', this.getMessage, false)
    }
  }

  componentWillUnmount() {
    const { data } = this.props
    if (data.type === 'dynamic') {
      window.removeEventListener('message', this.getMessage)
    }
  }

  render() {
    const iframe = this.props.data
    const scroll = this.state.dragging
    const scrllClass = scroll ? styles.scroll : ''
    const type = iframe.type ? iframe.type : 'default'
    const css = {
      scroll: styles.scroll,
      fixed: styles.fixed,
      default: styles.default,
      responsive: styles.responsive,
      video: styles.videoCompact
    }
    const BN = this.props.bestNights
    const renderIframe = (width, height, classes) => {
      if (type.indexOf('fullPage') !== -1) {
        return (
          <iframe
            width={width}
            height={height}
            frameBorder="no"
            scrolling="yes"
            src={iframe.url}
            onTouchEnd={this.onTouchEnd}
            allowFullScreen="allowfullscreen"
            title="fullPage"
            allow="geolocation"
          />
        )
      } else if (type.indexOf('compact') !== -1) {
        return (
          <div className={classNames(BN ? styles.frameBN : '', styles.compact)}>
            <iframe
              width={width}
              height={height}
              frameBorder="no"
              scrolling="yes"
              src={iframe.url}
              onTouchEnd={this.onTouchEnd}
              allowFullScreen="allowfullscreen"
              title="compact"
              allow="geolocation"
            />
          </div>
        )
      } else if (type.indexOf('dynamic') !== -1) {
        return (
          <div className={classes}>
            <div className={styles.iframeLayout}>
              <iframe
                onLoad={(e) => {
                  e.currentTarget.contentWindow.postMessage(
                    'loaded',
                    `${this.getOriginFromUrl(iframe.url)}`
                  )
                }}
                ref={this.dynamicIframe}
                id="dynamicIframe"
                width={width}
                height={height}
                frameBorder="no"
                scrolling="no"
                src={iframe.url}
                onTouchEnd={this.onTouchEnd}
                allowFullScreen="allowfullscreen"
                title="dynamic"
                allow="geolocation"
              />
            </div>
          </div>
        )
      }
      return (
        <div className={classes}>
          <div className={styles.iframeLayout}>
            <iframe
              width={width}
              height={height}
              frameBorder="no"
              scrolling="yes"
              src={iframe.url}
              onTouchEnd={this.onTouchEnd}
              allowFullScreen="allowfullscreen"
              title="fixed"
              allow="geolocation"
            />
          </div>
        </div>
      )
    }
    const classes = classNames(BN ? styles.backgroundBN : '', styles.iframeContainer, css[type], {
      [css.scroll]: scrllClass,
      [styles.iframeDefaultBackground]:
        iframe.background && iframe.background === 'default'
    })
    const width = iframe.width ? iframe.width : '100%'

    const height = iframe.height ? iframe.height : '100%'
    return <div>{renderIframe(width, height, classes)}</div>
  }
}
Iframe.propTypes = {
  data: PropTypes.object,
  bestNights: PropTypes.bool
}

Iframe.defaultProps = {
  data: {},
  bestNights: false
}
export default Iframe
