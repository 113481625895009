import React from 'react'
import PropTypes from 'prop-types'

import { Markdown, Overlay } from '../'

import styles from './connectButton.css'

const Connect = ({ connect, isOpen, close, tracking }) => {
  if (!connect || !connect.button || !connect.images) {
    return <div data-error="no-connnect" />
  }
  const icon = connect.button.url
  const link = connect.link
  const cta = connect.button.description
  const connectContent = connect.images.text
  const images = connect.images.images.map(item => (
    <div className={styles.images} key={item.id}><img src={item.url} alt={item.title} /></div>
  ))
  const trackingObj = {
    cta,
    url: link[0]
  }
  return (
    <Overlay
      isOpen={isOpen}
      close={close}
      direction="right"
      desktopCloseBtn
    >
      <div className={styles.overlayContainer}>
        <div className={styles.overlayStructure}>
          <div className={styles.connectImages}>
            {images}
          </div>
          <div className={styles.overlayContent}>
            <Markdown markdownHtml={connectContent} />
            <a href={link} target="_blank" onClick={() => tracking(trackingObj)} className={styles.overlayCta} rel="noopener noreferrer"><span style={{ backgroundImage: `url(${icon})` }} />{cta}</a>
          </div>
        </div>
      </div>
    </Overlay>
  )
}
Connect.propTypes = {
  isOpen: PropTypes.bool,
  connect: PropTypes.object,
  close: PropTypes.func.isRequired,
  tracking: PropTypes.func
}

Connect.defaultProps = {
  isOpen: false,
  connect: {},
  tracking: () => {}
}
export default Connect
