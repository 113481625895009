import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import {
  Accordion,
  AccordionItem,
  ArtistFeatured,
  ArtistSideBySide,
  AudioClipPlayer,
  DownloadButton,
  DownloadTracks,
  Event,
  EventDetail,
  EventFeaturedLong,
  EventList,
  FullBleedImage,
  Gallery,
  GallerySwiper,
  HalfWidthPng,
  HalfWidthPngChart,
  HorizontalContainer,
  Iframe,
  Markdown,
  Markup,
  Prost,
  Quote,
  Title,
  Tile,
  VideoFeature,
  ScrollToView,
  Carousel,
  HalfWidthMedia,
  HalfWidthVideo,
  BNTitleText,
  ThreeImagesQuote,
  MailchimpForm
} from 'components'
import {
  StoryContainer,
  ArtistBiogrpahy,
  Grid,
  FormContainer,
  VideoCarousel,
  IntegratedFormContainer,
  SalesforceFormContainer
} from 'containers'

import styles from './generic.css'
import BestNightsBanner from '../bestnightsbanner/bestnightsbanner'

class Generic extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false
    }
  }

  columnsRender = (component, trackingVideo, classes, contentType, theme) => (
    <div
      className={classNames(styles.column, {
        [styles[theme]]: theme
      })}
    >
      {' '}
      {component.components &&
        component.components.map((d) =>
          this.renderComponents(d, trackingVideo, classes, contentType, theme)
        )}{' '}
    </div>
  )
  renderComponents = (
    components,
    trackingVideo,
    classes,
    contentType,
    theme,
    force
  ) =>
    (components.contentType === 'title' && (
      <Title
        title={components.title}
        headingLevel={components.headingLevel}
        key={components.id}
        {...(force && { theme: theme })}
      />
    )) ||
    (components.contentType === 'markupComponent' && (
      <Markup key={components.id} data={components} />
    )) ||
    (components.contentType === 'fullWidthArticleTextComponent' && (
      <Markdown
        key={components.id}
        markdownHtml={components.text}
        theme="article"
        bestNights={this.props.bestNights}
      />
    )) ||
    (components.contentType === 'accordion' && (
      <Accordion
        key={components.id}
        data={components}
        contentType={contentType}
      />
    )) ||
    (components.contentType === 'accordionItem' && (
      <AccordionItem
        key={components.id}
        data={components}
        contentType={contentType}
      />
    )) ||
    (components.contentType === 'artistBiographyComponent' && (
      <ArtistBiogrpahy
        key={components.id}
        data={components}
        contentType={contentType}
      />
    )) ||
    (components.contentType === 'featuredEventComponent' && (
      <EventFeaturedLong key={components.id} data={components} />
    )) ||
    (components.contentType === 'fullBleedImageComponent' &&
      components.image && (
        <div key={components.id}>
          <FullBleedImage
            key={components.id}
            data={components}
            imageClass={styles.fullBleedImage}
            contentType={contentType}
            bestNights={this.props.bestNights}
            {...(force && { theme: theme })}
          />{' '}
        </div>
      )) ||
    (components.contentType === 'fullBleedImageGenericComponent' &&
      components.image && (
        <div key={components.id}>
          <FullBleedImage
            key={components.id}
            data={components}
            imageClass={styles.fullBleedImage}
            contentType={contentType}
            config={this.props.config}
            userData={this.props.userData}
            endPoint={this.props.endPoint}
            specialType="extensible"
            {...(force && { theme: theme })}
          />{' '}
        </div>
      )) ||
    (components.contentType === 'genericFullBleedComponent' &&
      components.image && (
        <div key={components.id}>
          <FullBleedImage
            key={components.id}
            data={components}
            imageClass={styles.fullBleedImage}
            contentType={contentType}
            config={this.props.config}
            userData={this.props.userData}
            endPoint={this.props.endPoint}
            {...(force && { theme: theme })}
          />{' '}
        </div>
      )) ||
    (components.contentType === 'prostComponent' && (
      <Prost
        key={components.id}
        toast={components.toast}
        freeze={components.freeze}
        frosted={components.frosted}
        temperatureInF={components.temperatureInF}
      />
    )) ||
    (components.contentType === 'quoteComponent' && (
      <Quote
        key={components.id}
        quote={components.quote}
        bestNights={this.props.bestNights}
      />
    )) ||
    (components.contentType === 'iframe' && (
      <div className={styles.genericIframe} key={components.id}>
        <Iframe data={components} bestNights={this.props.bestNights} />{' '}
      </div>
    )) ||
    (components.contentType === 'form' && (
      <div className={styles.content} key={components.id}>
        <FormContainer
          data={components}
          toggle={this.toggleContactText}
          endPoint={this.props.endPoint}
        />{' '}
      </div>
    )) ||
    (components.contentType === 'integratedForms' && (
      <div className={styles.content} key={components.id}>
        <IntegratedFormContainer
          data={components}
          config={this.props.config}
          integrationsFormEndpoint={this.props.integrationsFormEndpoint}
          complaintFormEndpoint={this.props.complaintFormEndpoint}
        />{' '}
      </div>
    )) ||
    (components.contentType === 'salesForceForm' && (
      <div className={styles.content} key={components.id}>
        <SalesforceFormContainer
          data={components}
          config={this.props.config}
          toggle={this.toggleContactText}
          salesforceSyncEndpoint={this.props.salesforceSyncEndpoint}
        />
      </div>
    )) ||
    (components.contentType === 'artistFeaturedComponent' && (
      <div className={classes} key={components.id}>
        <ArtistFeatured data={components} />{' '}
      </div>
    )) ||
    (components.contentType === 'artistSideBySideComponent' && (
      <div className={classes} key={components.id}>
        <ArtistSideBySide data={components} />{' '}
      </div>
    )) ||
    (components.contentType === 'eventComponent' && (
      <div className={classes} key={components.id}>
        <Event event={components} />{' '}
      </div>
    )) ||
    (components.contentType === 'eventDetailComponent' && (
      <div className={classes} key={components.id}>
        <EventDetail data={components} />{' '}
      </div>
    )) ||
    (components.contentType === 'eventListComponent' && (
      <div className={classes} key={components.id}>
        <EventList data={components} key={components.id} />{' '}
      </div>
    )) ||
    (components.contentType === 'contentComponent' && (
      <div
        className={classNames(classes, styles.blackBackgroundBN)}
        key={components.id}
      >
        <Markdown
          markdownHtml={components.text}
          contentType={contentType}
          backgroundColor={components.backgroundColor}
          theme={theme || components.hideBanners ? 'bannerLessLegal' : 'legal'}
        />{' '}
      </div>
    )) ||
    (components.contentType === 'galleryComponent' &&
      ((components.galleryType === 'swiper' && (
        <div className={classes} key={components.id}>
          <GallerySwiper data={components} />{' '}
        </div>
      )) || (
        <div className={classes} key={components.id}>
          <Gallery data={components} />{' '}
        </div>
      ))) ||
    (components.contentType === 'gridComponent' && (
      <div className={classes} key={components.id}>
        <Grid
          tiles={components.tiles}
          isCompact={components.isCompact}
          loadMoreThreshold={components.loadMoreThreshold}
          text={components.text}
          background={components.background}
        />{' '}
      </div>
    )) ||
    (components.contentType === 'videoComponent' &&
      ((components.video && components.video.url) || components.youtubeId) && (
        <div key={components.id}>
          <VideoFeature
            data={components}
            tracking={trackingVideo}
            contentType={contentType}
            theme={theme || 'default'}
            bestNights={this.props.bestNights}
          />{' '}
        </div>
      )) ||
    (components.contentType === 'audioClipPlayerComponent' && (
      <div key={components.id}>
        <AudioClipPlayer data={components} contentType={contentType} />{' '}
      </div>
    )) ||
    (components.contentType === 'downLoadTracks' && (
      <div key={components.id} className={styles.downloadTracks}>
        <DownloadTracks data={components} />{' '}
      </div>
    )) ||
    (components.contentType === 'column' &&
      this.columnsRender(
        components,
        trackingVideo,
        classes,
        contentType,
        theme
      )) ||
    (['halfWidthPngComponent', 'imageText'].includes(components.contentType) &&
      (components.image || components.video) && (
        <HalfWidthPng
          data={components}
          contentType={contentType}
          key={components.id}
          theme={theme || 'default'}
          bestNights={this.props.bestNights}
        />
      )) ||
    (components.contentType === 'halfWidthPngGenericComponent' &&
      components.image && (
        <HalfWidthPng
          data={components}
          contentType={components.contentType}
          key={components.id}
          theme={theme || 'default'}
          config={this.props.config}
          userData={this.props.userData}
          endPoint={this.props.endPoint}
          textLongest
          reverseMobile
        />
      )) ||
    (components.contentType === 'halfWidthPngComponentChart' &&
      components.image && (
        <HalfWidthPngChart
          data={components}
          contentType={contentType}
          key={components.id}
        />
      )) ||
    (components.contentType === 'horizontalContainer' && (
      <HorizontalContainer data={components} key={components.id} />
    )) ||
    (components.contentType === 'entryAnimation' && (
      <StoryContainer video={components.video} key={components.id} />
    )) ||
    (components.contentType === 'tileComponent' && (
      <div className={styles.tile}>
        <Tile
          title={components.title}
          caption={components.caption}
          image={components.image}
          linkTo={
            components.link && (components.link.url || components.link.route)
          }
          tint={components.tint}
          tracking={(e) => tracking(e, 'tile')}
        />
      </div>
    )) ||
    (components.contentType === 'downLoadButton' && (
      <div className={styles.downloadContainer}>
        <DownloadButton
          trackingEventType="Download marketing code pdf"
          trackingEventDetail="PDF"
          key={components.id}
          href={(components && components.button.url) || '#'}
        >
          {' '}
          {components.button.title}{' '}
        </DownloadButton>{' '}
      </div>
    )) ||
    (components.contentType === 'scrollToView' && (
      <ScrollToView
        components={components.scrollComponents}
        link={components.link}
        idx={components.id}
        theme={theme}
        config={this.props.config}
        userData={this.props.userData}
        autoScroll={components.autoScroll}
        endPoint={this.props.endPoint}
        salesforceSyncEndpoint={this.props.salesforceSyncEndpoint}
      />
    )) ||
    (components.contentType === 'carousel' && (
      <Carousel
        component={components}
        config={this.props.config}
        theme={theme}
      />
    )) ||
    (components.contentType === 'halfWidthMediaComponent' && (
      <HalfWidthMedia component={components} />
    )) ||
    (components.contentType === 'videoCarousel' && (
      <VideoCarousel
        key={components.id}
        component={components}
        bestNights={this.props.bestNights}
      />
    )) ||
    (components.contentType === 'halfWidthVideoComponent' && (
      <HalfWidthVideo
        key={components.id}
        component={components}
        trackingVideo={trackingVideo}
      />
    )) ||
    (components.contentType === 'titleTextComponent' && (
      <BNTitleText key={components.id} component={components} />
    )) ||
    (components.contentType === 'bestNightsBanner' && (
      <BestNightsBanner
        key={components.id}
        component={components}
        context={'components'}
      />
    )) ||
    (components.contentType === 'threeImagesQuote' && (
      <ThreeImagesQuote
        key={components.id}
        component={components}
        context={'components'}
      />
    )) ||
    (components.contentType === 'mailchimpForm' && (
      <MailchimpForm
        key={components.id}
        claim={components.claim}
        disclaimer={components.disclaimer}
        thankYouMessage={components.thankYouMessage}
        errorMessage={components.errorMessage}
        userAlreadyExistsErrorMessage={components.userAlreadyExistsErrorMessage}
        endpoint={this.props.bestNightsNewsletterEndpoint}
        context={'components'}
      />
    ))

  render() {
    const { components, trackingVideo, contentType, theme, force } = this.props
    const classes = classNames(styles.content, {
      [styles.open]: this.state.showTextg
    })
    return this.renderComponents(
      components,
      trackingVideo,
      classes,
      contentType,
      theme,
      force
    )
  }
}
Generic.propTypes = {
  components: PropTypes.object.isRequired,
  trackingVideo: PropTypes.func,
  endPoint: PropTypes.string,
  contentType: PropTypes.string,
  theme: PropTypes.string,
  bestNights: PropTypes.bool
}
Generic.defaultProps = {
  endPoint: '',
  contentType: '',
  theme: '',
  bestNights: false,
  trackingVideo: () => {}
}
export default Generic
