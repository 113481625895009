// Standard tile used by the grid

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './tweet.css'
import QuoteIcon from 'svgs/quote/quote'
import classNames from 'classnames/bind'
import { twitter as TwitterIcon } from 'svgs/socialIcons/SocialIcons'
import { ITEMS as GRID_ITEMS } from '../grid/Grid'


class Tweet extends Component {
  static defaultProps = {
    url: null,
    profileImage: null,
    isBrandAccount: false,
    tracking: () => {},
    gridItemConfig: null
  }

  static propTypes = {
    url: PropTypes.string,
    handle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isBrandAccount: PropTypes.bool,
    profileImage: PropTypes.object,
    tracking: PropTypes.func,
    gridItemConfig: PropTypes.oneOf(Object.keys(GRID_ITEMS).map(key => GRID_ITEMS[key]))
  }
  renderTweetContents() {
    return (<div>
      {this.props.profileImage &&
        <img
          className={styles.profileImage}
          src={this.props.profileImage.url}
          alt={this.props.profileImage.title}
        />}
      <div className={styles.quotemark}><QuoteIcon /></div>
      <div className={styles.icon}><TwitterIcon pathClass={styles.path} /></div>
      <h3 className={styles.handle}>@ {this.props.handle.replace('@', '')}</h3>
      {this.props.content &&
        <blockquote className={styles.content}>{this.props.content}</blockquote>}
    </div>)
  }
  render() {
    let tileStyle

    if (this.props.gridItemConfig) {
      switch (this.props.gridItemConfig) {
        case (GRID_ITEMS.SMALLEST):
          tileStyle = styles.tileSmallest
          break
        case (GRID_ITEMS.SMALL):
          tileStyle = styles.tileSmall
          break
        case (GRID_ITEMS.MEDIUM):
          tileStyle = styles.tileMedium
          break
        case (GRID_ITEMS.LARGE):
          tileStyle = styles.tileLarge
          break
        case (GRID_ITEMS.FULL):
          tileStyle = styles.tileFull
          break
        default:
          tileStyle = styles.tile
      }
    }

    tileStyle = classNames(tileStyle, { [styles.branded]: this.props.isBrandAccount })

    if (this.props.url) {
      return (
        <a
          href={this.props.url}
          target="_blank"
          rel="noopener"
          className={tileStyle}
          onClick={this.props.tracking}
        >
          {this.renderTweetContents()}
        </a>)
    }
    return (
      <div className={tileStyle}>
        {this.renderTweetContents()}
      </div>)
  }
}

export default Tweet
