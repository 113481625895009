import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Navigation } from '../'
import Input from './Input'
import VerificationError from './VerificationError'
import * as helper from './helper'

import styles from './agegate.css'

const defaultState = {
  DD: '',
  MM: '',
  YYYY: '',
  isValidDate: false,
  isLegalDate: false,
  blockSubmit: false,
  hasVerified: false,
  showIntro: true
}

class AgeGate extends Component {
  constructor(props) {
    super(props)
    this.placeholders = {
      day: 'DD',
      month: 'MM',
      year: 'YYYY'
    }
    this.state = defaultState
    this.trackingUpdate = false
  }
  componentWillMount() {
    this.inputRefs = {}
  }
  componentDidMount() {
    this.runIntro(1200)
    if (this.props.onMount) this.props.onMount()
  }
  componentDidUpdate() {
    if (this.props.onUpdate && !this.trackingUpdate) {
      this.props.onUpdate()
      this.trackingUpdate = true
    }
  }
  getCurrentAge() {
    const { DD, MM, YYYY } = this.state
    const date = new Date(YYYY, MM - 1, DD)
    if (!helper.isValidDate(date)) return 0
    return helper.getAge(date)
  }

  setValue = (id, value) => {
    this.setState({ [id]: value, blockSubmit: false }, () =>
      this.validateFullDate(true)
    )
  }

  clearValues = () => {
    this.setState(defaultState, () => {
      this.validateFullDate(true)
      this.runIntro(500)
    })
  }

  // mobile start
  handleChangeDateInput = (e) => {
    const DD = helper.pad(new Date(e.target.value).getDate(), 2)
    const MM = helper.pad(new Date(e.target.value).getMonth() + 1, 2)
    const YYYY = new Date(e.target.value).getFullYear().toString()

    this.setState({ DD, MM, YYYY, blockSubmit: true }, () =>
      this.validateFullDate()
    )
  }

  handleClickButton = () => {
    const { DD, MM, YYYY } = this.state
    const dateValue = new Date(YYYY, MM - 1, DD)
    this.setState({ blockSubmit: false }, () => {
      this.verifyAge(dateValue)
    })
  }

  isValidDayOfMonth() {
    const { DD, MM, YYYY } = this.state
    const dayOrOne = DD || 1
    const yearOrLeapYear = !YYYY || isNaN(YYYY) || !YYYY.trim() ? 400 : YYYY
    let month = null
    if (MM) month = parseInt(MM, 10) - 1
    const date = new Date(yearOrLeapYear, month, dayOrOne)
    return date.getDate() === parseInt(DD, 10)
  }
  isValidYearInput() {
    const { DD, MM, YYYY } = this.state
    const dayOrOne = DD || 1
    let month = null
    if (MM) month = parseInt(MM, 10) - 1
    const date = new Date(YYYY, month, dayOrOne)
    return date.getFullYear() === parseInt(YYYY, 10)
  }

  isVerificationError() {
    return (
      !this.state.blockSubmit &&
      !this.state.isLegalDate &&
      this.state.isValidDate
    )
  }
  jumpToNext(id) {
    const formatOrder = this.props.config.format.split('-')
    const nextIndex = formatOrder.indexOf(id) + 1
    if (formatOrder.length === nextIndex) return // do nothing if last field
    const nextId = formatOrder[nextIndex]
    this.inputRefs[nextId].focus()
  }
  jumpToPrev(id) {
    const formatOrder = this.props.config.format.split('-')
    const prevIndex = formatOrder.indexOf(id) - 1
    if (prevIndex >= 0) {
      const prevId = formatOrder[prevIndex]
      this.inputRefs[prevId].focus()
    }
  }

  runIntro(timeout) {
    const that = this
    setTimeout(() => {
      that.setState({
        showIntro: false
      })
      that.inputRefs[that.props.config.format.split('-')[0]].focus()
    }, timeout)
  }
  tryUnlock() {
    if (this.state.isLegalDate) {
      const age = this.getCurrentAge()
      if (this.props.onUnlock) this.props.onUnlock()
      setTimeout(() => {
        this.props.unlock(age)
      }, 500)
    }
  }

  validateFullDate() {
    const { DD, MM, YYYY } = this.state
    const date = new Date(YYYY, MM - 1, DD)
    const dateValid =
      helper.isValidDay(DD) &&
      helper.isValidMonth(MM) &&
      helper.isValidYear(YYYY) &&
      this.isValidDayOfMonth() &&
      helper.isValidDate(date) &&
      this.isValidYearInput()
    let that = this
    // delay for Accessibility purposes
    setTimeout(() => {
      console.log('validated')
      that.setState(
        {
          isValidDate: dateValid
        },
        () => !that.state.blockSubmit && that.verifyAge(date)
      )
    }, 500)

    return dateValid
  }
  validateInput(id) {
    const { DD, MM, YYYY } = this.state
    switch (id) {
      case 'DD':
        return helper.isValidDay(DD) && this.isValidDayOfMonth()
      case 'MM':
        return helper.isValidMonth(MM)
      case 'YYYY':
        return helper.isValidYear(YYYY) && this.isValidYearInput()
      default:
        return null
    }
  }

  verifyAge(dateValue) {
    const isLegal =
      this.state.isValidDate &&
      dateValue.getFullYear() >= 1900 &&
      helper.getAge(dateValue) >= this.props.config.legalAge
    this.setState(
      {
        isLegalDate: isLegal
      },
      this.tryUnlock
    )
  }
  // mobile end
  render() {
    const formatOrder = this.props.config.format.split('-')
    const { content, navigation, globalText } = this.props
    if (!content) {
      return <div data-error="No content for age gate" />
    }
    return (
      <div className={styles.wrapperNeededForIe11}>
        <div
          className={classNames({
            [styles.wrapper]: true,
            [styles.wrapperDark]: this.isVerificationError()
          })}
        >
          <main className={styles.content}>
            <div className={styles.column}>
              <div
                className={classNames(styles.countrySelector, {
                  [styles.countrySelectorBehindError]: this.isVerificationError()
                })}
              >
                {this.props.countrySelectorButton}
              </div>

              {/* Instructions: */}
              <div className={styles.instructions}>
                <h1 className={styles.instructionsText}>
                  {content.instructionsText.replace(/(<([^>]+)>)/gi, '')}
                </h1>
              </div>

              {/* Desktop Inputs: */}
              <form className={styles.desktop}>
                <fieldset className={styles.formFieldset}>
                  <legend className={styles.instructionsHeadline}>
                    {content.instructionsHeadline}
                  </legend>
                  {formatOrder.map((id) => (
                    <Input
                      key={id}
                      id={id}
                      value={this.state[id]}
                      maxLength={id.length} // TODO where to get this from?
                      label={content[id].label}
                      placeholder={content[id].placeholder}
                      ariaLabel={content[id].ariaLabel}
                      next={() => {
                        this.jumpToNext(id)
                      }}
                      prev={() => {
                        this.jumpToPrev(id)
                      }}
                      setInputRef={(element) => {
                        this.inputRefs[id] = element
                      }}
                      setValue={this.setValue}
                      showError={!this.validateInput(id)}
                      errorMessage={content[id].error}
                      showVerificationError={
                        this.state.isValidDate && !this.state.isLegalDate
                      }
                      showIntro={this.state.showIntro}
                    />
                  ))}
                </fieldset>
              </form>

              {/* Mobile input and button: */}
              {/* <div className={styles.mobile}>
                <div className={styles.mobileDatePicker}>
                  <div className={styles.mobileFakeInput}>
                    {formatOrder.map(id => `${helper.pad(this.state[id], id.length) || content[id].placeholder} `)}
                  </div>
                </div>
                <button
                  onClick={this.handleClickButton}
                  role="button"
                  arial-label={content.enterButtonText}
                  disabled={!this.state.isValidDate}
                  className={styles.mobileButton}
                >
                  {content.enterButtonText}
                </button>
              </div> */}
            </div>
            <div className={styles.column}>
              <VerificationError
                onFail={this.props.onFail}
                show={this.isVerificationError()}
                content={content.verificationError}
                close={this.clearValues}
                underAge={this.getCurrentAge() < this.props.config.legalAge}
              />
            </div>
          </main>
          <div className={styles.footer}>
            <Navigation
              navigation={navigation}
              itemClassName={styles.navItem}
              containerClassName={styles.navigation}
            />
            <div
              className={styles.slogan}
              dangerouslySetInnerHTML={{ __html: globalText.footerSlogan }}
            />
          </div>
        </div>
      </div>
    )
  }
}

AgeGate.propTypes = {
  config: PropTypes.shape({
    format: PropTypes.oneOf([
      'DD-MM-YYYY',
      'MM-DD-YYYY',
      'YYYY-MM-DD',
      'YYYY-DD-MM'
    ]),
    legalAge: PropTypes.number
  }).isRequired,
  unlock: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  navigation: PropTypes.object,
  countrySelectorButton: PropTypes.any.isRequired,
  onFail: PropTypes.func,
  onUnlock: PropTypes.func,
  onMount: PropTypes.func,
  onUpdate: PropTypes.func,
  globalText: PropTypes.shape({
    footerSlogan: PropTypes.string
  }).isRequired
}

AgeGate.defaultProps = {
  onFail: null,
  onUnlock: null,
  onMount: null,
  onUpdate: null,
  navigation: {},
  globalText: { footerSlogan: '' }
}
export default AgeGate
