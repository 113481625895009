import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from '../reducers'
import beaconMiddleware from './beaconMiddleware'


export default function configureStore(initialState, history) {

  const createStoreWithMiddleware = applyMiddleware(
      thunkMiddleware,
      beaconMiddleware
    )(createStore)
  const store = createStoreWithMiddleware(rootReducer, initialState,
    typeof window !== 'undefined' && window.devToolsExtension ? window.devToolsExtension() : f => f)

  if (module.hot) {
        // Enable hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
