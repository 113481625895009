import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Markup extends Component {
  componentDidMount() {
    const { data } = this.props
    if (data && data.markupFiles && data.markupFiles && data.type !== 'url') {
      data.markupFiles.map((files) => {
        if (files.url.indexOf('js') >= 0) {
          const script = document.createElement('script')
          script.key = files.id
          script.src = files.url
          script.async = true
          document.body.appendChild(script)
        } else if (files.url.indexOf('css') >= 0) {
          //TODO: handle stylesheets
          // return (
          //   <div
          //     key={files.id}
          //     dangerouslySetInnerHTML={{
          //       __html: this.linkStyles(files.url)
          //     }}
          //   />
          // )
        }
      })
    } else if (data && data.type === 'url' && data.scriptUrl) {
      const script = document.createElement('script')
      script.key = data.id
      script.src = data.scriptUrl
      script.async = true
      document.body.appendChild(script)
    }
  }
  linkStyles = (url) => `<link rel="stylesheet" type="text/css" href=${url} />`
  linkSscript = (url) => `<script async src=${url}></script>`
  render() {
    const { data } = this.props
    return (
      <div>
        {data && data.markup && (
          <div dangerouslySetInnerHTML={{ __html: data.markup }} />
        )}
      </div>
    )
  }
}

Markup.propTypes = {
  data: PropTypes.object.isRequired
}
export default Markup
