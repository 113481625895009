import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { HalfWidthPng, Generic, MetaData } from 'components'
import { Recommendations } from 'containers'

import styles from './jaegerTalesContent.css'

const JaegerTalesContent = (props) => {
  const data = props.content
  const endPoint = props.endPoint
  const jagertalesHero = styles.jagertalesHero
  return (
    <main className={styles.jaegerTalesContainer} role="main" id="mainContent">
      <MetaData metadata={data.metadata} />
      {data.hero && (
        <section className={styles.hero}>
          <HalfWidthPng
            data={data.hero}
            imageClass={styles.jaegerTalesImage}
            jagertalesHero
            reverseMobile
          />
        </section>
      )}
      {data.content &&
        data.content.map((d) => (
          <section className={styles.jaegerTalesContent} key={d.id}>
            <Generic
              components={d}
              key={d.id}
              theme="jaegerTales"
              endPoint={endPoint}
            />
          </section>
        ))}
      {data.sectionLink && data.sectionLink.route && (
        <section className={styles.jaegerTalesLink}>
          <Link
            to={data.sectionLink.route}
            key={data.sectionLink.route.text}
            className={styles.sectionLink}
          >
            {data.sectionLink.text}
          </Link>
        </section>
      )}
      {data.recommendations && (
        <Recommendations recommendations={data.recommendations} />
      )}
    </main>
  )
}

JaegerTalesContent.defaultProps = {
  content: {},
  theme: '',
  endPoint: ''
}
JaegerTalesContent.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.string,
  endPoint: PropTypes.string
}
export default JaegerTalesContent
