import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { getHeight, setHeight } from '../carousel/helper'
import {
  Img,
  Markdown,
  ArtistSocialMediaLinks,
  Button,
  DownloadButton,
  ShareButton
} from '../'

import styles from './halfWidthMedia.css'

const HalfWidthMedia = (props) => {
  const {
    component,
    slideIndex,
    isCarouselSlide = false,
    theme
  } = props
  const ref = useRef()
  //! calculate component height to set the correct height for the carousel in case a component was specially long
  // const calcHeight = debounce(() => {
  //   if (
  //     isCarouselSlide &&
  //     ref.current &&
  //     ref.current.clientHeight > 0 &&
  //     ref.current.clientHeight > getHeight()
  //   ) {
  //     setHeight(ref.current.clientHeight + 80, true)
  //   }
  // }, 150)

  // useEffect(() => {
  //   calcHeight()
  //   // don't add event listener if it's not part of a carousel
  //   if (isCarouselSlide) {
  //     window.addEventListener('resize', calcHeight)
  //   }
  //   return () => {
  //     if (isCarouselSlide) {
  //       window.removeEventListener('resize', calcHeight)
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   calcHeight()
  // }, [slideIndex])

  //* button type checker
  const renderButtons = (button) => {
    if (
      button.contentType === 'navigationItemExternal' ||
      button.contentType === 'navigationItemInternal'
    ) {
      return (
        <Button
          key={button.id}
          className={classNames(styles.button, {
            [styles.buttonsMargin]: true
          })}
          href={button.url || button.route}
        >
          {button.text}
        </Button>
      )
    } else if (button.contentType === 'downLoadButton') {
      return (
        <div
          key={button.id}
          className={classNames(styles.buttonsMargin, {
            [styles.iconMargin]: true
          })}
        >
          <DownloadButton key={button.id} href={button.button.url}>
            {button.title}
          </DownloadButton>
        </div>
      )
    } else if (button.contentType === 'shareButton') {
      return (
        <div
          key={button.id}
          className={classNames(styles.buttonsMargin, {
            [styles.shareMargin]: true
          })}
        >
          <ShareButton
            shareDialog={button.shareDialog}
            overlay={button.overlay}
          >
            Share
          </ShareButton>
        </div>
      )
    }
  }

  return (
    <div className={styles.container} ref={ref}>
      {component.carouselStyling && (
        <h1
          className={classNames(styles.headline, {
            [styles.carouselHeading]: component.carouselStyling,
            [styles.coldBrewHeading]:
              component.carouselStyling && theme === 'coldBrew'
          })}
        >
          {component.headline}
        </h1>
      )}
      <div
        className={classNames(styles.wrapper, {
          [styles.mediaLeft]: component.mode === 'mediaLeft',
          [styles.mediaRight]: component.mode === 'mediaRight'
        })}
      >
        <div
          className={classNames(styles.text, {
            [styles.carouselText]: component.carouselStyling
          })}
        >
          {!component.carouselStyling && (
            <h1
              className={classNames(styles.headline, {
                [styles.defaultHeading]: !component.carouselStyling,
                [styles.coldBrewHeading]: theme === 'coldBrew'
              })}
            >
              {component.headline}
            </h1>
          )}
          <Markdown markdownHtml={`<p>${component.text}</p>`} />
          <div className={styles.linksContainer}>
            <div className={styles.links}>
              <div
                className={classNames({
                  [styles.buttons]:
                    component.buttons && component.buttons.length > 0
                })}
              >
                {/* !single button is still supported for legacy usages */}
                {component.button ? (
                  <Button
                    className={styles.button}
                    href={component.button.url || component.button.route}
                  >
                    {component.button.text}
                  </Button>
                ) : (
                  component.buttons &&
                  component.buttons.length > 0 &&
                  component.buttons.map((button) => renderButtons(button))
                )}
              </div>
              <div>
                {component.socialLinks && component.socialLinks.length > 0 && (
                  <ArtistSocialMediaLinks
                    data={{ links: component.socialLinks }}
                    trackClick={() => {}}
                    musicPage
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.image, {
            [styles.carouselImage]: component.carouselStyling
          })}
        >
          <Img
            src={component.media.url}
            alt={component.media.title}
            objectFit
          />
        </div>
      </div>
    </div>
  )
}

HalfWidthMedia.propTypes = {
  component: PropTypes.shape({
    carouselStyling: PropTypes.bool.isRequired,
    headline: PropTypes.string,
    button: PropTypes.object,
    mode: PropTypes.string.isRequired,
    text: PropTypes.string,
    socialLinks: PropTypes.array,
    media: PropTypes.object.isRequired
  }).isRequired,
  isCarouselSlide: PropTypes.bool,
  minHeight: PropTypes.number,
  slideIndex: PropTypes.number,
  setMinHeight: PropTypes.func,
  theme: PropTypes.string
}

HalfWidthMedia.defaultProps = {
  component: {
    carouselStyling: true,
    headline: '',
    media: {},
    text: '',
    mode: 'mediaLeft'
  },
  isCarouselSlide: false,
  minHeight: 0,
  slideIndex: 0,
  setMinHeight: () => {},
  theme: 'default'
}

export default HalfWidthMedia
