import React from 'react'

import styles from './iconlist.css'

const svgs = {

  download: (<svg viewBox="0 0 39.2 39.2" fill="rgb(221, 90, 18)">
    <path
      d="M34.3,0H4.9C2.2,0,0,2.2,0,4.9v29.4c0,2.7,2.2,4.9,4.9,4.9h29.4c2.7,0,4.9-2.2,4.9-4.9V4.9C39.2,2.2,37,0,34.3,0z
M17.2,25.7c0,3.4-2.7,6.1-6.1,6.1s-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1c1.4,0,2.6,0.5,3.7,1.2V7.3h6.1v4.9h-3.7V25.7z M33.5,31.1H20.2
v-1.9h13.3V31.1z M26.9,27.3l-6.6-6.6h5V7.4h3.2v13.3h5L26.9,27.3z"
    />
  </svg>),
  calendar: (<svg
    viewBox="0 0 39.2 39.2" fill="rgb(221, 90, 18)"
  >
    <g>
      <path d="M9.1,30.6h21V16h-21V30.6z M19.6,20.2h7.5v7.5h-7.5V20.2z" />
      <path
        d="M34.3,0H4.9C2.2,0,0,2.2,0,4.9v29.4c0,2.7,2.2,4.9,4.9,4.9h29.4c2.7,0,4.9-2.2,4.9-4.9V4.9C39.2,2.2,37,0,34.3,0z M33.1,30.6c0,1.6-1.3,3-3,3h-21c-1.7,0-3-1.3-3-3l0-19.1c0-1.6,1.3-3,3-3h1.5v-3h3v3h12v-3h3v3h1.5c1.6,0,3,1.3,3,3V30.6z"
      />
    </g>
  </svg>),
  video: (<svg
    viewBox="0 0 39.2 39.2" fill="rgb(221, 90, 18)"
  >
    <path
      d="M34.3,0H4.9C2.2,0,0,2.2,0,4.9v29.4c0,2.7,2.2,4.9,4.9,4.9h29.4c2.7,0,4.9-2.2,4.9-4.9V4.9C39.2,2.2,37,0,34.3,0z M14.8,28.4V10.8l12.5,8.8L14.8,28.4z"
    />
  </svg>),
  music: (<svg
    viewBox="0 0 39.2 39.2" fill="rgb(221, 90, 18)"
  >
    <path
      d="M34.3,0H4.9C2.2,0,0,2.2,0,4.9v29.4c0,2.7,2.2,4.9,4.9,4.9h29.4c2.7,0,4.9-2.2,4.9-4.9V4.9C39.2,2.2,37,0,34.3,0z M30.4,12.4L30.4,12.4l0,14.4c0,2.5-2,4.6-4.6,4.6s-4.6-2-4.6-4.6s2-4.6,4.6-4.6c0.8,0,1.6,0.2,2.3,0.6V12.4H15.9v14.4 c0,2.5-2,4.6-4.6,4.6s-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6c0.8,0,1.6,0.2,2.3,0.6V7.8h16.9V12.4z"
    />
  </svg>),
  exclusive: (<svg
    viewBox="0 0 39.2 39.2" fill="rgb(221, 90, 18)"
  >
    <path
      d="M34.3,0H4.9C2.2,0,0,2.2,0,4.9v29.4c0,2.7,2.2,4.9,4.9,4.9h29.4c2.7,0,4.9-2.2,4.9-4.9V4.9C39.2,2.2,37,0,34.3,0z M28.4,33.1l-8.8-5.3l-8.8,5.3l2.3-10l-7.8-6.7l10.2-0.9l4-9.4l4,9.4l10.2,0.9l-7.8,6.7L28.4,33.1z"
    />
  </svg>)
}

export default ({ icons = [] }) => (
  <ul className={styles.container} key="artist links">
    {icons && icons.map(icon => (<li className={styles.item} key={`${icon.icon} + ${icon.text}`}>
      {svgs[icon.icon] && <div className={styles.icon}>
        {svgs[icon.icon]}
      </div>}
      {icon.text}
    </li>))}
  </ul>
  )
