/* global window */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Image, Markdown, OnVisible } from '..'
import PlayIcon from '../../svgs/media/play'
import PauseIcon from '../../svgs/media/pause'

import styles from './audioClipPlayer.css'

class AudioClipPlayer extends Component {
  constructor(...props) {
    super(...props)
    this.state = {
      selectedClip: 0,
      playing: false
    }
    this.audioPlayer = React.createRef()
  }

  componentDidMount() {
    this.playListener = this.audioPlayer.current.addEventListener('play', () =>
      this.setState({
        playing: true
      })
    )
    this.pauseListener = this.audioPlayer.current.addEventListener(
      'pause',
      () =>
        this.setState({
          playing: false
        })
    )
    this.endedListener = this.audioPlayer.current.addEventListener(
      'ended',
      () =>
        this.setState({
          playing: false
        })
    )
  }

  componentWillUnmount() {
    this.audioPlayer.current.removeEventListener('play', this.playListener)
    this.audioPlayer.current.removeEventListener('pause', this.pauseListener)
    this.audioPlayer.current.removeEventListener('ended', this.endedListener)
    this.audioPlayer.current.removeEventListener('load', this.loadListener)
  }

  setSelected = (type, idx) => {
    const audioPlayer = this.audioPlayer.current
    if(idx !== this.state.selectedClip) {
    this.props.trackAudio(type)
      this.setState({
        selectedClip: idx,
        playing: true,
      }, () => {
        idx !== this.state.selectedClip ? audioPlayer.load() : null;
        audioPlayer.play();
      })
    } else {
      this.setState({
        selectedClip: idx,
      })
      this.state.playing ? audioPlayer.pause() : audioPlayer.play();
    }

  }

  renderButtons = (audioClip, idx) => {
    const { data } = this.props
    const { playing, selectedClip } = this.state
    const isActive = idx === selectedClip
    const css = {
      active: styles.descriptionActive
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.button, {
      active: isActive
    })
    return (
      <li
        key={`button-${audioClip.id}`}
        id={`button-${audioClip.id}`}
        role="tab"
        tabIndex={isActive ? 0 : -1}
        aria-controls={`description-${audioClip.id}`}
        aria-selected={isActive}
        className={classes}
        onClick={this.setSelected.bind(this, audioClip.title, idx)}
      >
        <Image src={audioClip.image.url} sizes="100vw" alt="" />
        <span className={styles.buttonTitle}>{audioClip.title}</span>
        <button
        className={styles.playButton}
        type="button"
        onClick={this.setSelected.bind(this, audioClip.title, idx)}
        aria-live="polite"
        aria-label={playing ? data.pause : data.play}
      >
        {isActive && playing && idx === selectedClip ? <PauseIcon /> : <PlayIcon />}
      </button>
      </li>
    )
  }



  play = () => {
    if (this.audioPlayer.current) {
      this.audioPlayer.current.play()
    }
  }
  pause = () => {
    if (this.audioPlayer.current) {
      this.audioPlayer.current.pause()
    }
  }

  render() {
    const { data } = this.props
    const { selectedClip } = this.state
    return (
      <OnVisible
        className={styles.container}
        visibleClassName={styles.visible}
        percent={-10}
      >
        <div>
          <ul
            className={styles.buttons}
            role="tablist"
            aria-label={data.samplesLabel}
          >
            {data.audioClips.map(this.renderButtons)}
          </ul>

        </div>
        <audio
            ref={this.audioPlayer}
            src={data.audioClips[selectedClip].audio.url}
          />
      </OnVisible>
    )
  }
}

AudioClipPlayer.propTypes = {
  data: PropTypes.shape({
    samplesLabel: PropTypes.string.isRequired,
    play: PropTypes.string.isRequired,
    pause: PropTypes.string.isRequired,
    audioClips: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired
        }),
        audio: PropTypes.shape({
          url: PropTypes.string.isRequired
        }),
        description: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  trackHighlights: PropTypes.func
}

AudioClipPlayer.defaultProps = {
  trackAudio: () => {}
}

export default AudioClipPlayer
