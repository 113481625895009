import React from 'react'
import PropTypes from 'prop-types'

import {
  Generic,
  FullBleedImage,
  MetaData,
  Grid,
  Tile,
  VideoFeature
} from 'components'
// import MailchimpForm from '../../mailchimpForm/mailchimpForm'

import styles from './BestNightsArticle.css'

const BestNightsArticle = ({
  content,
  trackingVideo,
  endPoint,
  recaptchaKey,
  bestNightsNewsletterEndpoint
}) => {
  const bestNights = content
  if (!bestNights) {
    return <div data-error="No best nights content" />
  }
  // const mailchimpForm = bestNights.mailchimpForm
  const heroContent = bestNights?.hero[0]
  return (
    <main role="main" id="mainContent" className={styles.backgroundBNTexture}>
      <MetaData
        metadata={bestNights.metadata}
        canonicalBase="//www.jagermeister.com/en"
      />

      {heroContent && (
        <section className={styles.header}>
          {heroContent.contentType === 'fullBleedImageComponent' && (
            <FullBleedImage
              data={heroContent}
              key={heroContent.id}
              // theme="bestNightsBanner"
              theme="bestNightsHero"
              isArticlePage={true}
              textInnerClass={styles.heroText}
            />
          )}
          {heroContent.contentType === 'videoComponent' && (
            <VideoFeature
              data={heroContent}
              key={heroContent.id}
              tracking={trackingVideo}
              theme="bestNightsHero"
              bestNights={true}
            />
          )}
        </section>
      )}
      {bestNights.components && (
        <section className={styles.backgroundBN}>
          {bestNights.components.map((d) => (
            <Generic
              components={d}
              trackingVideo={trackingVideo}
              key={d.id}
              bestNights={true}
              endPoint={endPoint}
              bestNightsNewsletterEndpoint={bestNightsNewsletterEndpoint}
              config={{
                recaptchaKey
              }}
            />
          ))}
        </section>
      )}
      {/*mailchimpForm && (
        <MailchimpForm
          claim={mailchimpForm.claim}
          disclaimer={mailchimpForm.disclaimer}
          thankYouMessage={mailchimpForm.thankYouMessage}
          errorMessage={mailchimpForm.errorMessage}
          endpoint={bestNightsNewsletterEndpoint}
        />
      )*/}
      {bestNights.featuredArticles &&
        Object.keys(bestNights.featuredArticles).length && (
          <section>
            <section key={'BestNightsGrid'} className={styles.grid}>
              <Grid theme={'bestNights'} loadMoreThreshold={10}>
                {Object.keys(bestNights.featuredArticles).map((articleSlug) => {
                  if (!articleSlug) {
                    return null
                  }
                  const article = bestNights.featuredArticles[articleSlug]
                  return (
                    <Tile
                      key={article.id}
                      linkTo={`/best-nights/${articleSlug}`}
                      image={article.image}
                      caption={article.text}
                      title={article.headline}
                      theme={'bestNightsPage'}
                    />
                  )
                })}
              </Grid>
            </section>
          </section>
        )}
    </main>
  )
}

BestNightsArticle.propTypes = {
  content: PropTypes.object.isRequired,
  slug: PropTypes.string
}

BestNightsArticle.defaultProps = {
  slug: 'default'
}

export default BestNightsArticle
