import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Generic, HeroComponent, MetaData, Button } from 'components'
import { Recommendations } from 'containers'

import styles from './artistdetail.css'

class GenericPage extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false
    }
  }

  toggleContactText = () => {
    this.setState({ showText: !this.state.showText })
  };

  render() {
    const { content, trackingVideo, endPoint } = this.props
    const genericComponents = content.components
    const hero = content.hero
    const cta = content.cta
    return (
      <main className={styles.artistDetail} role="main" id="mainContent">
        <MetaData metadata={content.metadata} />
        { hero &&
        <section className={styles.artistDetailHeader}>
          { hero &&
            hero.contentType === 'heroComponent' ? <section key={hero.title} className={styles.hero}><HeroComponent hero={hero} /></section> : <Generic components={hero} trackingVideo={trackingVideo} key={hero.id} endPoint={endPoint} />
          }
        </section>
       }
        { genericComponents &&
        <section className={styles.artistDetailContainer}>
          { genericComponents && genericComponents.map((d, idx) => (
            <Generic components={d} trackingVideo={trackingVideo} key={d.id + idx} endPoint={endPoint} />
            ))}
        </section> }
        { cta && <div className={styles.button}>
          <Button onClick={this.seeMoreButtClick} href={cta.route} className={styles.cta}>{cta.text}</Button>
        </div>}
        {content.recommendations && <Recommendations recommendations={content.recommendations} /> }
      </main>
    )
  }
}

GenericPage.defaultProps = {
  content: {},
  trackingVideo: () => {},
  endPoint: '',
}
GenericPage.propTypes = {
  content: PropTypes.object,
  endPoint: PropTypes.string,
  trackingVideo: PropTypes.func,
}
export default GenericPage
