import PropTypes from 'prop-types';
import React from 'react'

import styles from './social-grid.css'

const SocialGrid = (
  {
    ugc,
  },
) => {
  if (!ugc) {
    return <div />
  }
  return (
    <div>
      {/* TODO: localise some content */}
      <h2 className={styles.title}>#jagertonic</h2>
      <p>Lorem ipsum</p>
      <ul>{ugc.map(item => <li className={styles.tag}>
        <h3 className={styles.subtitle}>{item.handle}</h3>
        <div>{item.url}</div>
        {item.profileImage && <img
          className={styles.image}
          src={item.profileImage.url}
          alt={item.profileImage.title}
        />}
        <div>{item.isBrandAccount}</div>
        <div>{item.contentType}</div>

        {item.image && <img className={styles.image} src={item.image.url} alt={item.image.title} />}
        {item.content && <div>{item.content}</div>}
      </li>)}</ul>
    </div>
  );
};

SocialGrid.propTypes = {
  ugc: PropTypes.string.isRequired
};

export default SocialGrid
