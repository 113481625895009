import { connect } from 'react-redux'

import Recommendations from 'components/recommendations/Recommendations'
import { trackPageView } from 'actions/analytics'

function mapDispatchToProps(dispatch) {
  return {
    onClick({ subtitle }) {
      dispatch(
        trackPageView({
          event: 'Bottom CTA',
          ContentType: 'Recommendations',
          ContentId: subtitle,
          url: document.location.href
        })
      )
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Recommendations)
