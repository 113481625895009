let carouselHeight = 750

const setHeight = (itemHeight, withCheck) => {
  if (withCheck) {
    if (itemHeight > carouselHeight) {
      carouselHeight = itemHeight
    }
  } else {
    carouselHeight = itemHeight
  }
}

const getHeight = () => {
  return carouselHeight
}

export { setHeight, getHeight }
