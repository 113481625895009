import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import * as socialIcons from 'svgs/socialIcons/SocialIcons'

import Link from '../staggerlink/staggerlink'
import { ConnectButton, SocialMedia } from '../'
import ScrollLock from '../overlay/ScrollLock'

import styles from './navbar.css'
import DropDown from './DropDown'

import {
  headerLogoAltText
} from 'utils/i18n'

class Navbar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false
    }
  }
  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }
  render() {
    if (!this.props.navItems) {
      return <div data-error="Missing nav items" />
    }

    const { showMenu } = this.state
    const css = {
      open: styles.open,
      navUp: styles.navUp
    }
    const cx = classNames.bind(css)
    const classes = cx(styles.navbar, {
      open: showMenu,
      navUp: this.props.navClass
    })
    const countryClasses = classNames(styles.countrySelector, styles.navItem)
    const socialLinks = this.props.socialLinks
    //TODO: move this to a shared place to be reusable.
    const navItems = this.props.navItems.map((item) => {
      const uuid = String(Math.floor(Math.random() * (10000 - 0)));
      if (item.url) {
        return (
          <Link
            key={`nav-links-${uuid}-${item.id}`}
            to={item.url}
            className={styles.navItem}
            onClick={() => this.props.trackNavigationClick(item.text)}
            rel={item.url.substring(0, 1) !== '/' ? 'external' : ''}
          >
            {item.text}
          </Link>
        )
      } else if (item.route) {
        return (
          <Link
            key={`nav-links-${uuid}-${item.id}`}
            to={item.route}
            className={styles.navItem}
            onClick={() => this.props.trackNavigationClick(item.text)}
          >
            {item.text}
          </Link>
        )
      } else if (item.links) {
        return (
          <DropDown
            key={`nav-links-${uuid}-${item.id}`}
            text={item.text}
            links={item.links}
            trackNavigationClick={this.props.trackNavigationClick}
          />
        )
      }
      // TODO: if a nav item is missing a url and route, it should be dealt
      // with in transform layer + item shoudn't be included
      return (
        <span key={item.id} className={styles.navItem}>
          {item.text}
        </span>
      )
    })
    if (this.props.disableNavigationAndConnectTray) {
      return <div />
    } else {
      const logoAltText = headerLogoAltText()
      return (
        <nav className={classes} role="navigation">
          <div className={styles.navContainer}>
            <div className={styles.navInner}>
              {!this.props.disableNavigation && (
                <div className={styles.logoContainer}>
                  <a href={`/${this.props.locale}`} className={styles.logo}>
                    {logoAltText}
                  </a>
                </div>
              )}
              <div className={styles.pages}>
                {!this.props.disableNavigation && (
                  <div className={styles.pagesInner}>
                    <div>
                      {navItems}
                      <div className={styles.countrySelectorMobile}>
                        {React.cloneElement(this.props.countrySelectorButton, {
                          mobileVersion: true
                        })}
                      </div>
                    </div>
                    <div className={styles.mobileNav}>
                      {socialLinks && (
                        <SocialMedia social={socialLinks} icons={socialIcons} />
                      )}
                      {this.state.showMenu && <ScrollLock />}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(styles.navInner, styles.navInnerRight)}>
              {!this.props.disableNavigation && (
                <div className={countryClasses}>
                  {this.props.countrySelectorButton}
                </div>
              )}
              {this.props.connect && this.props.connect.title && (
                <ConnectButton
                  connectTray={this.props.connect}
                  toggle={this.props.toggle}
                />
              )}
              {!this.props.disableNavigation && (
                <button
                  role="button"
                  className={styles.burger}
                  onClick={this.toggleMenu}
                  aria-label="Menu"
                />
              )}
            </div>
          </div>
        </nav>
      )
    }
  }
}

Navbar.propTypes = {
  navClass: PropTypes.string,
  disableNavigationAndConnectTray: PropTypes.bool,
  connect: PropTypes.object,
  navItems: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  countrySelectorButton: PropTypes.any.isRequired,
  socialLinks: PropTypes.object.isRequired,
  trackNavigationClick: PropTypes.func.isRequired
}
Navbar.defaultProps = {
  navClass: '',
  disableNavigationAndConnectTray: false,
  connect: {}
}
export default Navbar
