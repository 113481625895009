import basePage from 'hoc/basepage/basepage'
import { Generic } from 'components/pages'

export default basePage(Generic, {
  contentNodeName: 'genericPage',
  endPointName: 'newsLetterFormEndpoint',
  integrationEndpointName: 'integrationsFormEndpoint',
  complaintFormEndpointName: 'complaintFormEndpoint',
  salesforceSyncEndpointName: 'salesforceSyncEndpoint',
  trackDefaultPageName: 'Untitled Generic Page'
})
