import React, { useState, useEffect, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Close } from '../'
import styles from './shareoverlay.css'

const ShareOverlay = ({ shareDialog, overlay, setShowFallback }) => {
  const ref = useRef(null)
  const [FBLink, setFBLink] = useState('')
  const [twitterLink, setTwitterLink] = useState('')

  const eventListener = (e) => {
    if (ref.current && !ref.current.contains(e?.target)) {
      setShowFallback(false)
    }
  }

  useEffect(() => {
    createFBShareLink()
    createTwitterShareLink()
    window.addEventListener('click', eventListener)
    return () => {
      window.removeEventListener('click', eventListener)
    }
  }, [])
  const createFBShareLink = () => {
    setFBLink(
      `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}&display=popup${
        shareDialog ? `&quote=${encodeURIComponent(shareDialog)}` : ''
      }&hashtag=${
        overlay.fbHashtag ? encodeURIComponent(overlay.fbHashtag) : ''
      }`
    )
  }

  const createTwitterShareLink = () => {
    setTwitterLink(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        window.location.href
      )}${shareDialog ? `&text=${encodeURIComponent(shareDialog)}` : ''}${
        overlay.twitterHashtags ? `&hashtags=${overlay.twitterHashtags}` : ''
      }`
    )
  }

  return (
    <div className={styles.overlay} ref={ref}>
      <button
        className={styles.closeButton}
        onClick={() => {
          setShowFallback(false)
        }}
      >
        <Close />
      </button>
      <h2>{overlay.title}</h2>
      <div className={styles.buttonsContainer}>
        {overlay.facebook && (
          <a
            className={styles.shareButtons}
            target="_blank"
            rel="noopener noreferrer"
            href={FBLink}
            onClick={() => {
              setShowFallback(false)
            }}
          >
            Facebook
          </a>
        )}
        {overlay.twitter && (
          <a
            className={styles.shareButtons}
            target="_blank"
            rel="noopener noreferrer"
            href={twitterLink}
            onClick={() => {
              setShowFallback(false)
            }}
          >
            Twitter
          </a>
        )}
        {overlay.copy && (
          <CopyToClipboard text={window.location.href}>
            <button
              className={styles.shareButtons}
              onClick={() => {
                setShowFallback(false)
              }}
            >
              {overlay.copyText}
            </button>
          </CopyToClipboard>
        )}
      </div>
    </div>
  )
}

export default ShareOverlay
