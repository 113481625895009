import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import split from '../../lib/split'
import * as flags from 'svgs/flags/Flags'
import { Arrow } from '../'
import { countrySelector } from 'utils/i18n'

import styles from './countryselectorbutton.css'

const CountrySelectorButton = ({
  isOpen,
  countries,
  locales,
  currentLocale,
  toggle,
  mobileVersion
}) => {
  let currentCountry = null
  countries.forEach((country) => {
    if (country.locales.indexOf(currentLocale) !== -1) {
      currentCountry = country
    }
  })
  if (!currentCountry) {
    return <div data-error="No current country" />
  }

  const siteText = {
    countrySelector: countrySelector()
  }
  function handleKeyDownMenuBtn(e, open) {
    // console.log('key down', e.key, open)
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault()
      e.stopPropagation()
      toggle()
      // console.log('menu aperto ' + open)
    }
    if (e.key === 'Tab' && e.shiftKey) {
      // setOpen(false)
    }
  }

  return (
    <button
      onClick={toggle}
      onKeyDown={(e) => handleKeyDownMenuBtn(e, isOpen)}
      role="button"
      aria-haspopup="true"
      aria-expanded={isOpen}
      aria-controls="countrySelectorOverlay"
      className={classNames({
        [styles.button]: true,
        [styles.active]: isOpen,
        [styles.mobileVersion]: mobileVersion
      })}
      aria-label={siteText.countrySelector + ' - ' + currentCountry.title}
    >
      {mobileVersion && (
        <span className={styles.flag}>
          {typeof flags[currentCountry.flag] === 'function' &&
            flags[currentCountry.flag]()}
        </span>
      )}
      <div aria-hidden="true">
        {mobileVersion && (
          <span className={styles.locale}>
            {locales[currentLocale].title.replace('|', '•')}
          </span>
        )}
        {split(currentCountry.title, styles.split)}{' '}
        {!mobileVersion && (
          <Arrow
            active={isOpen}
            direction={isOpen ? 'up' : 'down'}
            usePresetSpacing
          />
        )}
      </div>
    </button>
  )
}

CountrySelectorButton.propTypes = {
  isOpen: PropTypes.bool,
  currentLocale: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  locales: PropTypes.object.isRequired,
  mobileVersion: PropTypes.bool
}

CountrySelectorButton.defaultProps = {
  isOpen: false,
  mobileVersion: false,
  countries: []
}

export default CountrySelectorButton
