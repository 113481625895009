import React from 'react'
import PropTypes from 'prop-types'
import styles from './globalpopup.css'
import ScrollLock from '../overlay/ScrollLock'
import { FormContainer } from 'containers'
import { Img, Close } from '../'

class GlobalPopup extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.isOpen) {
      return null
    }
    const { popupFormcontent, endPoint, noFrost } = this.props

    if (!popupFormcontent || !popupFormcontent) {
      return <div data-error="no-globalPopup" />
    }

    const headline = popupFormcontent.content.headlineText
    const subheadline = popupFormcontent.content.subtitleText
    const popupForm = popupFormcontent.content.popUpForm
    const popupFormImageDesktop = popupFormcontent.content.popupImageDesktop
    const popupFormImageMobile = popupFormcontent.content.popupImageMobile
    popupForm.autoFocusField = 'firstName'

    const frostClasses = !noFrost ? styles.frost : ''
    return (
      <ScrollLock>
        <div
          className={styles.overlay}
          role="dialog"
          aria-labelledby="popup-title"
          aria-describedby="popup-desc"
        >
          <div className={styles.popup}>
            <span
              aria-label="close"
              className={styles.closeButton}
              onClick={this.props.onClose}
            >
              <Close />
            </span>
            <div className={styles.sidebar}>
              {popupFormImageDesktop && (
                <div className={frostClasses}>
                  <Img
                    key={popupFormImageDesktop.id}
                    src={popupFormImageDesktop.url}
                    mobileImage={popupFormImageMobile}
                    alt={
                      popupFormImageDesktop.title ||
                      popupFormImageDesktop.description
                    }
                    className={styles.image}
                  />
                </div>
              )}
            </div>
            <div className={styles.main}>
              <div className={styles.intro}>
                <h2 id="popup-title" className={styles.headline}>
                  <strong>{headline}</strong>
                </h2>
                <p id="popup-desc" className={styles.subheadline}>
                  {subheadline}
                </p>
              </div>
              <FormContainer
                data={popupForm}
                formClass={styles.form}
                containerClass={styles.container}
                endPoint={endPoint}
                compact
              />
            </div>
          </div>
        </div>
      </ScrollLock>
    )
  }
}

GlobalPopup.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  popupFormcontent: PropTypes.object,
  endPoint: PropTypes.string
}

GlobalPopup.defaultProps = {
  popupFormcontent: {}
}

export default GlobalPopup
