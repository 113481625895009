export const isMobileOS = () => {
  const userAgent = navigator.userAgent

  if (
    (userAgent && /android/i.test(userAgent)) ||
    (userAgent && !window.MSStream && /iPad|iPhone|iPod/.test(userAgent))
  ) {
    return true
  }
  return false
}
