import basePage from 'hoc/basepage/basepage'
import { Products } from 'components/pages'

export default basePage(Products, {
  contentNodeName: 'productsPage',
  defaultSlug: 'products',
  trackContentCategory: 'Products Page',
  trackDefaultPageName: 'Products Page',
  trackAlwaysUseDefaultPageName: true
})
