export function isValidDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (isNaN(d.getTime())) {
      return false
    }
    return true
  }
  return false
}

export function pad(m, width) {
  if (!m) return m
  let n = `${m}`
  while (n.length < width) {
    n = `0${n}`
  }
  return n
}

export function getAge(dateString) {
  const today = new Date() // TODO this should come from a date service
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }
  return age
}

export function isValidDay(value) {
  const number = parseInt(value, 10)
  return !isNaN(value) && number !== 0 &&
          value.toString().trim().length === 2
}

export function isValidMonth(value) {
  const number = parseInt(value, 10)
  return !isNaN(value) && number <= 12 && number >= 1 &&
          value.toString().trim().length === 2
}

export function isValidYear(value) {
  return value.length === 4 && !isNaN(value)
}
