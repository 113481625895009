/* global document, window */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './story.css'

function attachCustomAttributes(domNode) {
  if (domNode) {
    domNode.setAttribute('muted', true)
    domNode.setAttribute('playsInline', true)
  }
}

class Story extends Component {
  constructor(...rest) {
    super(...rest)
    this.pixi = false
    this.timeouts = ''
    this.state = {
      height: null,
      videoLoaded: false,
      portrait: false,
      ended: false,
      mobileVideo: '',
      desktopVideo: ''
    }
  }
  componentDidMount() {
    // @TODO make this less horrible
    this.setState({
      height: window.innerHeight,
      portrait: window.innerHeight > window.innerWidth
    })
    this.videoType()
    this.timeouts = setTimeout(() => this.setState({ ended: true }), 5000)
  }
  componentWillUnmount() {
    this.clearTimeouts()
  }

  onComplete = () => {
    this.setState(
      {
        ended: true
      },
      this.props.onComplete
    )
  }

  clearTimeouts = () => {
    clearTimeout(this.timeouts)
  }

  videoType = () => {
    const video = this.props.video
    video.map((d) => {
      if (d.description.indexOf('mobile') !== -1) {
        this.setState({
          mobileVideo: d.url
        })
      } else {
        this.setState({
          desktopVideo: d.url
        })
      }
    })
  }

  render() {
    const { portrait, ended, height, mobileVideo, desktopVideo } = this.state
    const classes = cx(styles.story, {
      ended
    })
    return (
      <div className={classes}>
        <video
          ref={attachCustomAttributes}
          onClick={this.onComplete}
          onEnded={this.onComplete}
          onError={this.onComplete}
          muted
          playsInline
          autoPlay
          height={height}
        >
          <source src={portrait ? mobileVideo : desktopVideo} />
        </video>
      </div>
    )
  }
}

Story.propTypes = {
  onComplete: PropTypes.func.isRequired,
  video: PropTypes.array.isRequired
}

export default Story
