import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { trackPageView } from 'actions/analytics'
import DownloadButton from 'components/downloadbutton/downloadbutton'

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onClick: () => {
      dispatch(trackPageView({
        event: 'Downloads',
        eventType: ownProps.trackingEventType,
        eventDetail: ownProps.trackingEventDetail
      }))
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(DownloadButton))
