import { connect } from 'react-redux'
import { trackPageView } from 'actions/analytics'

import { visitorAge } from 'actions/visitorAge'
import Page from 'components/page/Page'

function mapDispatchToProps(dispatch) {
  return {
    onUnlock: ({ userAge }) => {
      dispatch(visitorAge(userAge))
    },
    onConnect: (trackingObj) => {
      dispatch(
        trackPageView({
          event: 'Connect Button',
          page: trackingObj.page,
          open: trackingObj.toggle
        })
      )
    },
    onConnectCta: (trackingObj) => {
      dispatch(
        trackPageView({
          event: 'Connect CTA',
          cta: trackingObj.cta,
          url: trackingObj.url
        })
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
    ageGateConfig: state.ageGateConfig,
    recaptchaKey: state.recaptchaKey,
    locale: state.locale,
    vistorAge: state.vistorAge
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
