import React from 'react'

const DownloadIcon = () => (
  <svg
    width="13px" height="15px"
  >
    <path
      fillRule="evenodd" fill="rgb(179, 172, 118)"
      d="M12.500,5.618 L9.071,5.618 L9.071,0.500 L3.929,0.500 L3.929,5.618 L0.500,5.618 L6.500,11.588 L12.500,5.618 ZM0.500,13.294 L0.500,15.000 L12.500,15.000 L12.500,13.294 L0.500,13.294 Z"
    />
  </svg>
)

export default DownloadIcon
