import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { HeroComponent, MetaData } from 'components'
import { JaegerTalesGrid, Recommendations } from 'containers'

import styles from './jaegerTales.css'

const JaegerTales = props => {
  const data = props.content

  return (

    <main className={styles.jaegerTales} role="main" id="mainContent">
      <MetaData metadata={data.metadata} />
      { data.hero &&
      <HeroComponent hero={data.hero} />
      }
      { data.content && data.content.map(d => (
        <section key={d.id} className={styles.content}>
          <JaegerTalesGrid tiles={d.tiles} />
        </section>
      ))
    }
      {data.recommendations && <Recommendations recommendations={data.recommendations} /> }
    </main>
  )
};

JaegerTales.defaultProps = {
  content: {},
  trackGridClick: () => {}
}
JaegerTales.propTypes = {
  content: PropTypes.object,
  trackGridClick: PropTypes.func
}
export default JaegerTales
