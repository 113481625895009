import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { HalfWidthMedia, Arrow } from '../'
import { setHeight, getHeight } from './helper'
import styles from './carousel.css'

// ? Commented out code to be used as a thumbnail implementation on the future

//! import { Swiper, SwiperSlide } from 'swiper/react'
//! import SwiperCore, { Pagination, Navigation } from 'swiper/core'

//! install Swiper modules
// SwiperCore.use([Pagination, Navigation])

class Carousel extends Component {
  constructor(props) {
    super(props)
    this.thumbnails = createRef()
    this.state = {
      prevSlide: this.props.component.items.length - 1,
      slideIndex: 0,
      transition: 'Left',
      margins: 0,
      // swiperInstance: null,
      // slidesPerView: 'auto'
      xDown: null,
      yDown: null
    }
  }

  componentDidMount() {
    const { component } = this.props
    if (this.thumbnails.current) {
      // SwiperCore.use([Pagination, Navigation])
      this.reCalcMargins()
      window.addEventListener('resize', this.reCalcMargins)
      if (window.innerWidth > 1024 && component.desktopMinHeight) {
        setHeight(component.desktopMinHeight, false)
      } else if (window.innerWidth < 1025 && component.mobileMinHeight) {
        setHeight(component.mobileMinHeight, false)
      }
    }
    if (this.props.config && this.props.config.history) {
      const param = new URLSearchParams(
        this.props.config.history.location.search
      )
      const slideIndex = param.get('recipe')
      if (slideIndex && !isNaN(slideIndex)) {
        this.setState({
          slideIndex: slideIndex * 1 - 1
        })
      }
    }
  }

  componentWillUnmount() {
    if (this.thumbnails.current) {
      window.removeEventListener('resize', this.reCalcMargins)
    }
  }

  reCalcMargins = debounce(() => {
    const clientWidth = window.innerWidth
    const numberOfItems = this.props.component.thumbnails.length
    const childrenWidth = numberOfItems * 260
    const slidesPerView = Math.ceil(Math.min(clientWidth / 250, numberOfItems))
    this.setState({
      margins: Math.max((clientWidth - childrenWidth) / 2, 0),
      slidesPerView: slidesPerView
    })
  }, 150)

  pushQuery = (index) => {
    // index can be changed to anything else.
    const { config } = this.props
    if (config && config.history) {
      history.pushState(
        {},
        '',
        window.location.pathname + `?recipe=${index + 1}`
      )
    }
  }

  jumpToSlide = (n, element) => {
    const clientWidth = this.thumbnails.current.clientWidth
    const elementPosition = element.offsetLeft

    const { component } = this.props
    const direction = n > this.state.slideIndex ? 'Left' : 'Right'
    if (n !== this.state.slideIndex) {
      const slideIndex = Math.min(n, component.items.length - 1)
      const prevSlide = this.state.slideIndex
      this.setState({
        slideIndex: slideIndex,
        prevSlide: prevSlide,
        transition: direction
      })
      this.thumbnails.current.scrollTo({
        left: elementPosition + element.clientWidth / 2 - clientWidth / 2,
        top: 0,
        behavior: 'smooth'
      })
      this.pushQuery(slideIndex)
    }
  }

  switchSlide = (n) => {
    const { component } = this.props
    const direction = n > 0 ? 'Left' : 'Right'
    let slideIndex
    let prevSlide
    //! if (direction === 'Left') {
    //!   this.state.swiperInstance.slideNext()
    //! } else {
    //!   this.state.swiperInstance.slidePrev()
    //! }
    if (
      this.state.slideIndex + n < component.items.length &&
      this.state.slideIndex + n > -1
    ) {
      slideIndex = this.state.slideIndex + n
      prevSlide = this.state.slideIndex
      this.setState({
        prevSlide: prevSlide,
        slideIndex: slideIndex,
        transition: direction
      })
    } else if (this.state.slideIndex + n < 0) {
      slideIndex = component.items.length - 1
      prevSlide = this.state.slideIndex
      this.setState({
        prevSlide: prevSlide,
        slideIndex: slideIndex,
        transition: direction
      })
    } else if (this.state.slideIndex + n > component.items.length - 1) {
      slideIndex = 0
      prevSlide = this.state.slideIndex
      this.setState({
        prevSlide: prevSlide,
        slideIndex: slideIndex,
        transition: direction
      })
    }

    if (
      component.thumbnails &&
      component.thumbnails.length > 0 &&
      this.thumbnails.current
    ) {
      this.pushQuery(slideIndex)
      const element = document.getElementById(`thumbnail-${slideIndex}`)
      const clientWidth = this.thumbnails.current.clientWidth
      this.thumbnails.current.scrollTo({
        left: element.offsetLeft + element.clientWidth / 2 - clientWidth / 2,
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  getTouches = (evt) => {
    return evt.touches
  }

  handleTouchStart = (evt) => {
    const firstTouch = this.getTouches(evt)[0]
    this.setState({ xDown: firstTouch.clientX, yDown: firstTouch.clientY })
  }

  handleTouchMove = (evt) => {
    const { xDown, yDown } = this.state
    if (!xDown || !yDown) {
      return
    }

    const xUp = evt.touches[0].clientX
    const yUp = evt.touches[0].clientY

    const xDiff = xDown - xUp
    const yDiff = yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        this.switchSlide(+1)
      } else {
        this.switchSlide(-1)
      }
    }
    this.setState({ xDown: null, yDown: null })
  }

  render() {
    const { component, theme } = this.props
    const {
      slideIndex,
      transition,
      prevSlide,
      margins
      // slidesPerView
    } = this.state
    return (
      <div
        className={classNames(styles.mainContainer, {
          [styles.coldBrew]: theme === 'coldBrew'
        })}
      >
        {/* <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={40}
          slidesPerGroup={1}
          loop
          loopFillGroupWithBlank={false}
          pagination={{
            clickable: true
          }}
          onSwiper={(swiper) => this.setState({ swiperInstance: swiper })}
          className={styles.thumbnails}
          style={{ maxWidth: component.thumbnails.length * 260 + 'px' }}
        >
          {component.thumbnails.map((thumbnail, idx) => (
            <SwiperSlide
              key={idx}
              id={`thumbnail-${idx}`}
              className={styles.thumbnail}
              onClick={(e) => {
                this.jumpToSlide(idx, e.currentTarget)
              }}
            >
              <img
                src={thumbnail.image.url}
                alt={thumbnail.image.title}
                height="100px"
              />
              <h3
                className={classNames(styles.thumbnailText, {
                  [styles.active]: slideIndex === idx
                })}
              >
                {thumbnail.text}
              </h3>
            </SwiperSlide>
          ))}
        </Swiper> */}
        {component.thumbnails && component.thumbnails.length > 0 && (
          <div className={styles.thumbnailsContainer}>
            <div
              className={styles.thumbnails}
              ref={this.thumbnails}
              style={{ margin: `0 ${margins}px` }}
            >
              {component.thumbnails.map((thumbnail, idx) => (
                <div
                  key={idx}
                  id={`thumbnail-${idx}`}
                  className={styles.thumbnail}
                  onClick={(e) => {
                    this.jumpToSlide(idx, e.currentTarget)
                  }}
                >
                  <img
                    src={thumbnail.image.url}
                    alt={thumbnail.image.title}
                    height="100px"
                  />
                  <p
                    className={classNames(styles.thumbnailText, {
                      [styles.active]: slideIndex === idx
                    })}
                  >
                    {thumbnail.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles.container}>
          <div
            className={classNames(styles.carousel, {
              [styles.coldBrewPaddings]: theme === 'coldBrew'
            })}
            style={{ minHeight: getHeight() }}
            onTouchStart={(e) => {
              this.handleTouchStart(e)
            }}
            onTouchMove={(e) => this.handleTouchMove(e)}
          >
            <div className={styles.slides}>
              {component.items &&
                component.items.map((item, idx) => (
                  <div key={idx} className={styles.slideContainer}>
                    {idx === slideIndex && (
                      <div>
                        <div
                          className={classNames(styles.slideNumbers, {
                            [styles.thumbnailCarouselSlideNumbers]:
                              component.thumbnails &&
                              component.thumbnails.length > 0
                          })}
                        >
                          <span className={styles.currentSlide}>{idx + 1}</span>
                          /<span>{component.items.length}</span>{' '}
                          {item.tag && (
                            <span
                              className={styles.tag}
                            >{`[${item.tag}]`}</span>
                          )}
                        </div>
                      </div>
                    )}
                    <div
                      className={classNames(styles.slide, {
                        [styles[`visible${transition}`]]: slideIndex === idx,
                        [styles[`hidden${transition}`]]: slideIndex !== idx,
                        [styles.hidden]: slideIndex !== idx && idx !== prevSlide
                      })}
                    >
                      <HalfWidthMedia
                        component={item}
                        theme={theme}
                        id={idx}
                        slideIndex={slideIndex}
                        isCarouselSlide
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.arrows}>
            <button
              className={classNames(styles.arrow, {
                [styles.leftArrow]: true
              })}
              onClick={() => this.switchSlide(-1)}
            >
              <Arrow direction="left" />
            </button>
            <button
              className={classNames(styles.arrow, {
                [styles.rightArrow]: true
              })}
              onClick={() => this.switchSlide(+1)}
            >
              <Arrow direction="right" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

Carousel.propTypes = {
  component: PropTypes.object.isRequired
}

export default Carousel
