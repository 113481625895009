import { connect } from 'react-redux'
import { trackPageView } from 'actions/analytics'

import FilterOption from 'components/filter/filteroption/filteroption'
import { toggleFilter } from 'actions/filter'

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onClick: () => {
      dispatch(toggleFilter({
        filterKey: ownProps.filterKey,
        groupKey: ownProps.groupKey,
        optionKey: ownProps.optionKey
      }))
    },
    onFilter: (filter) => {
      dispatch(trackPageView({
        event: 'Explore',
        eventType: filter.catagory,
        eventDetail: filter.type
      }))
    }
  }
}

// We don't handle filters server side, so we don't need to pass any server state
// to the client for this.
function mapStateToProps(state, ownProps) {
  let active
  if (state.filter
    && state.filter[ownProps.filterKey]
    && state.filter[ownProps.filterKey][ownProps.optionKey]) {
    active = true
  } else {
    active = false
  }
  return {
    active
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterOption)
