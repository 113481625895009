import React from 'react'
import styles from './share.css'

const Share = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    className={styles.share}
  >
    <path
      className={styles.st0}
      d="M54,44c-2.8,0-5.4,1.2-7.2,3.1l-27-13.5c0.1-0.5,0.1-1,0.1-1.6c0-0.5-0.1-1.1-0.1-1.6l27-13.5
	c1.8,1.9,4.4,3.1,7.2,3.1c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10S44,4.5,44,10c0,0.5,0.1,1.1,0.1,1.6l-27,13.5
	C15.4,23.2,12.8,22,10,22C4.5,22,0,26.5,0,32c0,5.5,4.5,10,10,10c2.8,0,5.4-1.2,7.2-3.1l27,13.5c-0.1,0.5-0.1,1-0.1,1.6
	c0,5.5,4.5,10,10,10s10-4.5,10-10C64,48.5,59.5,44,54,44z"
    />
  </svg>
)

export default Share
