/* eslint react/no-danger: 0 */
import React, { Component } from 'react'
import classNames from 'classnames/bind'
import { withRouter } from 'react-router-dom'
import { track } from 'hoc'

import defaultTheme from './markdown.css'
import legalTheme from './legal.css'
import articleTheme from './article.css'
import jaegerTalesTheme from './jaegerTales.css'
import scharfTheme from './scharf.css'
import coldBrewTheme from './coldbrew.css'
import bannerLessLegalTheme from './bannerlesslegal.css'

class Markdown extends Component {
  static defaultProps = {
    className: '',
    theme: 'default',
    styles: null,
    track: () => {},
    bestNights: false,
    contentType: 'HomePage'
  }

  static getTheme(themeName) {
    switch (themeName) {
      case 'default':
        return defaultTheme
      case 'legal':
        return legalTheme
      case 'article':
        return articleTheme
      case 'jaegerTales':
        return jaegerTalesTheme
      case 'scharf':
        return scharfTheme
      case 'coldBrew':
        return coldBrewTheme
      case 'bannerLessLegal':
        return bannerLessLegalTheme
      case 'none':
        return {}
      default:
        // unknown theme - log error?
        return defaultTheme
    }
  }

  constructor(props) {
    super(props)
    this.handleInternalLink = this.handleInternalLink.bind(this)
  }

  /*
    All hrefs starting with a slash (e.g. '/drinks') will be pushed into react router's history
    all external links (i.e everything that doesn't start with a slash) opens in a new window
    TODO: Make sure this goes into authoring guidelines
  */
  handleInternalLink = (evt) => {
    if (evt.target.tagName.toLowerCase() === 'a') {
      const href = evt.target.getAttribute('href')
      const isInternal = href.match(/^\//)
      this.props.track({
        event: 'CTA',
        ContentType: this.props.contentType || 'undefined',
        ContentId: evt.target.innerText
      })
      // need to check if its an internal link and not from an error page
      if (isInternal && this.props.history) {
        evt.preventDefault()
        this.props.history.push(href)
      } else if (!isInternal) {
        evt.preventDefault()
        // TODO: Test if this is blocked by pop up blockers:
        const win = window.open(href, '_blank')
        if (win) win.focus()
      }
    }
  }
  updateLinks = (html) => {
    const isLink = /(<a\s*(?!.*\brel=)[^>]*)(href="https?:)((?!(?:(?:www\.)?('|(?:www\.)?', $follow_list).'))[^"]+)"((?!.*\brel=)[^>]*)(?:[^>]*)>/g
    let newLink = ''
    let newHtml = ''
    if (html && html.match && html.match(isLink)) {
      newLink = '$1$2$3"$4 rel="noopener">'
      newHtml = html.replace(isLink, newLink)
    }
    return newHtml
  }

  render() {
    const BN = this.props.bestNights
    const noOrangeBanner = this.props.className === 'noOrangeBanner'
    const styles = this.props.styles || Markdown.getTheme(this.props.theme)
    const classes = classNames([
      BN ? styles.markdownBN : '',
      noOrangeBanner ? styles.noOrangeBanner : '',
      styles.markdown,
      this.props.className,
      { [styles.inlineOverrides]: this.props.inline }
    ])
    // looked at dom libraries for node on the server eg jsdom and cheerio but they are over 100k
    // which is too large so i have decided to use regex in this case.
    const isImage = (html) => {
      if (typeof html === 'string') {
        const regex = /<img src=([^<>+]*)(\b['"].*?\/?.*?)(alt=[^./<>]*?)[^/]>/g

        const isJpgRegex = /.png/g

        let subst = ''
        // we make the assumption that only jpg/png images are used
        if (html && html.match && html.match(isJpgRegex)) {
          subst =
            '<picture><source type="image/webp" srcset=$1?fm=webp&w=1000&q=66"><img src=$1?&w=1000&q=66" $3" /></picture>'
        } else {
          subst =
            '<picture><source type="image/webp" srcset=$1?fm=webp&w=1000&q=66"><img src=$1?&w=1000&q=66&fl=progressive" $3" /></picture>'
        }
        if (html && html.match) {
          this.updateLinks(html)
        }
        const result = html.replace
          ? html.replace(regex, subst)
          : html.key
          ? html.key
          : html
        return result
      }
    }
    // We want to listen to clicks within the markdown so that if an anchor is clicked,
    // we handle it accordingly. This doesn't affect a11y as the child elements that we
    // are looking for are anchors.
    //
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    const markdown = typeof this.props.markdownHtml === 'string' ? true : false
    return (
      (markdown && (
        <div
          onClick={this.handleInternalLink}
          className={classes}
          dangerouslySetInnerHTML={{ __html: isImage(this.props.markdownHtml) }}
        />
      )) || <div>{this.props.markdownHtml}</div>
    )
  }
}

const TrackedMarkdown = track(Markdown)
const MarkdownWithRouter = withRouter(TrackedMarkdown)
const MarkdownForErrorPage = Markdown
export { TrackedMarkdown as MarkdownNoRouter }
export { MarkdownWithRouter as Markdown }
export { MarkdownForErrorPage }
export default MarkdownWithRouter
