import React from 'react'
import PropTypes from 'prop-types'

import { FullBleedImage, Ingredients, Steps, NotFound, Markdown, MetaData, VideoToVideo, DrinkProst } from 'components'
import { Recommendations, DrinkCompleted } from 'containers'

import styles from './drink.css'

const Drink = ({ content }) => {
  const drink = content

  if (!drink || !drink.enabled) {
    return <NotFound />
  }
  const hasDrinkHeroMode = drink.hero && drink.hero.mode
  const hasDrinkVideo = drink.hero && drink.hero.videoOne
  return (<main  role="main" id="mainContent">
    <MetaData metadata={drink.metadata} />
    { hasDrinkHeroMode && <FullBleedImage data={drink.hero} imageClass={styles.fullBleedImage} />}
    { hasDrinkVideo && <VideoToVideo videos={drink.hero} />}
    {drink.ingredients && <Ingredients ingredients={drink.ingredients} />}
    {drink.steps && <Steps steps={drink.steps} />}
    {drink.taste && <div className={styles.taste}><Markdown markdownHtml={drink.taste} /></div> }
    {(drink.completedImage && (
      <DrinkCompleted completedImage={drink.completedImage} recipeDownload={drink.recipeDownload} tags={drink.tags} />)) ||
      <DrinkProst />
    }
    {/*
      Social grid/ugc is disabled for release 1 but may come back later
      <SocialGrid ugc={drink.ugc} />
    */}
    {drink.recommendations && <Recommendations recommendations={drink.recommendations} />}
  </main>)
}

Drink.propTypes = {
  content: PropTypes.object.isRequired
}

export default Drink
