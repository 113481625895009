// Standard tile used by the grid

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './instagramPost.css'
import { Image } from 'components'

import { instagram as InstagramIcon } from 'svgs/socialIcons/SocialIcons'

class InstagramPost extends Component {
  static defaultProps = {
    url: null,
    image: null,
    isBrandAccount: false,
    tracking: () => {}
  }

  static propTypes = {
    url: PropTypes.string,
    handle: PropTypes.string.isRequired,
    isBrandAccount: PropTypes.bool,
    image: PropTypes.object,
    tracking: PropTypes.func
  }
  renderContents() {
    return (
      <div>
        <div className={styles.icon}>
          <InstagramIcon pathClass={styles.path} />
        </div>
        {this.props.handle && (
          <h3 className={styles.handle}>
            @ {this.props.handle.replace('@', '')}
          </h3>
        )}
        {this.props.image && (
          <Image
            className={styles.image}
            src={this.props.image.url}
            alt={this.props.handle.replace('@', '')}
            mobileSizes={this.props.gridItemConfig === '25' ? '50vw' : '100vw'}
            // leave pictures that are wider thant the viewport big enough not to be downscaled
            sizes={
              (this.props.gridItemConfig < 50
                ? 50
                : this.props.gridItemConfig) + 'vw'
            }
          />
        )}
      </div>
    )
  }
  render() {
    if (this.props.url) {
      return (
        <a
          href={this.props.url}
          target="_blank"
          rel="noopener"
          className={this.props.isBrandAccount ? styles.brandedPost : styles.unbrandedPost}
          onClick={this.props.tracking}
        >
          {this.renderContents()}
        </a>)
    }
    return (
      <div className={this.props.isBrandAccount ? styles.brandedPost : styles.unbrandedPost}>
        {this.renderContents()}
      </div>)
  }
}

export default InstagramPost
