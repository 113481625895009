import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'

import classNames from 'classnames'

import { Generic, HeroComponent, MetaData, Button } from 'components'
import { DownloadButton, Recommendations } from 'containers'

import { downloadPdf } from 'utils/i18n'

import styles from './generic.css'

class GenericPage extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      showText: false
    }
  }

  toggleContactText = () => {
    this.setState({ showText: !this.state.showText })
  }

  render() {
    const {
      content,
      trackingVideo,
      endPoint,
      recaptchaKey,
      integrationsFormEndpoint,
      complaintFormEndpoint,
      salesforceSyncEndpoint
    } = this.props
    const genericComponents = content.components
    const hero = content.hero
    const siteText = {
      downloadPdf: downloadPdf()
    }
    return (
      <main className={styles.generic} role="main" id="mainContent">
        <MetaData metadata={content.metadata} />
        {hero && (
          <section className={styles.genericHeader}>
            {hero &&
              hero.map((d) =>
                d.contentType === 'heroComponent' ? (
                  <section key={d.title} className={styles.hero}>
                    <HeroComponent hero={d} />
                  </section>
                ) : (
                  <div>
                    <Generic
                      components={d}
                      trackingVideo={trackingVideo}
                      key={d.id}
                      endPoint={endPoint}
                      isHero={hero ? true : false}
                    />
                  </div>
                )
              )}
            <div className={styles.links}>
              {hero &&
                hero.map(
                  (d) =>
                    d.links &&
                    d.links.map((m) => (
                      <NavLink
                        to={m.route}
                        className={styles.link}
                        activeClassName={styles.active}
                      >
                        {m.text}
                      </NavLink>
                    ))
                )}
            </div>
          </section>
        )}
        {genericComponents && (
          <section
            className={classNames(styles.genericContainer, {
              [styles.backgroundBlack]: content.backgroundColor === 'black'
            })}
          >
            {genericComponents &&
              genericComponents.map((d) => (
                <Generic
                  components={d}
                  trackingVideo={trackingVideo}
                  key={d.id}
                  endPoint={endPoint}
                  integrationsFormEndpoint={integrationsFormEndpoint}
                  complaintFormEndpoint={complaintFormEndpoint}
                  salesforceSyncEndpoint={salesforceSyncEndpoint}
                  config={{
                    recaptchaKey
                  }}
                />
              ))}
            {content.downloadPdf && (
              <div className={styles.downloadContainer}>
                <DownloadButton
                  trackingEventType={siteText.downloadPdf}
                  trackingEventDetail="PDF"
                  href={(content.downloadPdf && content.downloadPdf.url) || '#'}
                >
                  {siteText.downloadPdf}
                </DownloadButton>
              </div>
            )}
          </section>
        )}
        {content.cta && (
          <section>
            <div className={styles.button}>
              <Button
                onClick={this.seeMoreButtClick}
                href={content.cta.url ? content.cta.url : content.cta.route}
                className={styles.cta}
              >
                {content.cta.text}
              </Button>
            </div>
          </section>
        )}
        {content.recommendations && (
          <Recommendations recommendations={content.recommendations} />
        )}
      </main>
    )
  }
}

GenericPage.defaultProps = {
  content: {},
  trackingVideo: () => {},
  endPoint: ''
}
GenericPage.propTypes = {
  content: PropTypes.object,
  endPoint: PropTypes.string,
  trackingVideo: PropTypes.func
}
export default GenericPage
