import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Markdown } from 'components'

import { Button } from 'containers'
import styles from './eventfeaturedlong.css'


const EventFeaturedLong = (props) => {
  const { data } = props
  const hasLink = (el = '', idx = 1) => {
    let link = el
    const id = idx
    if (link.route) {
      link = (<NavLink to={link.route} key={link.id + id} className={styles.lineUpLink}>{link.text}</NavLink>)
    } else if (link.url) {
      link = (<a href={link.url} key={link.id + id} target="_blank" rel="noopener noreferrer" className={styles.lineUpLink}>{link.text}</a>)
    } else {
      link = (<span key={link.id + idx}>{link.text || link.title}</span>)
    }
    return link
  }
  return (
    <div className={styles.container} key={data.id}>
      <div className={styles.dateContainer}>
        <div className={styles.date}>{data.date}</div>
        <div className={styles.day}>{data.day}</div>
        <div className={styles.month}> {data.month} </div>
      </div>
      <div className={styles.headline}>
        <h1>{data.title}</h1>
      </div>
      <div className={styles.wrapper}>
        <Markdown markdownHtml={data.description} />
        <div className={styles.lineUp}>
          <h5 className={styles.subtitle}>{data.subtitle}</h5>
          <ul className={styles.lineUpList}>
            { data.links && data.links.map((d, idx) => ( // eslint-disable-next-line react/no-array-index-key used only if 2 links are coppied.
              <li key={d.id + idx} className={styles.lineUpListItem}>{ hasLink(d, idx) }</li>)
            ) }
          </ul>
          { data.cta && data.cta.url && <Button className={styles.lineUpCta} href={data.cta.url}>{data.cta.text}</Button> }
        </div>
      </div>
    </div>
  )
}

EventFeaturedLong.propTypes = {
  data: PropTypes.object.isRequired,
}

export default EventFeaturedLong
