// There are 2 entry points to the client application - index.js and index.livereload.js
// Don't implement anything in these files that is needed by the application without
// implementing it in both files.
// Ideally don't use these files at all - put implementations in ClientApp
import React from 'react'
import { render } from 'react-dom'
import ClientApp from './ClientApp'

// removing as this causes changes to not be published.  needs investigation
import { unregisterServiceWorker } from '../shared/lib/service-worker-registration'

render(<ClientApp />, document.getElementById('app'))

unregisterServiceWorker()
