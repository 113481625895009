import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FieldsetBN from '../fieldsetBN/FieldsetBN'

import styles from './mailchimpForm.css'
import fetch from '../../lib/fetch'

class MailchimpForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      submitting: false,
      thankYou: false,
      failedSubmit: false,
      memberAlreadyExists: false
    }
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    if (this.state.submitting) {
      return
    }

    this.setState({
      submitting: true,
      failedSubmit: false,
      thankYou: false,
      memberAlreadyExists: false
    })
    fetch(this.props.endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        first_name: this.state.first_name,
        last_name: this.state.last_name
      })
    })
      .then(this.onSuccessfulSubmit)
      .catch(this.onFailedSubmit)
  }

  onSuccessfulSubmit = (response) => {
    this.setState({ submitting: false })

    if (!response.ok) {
      this.setFailedState()
      response.json().then(body => {
        if (body.title === 'Member Exists') {
          this.setState({memberAlreadyExists: true})
        }
        throw new Error(body.title)
      })
      return
    }

    this.setState({
      failedSubmit: false,
      thankYou: true
    })
  }

  onFailedSubmit = (error) => {
    this.setFailedState()
    throw new Error(error)
  }

  setFailedState = () => {
    this.setState({
      submitting: false,
      failedSubmit: true,
      thankYou: false
    })
  }

  replaceSup = (text) => {
    return text.replace('¹', '<sup><span>1</span></sup>')
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <FieldsetBN isLogo={false} />
        <div className={styles.content}>
          <div className={styles.column}>
            <div
              className={styles.claim}
              dangerouslySetInnerHTML={{ __html: this.replaceSup(
                this.state.thankYou ? this.props.thankYouMessage : this.props.claim
              ) }}
            />
          </div>
          <div className={styles.column}>
            <form
              action={this.props.endpoint}
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              onSubmit={this.onSubmit}
              className={this.state.thankYou ? styles.hidden : null}
            >
              <div className={styles.formContent}>
                <div className={styles.fieldWrapper}>
                  <input
                    value={this.state.first_name}
                    onChange={this.handleInputChange}
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                  />
                </div>
                <div className={styles.fieldWrapper}>
                  <input
                    value={this.state.last_name}
                    onChange={this.handleInputChange}
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                  />
                </div>
                <div className={styles.fieldWrapper}>
                  <input
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    type="email"
                    name="email"
                    required
                    placeholder="Email"
                  />
                </div>
                <div className={styles.submitWrapper}>
                  <button type="submit" className={styles.submitButton}>
                    Submit
                  </button>
                </div>
              </div>
              {this.state.failedSubmit && (
                <div className={styles.errorMessage}>
                  {this.state.memberAlreadyExists ? this.props.userAlreadyExistsErrorMessage : this.props.errorMessage}
                </div>
              )}
              {this.props.disclaimer && (
                <div className={styles.disclaimer}>{this.props.disclaimer}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

MailchimpForm.propTypes = {
  claim: PropTypes.string,
  disclaimer: PropTypes.string,
  thankYouMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  userAlreadyExistsErrorMessage: PropTypes.string,
  endpoint: PropTypes.string
}

MailchimpForm.defaultProps = {
  claim: '',
  disclaimer: '',
  thankYouMessage: 'Almost done, please check your inbox.',
  errorMessage: 'Something went wrong, please try again.',
  userAlreadyExistsErrorMessage: 'The email address is already registered.',
  endpoint: ''
}

export default MailchimpForm
