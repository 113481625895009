import { createMiddleware } from 'redux-beacon'
import { GoogleTagManager } from 'redux-beacon/targets/google-tag-manager'

const track = {
  eventFields: action => ({
    ...action.payload
  })
}

// Redux actions to track
const trackingEventMap = {
  PAGE_VIEW: track,
  VIDEO_PLAY: track,
  EVENT: track,
  HASH_EMAIL: track
  
}

const beaconMiddleware = createMiddleware(trackingEventMap, GoogleTagManager)

export default beaconMiddleware
