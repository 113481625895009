import React from 'react'
import styles from './fieldsetBN.css'
import PropTypes from 'prop-types'
import { Markdown } from '../index'
import classNames from 'classnames'
import Anchor from '../anchor/anchor'

const FieldsetBN = ({
  isVideo,
  text,
  isLogo,
  headline,
  isGridTile,
  linkTo,
  imgQuote,
  quotePosition
}) => {
  return (
    <fieldset
      className={classNames(
        imgQuote ? styles.borderOrangeQuote : styles.borderOrange,
        isLogo ? styles.borderOrangeWithLogo : '',
        isGridTile ? styles.blackGradient : '',
        isVideo ? styles.pointerVideo : ''
      )}
    >
      {!isGridTile && (
        <legend className={isLogo ? styles.borderLogo : styles.hideLogo}>
          <Anchor to={linkTo} aria-label="Best Nights">
            <div className={styles.logoImg}></div>
          </Anchor>
        </legend>
      )}
      {isGridTile === false ? (
        imgQuote === false ? (
          <div className={styles.textContainer}>
            <Anchor to={linkTo} aria-label="Best Nights">
              {headline !== '' && <Markdown markdownHtml={headline} />}
              <Markdown markdownHtml={text} />
            </Anchor>
          </div>
        ) : (
          <div
            className={classNames(
              styles.quoteContainer,
              quotePosition === 'Quote on right'
                ? styles.quoteRight
                : styles.quoteLeft
            )}
          >
            <div className={classNames(styles.quoteWrapper, styles.halfWidth)}>
              <div
                className={classNames(
                  styles.textQuote,
                  quotePosition === 'Quote on right'
                    ? styles.quoteRightPadding
                    : styles.quoteLeftPadding
                )}
              >
                {text}
              </div>
            </div>
            <div className={styles.halfWidth}></div>
          </div>
        )
      ) : (
        <div className={classNames(styles.textContainerGrid)}>
          {headline && (
            <h3
              className={styles.textContainerGridTitle}
              dangerouslySetInnerHTML={{
                __html: headline.replace(/(<([^>]+)>)/gi, '')
              }}
            ></h3>
          )}
          {text && (
            <Markdown markdownHtml={text} className={styles.textGridTile} />
          )}
        </div>
      )}
    </fieldset>
  )
}

FieldsetBN.propTypes = {
  text: PropTypes.string,
  isVideo: PropTypes.bool,
  isLogo: PropTypes.bool,
  headline: PropTypes.string,
  isGridTile: PropTypes.bool,
  linkTo: PropTypes.string,
  imgQuote: PropTypes.bool,
  quotePosition: PropTypes.string,
  isHero: PropTypes.bool
}

FieldsetBN.defaultProps = {
  text: '',
  isVideo: false,
  isLogo: true,
  headline: '',
  isGridTile: false,
  linkTo: '',
  imgQuote: false,
  quotePosition: 'Quote on right',
  isHero: false
}
export default FieldsetBN
