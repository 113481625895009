import React from 'react'

import styles from './artistsidebyside.css'
import { Tile } from '../'

export default ({ data }) => {
  const tile1 = data.artistTiles && data.artistTiles[0]
  const tile2 = data.artistTiles && data.artistTiles[1]
  // TODO: can make this a map
  return (<div className={styles.tiles} key={tile1.id}>
    {tile1 && <div className={styles.tile}>
      <Tile
        title={tile1.title}
        caption={tile1.caption}
        image={tile1.image}
        imageClassName={styles.image}
        linkTo={tile1.link && (tile1.link.url || tile1.link.route)}
        tint={tile1.tint}
      />
    </div>}
    {tile2 && <div className={styles.tile} key={tile2.id}>
      <Tile
        title={tile2.title}
        caption={tile2.caption}
        image={tile2.image}
        imageClassName={styles.image}
        linkTo={tile2.link && (tile2.link.url || tile2.link.route)}
        tint={tile2.tint}
      /></div>}
  </div>)
}
