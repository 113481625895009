import React from 'react'

import styles from './close.css'

const Close = () => (
  <svg viewBox="0 0 200 200" className={styles.close}>
    <title>close</title>
    <g>
      <path fill="none" stroke="#E9E3C7" strokeMiterlimit="10" strokeWidth="16px" d="M27 173L173 27M27 27l146 146" />
    </g>
  </svg>
)

export default Close
