import React from 'react'
import PropTypes from 'prop-types'

import styles from './downloadtracks.css'
import { DownloadButton } from '../'


const DownloadTracks = (props) => {
  const { data, forAccordion} = props
  return (
    <div className={styles.forAccordionContainer}>
      { data && data.title && <h3 className={styles.heading}>{data.title}</h3> }
      { data && data.tracks && data.tracks.map((d, idx) => (
        <div className={forAccordion ? styles.buttonLink : styles.artistTracks} key={d.id + idx}>
          <DownloadButton
            trackingEventType="Download music track"
            trackingEventDetail="MP3"
            href={d.button.url || '#'}
          >{d.title}</DownloadButton>
        </div>
    ))}
    </div>)
}

DownloadTracks.propTypes = {
  data: PropTypes.object,
  forAccordion: PropTypes.boolean
}
DownloadTracks.defaultProps = {
  data: {},
  forAccordion: false
}
export default DownloadTracks
