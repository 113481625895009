import React, { Component } from 'react'
import { connect } from 'react-redux'
import { trackPageView } from 'actions/analytics'
import IntegratedForm from 'components/form/IntegratedForm'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

class IntegratedFormContainer extends Component {
  render() {
    const { config, data } = this.props
    if (config?.recaptchaKey && data.formType !== 'complaint') {
      return (
        <GoogleReCaptchaProvider
          scriptProps={{
            async: true
          }}
          reCaptchaKey={config.recaptchaKey}
        >
          <IntegratedForm {...this.props} />
        </GoogleReCaptchaProvider>
      )
    }
    return <IntegratedForm {...this.props} />
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { data } = ownProps
  return {
    onSuccess: (formData) => {
      const formType = data.formType
      dispatch(
        trackPageView({
          event: formType,
          message: formData.message
        })
      )
    },
    trackOnSubmit: () => {
      if (data && data.onSubmitTracking) {
        dispatch(
          trackPageView({
            event: 'IntegratedForm',
            eventType: data.onSubmitTracking.eventType,
            eventDetail: data.onSubmitTracking.eventDetail
          })
        )
      }
    },
    trackOnSuccess: () => {
      if (data && data.onSuccessTracking) {
        dispatch(
          trackPageView({
            event: 'IntegratedForm',
            eventType: data.onSuccessTracking.eventType,
            eventDetail: data.onSuccessTracking.eventDetail
          })
        )
      }
    }
  }
}
function mapStateToProps(state) {
  return {
    locale: state.locale
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegratedFormContainer)
