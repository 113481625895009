import React from 'react'
import PropTypes from 'prop-types'

import { MetaData, Generics, Generic } from 'components'
import { Recommendations } from 'containers'

const ProductsPage = ({ content }) => (
  <main role="main" id="mainContent">
    <MetaData metadata={content.metadata} />
    {content.hero && <Generic components={content.hero} />}
    {content.components && <Generics components={content.components} />}
    {content.recommendations && (
      <Recommendations recommendations={content.recommendations} />
    )}
  </main>
)
ProductsPage.propTypes = {
  content: PropTypes.object
}

ProductsPage.defaultProps = {
  content: {}
}

export default ProductsPage
