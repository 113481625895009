/*

This component takes a set of child components.
It creates containers with a complex set of rules for each child component.

For rules - see MODULES_modular_grid_v3.ai
(ignore 15+)


On mobile, all 25% -> 50%, all others, 33%+ -> 100% width


when compact=true, if the grid has 2 or 3 items it displays in 1 row
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash.chunk'
import classNames from 'classnames'

import {
  Button,
  Tile,
  InstagramPost,
  Tweet,
  RibbonTile /* , Recommendation */
} from '..'
import { Markdown } from 'components'

import styles from './grid.css'
import BestNightsBanner from '../bestnightsbanner/bestnightsbanner'

/*
 Item sizes desktop:mobile

 1. 100%:100%
 2. 67%:100%
 3. 50%:100%
 4. 33%:100%
 5. 25%:50%

 Row types

1 1A - 1

2 2A - 2/3, 1/3
3 2B - 1/3, 2/3
4 2C - 50% 50%

5 3A - 50% 25% 25%
6 3B - 25% 25% 50%

7 4A - 4*25%
*/

const FULL = '100'
const LARGE = '67'
const MEDIUM = '50'
const SMALL = '33'
const SMALLEST = '25'

const ITEMS = {
  FULL,
  LARGE,
  MEDIUM,
  SMALL,
  SMALLEST
}

const ROW1A = [FULL]
const ROW2A = [LARGE, SMALL]
const ROW2B = [SMALL, LARGE]
const ROW2C = [MEDIUM, MEDIUM]
const ROW3A = [MEDIUM, SMALLEST, SMALLEST]
const ROW3B = [SMALLEST, SMALLEST, MEDIUM]
const ROW4A = [SMALLEST, SMALLEST, SMALLEST, SMALLEST]

const ROWS = {
  ROW1A,
  ROW2A,
  ROW2B,
  ROW2C,
  ROW3A,
  ROW3B,
  ROW4A
}

const GRID_CONFIG = {
  1: [ROW1A],
  2: [ROW1A, ROW1A],
  3: [ROW1A, ROW2A],
  4: [ROW2C, ROW2A],
  5: [ROW2B, ROW3A],
  6: [ROW2A, ROW2C, ROW2B],
  7: [ROW2A, ROW3B, ROW2B],
  8: [ROW2B, ROW2C, ROW2A, ROW2B],
  9: [ROW2C, ROW2A, ROW2B, ROW3A],
  10: [ROW2A, ROW4A, ROW2B, ROW2C],
  11: [ROW2C, ROW2B, ROW3B, ROW2A, ROW2C],
  12: [ROW2B, ROW2C, ROW2A, ROW4A, ROW2A]
}

const GRID_CONFIG_COMPACT = {
  ...GRID_CONFIG,
  2: [ROW2A],
  3: [ROW3A]
}

const GRID_CONFIG_BN = {
  ...GRID_CONFIG,
  5: [ROW2B, ROW2C, ROW1A],
  7: [ROW2A, ROW2C, ROW2B, ROW1A],
  9: [ROW2C, ROW2A, ROW2B, ROW2C, ROW1A],
  10: [ROW2A, ROW2B, ROW2C, ROW2B, ROW2A],
  11: [ROW2C, ROW2B, ROW2C, ROW1A, ROW2A, ROW2C],
  12: [ROW2B, ROW2C, ROW2A, ROW2B, ROW2C, ROW2A]
}

const GRIDS = {
  GRID_CONFIG,
  GRID_CONFIG_COMPACT
}

const renderChildrenChunk = (
  childrenChunk,
  compact = true,
  chunkIndex,
  loadMoreThreshold = null,
  theme
) => {
  let config
  let isBestNights = theme === 'bestNights'
  if (loadMoreThreshold > 0) {
    config = []
    for (let i = 0; i < Math.ceil(childrenChunk.length / 2); i++) {
      config.push([ITEMS.MEDIUM, ITEMS.MEDIUM])
    }
  } else if (compact) {
    config = GRID_CONFIG_COMPACT[childrenChunk.length]
  } else if (isBestNights) {
    config = GRID_CONFIG_BN[childrenChunk.length]
  } else {
    config = GRID_CONFIG[childrenChunk.length]
  }

  let n = 0
  return (
    childrenChunk && (
      <div key={chunkIndex} className={styles.tilesWrapper}>
        {config &&
          config.map((rowConfig, rowIndex) => (
            // I am using indexes for keys here as I can't think of an alternative, see:
            // https://github.com/yannickcr/eslint-plugin-react/issues/1123
            // http://stackoverflow.com/questions/42983067/breaking-children-in-to-chunks-and-react-no-array-index-key
            // eslint-disable-next-line react/no-array-index-key
            <div key={rowIndex} className={styles.row}>
              {rowConfig.map((itemConfig, itemIndex) => {
                const item = childrenChunk[n]
                const hidden = loadMoreThreshold > 0 && n >= loadMoreThreshold
                n += 1
                // eslint-disable-next-line react/no-array-index-key
                return (
                  <div
                    key={itemIndex}
                    className={classNames(
                      isBestNights ? styles.itemBestNights : '',
                      styles[`item${itemConfig}`],
                      {
                        [styles.hidden]: hidden
                      }
                    )}
                  >
                    <div className={styles.itemContainer}>
                      {item &&
                        React.cloneElement(item, {
                          gridItemConfig: itemConfig
                        })}
                    </div>
                  </div>
                )
              })}
            </div>
          ))}
      </div>
    )
  )
}

class Grid extends Component {
  static defaultProps = {
    isCompact: false,
    loadMoreThreshold: null,
    theme: 'default'
  }

  static getTileComponentsFromJson(tilesJson, tracking) {
    // TODO: map depending on type
    return tilesJson
      .filter((tileJson) => tileJson)
      .map((tileJson) => {
        switch (tileJson.contentType) {
          case 'tileComponent':
            return (
              <Tile
                title={tileJson.title}
                caption={tileJson.caption}
                image={tileJson.image}
                linkTo={
                  tileJson.link && (tileJson.link.url || tileJson.link.route)
                }
                tint={tileJson.tint}
                tracking={(e) => tracking(e, 'tile')}
              />
            )
          case 'tweet':
            return (
              <Tweet
                url={tileJson.url}
                handle={tileJson.handle}
                content={tileJson.content}
                isBrandAccount={tileJson.isBrandAccount}
                profileImage={tileJson.profileImage}
                tracking={(e) => tracking(e, 'twitter')}
              />
            )
          case 'instagramPost':
            return (
              <InstagramPost
                key={tileJson.key}
                url={tileJson.url}
                handle={tileJson.handle}
                isBrandAccount={tileJson.isBrandAccount}
                image={tileJson.image}
                trackInstagramClick
                tracking={(e) => tracking(e, 'instagram')}
              />
            )
          case 'ribbonTile':
            return (
              <RibbonTile
                title={tileJson.title}
                subtitle={tileJson.subtitle}
                caption={tileJson.caption}
                image={tileJson.image}
                linkTo={
                  tileJson.link && (tileJson.link.url || tileJson.link.route)
                }
                tracking={(e) => tracking(e, 'ribbon')}
              />
            )
          case 'bestNightsBanner':
            return (
              <BestNightsBanner
                component={tileJson}
                context={'grid'}
              />
            )
          // case 'recommendationComponent':
          //   return (<Recommendation
          //     title={tileJson.title}
          //     caption={tileJson.caption}
          //     image={tileJson.image}
          //     linkTo={tileJson.link && (tileJson.link.url || tileJson.link.route)}
          //   />)
          default:
            return null
        }
      })
  }


  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    tiles: PropTypes.array,
    isCompact: PropTypes.bool,
    loadMoreThreshold: PropTypes.number,
    theme: PropTypes.string
  }

  constructor(...rest) {
    super(...rest)
    this.state = {
      expanded: false
    }
    this.generateChildrenChunks(
      this.props.children,
      this.props.tiles,
      this.props.trackGridClick
    )
  }

  componentWillReceiveProps(nextProps) {
    // TODO: only need to reprocess if there have been changes
    this.generateChildrenChunks(
      nextProps.children,
      nextProps.tiles,
      this.props.trackGridClick
    )
  }

  generateChildrenChunks(children, tilesIn, tracking) {
    let mergedChildren = []
    if (children) {
      mergedChildren.push(...children)
    }
    if (tilesIn) {
      mergedChildren.push(...Grid.getTileComponentsFromJson(tilesIn, tracking))
    }

    // remove invalid
    mergedChildren = mergedChildren.filter((child) => child)

    // split in to groups of 11
    const childrenChunks =
      this.props.loadMoreThreshold > 0
        ? [mergedChildren] // Only one chunk
        : chunk(mergedChildren, 11)

    this.childrenChunks = childrenChunks
  }

  // TODO: figure out why this is re rendering on every scroll
  render() {
    const compact = this.props.isCompact
    const { text, background } = this.props
    return (
      <div
        className={classNames(styles.grid, {
          [styles.expanded]: this.state.expanded
        })}
      >
        {text && (
          <div
            className={classNames(styles.text, {
              [styles.black]: background === 'black'
            })}
          >
            <Markdown markdownHtml={text} theme="default" />
          </div>
        )}
        {this.childrenChunks &&
          this.childrenChunks.map((childrenChunk, index) =>
            renderChildrenChunk(
              childrenChunk,
              index !== 0 || compact,
              index,
              this.props.loadMoreThreshold,
              this.props.theme
            )
          )}
        {this.props.loadMoreThreshold > 0 &&
          this.childrenChunks[0].length > this.props.loadMoreThreshold && (
            <div className={styles.loadMore}>
              <Button
                onClick={() => {
                  this.setState({ expanded: true })
                }}
                className={styles.cta}
              >
                Load more
              </Button>
            </div>
          )}
      </div>
    )
  }
}

export default Grid

export { ROWS, ITEMS, GRIDS }
