import { VISITOR_AGE } from '../actions/visitorAge'


export default function (state = { age: '' }, action) {
  let hasChanged = false
  let newState
  switch (action.type) {
    case VISITOR_AGE:
      hasChanged = true
      newState = { ...state }
      newState.age = action.value
      break
    default:
      break
  }
  return hasChanged ? newState : state
}
