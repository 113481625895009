export const PAGEY = 'PAGEY'
export const DELTA = 'DELTA'

export function windowPosition(pos) {
  return {
    type: PAGEY,
    value: pos
  }
}

export function gesture(delta) {
  return {
    type: DELTA,
    value: delta
  }
}
