import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import RecommendationLink from './RecommendationLink'

import styles from './recommendations.css'

import { Image, Markdown } from '../'

class Recommendations extends Component {
  // if we had API docs for the transform layer we could generate these propTypes automatically
  static propTypes = {
    recommendations: PropTypes.shape({
      title: PropTypes.string.isRequired,
      recommendationItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string.isRequired,
          internalLink: PropTypes.shape({
            route: PropTypes.string
          }),
          image: PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string
          })
        })
      )
    }).isRequired,
    onClick: PropTypes.func.isRequired
  }
  renderNextPage() {
    const items = this.props.recommendations.recommendationItems
    return (
      items &&
      items.map(
        (d, idx) =>
          d &&
          (d.internalLink || d.externalLink) && (
            <RecommendationLink
              onClick={() => this.props.onClick({ subtitle: d.subTitle })}
              to={d.internalLink ? d.internalLink.route : d.externalLink}
              key={idx}
              className={styles.recommendationLink}
              internal={d.internalLink}
            >
              {d.image && (
                <Image
                  src={d.image.url}
                  sizes="50vw"
                  mobileSizes="100vw"
                  alt={d.image.title}
                  className={styles.recommendationImage}
                  objectFit={true}
                  lazyLoading={true}
                />
              )}
              <div className={styles.inner}>
                <div className={styles.headings}>
                  <h4 className={styles.subTitle}>{d.subTitle}</h4>
                  <h3 className={styles.textTitle}>
                    {<Markdown markdownHtml={d.title} />}
                  </h3>
                </div>
                {d.text && (
                  <div className={styles.content}>
                    <Markdown markdownHtml={d.text} />
                  </div>
                )}
              </div>
            </RecommendationLink>
          )
      )
    )
  }
  render() {
    if (!this.props.recommendations) {
      return <div />
    }
    return (
      <div className={styles.recommendations}>
        <Markdown
          className={styles.title}
          markdownHtml={this.props.recommendations.title}
        />
        <div className={styles.links} onClick={this.trackingLink}>
          {this.renderNextPage()}
        </div>
      </div>
    )
  }
}

export default Recommendations
