import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { Img, Markdown, OnVisible } from '../'

import styles from '../halfwidthpng/halfwidthpng.css'
import barStyles from './halfwidthpngchart.css'

const config = (styles, headlineClass) => {
  return {
    noText: {
      textClass: ''
    },
    imageLeftTextMiddleCenter: {
      headlineClass: headlineClass || styles.headline,
      textHorizontalClass: styles.textLeft,
      textVerticalClass: styles.textMiddle,
      imagePosition: 'left'
    },
    imageLeftTextBottomCenter: {
      headlineClass: headlineClass || styles.headline,
      textHorizontalClass: styles.textLeft,
      textVerticalClass: styles.textBottom,
      imagePosition: 'left'
    },
    imageRightTextBottomCenter: {
      headlineClass: classNames(
        headlineClass || styles.headline,
        styles.headlineRight
      ),
      textHorizontalClass: styles.textRight,
      textVerticalClass: styles.textBottom,
      imagePosition: 'right'
    },
    imageRightTextMiddleCenter: {
      headlineClass: classNames(
        headlineClass || styles.headline,
        styles.headlineRight
      ),
      textHorizontalClass: styles.textRight,
      textVerticalClass: styles.textMiddle,
      imagePosition: 'right'
    },
    imageCenterTextMiddleLeft: {
      headlineClass: headlineClass || styles.headline,
      textHorizontalClass: styles.textLeft,
      textVerticalClass: styles.textMiddle,
      imagePosition: 'center'
    }
  }
}
const renderFields = (fields, idx) => {
  const name = fields.barChartFieldName.toLowerCase()
  const classes = classNames(barStyles.row, barStyles[name])
  return (
    <div className={classes} key={idx}>
      <dt className={barStyles.label}> {fields.barChartFieldName} </dt>{' '}
      <dd className={barStyles.bar}>
        {' '}
        <span
          key={fields.id}
          className={barStyles.barValue}
          style={{
            width: `${fields.barChartFieldValue}%`
          }}
        />{' '}
      </dd>{' '}
    </div>
  )
}
const HalfWidthPngChart = (props) => {
  const { image, headline, text, mode, barChart } = props.data
  const {
    historyOne,
    historyTwo,
    historyThree,
    jagertalesHero,
    textToTheRight,
    circleImage,
    withCaption,
    textLonger,
    headlineClass,
    imageClass,
    contentWidthClass,
    animationOffsetPercent,
    reverseMobile,
    contentType
  } = props
  const wrapperClasses = classNames(
    styles.wrapper,
    {
      [styles.wrapperReverse]: config(styles)[mode].imagePosition === 'right',
      [styles.wrapperCenter]: config(styles)[mode].imagePosition === 'center',
      [styles.wrapperHistoryOne]: historyOne,
      [styles.wrapperHistoryTwo]: historyTwo,
      [styles.wrapperReverseMobile]: reverseMobile,
      [styles.jagertalesHero]: jagertalesHero
    }
  )
  const textClasses = classNames(
    barStyles.text,
    config(styles)[mode].textHorizontalClass,
    config(styles)[mode].textVerticalClass
  )
  const titleClass = classNames(
    config(styles, headlineClass)[mode].headlineClass,
    {
      [headlineClass]: headlineClass
    }
  )
  const textInnerClasses = classNames(barStyles.textInner, {
    [styles.textToTheRight]: textToTheRight,
    [styles.textLonger]: textLonger,
    [styles.innerTwentyFive]:
      config(styles)[mode].textVerticalClass === styles.textMiddle
  })
  const imageClasses = classNames(styles.image, {
    [styles.imageCircled]: circleImage,
    [styles.imageFullWidth]: config(styles)[mode].imagePosition === 'center',
    [styles.imageHistoryThree]: historyThree
  })
  const fields = barChart && barChart.barChartFields
  const visible = classNames(styles.transitionEnd, barStyles.visible)
  return (
    <OnVisible
      className={styles.transitionStart}
      visibleClassName={visible}
      percent={animationOffsetPercent}
    >
      <div className={wrapperClasses}>
        <div className={styles.column}>
          <div className={imageClass || imageClasses}>
            {' '}
            {image && <Img src={image.url} alt={image.title} />}{' '}
            {withCaption && (
              <span className={styles.caption}> {image.description} </span>
            )}{' '}
          </div>{' '}
        </div>{' '}
        <div className={styles.column}>
          <div className={textClasses || ''}>
            <div className={contentWidthClass || textInnerClasses}>
              <div className={titleClass}> {headline} </div>{' '}
              <div className={styles.markdown}>
                <Markdown markdownHtml={text} contentType={contentType} />{' '}
              </div>{' '}
            </div>{' '}
            <div className={barStyles.barChart}>
              <dl>
                {' '}
                {fields && fields.map((d, idx) => renderFields(d, idx))}{' '}
              </dl>{' '}
            </div>{' '}
          </div>{' '}
        </div>{' '}
      </div>{' '}
    </OnVisible>
  )
}

HalfWidthPngChart.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string,
    mode: PropTypes.oneOf(Object.keys(config))
  }).isRequired,
  circleImage: PropTypes.bool,
  withCaption: PropTypes.bool,
  textToTheRight: PropTypes.bool,
  textLonger: PropTypes.bool,
  historyOne: PropTypes.bool,
  historyTwo: PropTypes.bool,
  historyThree: PropTypes.bool,
  headlineClass: PropTypes.string,
  imageClass: PropTypes.string,
  contentWidthClass: PropTypes.string,
  animationOffsetPercent: PropTypes.number,
  reverseMobile: PropTypes.bool
}

HalfWidthPngChart.defaultProps = {
  data: {
    image: {
      url: '',
      title: ''
    },
    headline: '',
    text: '',
    mode: 'imageRightTextBottomCenter'
  },
  circleImage: false,
  withCaption: false,
  textToTheRight: false,
  textLonger: false,
  historyOne: false,
  historyTwo: false,
  historyThree: false,
  headlineClass: '',
  imageClass: '',
  contentWidthClass: '',
  animationOffsetPercent: 2,
  reverseMobile: false
}

export default HalfWidthPngChart
