import PropTypes from 'prop-types';
import React from 'react'
import { Markdown } from 'components'

import { need } from 'utils/i18n'
import styles from './ingredients.css'

const Ingredients = props => {
  if (!props.ingredients) {
    return <div />
  }
  // Only show images if every ingredient has an image
  const showImages = props.ingredients.length > 3 && props.ingredients
    .every(ingredient => ingredient.image && ingredient.image.image && ingredient.image.image.url)
  const siteText = {
    need: need()
  }
  return (
    <div className={showImages ? styles.ingredients : styles.ingredientsText}>
      <h2 className={styles.title}>{ siteText.need }</h2>
      {showImages && (() => {
        // split in to blocks of 4
        // TODO: do this processing in transform layer?
        let ingredientChunks = []
        props.ingredients.forEach((ingredient) => {
          // put in to first chunk that has less than 4 items
          const isTall = ingredient.image.image.height / ingredient.image.image.width > 1.25
          const chunkToAddTo = ingredientChunks.find(chunk => chunk.length < (isTall ? 3 : 4))
            || ingredientChunks[ingredientChunks.push([]) - 1]
          chunkToAddTo.push({ ...ingredient,
            isTall
          })
          if (isTall) chunkToAddTo.push(null)
        })
        // clean up - make sure large images are either first or 3rd item
        //  => i.e. nulls are 2/4
        //  => i.e. 0 indexed [2] should not be null
        ingredientChunks = ingredientChunks.map((ingredientChunk) => {
          if (ingredientChunk[2] === null) {
            return [
              ingredientChunk[1],
              ingredientChunk[2],
              ingredientChunk[0],
              ingredientChunk[3]
            ]
          }
          return ingredientChunk
        })
        return (<div className={styles.ingredientList}>
          {ingredientChunks.map((ingredientChunk, chunkIndex) => {
            let className = styles.ingredientGroup
            if (ingredientChunk[0].isTall && ingredientChunk.length < 3) {
              className = styles.singleTallImageGroup
            } else if (!ingredientChunk[0].isTall && ingredientChunk.length === 2) {
              className = styles.twoSquareImages
            }
            // using array index for chunk as no other distinguishable key
            // eslint-disable-next-line react/no-array-index-key
            return (<ul className={className} key={chunkIndex}>
              {ingredientChunk.map((ingredient) => {
                let ingredientClassName = styles.ingredient
                if (!ingredient) {
                  return null
                } else if (ingredient.isTall) {
                  ingredientClassName = styles.tallIngredient
                }
                return (<li className={ingredientClassName} key={ingredient.id}>
                  <div className={styles.ingredientLabel}>
                    <Markdown markdownHtml={ingredient.title} theme="none" />
                  </div>
                  <img
                    className={styles.ingredientImage}
                    src={ingredient.image.image.url}
                    alt={ingredient.image.image.title}
                  />
                </li>)
              }
          )}</ul>)
          })}
        </div>)
      })()
      }
      {!showImages &&
        <ul className={styles.textIngredientList}>
          {props.ingredients.map(ingredient => (
            <li className={styles.textIngredient} key={ingredient.id}>
              <Markdown markdownHtml={ingredient.title} theme="none" />
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

Ingredients.propTypes = {
  ingredients: PropTypes.array.isRequired
};

export default Ingredients
