import basePage from 'hoc/basepage/basepage'
import { Contact } from 'components/pages'

export default basePage(Contact, {
  contentNodeName: 'contactPage',
  endPointName: 'formEndpoint',
  complaintFormEndpointName: 'complaintFormEndpoint',
  salesforceSyncEndpointName: 'salesforceSyncEndpoint',
  trackContentCategory: 'Contact Page',
  trackDefaultPageName: 'Contact',
  trackAlwaysUseDefaultPageName: true
})
