/* global window */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Image, Markdown, OnVisible } from '../'

import styles from './productMystery.css'
class ProductMystery extends Component {
  constructor(...rest) {
    super(...rest)
    this.state = {
      height: null,
      selectedType: null
    }
  }
  componentDidMount() {
    // @TODO make this less horrible
    this.setState({
      height: window.innerHeight * (this.props.restrict / 100)
    })
  }
  setSelected(type) {
    this.setState({
      selectedType: type
    })
  }

  renderButtons(items) {
    const css = {
      active: styles.active
    }
    const cx = classNames.bind(css)
    return items.map((t, idx) => {
      const btnClasses =
        t.title === this.state.selectedType ? styles.active : styles.button
      const classes = cx(styles.flavour, {
        active: t.title === this.state.selectedType
      })
      return (
        <a className={classes} key={idx} onClick={this.props.trackMystery}>
          <div
            className={styles.flavourContainer}
            onClick={this.setSelected.bind(this, t.title, idx)}
          >
            <Image src={t.url} sizes="100vw" alt={t.title} />
            <button key={idx} className={btnClasses} role="button">
              {t.title}
            </button>
          </div>
        </a>
      )
    })
  }

  renderContent = (content) => {
    if (content.text && content.images) {
      return (
        <div key={content.text}>
          <div className={styles.productMysteryText}>
            <Markdown markdownHtml={content.text} />
          </div>
          <div className={styles.productMysterySelection}>
            <div className={styles.productMysteryType}>
              {this.renderButtons(content.images)}
            </div>
          </div>
        </div>
      )
    } else if (
      this.state.selectedType &&
      content.key === this.state.selectedType
    ) {
      return (
        <div className={styles.redBg} key="ingredients">
          <div className={styles.redBgContainer}>
            <ul>{this.renderIngredients(content.images)}</ul>
          </div>
        </div>
      )
    }
    return null
  }

  renderIngredients(items) {
    const { selectedType } = this.state
    return items.map((t, idx) => (
      <li
        className={styles.mysteryIngredients}
        key={`${selectedType}-${idx}`}
        style={{ transitionDelay: `${idx * 500}ms` }}
      >
        <div className={styles.ingredientContainer}>
          <Image
            src={t.url}
            sizes="33vw"
            key={t.id}
            alt={t.title}
            objectFit={false}
          />
          <h4 className={styles.ingredient}>{t.title}</h4>
          <p>{t.description}</p>
        </div>
      </li>
    ))
  }
  render() {
    const hasHeight = this.state.height
    const style = {}
    if (hasHeight) {
      style.height = this.state.height
    }
    const mystery = this.props.mystery
    return (
      <OnVisible
        className={styles.productMysteryContainer}
        visibleClassName={styles.visible}
        percent={-10}
      >
        {mystery && mystery.map(this.renderContent)}
      </OnVisible>
    )
  }
}

ProductMystery.propTypes = {
  mystery: PropTypes.array.isRequired,
  restrict: PropTypes.number,
  trackMystery: PropTypes.func
}
ProductMystery.defaultProps = {
  restrict: 100,
  trackMystery: () => {}
}

export default ProductMystery
