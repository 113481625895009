import React, { useRef } from 'react'
import styles from './galleryswiper.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper/core'
import classNames from 'classnames'
import { Image } from 'components'
import { useInView } from 'react-intersection-observer'
SwiperCore.use([Pagination, Navigation])

const GallerySwiper = (props) => {
  const swiperRef = useRef()
  const { ref } = useInView({
    triggerOnce: false,
    rootMargin: '0px',
    onChange: (inView) => {
      if (inView) {
        swiperRef.current.swiper.update()
      }
    }
  })
  const {
    data: {
      title = '',
      images = [],
      galleryImages = [],
      titleType,
      hideOrangeBanner
    } = {}
  } = props
  return (
    <div className={styles.container}>
      {title && title.trim() && (<h3
        className={classNames(styles.title, {
          [styles[
            `title${titleType}${hideOrangeBanner ? 'NoStrikeThrough' : ''}`
            ]]: titleType
        })}
      >
        {title}
      </h3>)}
      <div className={styles.swiperWrapper} ref={ref}>
        {images && (
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 'auto',
                pagination: { type: 'bullets', clickable: true }
              }
            }}
            className={styles.swiperContainer}
            pagination={{ type: 'fraction' }}
            navigation={true}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image key={image.id} src={image.url} alt={image.title} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
    </div>
  )
}
export default GallerySwiper
