import React from 'react'
import PropTypes from 'prop-types'

import { Generic, HeroComponent, Filter, Shotcounter, MetaData } from 'components'
import { DrinksGrid, DownloadButton, Recommendations } from 'containers'
import { downloadAllRecipe } from '../../../utils/i18n'

const Drinks = (
  {
    content,
    slug,
  },
) => {
  const drinksList = content
  if (!drinksList) {
    return <div data-error="No drinks list" />
  }
  const siteText = {
    downloadAllRecipe: downloadAllRecipe()
  }
  return (
    <main  role="main" id="mainContent">
      <MetaData metadata={drinksList.metadata} />
         { drinksList.hero && drinksList.hero.contentType === 'heroComponent' ? <HeroComponent hero={drinksList.hero}>
          { drinksList.recipesDownload && <DownloadButton
            trackingEventType={siteText.downloadAllRecipe}
            trackingEventDetail="Recipe Download"
            href={(drinksList.recipesDownload && drinksList.recipesDownload.url) || '#'}
          >{ siteText.downloadAllRecipe }</DownloadButton>}
        </HeroComponent> : <Generic components={drinksList.hero} key={drinksList.hero.id} />
        }
      <Filter groups={drinksList.filterTagGroups} filterKey={`drinks-${slug}`} />
      <DrinksGrid listItems={drinksList.listItems} filterKey={`drinks-${slug}`} />
      {drinksList.shotCounter && <Shotcounter data={drinksList.shotCounter} />}
      <Recommendations recommendations={drinksList.recommendations} />
    </main>
  )
}

Drinks.propTypes = {
  content: PropTypes.object.isRequired,
  slug: PropTypes.string
}

Drinks.defaultProps = {
  slug: 'default'
}

export default Drinks
